@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 마이페이지 > 관리자메뉴 > 예비창업자
*/

.p__admin-business .c__card.prospective {
	.card-top {
		background: {
			image: url("../../../../../img/bg-Uncertified-pre.png");
			repeat: no-repeat;
			position: 92% bottom;
		}
	}

	.content {
		padding-top: 100px;

		> dt {
			color: $primary-color;
		}
	}
}
