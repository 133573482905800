@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 투자 - 상단 검색 카드
*/

.search-card {
	padding: 30px 40px;
	margin-bottom: 30px;

	.f__group {
		margin-bottom: 20px;
	}

	//심사단계
	.status-chk-wrap {
		.f__label {
			line-height: 17px;
		}

		.f__control {
			display: flex;

			&.has-error .checkmark {
				border-color: $error-color;
			}
		}

		.checkbox-custom {
			margin-right: 20px;
		}

		.checkbox-label {
			font-size: 0.875rem;
			margin-bottom: 0;
		}
	}

	//검색항목
	.search-list-wrap .f__control-wrap {
		display: flex;

		.btn-search {
			width: 80px;
			height: 35px;
			border-radius: 2px;
			margin-left: 6px;
		}
	}

	.warning-phrase {
		color: #333;
		font-weight: 300;
		letter-spacing: -0.56px;
		border-top: 1px solid $border-color;
		padding-top: 20px;
	}
}
