@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * IR - 제품기술시장
*/

/*---공통---*/
.product-view,
.product-edit {
	padding: 60px 60px 33px;

	section:not(:last-child) {
		padding-bottom: 13px;
	}

	.section-title {
		padding-bottom: 17px;
	}

	.sub-section {
		&:nth-child(2) .f__label {
			border-bottom: none !important;
		}

		&:not(:nth-child(2)) .f__group {
			width: 100%;
			margin: 0 0 27px 0 !important;
		}
	}
}

/*---VIEW---*/
.product-view {
	.sub-section:not(:nth-child(2)) .f__control {
		min-height: 49px;
		border-bottom: 1px solid $border-color;
		align-items: center;
		padding: 7px 10px;
	}

	.f__control li {
		font-weight: 300;
	}

	.kind-select {
		min-width: 35px;
		margin-right: 80px;

		@media screen and (max-width: 1280px) {
			margin-right: calc(20px + (80 - 20) * ((100vw - 768px) / (1024 - 768)));
		}
	}

	.content {
		flex: 1;
		line-height: 1.3;
		word-break: break-word;
	}

	.name {
		margin-right: 30px;

		@media screen and (max-width: 1024px) {
			margin-right: calc(15px + (30 - 15) * ((100vw - 768px) / (1024 - 768)));
		}
	}

	.number {
		width: 30%;
		margin-left: 10px;
		word-break: break-word;
		text-align: right;
	}

	.customer-ratio {
		width: 60px;
		margin-left: 40px;
	}

	.ipr-date {
		margin-left: 40px;
		text-align: right;
	}

	.number,
	.customer-ratio {
		font-size: 1.25rem;
		font-weight: 600 !important;

		@media screen and (max-width: 1024px) {
			font-size: calc(16px + (20 - 16) * ((100vw - 768px) / (1024 - 768)));
			margin-left: calc(30px + (40 - 30) * ((100vw - 768px) / (1024 - 768)));
		}
	}
}

/*---EDIT---*/
.product-edit {
	.dynamicFields .f__control {
		@include customScroll(scroll-x);
	}

	.ipr-number {
		text-align: center;
	}

	.btn-round-delete svg {
		padding: 10px 10px 10px 5px;
	}

	.number .c__input,
	.customer-ratio .c__input {
		text-align: right;
	}

	.apply-number .c__input {
		text-align: center;
	}

	// 간격
	.kind-select .f__control,
	.ipr-date .f__control,
	.name .f__control {
		width: 162px;
	}

	.number .f__control,
	.apply-number .f__control {
		width: 200px;
	}

	.customer-ratio .f__control {
		width: 100px;
	}

	.content {
		width: 100%;
		min-width: 300px;
	}
}
