@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * 커뮤니티 > 등록하기 공통 (edit)
**/

.community-register-card {
	max-width: 1000px;
	margin: 0 auto;
	padding: 40px;

	.c__input.f__item {
		margin-bottom: 8px;
	}
	.f__label {
		min-width: 100px;
		line-height: 30px;
		display: block;
	}
	.c__textarea {
		height: 300px;

		&:not(:disabled):not([readonly])::placeholder {
			color: #999;
			font-weight: 300;
		}
	}
	.has-error {
		position: relative;
		.quill__feedback {
			display: block;
			width: 100%;
			color: #f44;
			font-size: calc(1em - 2px);
			height: calc((1em + 2px) * 1.5);
			padding-top: 0.2em;
			padding-left: 1.5px;
		}
		.quill {
			border: 1px solid $error-color;
		}
	}
	.community-img,
	.community-fileUpload {
		margin-top: 8px;
		padding: 8px 0;
		border-top: 1px solid #e4e4e4;
		border-bottom: 1px solid #e4e4e4;
	}
	.switch-group {
		display: flex;
		align-items: center;
		height: 50px;
		border-bottom: 1px solid #e4e4e4;

		div {
			display: flex;
			align-items: center;
		}

		.switch-label {
			min-width: 100px;
			padding-left: 16px;
		}
	}
	.fileUpload-btn {
		margin-bottom: 0;
	}
}

/*--- 커뮤니티 방 등록 리스트카드 ---*/
.Community-ListForm {
	.f__label {
		padding-left: 16px;
		margin-right: 20px !important;
	}
	.switch-label {
		margin-right: 20px;
	}
}

/*--- 커뮤니티 게시판 글, 공지사항 글 등록 리스트카드 ---*/
.Community-PostForm {
	.f__label {
		padding-left: 16px;
		margin-right: 8px !important;
	}
	.switch-label {
		margin-right: 8px;
	}

	//quill
	.quill {
		transition: 0.3s ease-in-out;
	}

	.ql-container {
		height: 400px;
	}

	strong {
		font-weight: bold !important;
	}
}
