@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

//팀원 정보 모달 > 메모 탭 상세
.teamInfo-memo.detail {
	.month-group {
		padding: 10px 0;
	}

	.month-title {
		padding-bottom: 11px;
		font-weight: 500;
		color: #333;
		border-bottom: 1px solid #f2f2f2;
	}

	.memo {
		display: inline-flex;
		align-items: center;
		height: 50px;
		width: 100%;
		padding: 0 5px;
		border-bottom: 1px solid #f2f2f2;
	}

	.date {
		font-size: 0.6875rem;
		font-weight: 300;
		color: #ccc;
		margin-right: 24px;
	}

	.content {
		font-size: 0.75rem;
		font-weight: 300;
		color: #868686;
	}
}

.memo-list-view {
	.title {
		margin-bottom: 31px;
	}

	.year-section {
		margin-bottom: 20px;
	}

	.year {
		padding-bottom: 11px;
		font-weight: 500;
		color: #333333;
		border-bottom: 1px solid #f2f2f2;
	}

	.f__group {
		height: 41px;
		border-bottom: 1px solid #f2f2f2;

		.date {
			color: #ccc;
			font-size: 0.6875rem;
			font-weight: 300;
		}

		.memo {
			color: #868686;
			font-size: 0.75rem;
			border-bottom: none;
		}
	}
}
