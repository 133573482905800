@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 투자 - 온라인심사 리스트
*/

//검색결과 없을 때
.p__invest-review .c__noData {
  height: 100%;

  svg {
    fill: #999;
    width: 100px;
    height: 100px;
    margin-bottom: 40px;
  }

  p {
    color: #666;
    line-height: 1;
  }

  p:first-of-type {
    font-size: 1.25rem;
    margin-bottom: 14px;
  }

  p:last-of-type {
    font-size: 0.875rem;
  }
}

//----- 상단 검색 카드
.p__invest-review {
  @import "./common/search";
}

/*---초기화면 페이지---*/
.p__invest-review .evaluate-landing-card {
  width: 1000px;
  display: flex;
  overflow: hidden;

  //----- 왼쪽
  .explain-section {
    width: 563px;
    height: 540px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  //----- 오른쪽
  .content-section {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .landing-title {
    color: $primary-color;
    font-size: 2.0625rem;
    font-weight: 200;
    line-height: 1.83;
    letter-spacing: -0.075em;
  }

  .landing-text {
    color: #333;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: -0.05em;
    border-left: 2px solid $primary-color;
    padding-left: 14px;
    margin: 19px 0 48px;
    white-space: pre-wrap;
  }

  // 퀵팁메뉴
  .quick-menu-title {
    color: #333;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: -0.05em;
  }

  .quick-tip-menu {
    border: solid 1px #e8e8e8;
    box-shadow: 0 4px 0 0 rgba(1, 1, 1, 0.04);
    margin: 12px 0 28px;
  }

  .menu-check-wrap {
    width: 360px;
    height: 57px;
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }

    .chk-mark {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1rem;
      letter-spacing: -0.05em;
      margin-right: 14px;
      background-color: #a8a8a8;

      &::before {
        content: "";
        width: 14px;
        height: 11px;
        background-image: url("../../../img/chkMark-icon.png");
        background-repeat: no-repeat;
      }

      &.complete {
        background-color: $primary-color;
      }
    }

    .menu-check {
      color: #333;
      font-size: 1rem;
      font-weight: 200;
      letter-spacing: 0.05em;
    }

    .link-caption {
      color: #333;
      font-size: 0.825rem;
      letter-spacing: -0.05em;
      transition: color 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        color: $primary-color;
      }

      &:before {
        content: "";
        width: 8px;
        height: 9px;
        background-image: url("../../../img/doubleArrow-icon.png");
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 4px;
      }
    }
  }

  .text-desc {
    display: flex;
    align-items: center;

    i {
      color: $primary-color;
      font-size: 1rem;
      font-weight: 300;
      font-style: normal;
      letter-spacing: -0.05em;
      margin-right: 11px;
    }

    p {
      color: #666;
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.5;
      letter-spacing: -0.05em;
      white-space: pre-wrap;
    }
  }
}

.p__invest-review {
  .l__pageHeader {
    position: relative;
  }

  /*---대기 페이지---*/
  //----- 대기카드
  .waiting-card {
    width: 345px;
    height: 372px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .no-img {
      width: 345px;
      height: 220px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-content {
      height: 152px;
      color: #666;
      letter-spacing: -0.025em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /*---리스트 페이지---*/
  .evaluate-list-wrap .c__card {
    height: 248px;
    padding: 23px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s;

    &:hover {
      border-color: $primary-color;
    }

    .top {
      display: flex;
      align-items: center;
      margin: 4px 0 15px;

      .img-wrap {
        width: 60px;
        height: 60px;
        border: 1px solid $border-color;
        background: #d7d7d7;
        border-radius: 50%;
        margin-right: 16px;
        flex-shrink: 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .name {
        color: #666;
        font-size: 0.75rem;
        line-height: 1;
        letter-spacing: -0.025em;
        margin-bottom: 9px;
      }

      .ceo {
        color: #333;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.025em;
      }
    }

    .content {
      color: #333;
      font-weight: 300;
      line-height: 1.43;
      letter-spacing: -0.025em;
      margin-bottom: 7px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .date {
      color: #666;
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 0.025em;
      margin-bottom: 20px;
    }
  }

  //----- hover 결과보기
  .evaluate-list-wrap {
    .result-check {
      display: none
    }

    .reject,
    .complete {
      border-color: transparent;
      position: relative;

      .result-check {
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 2.25rem;
        font-weight: 100;
        letter-spacing: -0.05em;
        text-align: center;
        border-radius: 13px;
        background: #000;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: 0.3s;
      }

      &:hover {
        border-color: transparent;

        .result-check {
          opacity: 0.7;
        }
      }
    }
  }

  //----- 심사진행 과정
  // 공통
  .custom-step {
    height: 40px;
    display: flex;
    justify-content: space-between;

    li {
      color: #cecece;
      line-height: 1.42;
      letter-spacing: -0.05em;
      flex: 1;
      display: flex;
      align-items: flex-end;
      position: relative;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background: #eaeaf0;
        border-radius: 50%;
        position: absolute;
        top: 0;
      }

      &:after {
        content: "";
        width: 100%;
        height: 3px;
        background: #eaeaf0;
        margin-top: 3px;
        position: absolute;
        top: 0;
      }
    }

    .step-waiting {
      color: $primary-color;

      &:before {
        width: 13px;
        height: 10px;
        border-radius: 0;
        margin-left: 0.5em;
        background: {
          image: url("../../../img/chkMark-blue-icon.png");
          color: transparent;
          size: cover;
          position: top;
          repeat: no-repeat;
        }
      }

      &:after {
        display: none;
      }
    }

    .step-evaluate,
    .step-reject {
      justify-content: center;

      &:after {
        left: calc(-50% - 1em);
      }
    }

    .step-complete {
      justify-content: flex-end;

      &:before {
        right: 0.5em;
      }

      &:after {
        right: calc(50% - 1em);
      }
    }
  }

  // 진행까지 상태, 완료까지 상태
  .confirm .step-evaluate,
  .complete .step-evaluate,
  .complete .step-complete {
    color: $primary-color;

    &:before,
    &:after {
      background: $primary-color;
    }
  }

  // 반려상태
  .reject .step-reject {
    color: $error-color;

    &:before {
      width: 18px;
      height: 16px;
      border-radius: 0;
      top: -5px;
      background: {
        image: url("../../../img/icon-step-rejection.png");
        color: transparent;
        size: cover;
        position: top;
        repeat: no-repeat;
      }
    }

    &:after {
      background: $error-color;
    }
  }
}
