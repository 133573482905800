@charset "UTF-8";
@import "src/assets/sass/utils/var";

//서명 등록 약관
.modal-signatureUpload .signature-policy {
	padding: 30px;

	.checkbox {
		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&.check-all {
			padding-bottom: 20px;
			border-bottom: 1px solid $border-color;
		}

		.checkbox-custom {
			align-items: flex-start;
		}

		input,
		.checkmark {
			margin-top: 2.5px;
			min-width: 18px;
			min-height: 18px;
		}

		.checkbox-label {
			min-width: initial;
			line-height: 1.25;

			b {
				display: inline-block;
				margin-left: 5px;
				font-size: inherit;
				font-weight: inherit;
				color: $primary-color;
			}
		}
	}
}

//서명그리기 & 이미지 올리기 공통
.modal-signatureUpload .modal-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;

	> div {
		flex: 1;
	}

	.modal-footer {
		width: calc(100% + 60px);
	}
}

//이미지 올리기
.modal-signatureUpload .signature-file,
.signature-draw {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 10px 30px 0;

	.single-uploader {
		margin: 10px 0 25px;
	}

	.upload-preview-actions {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		button {
			color: #d7d7d7;
		}
	}

	.notice {
		text-align: center;
		font-size: 0.875rem;
		font-weight: 300;
		line-height: 2;
		letter-spacing: -0.7px;
		color: #666;
		margin-bottom: 32px;
	}
}

/*---서명등록하기 모달---*/
.modal-signatureUpload {
	.signature-file,
	.signature-draw {
		height: 100%;
		padding: 10px 30px 0;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.modal-footer {
		margin-top: auto;
	}
}

//서명 그리기
.modal-signatureUpload .signature-draw {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 10px 30px 0;

	> button {
		margin-left: auto;
		font-size: 0.875rem;
		font-weight: 300;
		letter-spacing: -0.7px;
		padding: 10px 0;
		color: #7d7d7d;

		svg {
			fill: currentColor;
			margin-right: 5px;
			margin-top: 1.5px;
		}
	}

	.signature-pad {
		border-radius: 2px;
		background-color: #fafafa !important;
		border: 1px solid #d7d7d7;
	}
}
