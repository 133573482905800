@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * 기업 > 주요인력 > 팀 정보 불러오기 모달
  * 팀원 > 팀원정보 > 구성원 추가
**/

.getTeamInfo-modal .modal-container {
	height: calc(100vh - 202px);

	@media screen and (max-height: 807px) {
		min-height: calc(100vh - 92px);
	}
}

.getTeamInfo-modal .modal-content {
	height: calc(100vh - 374px);
	padding: 30px 20px;

	@media screen and (max-height: 807px) {
		height: calc(100vh - 265px);
	}
}

.getTeamInfo-modal .table-top {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 10px;

	.mem-total {
		color: #999;
		font-weight: 300;
	}
	.mem-status {
		span {
			display: inline-block;
			height: 30px;
			line-height: 30px;
			padding: 0 16px;
			border-radius: 15px;
			background: #f4f4f4;
			color: #595959;
			cursor: pointer;
			transition: 0.3s;

			&.active,
			&:hover:not(:disabled) {
				background: #e0eef9;
				color: #195ea9;
			}

			&:not(:first-child) {
				margin-left: 4px;
			}
		}
	}
}

.getTeamInfo-modal .teamInfo-table {
	.tr:hover {
		background: unset;
		cursor: unset;

		> .td {
			color: #333;
		}
	}

	.c__noData {
		height: 100%;
	}

	.mem-check {
		width: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 14px;
		padding-right: 4px;
	}

	.mem-name {
		width: 148px;
		padding: 0;
		.img-wrap img {
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
		}
	}

	.mem-group,
	.mem-position {
		flex: 1;
		text-align: center;
	}

	// 이메일
	.mem-email {
		flex: 1.5;
	}

	//  상태
	.mem-status {
		min-width: 50px;
		width: 130px;
		text-align: center;

		// 활성된 상태
		&.active {
			color: $primary-color;
		}
	}
	// 재직자 퇴직자 구분 상태
	.mem-state {
		min-width: 50px;
		width: 100px;
		text-align: center;
	}

	.th {
		&.mem-check .checkmark {
			background: #fff;
		}

		&.mem-check .checkbox-custom input:checked ~ .checkmark {
			background: $primary-color;
		}

		&.mem-name {
			text-align: left;
			padding-left: 24px;
		}

		&.mem-position {
			padding-right: 16px;
		}

		&.mem-status {
			margin-right: 10px;
		}
		&.mem-state {
			margin-right: 10px;
		}
	}

	.tbody {
		@include customScroll(scroll-y);
		height: calc(100vh - 171px - 54px - 75px - 202px);

		@media screen and (max-height: 807px) {
			height: calc(100vh - 171px - 54px - 75px - 94px);
		}

		// 프로필이미지 & 이름
		.mem-name {
			display: flex;
			align-items: center;

			.img-wrap {
				width: 32px;
				height: 32px;
				margin-right: 12px;
				border-radius: 50%;
				overflow: hidden;
				flex-shrink: 0;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}
		}
		// 재직자 & 퇴직자 상태
		.mem-state {
			span {
				border-radius: 10px;
				padding: 2px 8px;
				font-size: 12px;
			}
			// 재직자
			.work {
				background: #f8f8f8;
				color: #999;
			}
			// 퇴직자
			.quit {
				background: #fdd;
				color: #f44;
			}
		}
	}
}
