@charset "UTF-8";
@import "src/assets/sass/utils/var";

//팀원 정보 모달 > 경력정보 탭
.teamInfo-career.detail {
	.career-history {
		margin-top: 24px;

		&:has(.c__noData){
			margin-top: 0;
		}
	}

	.history-row {
		width: 100%;
		display: flex;
		align-items: flex-start;
		color: #333;

		&:last-child > .history-wrap {
			border-left-color: transparent;
		}

		.date {
			width: 132px;
			color: #333;
			font-size: 0.75rem;
			font-weight: 300;
			line-height: 0.875rem;
		}

		.history-wrap {
			flex: 1;
			position: relative;
			display: flex;
			border-left: 2px solid #e8e8e8;

			&::before {
				content: "";
				width: 8px;
				height: 8px;
				display: inline-block;
				border: 2px solid #0D9488;
				background-color: #fff;
				border-radius: 50%;
				position: absolute;
				left: -7px;
			}

			.name {
				width: 193px;
				font-weight: 300;
				padding-left: 35px;
			}

			.content {
				flex: 1;
				font-weight: 300;
				line-height: 1.5rem;
				margin-top: -0.35rem;
				padding-bottom: 30px;
				max-width: 335px;
			}
		}
	}
}
