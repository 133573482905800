@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";
/***
  * form
*/

.c__form {
	//가로구조
	&.horiz .f__group {
		align-items: flex-start;

		.f__label {
			margin-right: 10px;
			min-width: 100px;
			padding-top: 10px;
			line-height: 1.2;
		}

		.f__control-wrap {
			flex: 1;
		}
	}

	//세로 구조
	&.vertical .f__group {
		flex-direction: column;

		.f__label {
			line-height: 1;
			padding: 5px 0;
		}
	}

	&.data-view .f__control {
		display: inline-flex;
		align-items: center;
		height: 100%;
	}
}

.f__group {
	display: flex;

	&:focus-within {
		position: relative;
		z-index: 9;
	}

	.f__control {
		flex: 1;
		position: relative;
		z-index: 1;

		.f__item {
			font-size: inherit;
		}

		.input-icon {
			position: absolute;
			right: 11px;
			height: 100%;

			img {
				width: 24px;
				height: 24px;
			}
		}
	}

	.unit {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		color: #999;
	}

	//필수항목 *표시 기본값: 뒤쪽
	&.required {
		@include markPosition(back);
	}
}

.f__group-row-wrap {
	display: flex;
	gap: 12px;
	align-items: flex-start;
}

//form 라벨
.f__label {
	color: #333;
	display: inline-flex;
	align-items: center;
	height: auto;
	flex-shrink: 0;
}

//description 텍스트
.f__description {
	color: #999;
	font-size: calc(1em - 2px);
	height: calc((1em + 2px) * 1.5);
	padding-top: 0.2em;
	padding-left: 1.5px;
}

//validation 텍스트
.f__feedback {
	display: block;
	width: 100%;
	color: $error-color;
	font-size: calc(1em - 2px);
	height: calc((1em + 2px) * 1.5);
	padding-top: 0.2em;
	position: absolute;
	padding-left: 1.5px;
}

//validation 효과
.f__control.has-error {
	position: relative;

	.unit {
		top: 25%;
		transform: translateY(-25%);
	}

	.c__datePicker,
	.f__item:not(:disabled):not([readonly]) {
		border-color: $error-color;

		&:focus,
		&:active,
		&.open {
			border-color: $error-color;
			box-shadow: 0 0 0 2px rgba($error-color, 0.2);
		}
	}

	.f__feedback {
		position: relative;
	}
}

.f__item:not(.c__textarea) {
	height: 35px;
	//min-height: 35px;
}

//===========================================FixME
//중간에 ~ 와 같은 모양 있는 input group
.f__item-row {
	display: flex;
	align-items: center;

	.mark {
		margin: 0 3px;
	}
}

//dynamic input group일 때
//f__group 사이 간격 10px / 에러 있을 때 간격 1.5em + 10px
.f__group-row {
	content: "해당스타일 수정됨/확인필요";
	display: flex;

	.f__group {
		margin-bottom: 5px;
	}

	.f__control {
		&:not(.has-error) {
			margin-bottom: 5px;
		}

		&.has-error {
			padding-bottom: 5px;
		}
	}

	.f__feedback {
		margin-top: 5px;
	}
}
