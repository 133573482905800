@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 팀원 > 설정 공통 스타일
*/

// 헤더
.p__hr-setting {
	@include headerWithTab;
}

// 카드 내부
.p__hr-setting,
.p__vacation {
	//  카드 제목
	.title {
		color: #333;
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 35px;

		svg {
			fill: $primary-color;
			cursor: pointer;
		}

		// 툴팁
		.c__tooltip-btn {
			margin-left: 8px;
			position: relative;
		}

		.c__tooltip {
			line-height: 17px;
			position: absolute;
			top: -6px;
			left: 26px;
			transform: unset;

			&:after {
				top: 50%;
				right: 100%;
				margin-top: -5px;
				border-color: transparent #c0e9ff transparent transparent;
			}

			&:before {
				top: 50%;
				right: 99%;
				margin-top: -5px;
				border-color: transparent #eef9ff transparent transparent;
				z-index: 1;
			}
		}
	}

	// 카드 내부 switch radio
	.payday-edit-wrap .c__radio,
	.vacation-card .vacation-setting-section .c__radio {
		margin: 0;
		width: calc(100% / 2);
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		&:first-child .checkmark {
			border-right: none;
			border-radius: 4px 0 0 4px;

			&:after {
				border-radius: 4px 0 0 4px;
			}
		}

		&:last-child .checkmark {
			border-left: none;
			border-radius: 0 4px 4px 0;

			&:after {
				border-radius: 0 4px 4px 0;
			}
		}

		.checkmark {
			width: 100%;
			height: 100%;
			border: 1px solid $primary-color;
			border-radius: unset;
			margin: 0;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			&:after {
				width: inherit;
				height: inherit;
				border: 1px solid $primary-color;
				border-radius: inherit;
			}
		}

		input:checked ~ .radio-label {
			color: #fff;
		}

		.radio-label {
			z-index: 1;
		}
	}
}


.mb-160 {
	margin-bottom: 8.333vw; // 160
}
