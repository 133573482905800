@charset "UTF-8";
@import "../../../sass/utils/var";
@import "../../../sass/utils/mixin";

/***
  * 세무 > 대시보드
*/

// EmptyPage
.p__tax-dashboard {
	position: relative;

	.divider {
		z-index: 1;
	}

	.bg-img-wrap {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.page-wrap {
		margin: 0 auto 53px;
		z-index: 9;

		section {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		// 상단
		.tax-logo-wrap {
			margin-top: 50px;
		}

		.phrase-wrap {
			text-align: center;
			margin: 32px 0;

			h3 {
				font-weight: 700;
				font-size: 2rem;
				line-height: 46px;
				color: #333;
				margin-bottom: 24px;
			}

			p {
				font-size: 1rem;
				line-height: 26px;
				color: #333;
				white-space: pre;
			}
		}

		.btn-wrap {
			margin-bottom: 68px;

			button {
				width: 186px;
				height: 47px;
				letter-spacing: -0.06em;

				&:first-child {
					margin-right: 10px;
				}
			}
		}

		.img-wrap {
			width: 1200px;
			height: 663px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}

/*---세무 페이지---*/
.p__tax-dashboard {
	.c__card .no-content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 100px 0;

		.title,
		.desc {
			margin-bottom: 20px;
			letter-spacing: -0.025em;
		}

		.title {
			font-size: 1.5rem;
			font-weight: 500;
			color: #58afff;
		}

		.desc {
			font-size: 1rem;
			color: #333;
		}

		.btn__outline {
			height: 35px;
			padding: 0 15px;
			font-size: 0.75rem;
		}
	}

	.c__card .card-content {
		width: 100%;
		height: calc(100% - 57px);
	}

	/*---받을돈 나갈돈 카드---*/
	.profit-card {
		.content-wrap {
			display: flex;
			justify-content: stretch;

			> div {
				width: 50%;
				padding-top: 15px;
			}
		}

		// 바 차트
		.bar-chart-wrap {
			height: 100%;
			padding: 0 20px;
			display: flex;
			flex-direction: column;

			.label {
				color: #000;
				font-weight: 700;
				margin-bottom: 15px;
			}

			.bar-chart {
				margin-bottom: 40px;
				margin-top: auto;
				height: 100%;

				.amount-num {
					color: #000;
					font-size: 1rem;
					font-weight: 700;
					margin-bottom: 10px;
				}
			}
		}

		.bar-chart-wrap .bar-chart-data > div {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:first-child {
				margin-bottom: 7px;
			}

			dt {
				font-size: 0.75rem;
				color: #b9b9b9;

				&:before {
					content: "";
					display: inline-block;
					width: 8px;
					height: 8px;
					-webkit-border-radius: 50px;
					-moz-border-radius: 50px;
					border-radius: 50px;
					margin-right: 5px;
					background: #b9b9b9;
				}
			}

			dd {
				font-weight: 700;
				color: #666;
			}
		}

		// 리스트 테이블
		.profit-list-wrap {
			width: calc(100% - 10px);

			.list-title {
				font-weight: 700;
				color: #333;
				margin-bottom: 10px;
			}

			.scroll-wrap {
				@include customScroll(scroll-y);
				max-height: 490px;
			}
		}

		.profit-list-wrap .profit-list {
			height: 100%;
			display: block;
			padding: 5px 0;

			> div {
				display: flex;
				border-bottom: 1px solid #e5e5e5;

				&:first-child {
					border-top: 1px solid #e5e5e5;
				}
			}

			dt,
			dd {
				display: inline-flex;
				width: 50%;
				height: 42px;
				border-right: 1px solid #e5e5e5;
				padding: 0 11px;
				flex-direction: column;
				flex-shrink: 0;
				justify-content: center;
			}

			dt {
				border-left: 1px solid #e5e5e5;

				.company {
					@include ellipsis;
					width: 89px;
					font-size: 0.75rem;
					color: #333;
					margin-bottom: 4px;
				}

				.business-num {
					font-size: 0.6875rem;
					color: #b9b9b9;
				}
			}

			dd {
				text-align: right;
				font-weight: 700;
				color: #58afff;
			}
		}

		// 총매출
		.plus-money .bar-chart-data .data dt,
		.plus-money .bar-chart-data .data dd {
			color: #1890ff;

			&:before {
				background: #1890ff;
			}
		}

		.plus-money .profit-list-wrap {
			margin-right: 10px;

			.profit-list dd {
				color: #1890ff;
			}
		}

		// 총비용
		.minus-money .bar-chart-data .data dt,
		.minus-money .bar-chart-data .data dd {
			color: #fe6567;

			&:before {
				background: #fe6567;
			}
		}

		.minus-money .profit-list-wrap {
			margin-left: 10px;

			.profit-list dd {
				color: #fe6567;
			}
		}
	}

	/*---계좌 카드---*/
	.account-card .total-account {
		padding: 10px 0 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.label {
			color: #333;
			font-weight: 700;
			padding-left: 2px;
		}

		.amount-wrap {
			display: inline-flex;
			align-items: center;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1.25;
			color: #000;

			span {
				color: #999;
				font-size: 0.75rem;
				margin-right: 15px;
				line-height: 20px;
			}

			.unit {
				font-size: 0.75rem;
				font-style: normal;
				margin-left: 3px;
			}
		}
	}

	.account-card .card-account-list {
		@include customScroll(scroll-y);
		width: 100%;
		height: 200px;

		> div {
			width: 100%;
			padding: 2px 0;
			display: flex;
			flex-direction: row;
			align-items: center;

			p {
				font-weight: 300;
				margin: 2px 5px 2px 0;
			}

			> .account-name {
				width: 100px;
			}

			> .price {
				width: 145px;
				text-align: right;

				.price-number {
					font-size: 1rem;
					word-break: break-word;
				}
			}
		}

		.account-info {
			width: 230px;
			display: flex;
			flex-direction: column;

			.account-name {
				@include ellipsis;
				width: 230px;
			}
		}
	}

	.account-card .bar-chart-wrap:not(:last-child) {
		margin-bottom: 26px;
	}

	.account-card .bar-chart-wrap .title {
		color: #000;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 6px;

		span {
			font-weight: 300;
		}
	}

	.account-card .bar-chart-wrap .row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.bar-chart {
			width: 100%;
			padding: 16px 20px 11px 20px;
			background: #f1f9fc;

			@media screen and (max-width: 1440px) {
				width: 50%;
			}

			.bar {
				width: 290px;

				@media screen and (max-width: 1440px) {
					width: 100%;
				}
			}
		}
	}

	/*---차트 카드---*/
	.sales-card .chart-area,
	.costs-card .chart-area {
		width: 100%;
		height: calc(100% - 53px);

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	/*---세금 카드---*/
	.tax-card {
		color: #fff;
		background: {
			color: #013668;
			image: url("../../../img/nts-logo.png");
			position: 112% 150%;
			size: 265px;
			repeat: no-repeat;
		}

		// CARD-HEAD
		.card-head {
			border-bottom: 1px solid #025182;
		}

		.extra-condition .btn__outline {
			color: #58afff;
			border-color: #025182;

			svg {
				fill: rgba(255, 255, 255, 0.4);
			}

			&:hover {
				color: #fff;
				border-color: #fff;

				svg {
					fill: #fff;
				}
			}
		}

		// CARD-CONTENT
		.card-content .total-wrap {
			height: auto;
			align-items: flex-start;

			div {
				text-align: left;
			}

			&:last-child {
				border-bottom: none;
			}

			&:not(:last-child) {
				margin-bottom: 20px;
				border-bottom: 3px solid #025182;
				border-image-slice: 1;
				border-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, #025182 0);
				border-image-width: 0 100% 3px 100%;
			}
		}

		.card-content .total-wrap .title {
			font-size: 1.875rem;
			font-weight: 300;
		}

		.card-content .total-wrap .date {
			color: #fff;
			padding-left: 5px;
			margin: 12px 0;
			display: block;
		}

		.card-content .total-wrap .amount {
			display: block;
			font-size: 1.96875rem;
			font-weight: 800;
			color: #58afff;
			margin-bottom: 10px;
			padding-left: 2px;
		}

		.card-content .total-wrap .unit {
			font-size: 0.96875rem !important;
			font-weight: 400 !important;
			color: inherit;
		}

		.card-content .total-wrap .d-day {
			font-size: 2.5rem;
			font-weight: 300;
			color: hsla(0, 0%, 100%, 0.4);
		}
	}
}

/*---내 회사 목록 모달---*/
.company-list-modal .modal-content {
	@include customScroll(scroll-y);
	height: 250px;
	padding: 30px 40px;
}

.company-list-modal .list-table .tr {
	display: flex;
	height: 100%;
	min-height: 40px;
	border-bottom: 1px solid $border-color;
	border-left: 1px solid $border-color;
	transition: 0.3s;
	cursor: pointer;

	.th,
	.td {
		padding: 7px 10px;
		font-size: 0.75rem;
		display: inline-flex;
		align-items: center;
		border-right: 1px solid $border-color;
	}

	.th {
		color: #666;
		justify-content: center;
		border-top: 1px solid $border-color;
	}

	.td {
		color: #333;

		&.balance,
		&.deposit,
		&.cost {
			justify-content: flex-end;
		}
	}
}

// hover시 배경
.company-list-modal .list-table .tbody {
	position: relative;

	.tr:hover {
		background-color: #f8f8f8;
	}

	.tr.active {
		background-color: #f8f8f8;
	}
}

.company-list-modal .list-table {
	// 회사명
	.company {
		width: 199px;
		//padding: 0 9px;
	}

	// 사업자등록번호
	.number {
		width: 105px;
		justify-content: center;
	}

	// 대표자명
	.name {
		width: 97px;
		justify-content: center;
	}

	// 통합잔고, 입금, 비용
	.balance,
	.deposit,
	.cost {
		width: 101px;
		padding: 0 15px;
	}
}
