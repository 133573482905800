@charset "UTF-8";

//FixME: 분할할지말지 수정 필요

.yearly-history {
	min-width: 400px;
	max-width: 426px;
	margin: 0 15px 30px;
	padding: 0 15px;
}

.year {
	font-size: 1.125rem;
	font-weight: 500;
	letter-spacing: -0.025em;
	color: #333;
	line-height: 1.45;
	margin-bottom: 9px;
}

ol {
	margin-left: 5px;
}

.form-data-group {
	padding-bottom: 25px;
	font-weight: 300;
	line-height: 1;
	position: relative;
	padding-left: 17px;
	border-left: 2px solid #e8e8e8;
	margin-left: 5px;
	display: flex;
	overflow-wrap: anywhere;

	&:before {
		content: "";
		width: 12px;
		height: 12px;
		display: inline-block;
		border: 2px solid $primary-color;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		left: -6.2px;
		box-sizing: border-box;
	}

	&:last-of-type {
		border-color: transparent;
		padding-bottom: 40px;
	}
}

.history-date {
	min-width: 60px;
	font-weight: 300;
	margin-right: 12px;
}

.history-content {
	margin-top: -0.2em;

	li {
		margin-bottom: 4px;
		line-height: 1.42;

		div {
			font-weight: 300;
		}
	}
}
