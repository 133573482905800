@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/pages/common/modalGetTeam"; //팀정보불러오기 모달

/**
  * IR - 주요인력
*/

/*---공통---*/
.member-view,
.member-edit {
	// 카드 상단 프로필이미지
	.member-img {
		width: 160px;
		height: 160px;
		margin: 0 auto 30px;
	}
}

/*---주요인력 view---*/
.member-view {
	.c__card {
		height: auto;
		padding: 30px 40px;
	}

	// 카드 상단 프로필이미지
	.member-img {
		overflow: hidden;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	// 카드 하단 내용
	.card-content {
		display: flex;
		flex-flow: column;
	}

	.member-name {
		order: 2;
		font-size: 1.25rem;
		line-height: 1.45;
		letter-spacing: -0.025em;
		margin-bottom: 10px;
	}

	.member-position {
		order: 1;

		span {
			color: #666;
			font-size: 0.8125rem;
			font-weight: 300;
			line-height: 1.42;

			&:first-child {
				margin-right: 3px;
			}
		}
	}

	.member-career {
		@include customScroll(scroll-y);
		order: 3;
		height: 60px;
		font-weight: 300;
		line-height: 1.43;
		white-space: pre-wrap;
		line-break: anywhere;
	}
}

/*---주요인력 edit---*/
.member-edit-head {
	margin: -10px 0 14px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.dashed-btn {
		color: #666;
		border-color: #666;
		margin-top: 0;
	}
}

.member-edit {
	.c__card {
		height: auto;
		min-height: 416px;
		padding: 30px;
		margin-bottom: 30px;
		border: 1px solid transparent;
		position: relative;
		flex-shrink: 0;
		transition: 0.3s;
		cursor: pointer;

		&:hover {
			border: 1px solid $primary-color;
		}
	}

	// 카드 상단 프로필이미지
	.member-img {
		margin-bottom: 20px;
	}

	// 하단
	.data-form {
		.f__control.has-error {
			margin-bottom: 0;
		}
		.f__feedback {
			display: none;
		}
		.group-wrap .f__control-wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.f__control {
				&:first-child {
					margin-right: 2.5px;
				}

				&:last-child {
					margin-left: 2.5px;
				}
			}
		}

		.f__group:not(:last-child) {
			padding-bottom: 10px;
		}

		// textarea
		.staff-info-textarea .c__textarea {
			height: auto;
			min-height: 86px;
			padding: 9px 12px;
			background-color: #fafafa;
			@include customScroll(scroll-y);
		}
	}

	// 추가 카드
	.member-add-card {
		svg {
			fill: #9c9c9c;
			width: 60px;
			height: 60px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: 0.3s;
		}

		&:hover svg {
			fill: $primary-color;
		}
	}
}
