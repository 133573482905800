@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * IR - 성과 및 계획
*/

/*---공통---*/
.outcome-view,
.outcome-edit {
	padding: 60px;

	// 섹션
	section:first-child {
		padding-bottom: 15px;

		.section-title {
			padding-bottom: 27px;
		}
	}

	// 주요성과
	.sub-section .f__label {
		border-bottom: 2px solid #333 !important;
	}

	// 주요계획
	.outcome-plan-table {
		border-top: 2px solid #333;
		margin-top: 13px;

		.tr {
			min-height: 49px;
			border-bottom: 1px solid #e8e8e8;
			display: flex;
		}

		.th,
		.td {
			width: 50%;
			padding: 7px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.th {
			font-weight: 500;
		}
	}
}

/*---VIEW---*/
.outcome-view {
	// 주요성과
	.sub-section {
		.f__control {
			min-height: 49px;
			padding: 7px 10px;
			border-bottom: 1px solid $border-color;
			align-items: center;
		}

		.content {
			//252/660
			width: 38.18%;
			min-width: 38.18%;
			padding-right: 5px;
		}

		.date {
			//162/660
			width: 24.54%;
			min-width: 24.54%;
			text-align: right;
		}

		.content,
		.value,
		.date {
			font-weight: 300;
			word-break: break-word;
		}

		.value {
			width: 37.27%;
			min-width: 37.27%;
			text-align: center;
			padding-right: calc(12px + 6.96%);

			&:not(.award-value) {
				font-size: 1.25rem;
				font-weight: 600;

				@media screen and (max-width: 1200px) {
					font-size: calc(16px + (20 - 16) * ((100vw - 768px) / (1200 - 768)));
				}
			}
		}
	}

	// 주요계획
	.outcome-plan-table {
		.td {
			word-break: break-word;
		}

		.td:not(.plan-indicator) {
			font-weight: 300;
		}

		.plan-indicator {
			font-weight: 500;
			text-align: center;
		}
	}
}

/*---EDIT---*/
.outcome-edit {
	// 반응형
	@media screen and (max-width: 1340px) {
		.sub-section {
			flex-direction: column;

			> .f__group {
				width: 100%;

				&:first-child {
					margin-right: 0;
				}

				&:last-child {
					margin-left: 0;
				}
			}
		}
	}

	// 주요성과
	.sub-section {
		.content {
			min-width: 162px;
			flex: 1;
		}

		.result,
		.date {
			min-width: 120px;
		}

		.btn-round-delete {
			padding: 10px 10px 10px 5px;
		}
	}

	// 주요계획
	.outcome-plan .section-title {
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		.notice {
			font-weight: 300;
			letter-spacing: -0.025em;
		}
	}

	.scroll-wrap {
		@include customScroll(scroll-x);
	}

	.outcome-plan-table {
		min-width: 1020px;

		.th,
		.td {
			width: 19.4%;

			&:first-child {
				width: 20%;
				padding-left: 10px;
			}

			&:last-child {
				width: 15px;
				padding: 8px;
				flex: 1;
			}
		}

		.th {
			padding: 2.5px;
		}

		.td {
			padding: 7px 2.5px;

			&:not(.plan-indicator) input {
				text-align: right;
			}
		}

		.plan-indicator input {
			background: #f8f8f8;
		}
	}

	.dashed-btn {
		margin-bottom: 0;
	}
}
