@charset "UTF-8";
@import "src/assets/sass/utils/var";

/*---초기화면, 리스트 상단 설명글---*/
.p__shareholder-list {
	@import "./common";
}

/***
  * 주주 > 주주명부 > 리스트
*/

/*---주주명부(리스트)---*/
.p__shareholder-list {
	.shareholder-list-card {
		height: 263px;
		position: relative;
		background-color: #999;
		color: #fff;
		cursor: pointer;

		&:first-child {
			background-color: $primary-color;
		}
	}

	.shareholder-card-wrap {
		width: 100%;
		height: 100%;
		padding: 40px 20px 18px;

		.card-title {
			font-size: 1.625rem;
			font-weight: 200;
			line-height: 1.2;
			letter-spacing: -0.05em;
			word-break: break-word;
		}

		.card-status {
			width: calc(100% - 40px);
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: absolute;
			bottom: 18px;

			.date {
				font-size: 0.75rem;
				font-weight: 300;
				line-height: 1.5;
				letter-spacing: -0.025em;
			}

			svg {
				fill: #fff;
			}
		}
	}

	// 드롭다운 메뉴
	.c__dropdown {
		top: 41px;
		right: 4px;
		border-radius: 10px;
		padding-bottom: 10px;

		.c__dropdownItem {
			padding: 8px 5px 8px 5px;
		}
	}

	.btn-dropdown.vertical {
		height: auto;
		position: relative;
		top: 14px;
		right: 0;

		> span {
			width: 4px;
			height: 4px;
			margin-bottom: 3px;
			background: #fff;
		}
	}
}

/*---드롭다운 메뉴 클릭시 모달---*/
.password-form .modal-container {
	min-height: fit-content !important;
}

.password-form .modal-content {
	padding: 60px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(0, 0, 0, 0.65);

	.f__group {
		position: relative;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		// 자물쇠 이미지
		&:after {
			content: "";
			width: 10px;
			height: 35px;
			display: inline-block;
			position: absolute;
			right: 10px;
			background-image: url("../../../img/ico-lock.png");
			background-repeat: no-repeat;
			background-position: 100%;
			z-index: 1;
		}
	}
}

// 주주명부 복제 alertType svg 제거
.shareholder-copy-modal svg {
	display: none;
}
