@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 문의 탭
*/

.rounding-tabs-wrap .inquiry-tab {
	width: 880px;
	height: auto;
	margin: 40px auto;

	@media screen and (max-width: 1200px) {
		width: 88%;
	}
	@media screen and (max-width: 1024px) {
		width: 100%;
	}

	.qna-register-header {
		max-width: 100%;
		padding-bottom: 10px;
		border-bottom: 1px solid $border-color;

		h5 {
			color: $primary-color;
			font-size: 1.125rem;
			font-weight: normal;
			letter-spacing: -0.05em;
			margin-right: 10px;
			display: inline-block;
		}

		.qna-register-number {
			color: #999;
			font-weight: 300;
			letter-spacing: -0.05em;

			i {
				color: $primary-color;
				font-style: normal;
			}
		}
	}

	.qna-register-content {
		// 글 등록
		.qna-upload {
			max-width: 100%;
			margin: 21px 0 16px 0;
			display: flex;

			.qna-upload-box {
				width: 100%;
				display: flex;
				flex-wrap: nowrap;
				position: relative;

				.f__input {
					width: 100%;
					height: 40px;
					color: #999;
					font-size: 1rem;
					letter-spacing: -0.05em;
					background: #f8f8f8;
					padding-left: 21px;
					border-radius: 50px;

					@media screen and (max-width: 1023px) {
						font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (767 - 320)));
					}
				}

				button {
					width: 59px;
					height: 31px;
					position: absolute;
					top: 5px;
					right: 5px;
				}
			}
		}

		// 프로필 사진
		.user-picture,
		.admin-picture {
			width: 40px;
			min-width: 40px;
			height: 40px;
			min-height: 40px;
			margin-right: 6px;
			border-radius: 50%;

			& > img {
				width: 100%;
				min-width: 100%;
				height: 100%;
				min-height: 100%;
				border-radius: 50%;
				object-fit: contain;
			}
		}

		.admin-picture {
			border: 1px solid #e8e8e8;
		}

		// 작성된 글
		.register-question,
		.register-answer {
			width: 100%;
			padding: 0 10px 19px 19px;
			border: 1px solid #e8e8e8;
			border-radius: 8px;
			position: relative;

			// 드롭다운메뉴
			.c__dropdown-wrap {
				right: 0;
				top: 10px;

				.c__dropdown {
					right: 10px;
				}
			}
		}

		.user-box {
			display: flex;
			margin-bottom: 15px;

			.register-question-header {
				display: flex;
				justify-content: space-between;

				.left-box {
					display: flex;
				}

				.right-box {
					display: flex;
					margin-top: 10px;
				}
			}

			.register-user-name {
				color: #333;
				font-size: 1.125rem;
				font-weight: normal;
				line-height: 1;
				letter-spacing: -0.075em;
				padding: 20px 8px 0 0;
			}

			.answer-complete,
			.answer-ready {
				font-weight: 300;
				line-height: 1;
				letter-spacing: -0.05em;
				padding-top: 22px;
			}

			.answer-complete {
				color: $primary-color;
			}

			.answer-ready {
				color: #999;
			}

			.qna-register-date {
				color: #999;
				font-weight: 300;
				letter-spacing: -0.025em;
				padding-right: 30px;
				display: inline-block;
			}

			.user-question-content {
				color: #666;
				font-size: 1rem;
				font-weight: 300;
				line-height: 1.5;
				letter-spacing: -0.025em;
				margin-top: 10px;
			}
		}

		.admin-box {
			display: flex;
			padding-left: 45px;
			margin-bottom: 15px;

			.register-answer {
				background-color: #f8f8f8;
				border: 1px solid #f8f8f8;
			}

			.admin-answer-header {
				display: flex;
				justify-content: space-between;
			}

			.register-admin {
				color: #333;
				font-size: 1.125rem;
				font-weight: normal;
				line-height: 1;
				letter-spacing: -0.075em;
				padding: 20px 8px 0 0;
			}

			.admin-answer-content {
				color: #666;
				font-size: 1rem;
				font-weight: 300;
				line-height: 1.5;
				letter-spacing: -0.025em;
				margin-top: 10px;
			}
		}

		.read-more-btn {
			color: #666;
			font-weight: 300;
			letter-spacing: -0.05em;
			line-height: 1.71;
			margin-top: 9px;
		}
	}
}
