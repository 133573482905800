@charset "UTF-8";
@import "../../../sass/utils/var";

/***
  * 세무 > 설정
*/

//----- 카드 공통
.p__tax-setting .c__card {
	max-width: 1022px;
	min-width: 588px;
	height: 464px;
	display: flex;
	overflow: hidden;

	@media screen and (max-width: 1200px) {
		height: 360px;
	}
}

//----- 카드 내부 왼쪽
.p__tax-setting .content-wrap {
	width: 482px;
	background-color: #dee9f8;
	padding: 40px;
	position: relative;

	@media screen and (max-width: 1200px) {
		min-width: 330px;
		padding: 30px 20px;
	}

	.content {
		&-title {
			font-weight: 500;
			font-size: 1.75rem;
			line-height: 30px;
			letter-spacing: -0.06em;
			margin-bottom: 16px;

			@media screen and (max-width: 1200px) {
				font-size: calc(18px + (28 - 18) * ((100vw - 768px) / (1200 - 768)));
				margin-bottom: 12px;
			}
		}

		&-phrase {
			font-weight: 300;
			line-height: 20px;
			letter-spacing: -0.06em;

			@media screen and (max-width: 1200px) {
				font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
				line-height: 18px;
			}
		}
	}

	// 배경이미지
	.img-wrap {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		@media screen and (max-width: 1200px) {
			width: 300px !important;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

//----- 카드 내부 오른쪽
.p__tax-setting .login-wrap {
	min-width: 258px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.logo-img {
		margin-bottom: 40px;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.btn-connect {
		height: 50px;
		font-size: 1rem;

		@media screen and (max-width: 1200px) {
			height: 35px;
			font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1200 - 768)));
		}
	}
}

.p__tax-setting .c__card {
	// 연동전
	&.before-connect {
		.content-wrap .img-wrap {
			width: 445px;
			bottom: -3px;
		}

		.login-wrap {
			padding: 0 80px;

			@media screen and (max-width: 1200px) {
				padding: 0 30px;
			}

			.logo-img {
				width: 180px;
				height: 60px;

				@media screen and (max-width: 1200px) {
					width: 120px;
					height: 40px;
				}
			}

			// input
			.tax-login-form .f__group:first-child {
				margin-bottom: 10px;
			}

			.tax-login-form .c__input {
				height: 45px;

				@media screen and (max-width: 1200px) {
					min-width: 200px;
					height: 35px;
				}
			}

			.register-btn-wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 20px 0 30px;
				white-space: nowrap;

				@media screen and (max-width: 1200px) {
					flex-direction: column;
					align-items: flex-end;
					margin: 6px 0 20px;
				}

				> div {
					font-weight: 300;
					letter-spacing: -0.06em;
					color: #333;

					@media screen and (max-width: 1200px) {
						font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
					}

					button {
						font-size: inherit;
						font-weight: inherit;
					}

					.btn-pw {
						transition: 0.2s;

						&:hover {
							color: $primary-color;
						}

						@media screen and (max-width: 1200px) {
							margin-bottom: 8px;
						}
					}

					.btn-register {
						color: $primary-color;
						margin-left: 3px;
					}
				}
			}
		}
	}

	// 연동후
	&.after-connect {
		.content-wrap .img-wrap {
			width: 430px;
			bottom: -16px;
		}

		.login-wrap {
			align-items: center;

			.logo-img {
				width: 210px;
				height: 70px;

				@media screen and (max-width: 1200px) {
					width: 138px;
					height: 46px;
					margin-bottom: 36px;
				}
			}

			.main-desc {
				font-size: 1.5rem;
				line-height: 22px;
				letter-spacing: -0.06em;
				margin-bottom: 24px;

				@media screen and (max-width: 1200px) {
					font-size: calc(14px + (24 - 14) * ((100vw - 768px) / (1200 - 768)));
				}
			}

			.sub-desc {
				color: #333;
				font-size: 1rem;
				font-weight: 300;
				line-height: 24px;
				letter-spacing: -0.06em;
				text-align: center;

				@media screen and (max-width: 1200px) {
					font-size: calc(12px + (16 - 12) * ((100vw - 768px) / (1200 - 768)));
					line-height: 1.3;
				}
			}

			.btn-connect {
				width: 147px;
				margin-top: 70px;

				@media screen and (max-width: 1200px) {
					width: 89px;
					margin-top: 37px;
				}
			}
		}
	}
}
