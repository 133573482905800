@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

.c__fileUploader {
	display: flex;
	align-items: center;

	input[type="file"] {
		position: absolute;
		width: inherit;
		height: inherit;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		cursor: pointer;
		z-index: 1;

		&::-webkit-file-upload-button {
			cursor: pointer;
		}
	}

	.file-label {
		width: 110px;
		height: 35px;
		margin-left: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		border: 1px solid #0D9488;
		color: #0D9488;
		font-weight: 500;
		transition: 0.3s ease-in-out;
		position: relative;

		&:hover {
			background: $primary-color;
			color: #fff;
		}

		svg {
			width: 11px;
			height: 11px;
			margin-right: 7px;
			fill: currentColor;
		}
	}

	.file-preview {
		width: 284px;
		height: 35px;
		background: #f8f8f8;
		border: 1px solid #e4e4e4;
		padding-left: 8px;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		> svg {
			margin-top: 3px;
		}
	}

	.file-name {
		@include ellipsis;

		flex: 1;
		padding-top: 2px;
		padding-left: 5px;
	}

	.btn-delete {
		width: 24px;
		height: 35px;
		color: #666;

		> svg {
			fill: currentColor;
		}

		&:hover {
			cursor: pointer;
			color: $primary-color;
		}
	}
}

// 파일제출 상태 badge
.c__file-upload-form {
	.status-badge {
		width: fit-content;
		height: 24px;
		margin-left: 6px;
		padding: 2px 4px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-items: center;
	}

	.f__control span,
	.f__control a {
		color: #666;
		font-weight: 300;
		line-height: 20px;
	}

	.f__group.complete .f__control {
		margin-bottom: 10px;

		a:hover {
			color: $primary-color;
		}
	}

	// 파일 제출상태 badge 색상
	@each $status, $color, $backgroundColor in (complete, #349c5e, rgba(0, 200, 81, 0.1)),
		(later, #999, #e8e8e8), (no-submit, #f44, rgba(255, 68, 68, 0.1))
	{
		.f__group.#{$status} .status-badge {
			color: $color;
			background: $backgroundColor;
		}
	}
}

// 파일업로드 delete 버전 (커뮤니티 추가 컴포넌트)
.c__fileUploaderAdd {
	.fileUpload-btn {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 6px;

		input[type="file"] {
			position: absolute;
			width: inherit;
			height: inherit;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			cursor: pointer;
			z-index: 1;

			&::-webkit-file-upload-button {
				cursor: pointer;
			}
		}

		.file-label {
			width: 86px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 2px;
			border: 1px solid #0D9488;
			color: #0D9488;
			font-weight: 500;
			transition: 0.3s ease-in-out;
			position: relative;

			&:hover {
				background: $primary-color;
				color: #fff;
			}

			svg {
				width: 11px;
				height: 11px;
				margin-right: 7px;
				fill: currentColor;
			}
		}

		.file-text {
			font-weight: 300;
			font-size: 0.875em;
			line-height: 14px;
			letter-spacing: -0.06em;
			color: #7d7d7d;
		}
	}

	.fileUpload-add {
		display: flex;
		align-items: center;

		&:not(:first-child) {
			margin-top: 6px;
		}

		.file-preview {
			width: calc(100% - 32px);
			height: 35px;
			border: 1px solid #e4e4e4;
			padding-left: 8px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-right: 6px;
			> svg {
				margin-top: 3px;
			}
		}

		.file-name {
			@include ellipsis;

			flex: 1;
			padding-top: 2px;
			padding-left: 5px;
		}

		.btn-round-delete {
			svg {
				width: 20px;
				height: 20px;
				fill: #bbb;
				transition: 0.3s ease-in-out;

				&:hover {
					cursor: pointer;
					fill: $primary-color;
				}
			}
		}
	}
}
