@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * input
*/

.c__input {
	display: inline-flex;
	align-items: center;
	width: 100%;
	height: 35px;
	padding: 0 11px 1px;
	font-size: 0.875rem;
	color: #333;
	line-height: 1.5;
	background: #fff;
	border: 1px solid $border-color;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	transition: 0.3s ease-in-out;

	//---기본
	&:not(:disabled):not(.disabled):not([readonly]):not(.readonly) {
		&:hover {
			border-color: $primary-color;
		}

		&:focus,
		&:active {
			border-color: $primary-color;
			box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
		}

		&::placeholder {
			font-weight: 300;
			color: #999;
		}
	}

	//---disabled
	&:disabled,
	&.disabled {
		color: #d7d7d7;
		background: #fafafa;
		border-color: #e8e8e8;
		cursor: not-allowed;

		&::placeholder {
			color: #d7d7d7;
		}
	}

	//---readonly
	&[readonly],
	&.readonly {
		background: #f8f8f8;
		color: #b2b2b2;
		cursor: default;
	}

	&[name=pw] {
		padding-right: 40px;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
	cursor: pointer; 
}

.download_input_wrapper {
	position: relative;
	width: fit-content;

	
	.download_input {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		cursor: pointer;
		pointer-events: auto; /* Ensure the input can still be clicked */
	}
}