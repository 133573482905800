@charset "UTF-8";

/***
  * Breadcrumb 컴포넌트
*/

.c__breadcrumb {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;

  > li {
    display: inline-flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 300;
    letter-spacing: -0.025em;
    line-height: 20px;
    color: #999;
    padding: 0 4px;
    margin: 0 4px;

    &:not(:first-child):before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 1em;
      position: relative;
      top: 0.5px;
      left: -8px;
      background: #ccc;
    }

    &:last-child {
      font-weight: 400;
      color: #333;
      cursor: default;
    }

    &.disabled {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }
}
