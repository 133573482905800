@charset "UTF-8";
@import "../../../sass/utils/var";
@import "../../../sass/utils/mixin";

/***
  * VC정보 > 상세페이지
*/

/*---전체 카드---*/
.p__vc-info .info-detail-card {
	display: flex;
	padding: 40px;
}

/*---왼쪽---*/
.p__vc-info .basic-info-section {
	width: 370px;
	margin-right: 40px;

	.basic-info-wrap {
		position: relative;
	}

	//----- 회사정보, 대표정보
	.company-data-top,
	.company-data {
		border-bottom: 1px solid #e8e8e8;

		//----- 회사정보
		.logo-wrap {
			width: 70px;
			min-width: 70px;
			height: 70px;
			border: 1px solid #e8e8e8;
			border-radius: 50%;
			margin-bottom: 19px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.company .company-associate {
			color: #333;
			font-family: "Roboto", sans-serif;
			padding: 0 4px 4px;
			display: block;
		}

		.company .company-name {
			color: #333;
			font-size: 1.875rem;
			font-weight: 700;
			line-height: 1.17;
			letter-spacing: -0.05em;
			margin: 0;
		}

		.company-url {
			color: #999;
			display: inline-block;
			font-size: 0.75rem;
			font-weight: 300;
			margin: 6px 0 30px 3px;
			word-break: break-word;
			cursor: pointer;
			transition: 0.2s;

			&:hover {
				color: $primary-color;
			}
		}

		//----- 대표정보
		.company-ceo {
			color: #666;
			font-size: 0.75rem;
			font-weight: 300;
			padding: 20px 0 15px;

			b {
				color: #333;
				font-size: 1.125rem;
				font-weight: 600;
				letter-spacing: 0.05em;
				margin-right: 5px;
			}
		}

		.company-tel,
		.company-mail {
			padding: 0 3px 8px;
			display: flex;

			dt {
				color: #333;
				font-weight: 700;
				line-height: 1.14;
				letter-spacing: -0.01em;
				margin-right: 10px;
			}

			dd {
				color: #666;
				font-weight: 300;
				line-height: 1.14;
				word-break: break-all;
			}
		}

		.company-address {
			color: #666;
			font-weight: 300;
			line-height: 1.29;
			letter-spacing: -0.025em;
			padding-bottom: 18px;
			word-break: keep-all;
		}
	}

	.basic-info-title {
		color: #333;
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.375;
		letter-spacing: -0.05em;
		padding-top: 17px;
	}

	//----- 기본정보
	.basic-info-content {
		padding: 10px 3px 12px;

		li {
			color: #666;
			line-height: 1.3;
			letter-spacing: -0.025em;
			padding-bottom: 6px;

			&:before {
				content: "-";
				margin-right: 4px;
			}
		}
	}

	//----- 선호투자유형
	.invest-type-chart {
		padding-bottom: 13px;
	}

	//----- apply 버튼
	.btn-apply .btn__solid {
		width: 100%;
		height: 50px;
		border-radius: 5px;
		letter-spacing: -0.025em;
		padding: 7px 10px;

		&:disabled {
			background: #d7d7d7;
			opacity: 1;
		}
	}
}

/*---오른쪽---*/
.p__vc-info .detail-info-section {
	flex: 1;
	border-left: 1px solid #e8e8e8;
	padding-left: 40px;

	@media screen and (min-width: 1900px) {
		width: calc(100% - 371px);
	}

	.detail-info-title {
		color: #333;
		font-size: 1.125rem;
		font-weight: 600;
		letter-spacing: -0.05em;
		padding: 10px 0 20px;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
	}
}

.p__vc-info .detail-info-section {
	//----- 총 투자 집행
	.invest-execution-chart {
		width: calc(100% - 149px);
		height: 316px;
		margin-bottom: 30px;
	}

	.invest-num {
		width: 112px;
		min-width: 112px;
		height: 200px;
		border: 1px solid #e8e8e8;
		margin: 46px 5px 0 32px;

		li {
			height: 50%;
			color: #333;
			text-align: center;
			letter-spacing: -0.05em;
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;

			&:first-child {
				border-bottom: 1px solid #f8f8f8;
			}
		}

		b {
			font-size: 1.75rem;
			padding-top: 11px;
		}
	}

	//----- 포트폴리오
	.portfolio-map {
		margin-bottom: 40px;

		.row {
			align-items: flex-end;
			justify-content: space-between;

			.c__select {
				width: 87px;
				max-width: 87px;
				height: 30px;
				margin-bottom: 9px;
			}
		}

		.company-rel-wrap {
			@include customScroll(scroll-y);
			width: 100%;
			min-height: 258px;
			max-height: 478px;
			background: #f8f8f8;
			display: flex;
			justify-content: center;
		}
	}

	.portfolio-map .startup-list {
		width: 100%;
		max-height: calc(100% - 21px);
		margin: 10px;
		display: flex;
		flex-wrap: wrap;

		.startup-item {
			width: calc(25% - 20px);
			min-width: 217px;
			height: 217px;
			margin: 10px;
			border-radius: 5px;
			background: #fff;
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.13%);

			.img-wrap {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 110px;
				border-bottom: 1px solid #e8e8e8;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.company-info {
			width: 100%;
			height: 107px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0;

			.notice {
				font-size: 0.75rem;
				letter-spacing: -0.05em;
				color: #666;
			}

			.company-name {
				color: $primary-color;
				font-size: 1rem;
				font-weight: 500;
				letter-spacing: -0.025em;
				margin: 7px 0;
			}
		}
	}

	//----- 라운드 공지
	.round-notice {
		margin-bottom: 20px;

		.rounding-list {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
		}

		.c__card {
			min-width: 236px;
			max-width: 316px;
			height: auto;
			margin: 0 15px 20px 0;
			border-radius: 13px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
			position: relative;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			.rounding-img,
			.complete-card {
				padding-top: calc((177 / 301) * 100%);
			}
		}

		.rounding-img {
			width: 100%;
			height: 0;
			padding-top: calc((220 / 345) * 100%);
			overflow: hidden;
			border-top-right-radius: 13px;
			border-top-left-radius: 13px;
			position: relative;

			img {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				max-width: 100%;
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: left;
			}
		}

		.rounding-container {
			width: 100%;
			height: 124px;
			padding: 18px 15px 15px 22px;
			display: flex;
			flex-flow: column;
			justify-content: space-between;

			.rounding-content {
				color: #333;
				font-size: 1.0625rem;
				font-weight: 600;
				line-height: 1.41;
				letter-spacing: -0.025em;
				word-break: keep-all;
				overflow: hidden;
			}
		}

		.rounding-container-footer {
			color: #999;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			.update {
				font-weight: 300;
				line-height: 1.5;
				padding-bottom: 6px;
			}

			.btn__solid {
				width: 87px;
				height: 34px;
			}
		}
	}

	// 지원마감 카드
	.round-notice .apply-end {
		.complete-card {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 0;
			background: rgba(0, 0, 0, 0.7);
			-webkit-border-radius: 13px 13px 0 0;
			-moz-border-radius: 13px 13px 0 0;
			border-radius: 13px 13px 0 0;
			z-index: 1;

			span {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 30px;
				font-weight: 100;
				letter-spacing: -0.05em;
			}
		}

		.rounding-content,
		.update {
			color: #ccc;
		}

		.btn__solid {
			background: #d7d7d7;

			&:hover,
			&:focus,
			&:active {
				background: #d7d7d7;
			}
		}
	}

	//----- 투자조합
	.fund-table {
		width: 100%;
		margin-bottom: 40px;
		border-top: 1px solid #666;

		ul {
			height: 60px;
			border-bottom: 1px solid #e8e8e8;
			display: flex;
			align-items: center;

			li {
				color: #666;
				font-weight: 300;
				text-align: center;
				line-height: 1.36;
				letter-spacing: -0.025em;
				padding: 0 8px;

				span {
					color: #999;
					font-size: 0.75rem;
					font-weight: 300;
					line-height: 1.42;
					letter-spacing: -0.025em;
				}
			}

			&.table-head {
				height: 44px;
				border-bottom: 1px solid $border-color;

				li {
					color: #333;
					font-weight: 600;
					letter-spacing: -0.05em;
				}
			}
		}

		.fund-num {
			width: 3.89%; //38
			min-width: 38px;
		}

		.fund-name {
			width: 39.47%; //386
		}

		.fund-size {
			width: 8.38%; //82
			min-width: 82px;
		}

		.fund-lp {
			width: 12.27%; //120
			min-width: 54px;
		}

		.fund-start-date {
			width: 9.61%; //94
			min-width: 94px;
		}

		.fund-end-date {
			width: 15.75%; //154
			min-width: 94px;
		}

		.fund-manager {
			width: 11.15%; //109
			min-width: 78px;
		}
	}

	.fund-table .table-body {
		.fund-num {
			font-size: 0.75rem;
		}

		.fund-name {
			color: #333;
			text-align: left;
			letter-spacing: -0.05em;
			word-break: keep-all;
		}

		.fund-name,
		.fund-start-date,
		.fund-end-date {
			display: flex;
			flex-flow: column;
		}
	}

	//----- 관련뉴스
	.relation-news .news-list {
		padding-bottom: 20px;

		.no-content {
			//display: flex;
			//flex-direction: column;
			svg {
				width: 1.25rem;
				height: 1.25rem;
				margin-right: 5px;
				margin-top: 2px;
				fill: #a8a8a8;
			}
		}

		li {
			width: 100%;
			min-height: 109px;
			display: flex;
			align-items: center;
			padding: 15px 0;
			border-bottom: 1px solid #e8e8e8;
			cursor: pointer;
		}

		.img-wrap {
			width: 126px;
			min-width: 126px;
			height: 80px;
			border: 1px solid $border-color;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.column {
			flex: 1;
			padding: 0 27px;

			.news-title {
				color: #333;
				font-size: 1rem;
				font-weight: 300;
				letter-spacing: -0.05em;
				word-break: keep-all;
				padding-bottom: 11px;
			}

			.news-content {
				height: 32px;
				color: #666;
				font-weight: 300;
				line-height: 1.21;
				letter-spacing: -0.025em;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				word-break: keep-all;
				overflow: hidden;
			}
		}

		.news-info {
			min-width: 130px;
			display: flex;
			align-self: flex-end;
			justify-content: center;

			span {
				color: #999;
				font-size: 0.75rem;
				font-weight: 300;
				letter-spacing: -0.025em;
			}

			.divider {
				display: inline-block;
				width: 1px;
				margin: 0 10px;
				background: #999;
			}
		}
	}
}

// 반응형
.p__vc-info {
	@media screen and (max-width: 1200px) {
		.info-detail-card {
			flex-flow: column;
			padding: 30px;
		}

		.basic-info-section {
			width: 100%;
			margin-bottom: 40px;

			.basic-info-wrap {
				display: flex;

				.company-data-top,
				.column {
					min-width: 220px;
					border-right: 1px solid #e8e8e8;
				}

				.company-data-top {
					padding: 10px 15px 0 0;
				}

				.column {
					padding: 0 15px;
				}

				.invest-type-chart {
					width: 50%;
					padding-left: 15px;
				}
			}

			.company-data-top,
			.company-data {
				border-bottom: 0;

				.company {
					@include ellipsis;

					.company-name {
						word-break: break-word;
						font-size: calc(24px + (30 - 24) * ((100vw - 1023px) / (1200 - 768)));
					}
				}

				.company-ceo {
					padding-top: 20px;
				}

				.company-address {
					padding-bottom: 0;
				}
			}

			.basic-info-title {
				padding-top: 16px;
			}

			.basic-info-content {
				padding: 10px 3px 0;

				li {
					width: 100%;
					border-right: 0;
				}
			}

			.btn-apply {
				position: absolute;
				left: 0;
				bottom: 40px;
			}
		}

		.detail-info-section {
			width: 100%;
			padding: 32px 0 0;
			border-left: 0;
			border-top: 1px solid #e8e8e8;

			.fund-table ul li {
				font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
			}
		}
	}

	@media screen and (max-width: 1078px) {
		.basic-info-section {
			.basic-info-wrap {
				flex-flow: column;

				.company-data-top,
				.column,
				.invest-type-chart {
					width: 100%;
					padding: 0;

					&:not(.invest-type-chart) {
						border-right: 0;
						border-bottom: 1px solid #e8e8e8;
					}
				}
			}

			.company-data-top,
			.company-data {
				.company {
					width: calc(100% - 240px);
					white-space: unset;
				}

				.company-url {
					width: calc(100% - 240px);
				}
			}

			.basic-info-content {
				padding-bottom: 12px;
			}

			.btn-apply {
				top: 110px;
				bottom: auto;
				left: auto;
				right: 0;

				.btn__solid {
					font-size: 12px;
					font-weight: 300;
					height: 45px;
				}
			}
		}

		.detail-info-section {
			padding-top: 20px;

			.invest-execution-chart {
				width: calc(100% - 119px);
			}

			.invest-num {
				width: 92px;
				min-width: 92px;
				height: 160px;
				margin-left: 22px;

				b {
					font-size: 24px;
				}
			}

			.portfolio-map .company-rel-wrap {
				flex-flow: column;
			}

			.round-notice .rounding-list {
				flex-wrap: wrap;
			}

			.round-notice .c__card {
				max-width: 236px;
			}
		}
	}

	@media screen and (max-width: 900px) {
		.fund-table {
			@include customScroll(scroll-x);

			ul {
				width: max-content;
			}

			.fund-num {
				width: 38px;
				min-width: 38px;
			}

			.fund-name {
				width: 206px;
				min-width: 186px;
			}

			.fund-size {
				width: 82px;
				min-width: 82px;
			}

			.fund-lp {
				width: 120px;
				min-width: 54px;
			}

			.fund-start-date {
				width: 94px;
				min-width: 94px;
			}

			.fund-end-date {
				width: 154px;
				min-width: 94px;
			}

			.fund-manager {
				width: 109px;
				min-width: 78px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.detail-info-section {
			.invest-execution-chart {
				width: calc(100% - 79px);
			}

			.invest-num {
				width: 62px;
				min-width: 62px;
				height: 120px;
				margin-left: 12px;

				li {
					font-size: 12px;
				}

				b {
					padding-top: 6px;
					font-size: 20px;
				}
			}
		}
	}
}
