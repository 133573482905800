@charset "UTF-8";
@import "src/assets/sass/utils/var";

$correctColor: #369303;

//팀원 정보 모달 > 기본정보 탭 > 개인정보 상세

//----공통
.teamInfo-personal.detail {
	@import "basicInfoCommon";

	dl .row {
		min-height: auto;
		padding: 15px 0;
		align-items: flex-start;
	}

	.img-row dd{
		flex-wrap: wrap;
		gap: 12px;
	}
}

//----급여계좌
.teamInfo-personal.detail .account {
	.verification {
		display: inline-flex;
		align-items: center;
		font-size: inherit;
		margin-left: 20px;

		svg {
			fill: currentColor;
			margin-right: 4px;
		}

		&.completed {
			color: $correctColor;
		}

		&.uncompleted {
			color: #ff8787;
		}
	}
}
