@charset "UTF-8";
@import "../../../../sass/utils/var";

/**
  * 대시보드 - 최근 알람 공통
*/

.recent-alarm {
	padding-bottom: 38px;
	flex-basis: 1;

	&-top {
		padding-bottom: 14px;
		border-bottom: 1px solid #d6d6d6;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.btn-alarm-plus svg {
		width: 21px;
		height: 21px;
		fill: #b2b2b2;
		margin-right: 6px;
	}
}

.recent-alarm .row {
	height: 41px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 13px 0 4px;

	&:not(:last-child) {
		border-bottom: 1px solid #f3f3f3;
	}

	.alarm-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #333;
		font-weight: 300;
		letter-spacing: -0.05em;
		max-width: 80%;
		.alarm-title-ellipsis {
			width: 100%;
			height: 26px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&::before {
			content: "";
			width: 3px;
			height: 3px;
			display: inline-block;
			background: #b2b2b2;
			margin-right: 8px;
		}
	}
	.alarm-date {
		color: #999;
		font-size: 0.75rem;
		font-weight: 300;
		min-width: 70px;
		text-align: right;
	}
}


//2024.05.17 added - 공통 최근 보드
.recent-board-initial {
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		color: #999;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.05em;
	}
}

.recent-board {
	padding-bottom: 38px;
	flex-basis: 1;
	&-top {
		padding-bottom: 14px;
		border-bottom: 1px solid #d6d6d6;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.recent-board .row {
	height: 41px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 13px 0 4px;

	&:not(:last-child) {
		border-bottom: 1px solid #f3f3f3;
	}

	&.notice {
		cursor: pointer;
		.board-title {
			&::before {
				display: none;
			}
		}
		.category{
			color: $primary-color;
			margin-right: 4px;
		}
	}

	.board-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #333;
		font-weight: 300;
		letter-spacing: -0.05em;
		max-width: 80%;
		.board-title-ellipsis {
			width: 100%;
			height: 26px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&::before {
			content: "";
			width: 3px;
			height: 3px;
			display: inline-block;
			background: #b2b2b2;
			margin-right: 8px;
		}
	}


	.board-date {
		color: #999;
		font-size: 0.75rem;
		font-weight: 300;
		min-width: 70px;
		text-align: right;
	}
}
