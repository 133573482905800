@charset "UTF-8";
@import "src/assets/sass/utils/var";

//팀원 정보 모달 > 프로필 상세
.teamInfo-profile.detail {
	// 프로필 이미지
	.profile-img-view {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		flex-shrink: 0;
		overflow: hidden;

		.profile-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	// 소개글
	.profile-intro-view {
		color: #989898;
		font-size: 0.75rem;
		letter-spacing: -0.025em;
		margin-bottom: 16px;
		white-space: nowrap;
		line-height: 1.5;
	}
}
