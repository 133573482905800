@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * 커뮤니티 > 게시판 리스트 공통 스타일
**/

.community-list-section,
.notice-list-section {
	padding: 40px 40px 100px;
	position: relative;

	.c__noData {
		height: 708px;
	}

	//--- 게시판 view-header
	.view-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		.view-list {
			ul {
				list-style: none;
			}
			display: flex;
			align-items: center;
		}
		.view-item:not(:last-child) {
			margin-right: 4px;

			svg {
				fill: #b2b2b2;

				&:not(:disabled):hover {
					fill: $primary-color;
					cursor: pointer;
				}
			}
			&:not(:disabled).active svg {
				fill: $primary-color;
			}
		}
		.view-item:last-child {
			svg {
				stroke: #b2b2b2;

				&:not(:disabled):hover {
					stroke: $primary-color;
					cursor: pointer;
				}
			}
			&:not(:disabled).active svg {
				stroke: $primary-color;
			}
		}

		.search-box {
			display: flex;
			justify-content: space-between;
		}
		.c__select {
			width: 120px;
			margin-right: 4px;
		}
	}

	.pagination {
		height: auto;
		position: absolute;
		left: 50%;
		bottom: 40px;
		transform: translateX(-50%);
		border-top: none;
	}

	//---- 커뮤니티 table 전체 공통 스타일
	.community-list-table,
	.community-card-table,
	.community-album-table {
		height: 100%;

		// 제목
		.title-box,
		.td.title {
			display: flex;
			align-items: center;
			color: #333;
			letter-spacing: -0.06em;

			.text {
				width: auto !important;
				@include ellipsis;
				padding-right: 3px;
			}

			.icon {
				display: flex;
				align-items: center;
				margin-left: 4px;
			}

			.clip {
				display: flex;
				align-items: center;
				margin-right: 4px;

				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
		.num {
			font-family: "Exo", sans-serif;
			font-size: 1rem;
			font-weight: 500;
			color: $primary-color;
		}
		.position {
			color: #999;
		}

		// List-view , Card-view 공통
		.th {
			font-weight: 500;
			font-size: 1rem;
			color: #333;
		}
		.td {
			font-size: 1rem;
			letter-spacing: -0.06em;
		}
		.tr .title {
			flex: 1;
			min-width: 320px;
		}
		.tr .name {
			width: 160px;
			text-align: center;
		}
		.tr .date {
			width: 160px;
			text-align: center;
		}

		// Card-view , Album-view 공통
		.title-box,
		.text-box {
			line-height: 26px;
			letter-spacing: -0.06em;
		}
		.title-box {
			margin-bottom: 8px;
		}

		.text-box {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			white-space: pre-wrap;
			overflow: hidden;
			font-weight: 300;
			font-size: 1rem;
		}
	}
	.table-image,
	.album-image {
		position: relative;

		.tag {
			font-family: "Poppins", sans-serif;
			background: rgba(27, 25, 27, 0.8);
			border-radius: 20px;
			height: 18px;
			line-height: 18px;
			padding: 0 8px;
			font-weight: 400;
			font-size: 0.75rem;
			color: #fff;
			position: absolute;
			right: 10px;
			bottom: 10px;
		}
	}

	// 커뮤니티 list table
	.community-list-table {
		min-height: 564px;

		.td.title .text {
			font-weight: 300;
			font-size: 1rem;
			letter-spacing: -0.06em;
			color: #333;
		}
		.td {
			font-size: 1rem;
		}
	}

	// 커뮤니티 card table
	.community-card-table {
		min-height: 708px;

		.td {
			height: 160px;

			@media screen and (max-width: 1200px) {
				height: 100px;
			}

			&.name,
			&.date {
				line-height: 160px;

				@media screen and (max-width: 1200px) {
					line-height: 100px;
				}
			}
		}

		.td.title {
			display: flex;
			padding: 23px 0;
		}

		.table-image {
			max-width: 200px;
			width: calc(100px + (200 - 100) * ((100vw - 1200px) / (1920 - 1200)));
			height: 120px;
			margin-right: 10px;
			flex: 1;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@media screen and (max-width: 1200px) {
				height: 80px;
			}
		}

		.table-box {
			min-width: 200px;
			margin-left: 14px;
			flex: 2;

			.title-box {
				white-space: pre-wrap;
			}
			.text {
				font-weight: 500;
				font-size: 1.125rem;
			}

			.text-box {
				height: 78px;
				-webkit-line-clamp: 3;

				@media screen and (max-width: 1200px) {
					height: 56px;
					-webkit-line-clamp: 2;
				}
			}
		}
	}

	// 커뮤니티 album table
	.community-album-table {
		width: 100%;
		min-height: 708px;
		border-top: 1px solid #d7d7d7;

		.c__cardRow {
			margin: 0 -10px;
		}

		.c__card {
			max-width: 330px;
			box-shadow: none;
			border-radius: 0;
			margin: 30px 10px 10px;

			&:hover {
				cursor: pointer;
			}
		}

		.album-image {
			width: 100%;
			height: 220px;
			margin-bottom: 8px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.text {
			font-weight: 500;
			font-size: 1.125rem;
		}

		.title-name {
			display: flex;
			align-items: center;
			margin-bottom: 8px;

			span {
				font-weight: 300;
				font-size: 1rem;
				line-height: 23px;
				letter-spacing: -0.06em;
				color: #333;
			}
			.position {
				color: #999;
			}

			.status {
				position: relative;
				margin-left: 11px;

				&::before {
					content: "";
					width: 3px;
					height: 3px;
					border-radius: 10px;
					background: #b2b2b2;
					position: absolute;
					top: 50%;
					left: -7px;
					transform: translateY(-50%);
				}
			}
		}
		.text-box {
			height: 52px;
			-webkit-line-clamp: 2;
		}
	}
}
