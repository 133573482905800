@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 마이페이지 > 관리자메뉴 > 사업자등록번호 및 기업구분 form
*/

.p__admin-business .c__card.bizNumForm {
	.card-top {
		padding-top: 60px;
		padding-right: 70px;
	}

	.content > dt {
		color: #999;
	}

	.c__form {
		padding-top: 20px;

		.f__control-wrap {
			display: flex;

			.f__item {
				height: 45px;
			}

			button {
				width: 94px;
				height: 45px;
				margin-left: 6px;
				-webkit-border-radius: 2px;
				-moz-border-radius: 2px;
				border-radius: 2px;
			}
		}
	}
}
