@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/*---기업정보(보기, 쓰기) 공통---*/
.p__company-info {
	@import "./common";
}

/***
  * 기업 > 기업정보 > 등록수정 페이지
*/
.p__company-info {

	.certification-wrap {
		border: 1px solid #D7D7D7;
		display: flex;
		gap: 24px;
		&.certified {
			.result_wrap {
				.status {
					color: #0D9488;
				}
				.description {
					color: #7D7D7D;
				}
			}
		}
		.img_wrap {
			border-right: 1px solid #D7D7D7;
			padding: 8px;
			min-width: 160px;
			width: 130px;
			min-height: 216px;
			height: 216px;
			.no-img {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				background: #F8F8F8;
				height: 100%;
				width: 100%;
				img {
					width: 100%;
				}
			}

		} // img_wrap end
		.result_wrap {
			padding: 20px 24px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			.status {
				font-size: 24px;
				font-weight: 400;
				margin-bottom: 8px;
				color: #7D7D7D;
				line-height: 36px;
			}
			.description {
				line-height: 22px;
				color: #7D7D7D;
				font-size: 14px;
				word-break: keep-all;
			}
			.biz-number {
				background: #F0F9FF;
				border-radius: 45px;
				font-size: 14px;
				color: #7D7D7D;
				min-height: 30px;
				padding: 4px 16px;
				display: flex;
				gap: 8px;
				align-items: center;
			}
		}
	} // certification end

	.signature-wrap {
		margin-top: 40px;
		display: flex;
		justify-content: flex-end;
		.signature-part {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			width: 400px;
			border-bottom: 2px solid #1B1B1B;
			padding-bottom: 12px;
			.label {
				color: #A8A8A8;
				font-size: 16px;
			}
			@media screen and (max-width: 608px) {
				.animate__animated {
					width: 350px;
				}
			}

			.register-signature-button {
				width: 158px;
				height: 40px;
				border: 1px dashed #d7d7d7;
				border-radius: 4px;
				color: #7D7D7D;
			}

		}
		.img-wrap {
			width: 236px;
			height: 122px;
			border: 1px solid #D7D7D7;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			img {
				max-height: 100%;
				//max-height: 80%;
			}
		}

	} // signature_wrap end

	.pageHeader-btn-group .btn-circle + .btn__solid {
		min-width: 122px;
		padding: 0 23px 0 22px;
	}

	.company-info-view {
		padding: 60px 60px 100px;

		@media screen and (max-width: 768px) {
			padding: 40px 30px 100px;
		}

		.top-wrap {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}

		// 공개중 태그
		.tag-open {
			letter-spacing: -0.06em;
			border-radius: 6px;
			width: 83px;
			height: 42px;
			font-size: 1.125rem;
			font-weight: 700;
			background: $primary-color;
			color: #fff;
			border: 1px solid $primary-color;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (max-width: 1200px) {
				margin-bottom: 10px;
			}

			@media screen and (max-width: 768px) {
				width: 73px;
				height: 38px;
				font-size: 1rem;
			}
		}

		//기업개요
		.company-desc .f__control {
			min-height: 100px;
			border: 1px solid #d7d7d7;
			background: #fafafa;
			padding: 20px 16px;
			letter-spacing: -0.06em;
			line-height: 1.3;
			color: #333;
		}

		//주요제품
		.info-product {
			.f__group {
				border-bottom: 1px solid #ccc;
				padding: 7px 10px;
			}

			.f__control {
				min-height: 35px;
				height: auto;
				line-height: 1.5;
				font-weight: 300;
			}
		}
	}
}

.print-alert-modal {
	.print-title-wrap {
		margin-bottom: 0 !important;
	}

	.desc-box {
		background-color: #f8f8f8 !important;
		color: #404040;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 6px;
		font-size: 14px;
		padding: 16px 24px 20px;
		margin-top: 20px;

		.desc-title {
			font-weight: 700;
		}
	}

	.checkbox {
		width: 350px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-right: 0 !important;
		margin-bottom: 24px;
		margin-top: 10px !important;

		.checkmark {
			border: 1px solid #d7d7d7;
			margin-right: 10px;
		}

		.checkbox-label {
			font-size: 14px;
			color: #404040;
		}
	}
}
