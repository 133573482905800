@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 모달
*/

//모달 마스크 포함
.c__modal {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	z-index: 1200;
	transition: 0.3s;
	display: none;
	overflow: hidden;

	&.toggleOpen {
		display: flex;
		opacity: 1;
		animation-name: fadeIn;
		animation-duration: 0.3s;
	}

	&.toggleClose {
		opacity: 0;
		animation-delay: 0.5s;
		animation-name: fadeOut;
		animation-duration: 0.3s;

		.modal-container {
			animation-name: zoomOut;
			animation-duration: 0.2s;
		}
	}

	&.show-mask {
		background: rgba(#000, 0.7);
	}
}

.modal-alert-v2 {
	.alert-icon {
		margin-bottom: 20px;
	}

	.alert-body.vertical .alert-content {
		padding: 0 28px;
		margin-bottom: 0;
		line-height: auto;
	}

	.main-desc {
		font-size: 24px;
		margin-bottom: 8px;
	}

	.sub-desc {
		font-size: 14px;
		font-weight: 400;
	}

	.alert-footer {
		border: none;
	}
}

//마스크 제외 내용 영역
.modal-container {
	display: flex;
	flex-direction: column;
	border-radius: 13px;
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
	background-color: #fff;
	position: relative;
	animation-name: zoomIn;
	animation-duration: 0.3s;
}

//헤더 - 타이틀
.modal-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	width: 100%;
	height: 70px;
	padding: 0 15px 0 30px;
	border-bottom: 1px solid $border-color;

	.modal-title {
		display: inline-block;
		font-size: 1.25rem;
		font-weight: 300;
		letter-spacing: -1px;
		color: #333;
	}
}

//헤더 - 닫기 아이콘
.modal-close {
	padding: 10px 15px;
	color: #ccc;
	transition: 0.3s ease-in-out;

	&:not(:disabled):hover,
	&:not(:disabled):active {
		color: $primary-color;
	}

	svg {
		width: 12px;
		height: 12px;
		fill: currentColor;
	}
}

.modal-body {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
}

//푸터
.modal-footer {
	text-align: center;
	width: 100%;
	margin-top: auto;
	border-top: 1px solid $border-color;

	> button {
		width: 108px;
		margin: 30px 0;

		&:first-child:not(:last-child) {
			margin-right: 10px;
		}
	}

	.btn__solid_v2 {
		min-width: 108px;
		width: auto;
	}
}

.persona-modal {
	.modal-header {
		border: none;
	}	
	.modal-footer {
		border: none;
	}
}

.c__modal_v2 {
	.modal-header {
		border: none;

		.modal-title {
			font-weight: 700;
		}
	}

	.f__control {
		.c__select, .c__input, .c__switch-radio {
			height: 40px;
			border-radius: 8px;
			font-size: 0.875rem;

			li {
				height: 100%;
			}
		}

		.c__switch-radio {
			overflow: hidden;
		}

		.c__datePicker {
			padding: 0 11px 0 28px ;
		}

		.react-datepicker__input-container::after {
			height: 40px;
		}

		.c__textarea {
			border-radius: 8px;
			padding: 10px 12px;
			font-size: 0.875rem;
		}
	}

	.modal-footer {
		border: none;

		button {
			margin: 24px 0;
		}
	}
}


//모달 애니메이션
@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(0.8);
	}
	to {
		-webkit-transform: scale(1);
	}
}

@keyframes zoomIn {
	from {
		transform: scale(0.8);
	}
	to {
		transform: scale(1);
	}
}

@keyframes zoomOut {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0.8);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeOut {
	from {
		display: flex;
	}
	to {
		display: none;
	}
}
