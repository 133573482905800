@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * IR - 리스트
*/

.p__ir-list {
	// 카드
	.ir-list-card {
		min-width: 280px;
		max-width: 345px;
		height: 263px;
		cursor: pointer;
		position: relative;
	}

	// 드롭다운 메뉴
	.c__dropdown {
		top: 41px;
		right: 4px;
		border-radius: 10px;

		.c__dropdownItem {
			padding: 8px 5px 8px 5px;
		}
	}

	.btn-dropdown.vertical {
		height: auto;
		position: relative;
		top: 14px;
		right: 0;

		> span {
			width: 4px;
			height: 4px;
			margin-bottom: 3px;
			background: #fff;
		}
	}

	// 카드 상단
	.card-head {
		height: 148px;
		background: rgb(0, 125, 243);
		display: flex;
		align-items: center;
		justify-content: center;
		border-top-left-radius: 13px;
		border-top-right-radius: 13px;
		div {
			width: calc(100% - 20px);
			display: flex;
			align-items: center;
			justify-content: center;
			h3 {
				@include ellipsis;
				width: 100%;
				height: 100%;
				color: #fff;
				font-size: 1.75rem;
				font-weight: 400;
				font-family: "Exo", sans-serif;
				text-align: center;
				letter-spacing: -0.025em;
			}
		}
	}

	// 카드 하단
	.card-container {
		height: 115px;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		padding: 10px 20px 18px;
		position: relative;
	}

	.progress-number {
		color: rgb(0, 125, 243);
		line-height: 1.5;
		text-align: right;
		margin-bottom: 10px;
	}

	.progress {
		height: 7px;
		background: #ccc;
		border-radius: 0.25rem;
		display: flex;
		overflow: hidden;

		.progress-bar {
			flex-direction: column;
			justify-content: center;
			background: rgb(0, 125, 243);
			transition: width 0.6s ease;
		}
	}

	.card-status {
		display: flex;
		justify-content: space-between;
		align-items: center;

		dt,
		dd {
			color: #999;
			font-size: 0.75rem;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.025em;
			display: inline-block;
		}

		dt {
			margin-right: 5px;
		}

		.lock-status {
			width: 24px;
			height: 24px;
			fill: rgb(0, 125, 243);
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	// 색상변경 드롭다운 메뉴
	.color-dropdown-menu {
		width: 72px;
		min-height: 40px;
		border-radius: 10px;
		border: 1px solid $border-color;
		background-color: #fff;
		padding: 10px 0;
		display: inline-block;
		position: absolute;
		right: 3px;
		top: 48px;

		.dropdown-item {
			min-width: 70px;
			height: 30px;
			transition: 0.3s;

			&:not(:last-child) {
				border-bottom: 1px solid #f0f0f0;
			}
		}
	}
}
.bgColorChangeModal {
	.modal-header {
		border-bottom: none;
		height: 60px;
		padding-left: 20px;
		padding-right: 10px;
	}

	.modal-header {
		.modal-title {
			font-weight: bold;
			color: black;
		}
	}
	.modal-content {
		padding-left: 17px;
		padding-right: 17px;

		.tdCell {
			height: auto;
			float: left;
			width: 16.6%;
			padding: 0px;
			padding-bottom: 9px;

			div.colorCell1 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #00bff3;
				cursor: pointer;
				position: relative;
			}

			div.colorCell2 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #f464ac;
				cursor: pointer;
				position: relative;
			}

			div.colorCell3 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #6a3fff;
				cursor: pointer;
				position: relative;
			}

			div.colorCell4 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #ef3567;
				cursor: pointer;
				position: relative;
			}

			div.colorCell5 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #fea83e;
				cursor: pointer;
				position: relative;
			}

			div.colorCell6 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #819800;
				cursor: pointer;
				position: relative;
			}

			div.colorCell7 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #3751e2;
				cursor: pointer;
				position: relative;
			}

			div.colorCell8 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #101d6d;
				cursor: pointer;
				position: relative;
			}

			div.colorCell9 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #c13d7f;
				cursor: pointer;
				position: relative;
			}

			div.colorCell10 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #ac0f39;
				cursor: pointer;
				position: relative;
			}

			div.colorCell11 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #cf780e;
				cursor: pointer;
				position: relative;
			}

			div.colorCell12 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #536200;
				cursor: pointer;
				position: relative;
			}

			div.colorCell13 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #62c6be;
				cursor: pointer;
				position: relative;
			}

			div.colorCell14 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #01c78a;
				cursor: pointer;
				position: relative;
			}

			div.colorCell15 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #9a9a9a;
				cursor: pointer;
				position: relative;
			}

			div.colorCell16 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #505050;
				cursor: pointer;
				position: relative;
			}

			div.colorCell17 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #a86042;
				cursor: pointer;
				position: relative;
			}

			div.colorCell18 {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin: 0px auto;
				background: #2a3100;
				cursor: pointer;
				position: relative;
			}

			div.active:after {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				background: url("/assets/img/color_check.png") no-repeat;
				background-position: center center;
				position: absolute;
				top: 0px;
				left: 0px;
			}
		}

		.tdCell:nth-child(6n) {
			width: 17%;
		}
	}

	.modal-footer {
		height: 68px;
	}

	.modal-footer button {
		margin-top: 14px;
	}
}
