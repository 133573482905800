@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

.org-delete-modal {
	@import "src/assets/sass/pages/hr/modal/common/secondDepthModal";
}

/***
  * 팀원 > 팀원정보 > 조직도 설정 > 조직 삭제 모달
*/

.org-delete-modal .modal-body {
	min-height: 55px;
	align-items: center;

	.text {
		width: fit-content;
		height: fit-content;
		position: relative;
		padding-top: 5px;

		p {
			display: inline-block;
			width: fit-content;
			height: 1em;
			line-height: 1;
			padding: 0 5px;
			font-size: 1rem;
			letter-spacing: -0.025em;
			color: #25232a;
			position: relative;
			z-index: 2;
		}

		+ .checkbox {
			padding-top: 17px;
			margin-bottom: -3px;
		}
	}

	.checkbox-custom input,
	.checkbox-custom .checkmark {
		width: 20px;
		height: 20px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		top: 1px;
	}

	.checkbox-custom .checkmark:after {
		left: 25%;
		width: 25%;
		height: 50%;
	}

	.checkbox-label {
		font-size: 0.875rem;
		color: #565360;
	}
}
