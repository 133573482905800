@charset "UTF-8";

//회원가입 등 가로, 세로 스크롤 X
.l__onePage {
	width: 100%;
	min-height: 100vh;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow-y: auto;

	@media screen and (max-width: 1060px) {
		.c__card {
			margin: 120px 0;
			height: fit-content;
		}
	}
}

.l__onePageContainer {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

//기본 레이아웃, 세로 스크롤
.l__dashboard {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.l__superAdmin {
	display: flex;
	width: 100vw;
	height: 100vh;
	overflow: hidden;

	.l__pageHeader {
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;

		.page-name {
			margin: 0;
			font-size: 1.5rem;
		}
		
		.c__breadcrumb {
			gap: 10px;

			> li {
				margin: 0;

				&:first-child {
					padding-left: 0;
				}

				&:not(:first-child):before {
					content: ">";
					width: 0;
					height: 0;
					background: none;
					left: -12px;
					top: -9px;
				}
			}
		}

		.divider {
			display: none;
		}
	}

	.pageHeader-btn-group {
		top: 28px;
	}
}

//컨텐츠 영역 + footer + sidebar
.l__mainContainer {
	display: flex;
	flex: 1;
	background: #f8f8f8;
}

//컨텐츠 영역 + footer
.l__main {
	display: flex;
	flex: 1;
	flex-direction: column;
	max-height: calc(100vh - 100px);
	overflow-x: hidden;
	overflow-y: auto;
}

//컨텐츠 영역
.l__content {
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
	padding: $contentPadding $contentPadding $contentBottomPadding $contentPadding;

	@media screen and (max-width: 768px) {
		padding: 30px 30px 27px;
	}
}

//페이지 헤더
.l__pageHeader {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 30px;

	.page-name {
		display: inline-block;
		width: fit-content;
		font-size: 1.75rem;
		letter-spacing: -0.05em;
		line-height: 1;
		color: #333;
		margin-right: 12px;
	}

	.divider {
		width: 100%;
		height: 1px;
		background: #d7d7d7;
		margin-top: 23px;
	}
}

//페이지 헤더 버튼 그룹
.pageHeader-btn-group {
	position: absolute;
	right: $contentPadding;
	top: $contentPadding;

	@media screen and (max-width: 768px) {
		right: 30px;
		top: 30px;
	}
}

//탑버튼
.top-btn-wrap {
	width: 60px;
	height: 60px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	background: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 72px;
	right: 36px;

	svg {
		fill: #424242;
	}

	&:hover {
		background: $primary-color;

		svg {
			fill: #fff;
		}
	}
}

//적응형 가로 스크롤 생성
//.l__mainContainer{
//  width: 100vw;
//  overflow: hidden;
//}
//.l__content{
//  max-width: calc(100vw - 120px - 230px);
//  overflow-x: auto;
//}