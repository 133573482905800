@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 마이페이지 > 라이센스 > 결제이력 > 리스트
*/

//---- 결제검색 form
.payment-search-card {
	display: flex;
	justify-content: space-between;
	padding: 30px;

	@media screen and (max-width: 1200px) {
		align-items: flex-end;
	}

	.payment-form {
		display: flex;
		align-items: center;

		@media screen and (max-width: 1200px) {
			display: block;
		}

		.datePicker-group {
			width: 368px;
			margin-right: 10px;

			.dash {
				margin: 0 6px;
			}
		}
		.f__label {
			width: 52px;
			font-weight: 400;
			font-size: 0.875rem;
			letter-spacing: -0.06em;
			margin-right: 20px;
		}

		// 분기 선택 switch radio
		.c__radio-wrap {
			width: 440px;

			@media screen and (max-width: 1200px) {
				width: calc(297px + (594 - 297) * ((100vw - 768px) / (1200 - 768)));
				min-width: 297px;
				margin-top: 10px;
				margin-left: 71px;
			}
		}
		.c__radio {
			margin: 0;
			width: fit-content;
			min-width: calc(100% / 6);
			height: 35px;
			padding: 0 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			white-space: nowrap;

			&:first-child .checkmark {
				border-radius: 4px 0 0 4px;
			}

			&:last-child .checkmark {
				border-radius: 0 4px 4px 0;
			}

			&:not(:last-child) .checkmark {
				border-right: none;
			}

			.checkmark {
				width: 100%;
				height: 100%;
				border: 1px solid $primary-color;
				border-radius: unset;
				margin: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				&:after {
					width: inherit;
					height: inherit;
					border: 1px solid $primary-color;
					border-radius: inherit;
				}
			}

			input:checked ~ .radio-label {
				color: #fff;
			}

			.radio-label {
				z-index: 1;
			}
		}
	}
	.btn-search {
		min-width: 80px;
		height: 35px;
		border-radius: 2px;
		margin-left: 6px;
	}
}

//---- 결제이력 게시판
.payment-list-card {
	min-height: 684px;
	position: relative;
	padding: 30px 30px 90px;

	.payment-list-table,
	.tbody,
	.c__noData {
		height: 100%;
	}

	.c__table {
		height: 100%;

		.title {
			@include ellipsis;

			flex: 1;
			min-width: 250px;
		}

		.th:not(.title),
		.td:not(.title) {
			width: 160px;
			text-align: center;
		}

		.tr > .td {
			// 결제성공
			&.complete {
				color: $primary-color;
			}
			// 결제실패
			&.fail {
				color: $error-color;
			}
		}
	}
	.pagination {
		height: auto;
		position: absolute;
		left: 50%;
		bottom: 30px;
		transform: translateX(-50%);
		border-top: none;
	}
}
