@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";
/***
  * address group
*/

.c__addressGroup {
	.postcode-wrap {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.btn__postcode {
		flex-shrink: 0;
		width: 88px;
		height: 35px;
		margin-right: 6px;
		font-weight: 400;
		letter-spacing: -0.025em;
	}
}
