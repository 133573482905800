@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

//팀원 정보 모달 > 메모 탭 수정
.teamInfo-memo.edit {
	.title-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 42px;
	}

	.btn__add {
		height: 35px;
		padding: 0 11px;
		font-size: 0.75rem;
		letter-spacing: -0.025em;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;

		svg {
			fill: currentColor;
			width: 11px;
			height: 11px;
			margin-top: 2.5px;
		}
	}

	.memo-list {
		margin-bottom: 10px;
	}

	.row {
		display: inline-flex;
		align-items: center;
		width: 100%;
		height: 50px;
		border-bottom: 1px solid #f2f2f2;

		&:first-child {
			border-top: 1px solid #f2f2f2;
		}
	}

	.date-picker {
		width: 100px;
		margin-right: 6px;
	}

	.content {
		flex: 1;
	}

	.c__input {
		font-size: 0.75rem;
		font-weight: 300;

		&::placeholder {
			color: #868686;
			font-weight: 300;
		}
	}

	.btn__close {
		width: 30px;
		height: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		color: #9a9a9a;

		svg {
			width: 8px;
			height: 8px;
			fill: currentColor;
		}

		&:not(:disabled):hover,
		&:not(:disabled):active {
			color: $primary-color;
		}
	}

	.c__noData {
		margin-top: 50px;
	}
}
