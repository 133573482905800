@charset "UTF-8";
@import "src/assets/sass/utils/var";

$correctColor: #369303;

//팀원 정보 모달 > 기본정보 탭 > 인사정보 상세

//----공통
.teamInfo-hr.detail {
	@import "basicInfoCommon";

	dl .row {
		min-height: auto;
		padding: 17.5px 0 12.5px;
	}
}

//----현재 급여
.teamInfo-hr.detail .annual-income {

	.contract {
		display: inline-flex;
		align-items: center;
		margin-left: 16px;

		svg {
			fill: currentColor;
			margin-right: 4px;
		}

		.status {
			display: inline-flex;
			align-items: center;
			font-size: 0.75rem;

			&:after {
				content: "";
				display: inline-block;
				background: #f2f2f2;
				width: 1px;
				height: 15px;
				margin: 0 10px;
			}
		}

		.btn-contract {
			width: 70px;
			height: 26px;
			border: 1px solid;
			font-size: 0.6875rem;
			letter-spacing: -0.05em;
		}

		//계약 완료일 때
		&.completed {
			color: $correctColor;

			.btn-contract {
				&:not(:hover),
				&:not(:active) {
					border-color: rgba($correctColor, 0.5);
					color: $correctColor;
				}

				&:hover,
				&:active {
					background: rgba($correctColor, 0.5);
					color: #fff;
				}
			}
		}
	}
}

//----근무상태
.teamInfo-hr.detail .working-status dd {
	flex: none;
	width: fit-content;
	font-size: 12px;
	border-radius: 4px;
	padding: 4px 6px;
	display: inline-block;
	white-space: nowrap;


	&.working {
		color: #22C55E;
		background-color: #F0FDF4;
	}

	&.absence {
		color: black;
		background-color: #F8F8F8;
	}

	&.quit {
		color: #ff5656;
	background-color: #ffdbdb;
	}
}
