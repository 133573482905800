@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * checkbox
*/

.checkbox-custom {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:not(.disabled) {
		cursor: pointer;
	}

	&:hover input ~ .checkmark {
		border-color: $persona-teal-950;
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 16px;
		width: 16px;

		@at-root .checkbox-select .ant-select-dropdown-menu-item-selected .checkmark,
			&:checked ~ .checkmark {
			border-color: $persona-teal-950;
			background-color: $persona-teal-950;

			&:after {
				content: " ";
				position: absolute;
				left: 3px;
				display: table;
				border: 2px solid #fff;
				border-top: 0;
				border-left: 0;
				-webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
				transform: rotate(45deg) scale(1) translate(-50%, -50%);
				opacity: 1;
				-webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
				transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
			}
		}
	}

	.checkmark {
		position: relative;
		display: inline-block;
		width: 16px;
		min-width: 16px;
		height: 16px;
		min-height: 16px;
		flex-shrink: 0;
		background-color: #fff;
		border: 1px solid $border-color;
		border-radius: 4px;
		border-collapse: separate;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		margin-right: 10px;
		box-sizing: content-box;

		&:after {
			content: " ";
			position: absolute;
			top: 45%;
			left: 30%;
			display: table;
			width: 34%;
			height: 56%;
			border: 2px solid #fff;
			border-top: 0;
			border-left: 0;
			-webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
			transform: rotate(45deg) scale(0) translate(-50%, -50%);
			opacity: 0;
			-webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
			transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
		}
	}

	.checkbox-label {
		display: inline-block;
		min-width: fit-content;
		font-size: 0.875rem;
		font-weight: 300;
		letter-spacing: -0.04em;
		color: #333;
		line-height: 17px;

		b {
			color: $persona-teal-950;
			font-weight: 300;
		}
	}
}

.checkbox-group {
	display: flex;
}
