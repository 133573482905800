@charset "UTF-8";
@import "src/assets/sass/utils/var";

/*---초기화면, 리스트 상단 설명글---*/
.p__shareholder-list {
	@import "./common";
}

/***
  * 주주 > 주주명부 > 초기화면
*/

/*---주주명부(초기화면)---*/
.p__shareholder-list .no-content {
	width: 345px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	overflow: hidden;

	.no-img {
		width: 100%;
		height: 220px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.card-content {
		width: 100%;
		height: 152px;
		padding-top: 46px;
		text-align: center;
		color: #666;
		font-weight: 300;
		letter-spacing: -0.025em;
		line-height: 1.57;

		b {
			font-weight: 300;
			color: $primary-color;
		}
	}
}
