@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * textarea
*/

.c__textarea {
	@include customScroll(scroll-y);
	width: 100%;
	font-size: 0.875rem;
	color: #333;
	line-height: 1.5;
	min-height: 120px;
	padding: 0.375em 0.625em;
	background: #fff;
	border: 1px solid $border-color;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	transition: 0.3s ease-in-out;
	resize: none;
	box-sizing: border-box;

	&.resizable {
		resize: vertical;
	}

	//---기본
	&:not(:disabled):not([readonly]) {
		&:hover {
			border-color: $primary-color;
		}

		&:focus,
		&:active {
			border-color: $primary-color;
			box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
		}

		&::placeholder {
			color: #ccc;
		}
	}

	//---disabled
	&:disabled {
		color: #ccc;
		background: rgba(#f8f8f8, 0.5);
		border-color: rgba($border-color, 0.5);
		cursor: not-allowed;

		&::placeholder {
			color: #ccc;
		}
	}

	//---readonly
	&[readonly] {
		background: #f8f8f8;
		color: #999;
		cursor: default;
	}
}

.character-length {
	color: #666;
	font-weight: 300;
	line-height: 1.45;
	text-align: right;
	position: absolute;
	right: 0;
	bottom: -1.5em;
}
