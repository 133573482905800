@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * 투자 - 라운드 공지 리스트
*/

//----- 상단 검색 카드
.p__invest-round {
	@import "../common/search";

	.search-card {
		padding-bottom: 10px;
	}
}

//검색결과 없을 때
.p__invest-round .c__noData {
	height: 100%;

	svg {
		fill: #999;
		width: 100px;
		height: 100px;
		margin-bottom: 40px;
	}

	p {
		color: #666;
		line-height: 1;
	}

	p:first-of-type {
		font-size: 20px;
		margin-bottom: 14px;
	}

	p:last-of-type {
		font-size: 14px;
	}
}

.p__invest-round {
	/*-----라운드 전체 리스트-----*/
	.list-card {
		//	min-width: 300px;
		//	max-width: 345px;
		height: auto;
		position: relative;
		//	cursor: pointer;
	}

	// 배지
	.custom-badge {
		color: #fff;
		font-size: 0.6875rem;
		font-weight: 500;
		text-align: center;
		line-height: 1;
		letter-spacing: -0.025em;
		background: $primary-color;
		padding: 5px 12px;
		border-radius: 20px;
		position: absolute;
		right: auto;
		top: 10px;
		left: -10px;
		display: inline-block;
		z-index: 2;
	}

	// 이미지
	.rounding-img {
		width: 100%;
		height: 0;
		position: relative;
		padding-top: calc((192 / 300) * 100%);
		border-radius: 13px 13px 0 0;
		overflow: hidden;

		img {
			width: 100%;
			max-width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			object-fit: cover;
			object-position: left;
		}
	}

	// 지원완료
	.complete-card {
		width: 100%;
		height: 0;
		background: rgba(0, 0, 0, 0.7);
		border-radius: 13px 13px 0 0;
		padding-top: calc((192 / 300) * 100%);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		display: none;

		span {
			width: 100%;
			height: 100%;
			color: #fff;
			font-size: 1.875rem;
			font-weight: 100;
			letter-spacing: -0.05em;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.active {
			display: block;
			text-align: center;
		}
	}

	// 하단 컨텐츠 내용
	.rounding-container {
		height: 140px;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		padding: 24px 28px 18px 30px;

		.rounding-content {
			@include ellipsis;
			color: #333;
			font-size: 1.25rem;
			font-weight: 300;
			line-height: 1.45;
			letter-spacing: -0.05em;
		}

		.rounding-container-footer {
			color: #999;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			.due-date {
				font-size: 2.05rem;
				font-weight: 200;
				font-family: "Poppins", sans-serif;
				line-height: 1.35;
				letter-spacing: -0.061em;

				&.end {
					color: #999;
				}

				&.open {
					color: $primary-color;
				}
			}
		}
	}
}
