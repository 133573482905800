@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

.p__join {
	@import "src/assets/sass/pages/auth/common/authForm";
}

/***
  * 회원가입
*/

/*---회원가입 공통---*/
.p__join {
	background: {
		image: url("../../../img/bg-login.jpg");
		position: center;
		size: cover;
		repeat: no-repeat;
	}

	.c__card {
		width: 682px;
	}

	.card-header {
		display: flex;
		justify-content: space-between;

		.logo {
			cursor: pointer;
		}

		.nav-dots {
			display: flex;
			align-items: center;

			> li {
				margin-left: 10px;
				color: #d7d7d7;

				svg {
					fill: currentColor;
				}

				&.active {
					color: $primary-color;
				}
			}
		}
	}

	.btn-wrap {
		display: flex;
		justify-content: center;
		align-items: center;

		.btn__solid {
			width: 120px;
			height: 50px;
			font-size: 1.125rem;

			&[color="primary"]:disabled {
				background: #d7d7d7;
				opacity: 1;
			}
		}
	}

	.card-bottom-alarm {
		text-align: center;
		margin-top: 30px;
		color: #333;
		font-weight: 300;
		letter-spacing: -0.025em;

		a {
			border-bottom: 1px solid $primary-color;
			color: $primary-color;
			font-weight: 300;
		}
	}

	.navigate-login {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.875rem;
		font-weight: 300;
		letter-spacing: -0.05em;
		color: #333;
		margin-top: 30px;

		button {
			color: $primary-color;
			font-size: inherit;
			font-weight: inherit;
			letter-spacing: inherit;
			position: relative;
			margin-left: 5px;

			&:after {
				display: block;
				content: "";
				position: absolute;
				width: 100%;
				height: 1px;
				background: $primary-color;
				top: calc(100% + 3px);
			}
		}
	}
}

/*---회원가입 이용약관---*/
.p__join .section-policy {
	padding: 60px 80px;

	.card-header {
		align-items: center;
		margin-bottom: 32px;
	}

	.join-auth-all {
		border-bottom: 1px solid #e8e8e8;
		padding-bottom: 22px;
		margin-bottom: 22px;

		.join-chk {
			letter-spacing: -0.05em;
		}
	}

	.join-auth-wrap {
		margin-bottom: 12px;

		.join-chk {
			letter-spacing: -0.05em;

			b {
				font-size: 1rem;
				letter-spacing: -0.05em;
			}
		}
	}

	.btn-wrap {
		padding-top: 18px;

		.btn__solid {
			letter-spacing: -0.05em;
			transition: 0.3s ease-in-out;

			&:first-child {
				margin-right: 10px;
			}
		}
	}

	//약관 부분
	.article {
		@include customScroll(scroll-y);

		width: 100%;
		height: 120px;
		overflow-y: auto;
		border-radius: 2px;
		border: solid 1px $border-color;
		background-color: #fafafa;
		margin: 10px 0;
		padding: 16px 20px;
		white-space: pre-wrap;
		line-height: 1.57;
		font-size: 0.875rem;
		font-weight: 300;
		letter-spacing: -0.025em;
		color: #666;

		b {
			font-weight: 500;
		}

		table {
			width: 100%;
			th {
				text-align: center;
				background-color: #e8e8e8;
				font-weight: 400;
			}
		}

		table,
		th,
		td {
			border: 1px solid #666;
			border-collapse: collapse;
			padding: 10px 5px;
		}
	}
}

/*---회원가입 유형선택---*/
.p__join .section-bizType {
	padding: 70px 90px 50px 80px;

	.type-wrap {
		padding-top: 87px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.type-select {
		width: 180px;
		height: 200px;
		border-radius: 10px;
		background-color: #f2f2f2;
		position: relative;
		transition: 0.3s;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 40px;
		}

		.img-wrap {
			position: absolute;
			bottom: 45px;
			left: 50%;
			transform: translate(-50%);
		}

		// hover, onclick 시
		&:hover,
		&.active {
			background-color: #e5f6ff;

			.type-name {
				background-color: $primary-color;
				color: #fff;
			}
		}
	}

	// 하단 타입이름
	.type-name {
		width: 100%;
		height: 48px;
		color: #666;
		font-size: 1rem;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
		background-color: #ddd;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
		transition: 0.3s;
	}

	.phrase {
		color: #333;
		font-size: 1.125rem;
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

/*---회원가입 입력 / 오류---*/
.p__join .section-joinForm {
	padding: 70px 80px 50px;

	.card-header {
		align-items: baseline;
		margin-bottom: 40px;
	}

	.f__control-wrap {
		display: flex;

		.btn__solid {
			width: 94px;
			height: 45px;
			margin-left: 6px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}
	}

	.btn-wrap {
		margin-top: 24px;

		.btn__solid {
			letter-spacing: -0.05em;

			&:first-child {
				margin-right: 10px;
			}
		}
	}
}

/*---회원가입 인증메일 전송---*/
.p__join .section-mailSend {
	height: 594px;
	padding: 80px 90px 53px 80px;

	.card-header {
		align-items: center;
	}

	.phrase-wrap {
		text-align: center;

		svg {
			width: 142px;
			height: 140px;
			fill: #d7d7d7;
			margin-top: 40px;
		}

		h3 {
			color: #333;
			font-size: 1.625rem;
			letter-spacing: -0.05em;
			margin: 29px 0 18px 0;
		}

		p {
			color: #666;
			font-size: 1rem;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.025em;
			margin-bottom: 29px;
			white-space: pre;
		}
	}

	.btn-wrap .btn__solid {
		letter-spacing: -0.025em;
	}

	.mail-resend {
		color: #999;
		font-weight: 300;
		text-align: right;
		line-height: 1.43;
		letter-spacing: -0.025em;
		padding-top: 28px;
		display: flex;
		align-items: center;
		justify-content: center;

		.btn-mail-resend {
			color: $primary-color;
			font-weight: 300;
			letter-spacing: -0.025rem;
			margin-left: 5px;
		}
	}
}

/*---회원가입 완료---*/
.p__join .section-complete {
	height: 470px;
	padding: 80px 90px 80px 80px;

	.card-header {
		align-items: center;
	}

	.phrase-wrap {
		text-align: center;

		h3 {
			color: #333;
			font-size: 2.5rem;
			letter-spacing: -0.025em;
			font-weight: 700;
			margin: 69px 0 20px 0;
		}

		p {
			color: #666;
			font-size: 1.125rem;
			font-weight: 300;
			letter-spacing: -0.025em;
			line-height: 1.67;
			margin-bottom: 40px;
			white-space: pre;
		}
	}

	.btn-wrap .btn__solid {
		width: 130px;
		letter-spacing: -0.025em;
	}
}

// 채널톡 위치
.p__join .channel-bot {
	position: fixed;
	bottom: 80px;
}
