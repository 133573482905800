@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * select
*/

.c__select {
	display: block;
	width: 100%;
	height: 35px;
	border: 1px solid $border-color;
	position: relative;
	color: #333;
	line-height: 1.5;
	background: #fff;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	transition: 0.3s ease-in-out;

	//---기본
	&:not(.disabled) {
		&:hover {
			border-color: $primary-color;
			cursor: pointer;
		}

		&.open {
			border-color: $primary-color;
			box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
		}
	}

	//---disabled
	&.disabled {
		color: #ccc;
		background: rgba(#f8f8f8, 0.5);
		border-color: rgba($border-color, 0.5);
		cursor: not-allowed;
	}
}

//선택된 옵션 / default 옵션 / placeholder
.c__selection-item {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 12px 1px;
	width: inherit;
	height: inherit;
	min-height: inherit;
	white-space: nowrap;
	font-size: inherit;
	color: #333;

	span {
		margin-right: 6px;
		font-size: inherit;
	}

	.placeholder {
		font-size: inherit;
		font-weight: inherit;
		color: #ccc;
	}
}

//select 화살표 아이콘
.ico_select-arrow {
	display: inline-flex;
	margin-left: auto;
	fill: #a3a1a1;
}

//드롭다운 전체
.c__select-dropdown {
	@include customScroll(scroll-y);
	display: none;
	width: inherit;
	max-height: 245px;
	position: absolute;
	top: calc(100% + 6px);
	right: 0;
	left: 0;
	padding: 6px 0;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.05),
		0 9px 28px 8px rgba(0, 0, 0, 0.02);
	z-index: 9;
	//overflow: hidden;
	font-size: inherit;
}

//옵션 각각 스타일
.c__select-dropdown-item {
	display: inline-flex;
	align-items: center;
	width: 100%;
	height: 35px;
	padding: 0 12px;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	transition: 0.3s ease-in-out;

	&:hover {
		background: #f8f8f8;
	}

	&.selected {
		background: #f8f8f8;
	}
}

///드롭다운 열기
.c__select.open {
	.c__select-dropdown {
		display: block;
		animation: antSlideUpIn 0.2s;
		//-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		//animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}
}

@keyframes antSlideUpIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}

@keyframes antSlideDownOut {
	0% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
}
