@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * new 랜딩페이지 > keyframes 애니메이션 효과
*/

.p__newMain {
	// 상단 페이드아웃 효과
	@keyframes reveal-bg {
		50% {
			opacity: 1;
			border-radius: 20px;
		}

		100% {
			width: calc(100% - 60px);
			top: 75px;
			left: 30px;
			border-radius: 20px;
			opacity: 0;
			z-index: -1;
		}
	}

	.curtain-effect:before {
		content: "";
		width: 100%;
		height: 100vh;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 1;
		background-color: $primary-color;
		animation: reveal-bg 1s 0.3s forwards cubic-bezier(0.25, 0.1, 0.18, 1);
		z-index: 99;

		@media screen and (max-width: 1024px) {
			display: none;
		}
	}

	// 버튼 텍스트 효과
	@keyframes btn-up {
		0% {
			transform: translateY(100%);
		}

		50% {
			transform: translateY(-24%);
		}

		100% {
			transform: translateY(0);
		}
	}

	.text-up {
		overflow: hidden;

		span {
			display: flex;
			align-items: center;
		}

		&:hover span {
			animation: btn-up 0.5s cubic-bezier(0.46, 0.4, 0.56, 0.87) forwards;
		}

		@for $i from 1 through 20 {
			span {
				&:nth-child(#{$i}) {
					animation-delay: calc((#{$i - 1} * 0.015s));
				}
			}
		}
	}

	// 배경 on-off 효과
	@keyframes blink-effect {
		50% {
			background: {
				image: url("../../../../../../src/assets/img/newLanding/bg-dao-off.png");
			}
		}
	}

	@media screen and (max-width: 950px) {
		@keyframes blink-effect {
			50% {
				background-image: url("../../../../../../src/assets/img/newLanding/mobile-bg-dao-off.png");
			}
		}
	}

	// 로드맵 애니메이션
	@keyframes fadeInBar {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
			visibility: visible;
		}
	}

	// 구름 효과
	@keyframes cloud-jump {
		50% {
			transform: translateY(10%);
		}
	}

	// 비행기 효과
	@keyframes test {
		from {
			left: -30%;
		}
		to {
			left: 100%;
		}
	}
}
