.btn-upload-wrap {
    width: 100%;
    display: flex;
    column-gap: 12px;
    row-gap: 8px;
    flex-wrap: wrap;
    
    .btn-upload {
        font-weight: 500;
        padding: 2px 8px;
        border: 1px solid #042f2e;
        color: #042f2e;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: normal;
        vertical-align: bottom;
        border-radius: 8px;
        cursor: pointer;

        input {
            position: absolute;
            width: inherit;
            height: inherit;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
    
    .img-preview-wrap {
        position: relative;

        .img-preview {
            width: 40px;
            height: 40px;
            object-fit: contain;
            border: 0.5px solid #ebebeb;
            border-radius: 8px;
        }

        .close-btn {
            width: 16px;
            position: absolute;
            cursor: pointer;
            top: 2px;
            right: 2px;
        }
    }
}