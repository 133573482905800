@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/*---기업정보(쓰기) 비공개설정모달---*/
.p__company-info .modal-private {
	width: 400px;
}

.p__company-info .modal-private .modal-content {
	min-height: 140px;
	padding: 40px 30px;
	display: flex;
	align-items: flex-start;

	svg {
		fill: #fd7e14;
		margin-right: 20px;
	}

	.dialog-title {
		font-size: 1.125rem;
		font-weight: 500;
		line-height: 1;
		letter-spacing: -0.05em;
		padding-bottom: 7px;
	}

	.dialog-text {
		color: #666;
		font-weight: 300;
		line-height: 1.3;
		letter-spacing: -0.05em;
	}
}

/*---기업정보 로고등록(팝업)---*/
.p__company-info .modal-logo-setting {
	width: 430px;
}

.p__company-info .modal-logo-setting .modal-content {
	text-align: center;
	padding: 30px 0;

	svg {
		fill: #fd7e14;
		margin-bottom: 20px;
	}

	.modal-text {
		font-size: 1rem;
		line-height: 1.5;
		letter-spacing: -0.025em;
		text-align: center;
		color: #333;
	}
}

/*---기업정보 로고 등록(팝업)---*/
.modal-logo {
	padding: 24px;

	.modal-title {
		color: #1b1b1b;
		font-weight: 400;
	}

	.modal-content {
		padding: 20px 24px;

		.sub-text {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 8px;

			b {
				color: $primary-color;
			}
		}

		.info-text {
			display: flex;
			flex-direction: column;
			gap: 4px;
			font-weight: 400;
			line-height: 1.3;
			color: #a8a8a8;
		}
	}

	.logo-uploader {
		width: 100%;
		height: 100%;
		border: 1px solid #d7d7d7;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 24px;
		margin-bottom: 16px;
		background-color: #fafafa;

		@media screen and (max-width: 835px) {
			width: 100%;
		}

		.logo-wrap {
			width: 364px;
			height: 126px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px;
			background-color: #fff;
			border: 1px solid #d7d7d7;

			&.logo-exist {
				padding: 0;
				border: unset;
			}
		}

		.uploader-select {
			background-color: #f8f8f8;
		}

		.upload-preview-actions {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			button {
				color: #d7d7d7;
			}
		}
	}
}
