.sidebar-sa {
    width: 240px;
    position: relative;
    transition: width 0.3s ease-in-out;
    background: #fff;
    border-right: 1px solid #E5E7EB;

    .logo-wrap {
        display: flex;
        padding: 24px 24px 10px;
        align-items: center;
        color: #111827;
        gap: 16px;
        cursor: pointer;

        img {
            width: 42px;
            height: 42px;
        }
    }

    .main-menu {
        padding: 12px;
    }

    .main-item, .menu-item {
        padding: 12px 16px;
        cursor: pointer;
        border-radius: 8px;

        span {
            color: #6B7280;
            font-size: 15px;
        }

        // path {
        //     stroke: #6B7280;
        // }
    }

    .menu-item {
        &.active, &:hover {
            background-color: #F0FDFA;
        }

        &.active {
            span {
                color: #0D9488;
            }

            path {
                stroke: #0D9488;
            }
        }
    }

    .main-item {
        &.active, &:hover {
            background-color: #F9FAFB;
        }

        &.active {
            span {
                color: #141522;
            }

            path {
                stroke: #141522;
            }
        }

        &.active + .sub-menu{
            display: block;
            margin-top: 4px;
        }
    }

    .sub-menu .menu-item{
        position: relative;
        padding: 10px 16px;

        &::after {
            content: "";
            height: 100%;
            width: 3px;
            background-color: #F3F4F6;
            position: absolute;
            top: 0;
            left: 28px;
        }

        span {
            display: block;
            padding-left: 30px;
            font-size: 14px;
            line-height: 24px;
        }

        &.active {
            color: #0D9488;

            &::after {
                background-color: #0D9488;
            }
        }
    }
}