@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 마이페이지 > 관리자메뉴 > 투자사 연결
*/

.p__admin-vcconnect .page-top {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: flex-start;

		.button-group {
			margin-left: auto;
			justify-self: flex-end;
			margin-bottom: 20px;
		}
	}

	.button-group {
		height: 40px;
		background: #f8f8f8;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		overflow: hidden;

		button {
			width: 68px;
			text-align: center;
			height: 40px;
			line-height: 39px;
			font-size: 14px;
			letter-spacing: -0.025em;
			color: #999;
			transition: 0.3s;

			&:not(:last-of-type) {
				border: 1px solid #e9e9e9;
			}

			&:not(:disabled).active {
				background: #fff;
				color: $primary-color;
			}

			&:not(:disabled):hover {
				color: $primary-color;
			}
		}
	}
}

.p__admin-vcconnect .vc-connect-table {
	padding: 39px 40px 40px;

	.thead,
	.tr {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $border-color;
		transition: 0.3s;

		.th,
		.td {
			font-weight: 300;
			line-height: 1.5;
			color: #333;
			text-align: center;
			transition: 0.5s;
		}
	}

	.no-content {
		width: 100%;
		height: 80px;
		line-height: 80px;
		text-align: center;
		border-bottom: 1px solid $border-color;
	}
}

.p__admin-vcconnect .vc-connect-table .thead {
	height: 40px;

	li {
		height: 40px;
		font-weight: 500;
		line-height: 1.5;
		letter-spacing: 0.05em;
		padding: 0 0.75rem 21px;
		margin-top: -0.25em;

		&.connect-status {
			padding-right: 42px;
		}
	}
}

.p__admin-vcconnect .vc-connect-table .tr {
	height: 80px;

	&:hover {
		background: #f8f8f8;
	}

	// 투자사
	.connect-name .connect-vc {
		display: flex;
		align-items: center;

		.connect-vc-logo {
			display: flex;
			align-items: center;
			width: 50px;
			height: 50px;
			margin-right: 20px;
			box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
			border-radius: 6px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.connect-name .connect-vc dl {
		text-align: left;
		padding: 3px 0;

		.connect-vc-name {
			font-size: 18px;
			letter-spacing: -0.01em;
			color: #333;
			line-height: 1.3;
		}

		.connect-vc-url {
			font-size: 12px;
			font-weight: 300;
			color: #999;
			line-height: 1.5;
		}
	}

	//  연결 아이콘
	.connect-check.active svg {
		fill: $primary-color;
	}
}

// 간격 조정
.p__admin-vcconnect .vc-connect-table {
	.connect-num {
		width: 77px;
	}

	.connect-name {
		flex: 1;
	}

	.connect-check {
		width: 100px;
		text-align: left;
		padding-left: 10px;
	}

	.connect-date {
		width: 140px;
	}

	.connect-status {
		width: 150px;
		color: #333;
		padding-right: 30px;
		position: relative;

		&.active {
			color: #e8e8e8;
		}

		&.inactive {
			color: #f00;
		}
	}
}

/*---투자사 연결하기 모달---*/
.connect-vc-modal .modal-content {
	height: 535px;
	padding: 48px 80px 16px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: space-between;

	background: {
		image: url("../../../../img/bg-connect-modal.png");
		repeat: no-repeat;
		position: center bottom;
	}

	.btn__outline {
		width: 180px;
		height: 46px;
		margin-bottom: 24px;
		border-width: 3px;
		font-size: 18px;
		background-color: #fff;
	}

	.connect-vc-title {
		text-align: center;

		b {
			font-size: inherit;
			font-weight: 600;
		}

		dt,
		dd {
			font-size: 36px;
			font-weight: 300;
			letter-spacing: -0.06em;
			line-height: 1.17;
		}

		dt {
			color: #333;
		}

		dd {
			color: $primary-color;
		}
	}

	.connect-vc-content {
		padding-top: 18px;
		text-align: center;
		word-break: keep-all;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.035em;
		line-height: 1.39;
		color: #999;
	}
}

/*---투자사 연결끊기 모달---*/
.connect-vc-modal.disconnect .modal-content {
	background-image: url("../../../../img/bg-disconnect-modal.png");

	dd {
		color: #ff8c25;
	}
}
