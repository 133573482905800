@charset "UTF-8";

/***
  * 푸터
*/

.l__footer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: auto;
	padding: 14px 40px 14px;
	position: relative;
	background: #f0f0f0;
	border-top: 1px solid #e8e8e8;
	z-index: 1;
}

.footer-info {
	display: inline-block;
	margin-right: 20px;

	li {
		display: inline-block;
		word-break: keep-all;
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 1.6;
		color: #a0a0a0;

		&:not(:last-child):after {
			content: "|";
			padding: 0 8px;
			vertical-align: 1px;
			font-weight: 200;
			line-height: 1;
			color: #c1c1c1;
		}

		b {
			font-weight: 700;
			letter-spacing: -0.025em;
			color: #a0a0a0;
		}
	}

	@media screen and (max-width: 1228px){
		br {
			display: none;
		}
	}
}

.footer-policy {
	flex-shrink: 0;
	padding: 5px 10px 0 0;

	> a {
		display: inline-block;
		width: fit-content;
		padding: 0 10px;
		line-height: 12px;
		font-size: 0.75rem;
		letter-spacing: -0.025em;
		color: #666;
		transition: color 0.3s ease-in-out;

		&:first-child:not(:last-child) {
			border-right: 1px solid #999;
		}

		&:hover {
			color: $primary-color;
		}
	}
}

//---로그인 전 페이지 footer
.l__authFooter {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	bottom: 0;
	text-align: center;

	* {
		font-size: 0.75rem;
		font-weight: 300;
		line-height: 1;
		color: #A8A8A8;
	}

	li {
		display: inline-block;
		padding: 0 8px;
		margin: 8px 0 5px;

		+ li {
			border-left: 1px solid rgba(200, 200, 200, 0.5);
		}

		@media screen and (max-width: 824px) {
			margin-bottom: 0;
		}
	}

	a {
		display: inline-block;
		transition: color 0.3s ease-in-out;

		&:not(:disabled) {
			&:hover,
			&:active {
				color: $primary-color;
			}
		}
	}

	b {
		font-weight: 500;
		color: $primary-color;
	}
}
