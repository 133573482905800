@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 팀원 > 설정 > 급여일
*/

//----- 공통
.p__vacation .payday-card,
.p__hr-setting .payday-card {
	width: 460px;
	min-height: 390px;
	padding: 30px;

	.payday-title {
		font-size: 1.125rem;
		font-weight: 500;
		line-height: 26px;
		margin-top: 40px;
	}

	.payday-wrap {
		border-top: 2px solid #000;
		margin: 20px 0 40px;
	}

	.c__form .f__group {
		border-bottom: 1px solid $border-color;
		padding: 12px 10px;

		.f__label {
			min-width: initial;
			color: #333;
			font-size: 1rem;
			font-weight: 400;
			margin-right: 40px;
		}
	}

	&.edit .alert-content {
		padding-left: 40px;

		.alert-phrase {
			position: relative;

			i {
				font-style: normal;
				position: absolute;
				left: -17px;
			}
		}
	}
}

//----- view
.p__vacation .payday-card,
.p__hr-setting .payday-card {
	.f__control {
		color: #333;
		font-size: 1rem;
		font-weight: 500;
		line-height: 35px;
	}

	// 안내문구
	.alert-content {
		width: 400px;
		padding: 16px 32px;
		background: #eef9ff;
		border: 1px solid $primary-color;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.alert-phrase {
			color: #333;
			font-weight: 300;
			line-height: 20px;

			b {
				color: $primary-color;
				font-weight: unset;
			}
		}
	}
}

//----- edit
.p__vacation .payday-edit-wrap .f__group,
.p__hr-setting .payday-edit-wrap .f__group {
	justify-content: space-between;

	.f__control {
		flex: unset;
	}

	.c__radio-wrap {
		width: 214px;
	}
}

// 지급날짜
.p__vacation .select-payday,
.p__hr-setting .select-payday {
	.f__label {
		height: 35px;
	}

	.f__control-wrap {
		width: 214px;
	}

	.c__select {
		width: 100%;
		height: 35px;
		border-radius: 2px;
	}

	.lastDay {
		padding-top: 10px;
	}
}
