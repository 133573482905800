@charset "UTF-8";
@import "src/assets/sass/utils/var";

.l__nav {
	display: flex;
	align-items: center;
	width: 100%;
	height: $topNavHeight;
	flex-shrink: 0;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);
	z-index: 999;
}

/*--------------왼쪽-----------------*/
.l__nav .nav-left {
	display: inline-flex;
	align-items: center;
	height: 100%;
	position: relative;
	z-index: 1;

	.btn__neumorphic {
		padding: 0 16px;
	}
}

.l__nav .btn-menuResize {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	margin: 0 25px;
	color: #fff;

	svg {
		height: 12px;

		&.main {
			width: 10px;
		}
	}
}

.l__nav .search-wrap {
	display: inline-flex;
	align-items: center;
	width: 230px;
	height: 38px;
	border-radius: 20px;
	background: #f8f8f8;
	padding: 4px 7.5px 4px 16px;
	vertical-align: bottom;
	margin-right: 30px;

	#search {
		flex: 1;
		height: 30px;
		background: transparent !important;
		font-size: 0.875rem;
		font-weight: 300;
		line-height: 30px;
		letter-spacing: -0.35px;

		&::placeholder {
			color: #6e777f;
		}
	}

	#btn-search {
		width: 30px;
		height: 30px;
		flex-shrink: 0;
		color: #ccc;

		&:not(:disabled):hover {
			color: $primary-color;
		}

		.simple-icon-magnifier {
			font-size: 1.0625rem;
		}
	}
}

/*--------------로고-----------------*/
.l__nav .logo-wrap {
	position: absolute;
	width: calc(100% - 120px);
	margin-left: 120px;

	.logo {
		width: 157px;
		height: 40px;
		margin: 0 auto;

		&:hover {
			cursor: pointer;
		}
	}

	@media screen and (max-width: 1200px) {
		width: 100%;
		margin-left: 0;
	}
}

/*--------------오른쪽-----------------*/
.l__nav .nav-right {
	display: inline-flex;
	align-items: center;
	margin-left: auto;
	position: relative;

	.neumorphic-list {
		width: fit-content;
		height: 40px;
		margin-right: 20px;
		display: inline-flex;
		align-items: center;
		gap: 24px;

		&.add-admin {
			border-radius: 100px;
			padding: 0 20px;
			box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05) inset,
				0px -1px 7px 0px rgba(0, 0, 0, 0.05) inset;
		}

		.neumorphic-item {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			cursor: pointer;
			transition: 0.3s;

			svg {
				fill: #999;
			}

			svg {
				fill: #999;
			}

			&:hover svg {
				fill: $primary-color;
				transition: 0.3s;
			}
		}
	}
}

.l__nav .neumorphic-item .badge {
	width: fit-content;
	min-width: 20px;
	height: 15px;
	padding: 0 5px;
	color: #fff;
	font-size: 0.625rem;
	border-radius: 20px;
	background: $primary-color;
	position: absolute;
	bottom: calc(100% - 12px);
	left: calc(100% - 11px);
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.l__nav .alarm-list > li {
	padding: 15px 5px 18px 15px;
	border-bottom: 1px solid #e8e8e8;

	.content {
		width: 100%;
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		word-break: keep-all;
		font-size: 0.875rem;
		font-weight: 200;
		letter-spacing: -0.05em;
		line-height: 1.43;
		color: #666;
	}

	.date {
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: -0.025em;
		color: #ccc;
		line-height: 1;
		padding-top: 3px;
	}
}

.l__nav .btn-alarm-more {
	display: flex;
	align-items: center;
	margin: 8px 0 2px auto;
	padding: 6px 0 6px 6px;
	font-size: 0.75rem;
	font-weight: 200;
	letter-spacing: -0.05em;
	color: #666;

	svg {
		height: 10px;
		fill: #bfbfbf;
		margin-left: 10px;
	}

	&:hover {
		color: $primary-color;

		svg {
			fill: $primary-color;
			transition: 0.3s ease-in-out;
		}
	}
}

.l__nav .ico-plugin {
	width: 25px;
	height: 25px;
	fill: $primary-color;
}

.l__nav .user-wrap {
	position: relative;
	margin-right: 40px;
}

.l__nav .user {
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	p {
		font-size: 0.8125rem;
		line-height: 1.43;
		letter-spacing: -0.025em;
		color: #999;
		margin-right: 24px;
	}

	.img-wrap {
		width: 40px;
		height: 40px;
		border: 1px solid #e4e4e4;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		svg {
			width: 20px;
			height: 21px;

			path {
				stroke: #e4e4e4;
			}
		}
	}
}

.l__nav .user-dropdown-menu {
	position: absolute;
	top: calc(100% + 7px);
	right: 0;
	min-width: 145px;
	padding: 14px 7.5px 14px 8.5px;
	background-color: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 13px;
	box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.05);

	> li {
		width: 100%;
		padding: 0 10px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		font-size: 0.875rem;
		color: #999;
		text-align: center;
		transition: color 0.3s ease-in-out;

		// &:last-child {
		// 	margin-top: 10px;
		// 	padding-top: 4px;
		// 	border-top: 1px solid #e0e0e0;
		// 	transition: 0.3s;
		// }

		&:hover {
			color: $primary-color;
			cursor: pointer;
		}
	}
}
