@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 마이페이지 > 라이센스 > 결제수단관리
*/

//---- 결제수단관리 카드 공통 스타일
.my-payment-card,
.all-payment-card {
	&.c__card {
		padding: 30px 30px 10px 30px;
	}

	h3 {
		font-weight: 500;
		font-size: 1rem;
		line-height: 16px;
		letter-spacing: -0.06em;
		margin-bottom: 16px;
	}

	// 결제수단 제목
	.payment-card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;

		h3 {
			margin-bottom: 0;
		}

		.plus-btn {
			width: fit-content;
			min-width: 144px;
			height: 35px;
			border: 1px dashed #666;
			color: #666;
			font-weight: 500;
			letter-spacing: -0.06em;
			border-radius: 1.7px;
			padding: 0 8px;
			font-size: 0.875rem;

			svg {
				fill: #666;
			}

			&:not(:disabled):hover {
				color: $primary-color;
				border-color: $primary-color;

				svg {
					fill: $primary-color;
				}
			}
		}
	}

	// 결제수단 내부 스타일
	.payment-card-wrap {
		display: flex;
		justify-content: space-between;
		flex-flow: wrap;
	}
	.payment-card {
		padding: 16px;
		width: calc(50% - 10px);
		border-radius: 8px;
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		.payment-img {
			margin-right: 10px;
		}

		.payment-info {
			color: #333;

			.card-name {
				font-weight: 400;
				font-size: 0.875rem;
				line-height: 14px;
				letter-spacing: -0.06em;
				margin-bottom: 12px;
			}
			.card-number {
				font-family: "Exo 2", sans-serif;
				font-weight: 500;
				font-size: 1.25rem;
				line-height: 20px;
			}
		}

		.btn-dropdown {
			padding: 16px;
			height: auto;
		}
		.c__dropdown {
			top: 32px;
			right: 16px;
		}
	}
}

//---- 나의 기본 결제 수단
.my-payment-card {
	.payment-card {
		background: #f5f5f5;
	}
}

//---- 모든 결제 수단
.all-payment-card {
	// 결제수단 등록
	.payment-register {
		width: calc(50% - 10px);
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		border: 1px dashed #d7d7d7;
		background: #fafafa;
		transition: 0.2s;
		font-weight: 400;
		font-size: 0.75rem;
		line-height: 12px;
		color: #666;
		letter-spacing: -0.06em;

		&:hover {
			cursor: pointer;
			border-color: #333;
			color: #333;

			svg {
				fill: #333;
			}
		}

		svg {
			transition: 0.2s;
			fill: #b2b2b2;
			margin-right: 4px;
		}
	}

	.payment-card {
		background: #fff;
		border: 1px solid #d7d7d7;
	}
}

//----- 결제수단 등록하기 모달
.payment-modal {
	.modal-content {
		padding: 30px;
		position: relative;

		.f__group:not(:last-child) {
			margin-bottom: 21px;
		}
		.f__label {
			width: 100px;
			color: #333;
			font-weight: 500;
		}
		.card {
			&-toggle2,
			&-toggle3 {
				z-index: 10;
				position: absolute;
				cursor: pointer;

				svg,
				path {
					fill: #999;
				}
			}
			&-toggle2 {
				top: 32px;
				left: calc(100px + (77px * 2) + (9px * 1));
			}
			&-toggle3 {
				top: 32px;
				left: calc(100px + (77px * 3) + (9px * 2));
			}
		}

		.f__control-wrap {
			display: flex;

			.f__control:not(:last-child) {
				margin-right: 9px;
			}
		}
		.f__control.date {
			width: 75.25px;
			position: relative;

			&:first-child {
				margin-right: 15px;
			}

			.c__input {
				width: 100%;
			}
			&:first-child:after {
				content: "/";
				position: absolute;
				line-height: 35px;
				margin: 0 5px;
				color: #333;
				font-weight: 500;
			}
		}
		.password .f__control {
			display: flex;
			align-items: center;

			&:after {
				content: "**";
				font-weight: 500;
				color: #333;
				margin: 5px 0 0 9px;
			}

			.c__input {
				width: 34px;
			}
		}
	}
}
