@charset "UTF-8";
@import "../../../../sass/utils/var";

/***
  * 마이페이지 > 내 프로필
*/

.p__management-profile,
.p__account-profile {
	background: {
		image: url("../../../../img/mypage-bg.jpg");
		position: 0 0;
		size: cover;
		repeat: no-repeat;
	}
}

.p__management-profile,
.p__account-profile {
	//사원증 모양 배경
	.c__card-wrap {
		display: flex;
		margin-top: -30px;
		margin-right: 6.1224489%; /*90/1470*/
		justify-content: center;

		@media screen and (max-width: 1400px) {
			margin-right: 0;
		}
	}

	$profile-bg: front, back;

	@each $type in $profile-bg {
		.c__card.#{$type} {
			display: inline-block;
			width: 340px;
			margin: 0 15px;
			//overflow: hidden;
			background: transparent;
			box-shadow: none;

			background: {
				image: url("../../../../img/profile-#{$type}.png");
				position: 0 0;
				size: 100%;
				repeat: no-repeat;
			}
		}
	}

	.content-wrap {
		position: relative;
		padding-top: calc(608 / 340 * 100%);
		width: 100%;
	}

	.content {
		position: absolute;
		top: calc(86 / 608 * 100%);
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
	}
}

//사원증 앞면
.p__management-profile .front,
.p__account-profile .front {
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&:after {
			content: "";
			display: block;
			width: inherit;
			height: calc(314 / 522 * 100%);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
			background: #fff;
			border-bottom-left-radius: 13px;
			border-bottom-right-radius: 13px;
		}

		> * {
			z-index: 1;
			position: relative;
		}
	}

	.profile-intro {
		display: flex;
		width: 100%;
		font-size: 0.875rem;
		font-weight: 300;
		line-height: 1.35; //19px
		letter-spacing: -0.35px;
		color: #fff;
		padding-left: 14px;
		margin-top: 22px;

		&:before {
			content: "";
			display: inline-block;
			margin-right: 11px;
			width: 2px;
			height: 100%;
			background: $primary-color;
		}
	}

	.profile-img {
		position: relative;
		width: 160px;
		height: 160px;
		border-radius: 50%;
		margin: 48px 0 15px;

		.profile-authority {
			display: inline-block;
			width: 65px;
			height: 30px;
			line-height: 29px;
			text-align: center;
			background: $primary-color;
			color: #fff;
			font-size: 0.875rem;
			-webkit-border-radius: 14px;
			-moz-border-radius: 14px;
			border-radius: 14px;
			position: absolute;
			top: 16px;
			right: -27px;
			z-index: 1;
		}

		img {
			width: 100%;
			height: 100%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	.profile-position {
		font-size: 1rem;
		font-weight: bold;
		line-height: 1.875; //30px
		color: #838589;
		margin-bottom: 3px;
	}

	.profile-affiliation {
		color: #999;
		line-height: 1.5;
	}

	.profile-name {
		font-size: 1.5rem;
		font-weight: bold;
		line-height: 1.25; //30px
		letter-spacing: -0.6px;
		color: #333;
		margin-bottom: 14px;
	}

	.contact-info {
		display: inline-block;
		text-align: center;
		font-size: 0.6875rem;
		font-weight: 300;
		line-height: 1.81; //20px
		color: #868686;
		margin-bottom: 53px;
	}
}

//사원증 뒷면
.p__management-profile .back,
.p__account-profile .back {

	.c__noData {
		.add-button {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 104px;
			height: 104px;
			border-radius: 50%;
			border: 1.5px dashed #767676;
			background: #8D8F93;
			margin-bottom: 32px;
			svg {
				margin-bottom: 0;
			}
		}
		.main {
			color: #fff;
		}
	}


	.content {
		padding-top: 63px;
	}

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: -1px;
		color: rgba(#ebebeb, 0.5);
		margin-bottom: 26px;
		padding: 0 24px;
		button {
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
	}

	.scroll-wrap {
		width: calc(100% - 6px);
		padding: 0 8px 0 20px;
		max-height: 400px;
		overflow-y: auto;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			border-radius: 6px;
			background: #6a6b6e;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #4a4b4d;
			border-radius: 6px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #8d8d8d;
		}
	}

	.career-list {
		//border-top: 1px solid rgba(#b0b1b4, 0.3);
		border-top: 1px solid #767676;

		.c__noData {
			height: 350px;
			margin-right: 8px;
			color: #ccc;

			svg {
				fill: currentColor;
			}
		}

		li {
			width: 100%;
			height: 40px;
			padding: 0 6px 0 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			//border-bottom: 1px solid rgba(#b0b1b4, 0.3);
			border-bottom: 1px solid #767676;
		}

		.work {
			font-size: 0.75rem;
			font-weight: 300;
			letter-spacing: -0.3px;
			color: #fafafa;
		}

		.date {
			min-width: 82px;
			font-size: 0.6875rem;
			font-weight: 300;
			color: rgba(#fafafa, 0.5);
		}
	}
}
