@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 투자 - 라운드 동적 폼
*/

.round-modal .round-form {
	padding: 32px 60px 60px;

	.round-title {
		width: calc(100% - 365px);
		word-break: keep-all;
		line-height: 1.3;

		h3 {
			font-size: 2.25rem;
			font-weight: 200;
			letter-spacing: -1.8px;
			margin-bottom: 4.5px;
			color: $primary-color;
		}

		p {
			font-size: 0.875rem;
			font-weight: 300;
			letter-spacing: -0.7px;
			padding-left: 2px;
			color: #b2b2b2;
		}

		@media screen and (max-width: 1023px) {
			font-size: calc(30px + (36 - 30) * ((100vw - 768px) / (1024 - 768)));
			width: calc(100% - 170px);
		}
	}

	.round-form-head {
		padding: 22px 0 86px;
		position: relative;
		overflow: hidden;

		b {
			display: block;
			color: #333;
			font-size: 2.25rem;
			font-weight: 600;
			letter-spacing: -1.8px;
			margin-bottom: 7.6px;
		}

		&:before {
			content: "";
			display: inline-block;
			width: 226px;
			height: 218px;
			position: absolute;
			top: 0;
			right: 45px;

			background: {
				image: url("../../../../img/bg-rounding.png");
				size: 100%;
				repeat: no-repeat;
			}

			@media screen and (max-width: 1023px) {
				width: 200px;
				height: 192px;
				right: 10px;
				top: 40px;
			}
		}
	}

	.certified-phrase:before {
		content: "※";
		display: inline-block;
		min-width: 1.71em;
		//height: 1.71em;
		//line-height: 35px;
		padding: 7px 0;
	}

	.note-list {
		padding-top: 12px;
		display: flex;
		flex-flow: column;

		li {
			display: flex;
			flex-flow: row;
			align-items: center;
			color: #999;
			font-weight: 300;
			padding-bottom: 12px;

			&.default-note:before {
				content: "";
				width: 3px;
				height: 3px;
				vertical-align: middle;
				display: inline-block;
				margin-right: 10px;
				background: #b2b2b2;
			}
		}
	}

	.c__form .f__group {
		border-top: 1px solid $border-color;
	}

	@media screen and (max-width: 1000px) {
		.file-form .f__group {
			flex-direction: column;
			gap: 8px;

			.f__control-wrap {
				width: 100%;
			}

			.c__fileUploader .file-preview {
				width: 100%;
			}
		}

		.multi-select-form-wrap .f__group {
			flex-direction: column;
			gap: 8px;

			.f__control-wrap {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	.f__label {
		word-break: break-all;
		width: 235px;
	}

	.f__group {
		min-height: 49px;
		padding: 7px 10px;
	}

	.category-title {
		font-size: 1.5rem;
		font-weight: 500;
		line-height: 1.25em;
		letter-spacing: -1.2px;
		margin-top: 60px;
		padding-bottom: 12px;
		color: #333;
		border-bottom: 1px solid $border-color;

		&:first-child {
			margin-top: -45px;
			border-bottom: none;
		}
	}

	.checkbox-custom {
		margin: 10px 0;
	}

	.agree-type {
		flex-direction: column;

		.f__control-wrap {
			width: 100%;
		}

		.f__label {
			width: 100%;
			font-size: 1rem;
			margin-bottom: 10px;
		}

		.c__textarea {
			color: #999;
			background: #fafafa;
			padding: 20px;

			&:hover {
				border-color: $border-color;
			}

			&:focus {
				box-shadow: none;
			}
		}

		.has-error .checkmark {
			border-color: $error-color;
		}
	}
}
