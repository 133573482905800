@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 팀원 > 팀원정보 > 리스트 조직도 설정 모달
*/

.org-setting-modal .modal-container {
	/*---모달 바디 상단---*/
	.modal-body-top {
		height: 60px;
		padding: 0 20px;
		background-color: #e9f2ff;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.company {
			letter-spacing: -0.05em;
			font-weight: 600;
			color: #3982d8;
			font: {
				size: 1rem;
				weight: 500;
			}
		}

		// 조직추가 버튼
		.btn__outline {
			width: fit-content;
			min-width: 100px;
			padding: 0 10px;
			height: 38px;
			color: #3982d8;
			border: 1px solid #3982d8;
			background-color: #fff;

			svg {
				width: initial;
				height: initial;
				flex-shrink: 0;
			}
		}
	}

	.scroll-wrap {
		@include customScroll(scroll-y);
		height: 458px;
		min-height: 420px;
	}
}

//---TODO 보여주기용 퍼블, 개발 완료 후 삭제 및 다시 퍼블
.org-setting-modal {
	/*---모달 바디 하단---*/
	.org-list {
		color: #25232a;
		padding: 20px 20px 50px;

		.btn-modify,
		.btn-delete {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 28px;
			height: 28px;
			border-radius: 4px;
			transition: 0.3s ease-in-out;

			svg {
				fill: #bebbc9;
				transition: 0.3s ease-in-out;
			}

			&:not(:disabled) {
				&:hover,
				&:active {
					background-color: #ebe9f1;

					svg {
						fill: #565360;
					}
				}
			}
		}

		/*---조직 바 hover 시 수정,삭제 버튼---*/
		.btn-edit-wrap {
			display: inline-block;
			transition: opacity 0.3s ease-in-out;
			opacity: 0;
		}
	}
}
