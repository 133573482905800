@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";
/***
  * 테이블
*/

.c__table {
	width: 100%;

	.tr {
		display: flex;
		align-content: center;
		line-height: 1.5;
		border-bottom: 1px solid $border-color;

		//@media screen and (max-width: 1200px) {
		//	font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
		//}
	}

	.th,
	.td {
		height: 50px;
		line-height: 50px;
		padding: 0 14px;
		white-space: nowrap;
	}

	//  thead
	.thead {
		color: #333;
		background-color: #f8f8f8;
		border-top: 3px solid $primary-color;
		line-height: 2.29;
		letter-spacing: -0.025em;

		.th {
			text-align: center;

			//@media screen and (max-width: 1200px) {
			//	font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1200 - 768)));
			//}
		}
	}

	//  tbody
	.tbody {
		.tr {
			transition-duration: 0.3s;
			transition-property: background, color;

			&:hover {
				background-color: #f8f8f8;
				cursor: pointer;

				> .td {
					color: #333;
				}
			}
		}

		.td {
			color: #666;
			font-weight: 300;
			letter-spacing: -0.05em;
		}

		.no-content {
			padding: 45px 0 30px;
			text-align: center;
			color: #666;
		}
	}
}

.c__table_v2 {
	width: 100%;
	border: 1px solid #E5E7EB;
	border-radius: 8px;
	border-collapse: separate;
	
	.tr {
		display: flex;
		align-items: center;
		line-height: 1.5;
		border-bottom: 1px solid #E5E7EB;
	}

	.th,
	.td {
		padding: 0 12px;
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.td {
		padding: 0 12px;
		text-align: left;
	}

	//  thead
	.thead {
		color: #374151;
		background-color: #F3F4F6;
		border-radius: 8px 8px 0 0;

		.tr {
			height: 42px;
		}

		.th, .th span {
			font-weight: 500;
			font-size: 0.75rem;
			text-transform: uppercase;
		}

		.th span {
			max-width: calc(100% - 17px - 0.25rem);
			overflow-x: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	//  tbody
	.tbody {
		// max-height: calc(100vh - 4.5rem - 125px);
		// scrollbar-gutter: stable;

		// @include customScroll(scroll-y);

		// &::-webkit-scrollbar-track {
		// 	background: none;
		// }

		// &::-webkit-scrollbar-thumb {
		// 	background-color: #ccc;
		// }

		// &::-webkit-scrollbar {
		// 	margin-left: -10px;
		// }

		.tr {
			transition-duration: 0.3s;
			transition-property: background, color;
			height: 52px;

			&:hover {
				background-color: #f8f8f8;
				cursor: pointer;
			}

			&:last-child {
				border-bottom: none;
			}
		}

		.td {
			// color: #666;
			font-weight: 400;
			font-size: 0.875rem;
		}

		.no-content {
			padding: 45px 0 30px;
			text-align: center;
			color: #666;
		}
	}

	.c__select {
		border-radius: 8px;

		.c__select-dropdown {
			padding: 0;
		}

		.c__selection-item {
			gap: 8px;
		}

		span, li {
			font-size: 0.875rem;
		}

		&:hover {
			border-color: transparent;
		}

		&:not(.disabled).open {
			box-shadow: none;
			border-color: transparent;
		}
	}
}
