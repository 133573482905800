@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 마이페이지 > 관리자메뉴 > 인증 완료
*/

.p__admin-business .c__card.certified {
	.card-top {
		display: flex;
		justify-content: space-between;
	}

	.content {
		padding-top: 30px;

		> dt {
			color: $primary-color;
		}
	}
}
