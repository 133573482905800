@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * IR - 재무정보
*/

/*---공통---*/
.finance-view,
.finance-edit {
	padding: 60px;

	.section-title {
		padding-bottom: 17px;
	}

	.sub-title {
		color: #333;
		font-weight: 500;
		line-height: 1.5;
		letter-spacing: -0.025em;
		padding-bottom: 16px;
		border-bottom: 2px solid #333;
	}

	.sub-section .f__label {
		font-weight: 500;
		letter-spacing: -0.025em;
	}

	.unit {
		position: unset;
		transform: unset;
	}

	//----- 주요재무현황
	// 재무상태표, 부채상태표
	.sub-section-table {
		display: flex;
		flex-direction: column;

		.f__row {
			display: flex;

			.f__label {
				border-bottom: none !important;
			}
		}

		.f__column {
			width: 100%;
			min-width: 33.33%;
			padding: 0 20px;
			border-bottom: 1px solid $border-color;
			display: flex;
			justify-content: center;
			align-items: center;

			.f__group {
				display: flex;
				align-items: center;
				flex-flow: column;
			}

			.f__label {
				line-height: 1.5;
				padding-bottom: 16px !important;
			}

			.f__control {
				word-break: break-word;
				font-weight: 300;
				line-height: 1.5;
			}
		}

		.f__field {
			width: 100%;
			border-left: 1px solid $border-color;

			.f__group {
				min-height: 49px;
				border-bottom: 1px solid $border-color;
				margin: 0;
				display: flex;
				flex-direction: row;
			}

			.f__label {
				width: 50%;
				padding: 7px 10px !important;
			}

			.f__control {
				width: 50%;
				font-weight: 300;
				border-left: 1px solid $border-color;
				word-break: break-word;
				padding: 7px 10px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	// 총계
	.total {
		width: 100%;
		min-height: 80px;
		padding: 7px 0;
		display: flex;
		align-items: center;
		border-bottom: 2px solid #333;

		div {
			font-weight: 500;
			letter-spacing: -0.025em;
			padding: 0 15px;
		}

		.total-label {
			width: 33.33%;
			font-size: 1rem;
			text-align: center;
			line-height: 1.5;
		}

		.f__control {
			color: $primary-color;
			font-size: 1.5rem;
			justify-content: flex-end;
			word-break: break-word;

			@media screen and (max-width: 1200px) {
				font-size: calc(20px + (24 - 20) * ((100vw - 768px) / (1200 - 768)));
			}
		}
	}

	// 설명박스
	.alert-content {
		background-color: #eef9ff;
		padding: 16px 16px 14px;
		border: 1px solid $primary-color;
		margin: 30px 0 14px;
		position: relative;

		> div {
			display: flex;
		}

		dt {
			font-weight: 500;
			line-height: 1.5;
			letter-spacing: -0.025em;
			margin-right: 3px;
			white-space: nowrap;

			&:before {
				content: "\00B7";
				width: 0.7em;
				display: inline-block;
			}
		}

		dd {
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.025em;

			&:before {
				content: "\003D";
				width: 0.7em;
				display: inline-block;
			}
		}
	}

	// 손익계산서 표
	.income-status-table {
		@include customScroll(scroll-x);
		width: 100% !important;
		margin: 0 0 50px 0 !important;
		overflow-x: auto;

		.title {
			min-width: auto;
			font-weight: 500;
			line-height: 1.5;
			letter-spacing: -0.025em;
			color: #333;
			padding-bottom: 16px;
			text-align: center;
		}

		.num {
			color: #333;
			font-weight: 300;
			line-height: 1.5;
		}

		.f__control {
			display: flex;
			min-width: 1060px;
			overflow-x: hidden;
			border: 1px solid $border-color;
			border-top: none;
		}

		li {
			width: 10%;
			padding: 24px 10px 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			&:not(:last-child) {
				border-right: 1px solid $border-color;
			}
		}
	}

	//----- 주요채무현황
	.debt-status {
		.section-title {
			padding-bottom: 13px;
		}

		&-table {
			width: 100%;
			border: 1px solid $border-color;
			border-top: 2px solid #333;

			.tr {
				width: 100%;
				display: flex;

				div:not(.repayment-condition) {
					width: 166px;
				}

				.repayment-condition {
					flex: 1;
					min-width: 166px;
				}
			}

			.th,
			.td {
				min-height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 7px 10px;
				line-height: 1.5;
				word-break: break-word;

				&:not(:last-child) {
					border-right: 1px solid $border-color;
				}
			}

			.th {
				font-weight: 500;
				letter-spacing: -0.025em;
			}

			.td {
				font-weight: 300;
				border-top: 1px solid $border-color;

				&.repayment-condition {
					justify-content: flex-start;
				}
			}
		}
	}
}

/*---EDIT---*/
.finance-edit {
	//----- 주요재무현황
	// 재무상태표, 부채상태표
	.f__field {
		.f__control {
			padding: 7px;
		}

		.c__input {
			text-align: right;
		}
	}

	// input 가운데 정렬
	.f__column .c__input,
	.num .c__input {
		text-align: center;
	}

	//----- 주요채무현황
	.debt-status-table {
		@include customScroll(scroll-x);
		width: 100%;
		border : {
			right: none;
			left: none;
			bottom-color: $border-color;
		}

		.tr div:not(.repayment-condition) {
			width: 162px;
		}

		.th,
		.td {
			&:not(:last-child) {
				border-right: none;
			}
		}

		.td {
			padding: 7px 2.5px;
			border-top: 1px solid $border-color;
		}

		.borrower-name {
			padding-left: 10px;
		}
	}

	.btn-wrap {
		width: 38px !important;
	}
}
