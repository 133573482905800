@charset "UTF-8";
@import "../utils/var";

/***
  * 드롭다운 메뉴
*/

.btn-vertical-dot {
	position: absolute;

	span {
		width: 4px;
		height: 4px;
		display: block;
		border-radius: 50%;
		background-color: #999;

		&:not(:last-child) {
			margin-bottom: 3px;
		}
	}

	&:focus span {
		background-color: $primary-color;
	}

	&:focus .dropdown-menu {
		display: block;
	}

	.dropdown-menu {
		width: 74px;
		height: auto;
		padding: 10px 0;
		border: 1px solid $border-color;
		border-radius: 10px;
		background-color: #fff;
		position: absolute;
		top: 0;
		//left: 10px;
		display: none;

		.dropdown-item {
			width: 100%;
			min-height: 31px;
			color: #333;
			font-size: 0.875rem;
			font-weight: 300;
			line-height: 31px;
			letter-spacing: -0.05em;
			padding: 0 4px;
			white-space: nowrap;
			cursor: pointer;
			transition: 0.3s;

			&:not(:last-child) {
				border-bottom: 1px solid #f0f0f0;
			}

			&:hover {
				background-color: #f2f2f2;
			}
		}
	}
}
