@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/*---주주명부 VIEW, EDIT 공통---*/
.p__shareholder-list {
	@import "./common";
}

/***
  * 주주 > 주주명부 > VIEW
*/

.shareholder-view-card {
	padding: 60px;

	.date {
		color: #999;
		font-size: 0.75rem;
		font-weight: 300;
		line-height: 1.67;
		margin-bottom: 18px;

		@media screen and (max-width: 1024px) {
			margin-bottom: 16px;
		}
	}

	.shareholder-header h2 {
		color: #333;
		font-size: 2.8125rem;
		font-weight: 700;
		text-align: center;
		line-height: 1.45;
		letter-spacing: -0.1em;
		margin-bottom: 32px;

		@media screen and (max-width: 1024px) {
			font-size: calc(30px + (45 - 30) * ((100vw - 768px) / (1024 - 768)));
			margin-bottom: 30px;
		}
	}

	.section-title {
		padding-bottom: 13px;
	}

	.certified-phrase {
		color: #333;
		font-size: 1rem;
		font-weight: 300;
		line-height: 1.5;
		padding-top: 10px;

		@media screen and (max-width: 1024px) {
			font-size: calc(12px + (16 - 12) * ((100vw - 768px) / (1024 - 768)));
		}
	}

	.create-date {
		color: #333;
		font-size: 1.75rem;
		font-weight: 300;
		text-align: center;
		line-height: 1.3;
		padding: 45px 0 50px;

		@media screen and (max-width: 1024px) {
			font-size: calc(20px + (28 - 20) * ((100vw - 768px) / (1024 - 768)));
		}
	}

	.scroll-wrap {
		@include customScroll(scroll-x);
	}
}

//---- 테이블
.shareholder-view-card .shareholder-table {
	width: 100%;
	min-width: max-content;
	border: {
		bottom: 2px solid #000;
		top: 2px solid #000;
	}

	.th,
	.td {
		padding: 7px 10px;
		white-space: nowrap;
	}

	// thead
	.thead .tr {
		min-height: 50px;
	}

	//	tbody
	.tbody .tr {
		min-height: 76px;

		.shareholder-info {
			display: flex;
			flex-flow: column;
		}
	}

	//	tfoot
	.tfoot .tr {
		min-height: 80px;

		.td {
			color: #333;
			font-size: 1.125rem;
			font-weight: 500;
			line-height: 1.5;
			letter-spacing: -0.025em;
		}

		.total-label {
			width: 50%;
			font-size: 1rem;
			font-weight: 500;
			text-align: left;
			padding-left: 15%;
		}

		.total-stock,
		.total-amount,
		.total-ratio {
			color: #333;
			font-size: 1.25rem;
			font-weight: 500;
			text-align: center;
			line-height: 1.13;
		}

		.total-stock {
			flex: 1;
		}

		.total-amount {
			flex: 1;
		}

		.total-ratio {
			width: 100px;
		}
	}
}

// 주주명부 보기 테이블 간격
.shareholder-view-card .shareholder-table {
	.shareholder-info {
		min-width: 210px;

		.shareholder-name,
		.shareholder-number {
			line-height: 1.3;
			text-align: left;
		}
	}

	.shareholder-address {
		width: 380px;
		white-space: unset;
	}

	.shareholder-price {
		min-width: 110px;
	}

	.shareholder-stock {
		min-width: 196px;
		flex: 1;
	}

	.shareholder-total {
		min-width: 196px;
		flex: 1;
	}

	.equity-ratio {
		width: 100px;
	}
}

.shareholder-view-card .seal-area {
	@media screen and (max-width: 1024px) {
		width: 40%;
	}
}
