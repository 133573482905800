@charset "UTF-8";

.c__noData {
	width: 100%;
	color: #666;
	font-weight: 300;
	line-height: 1.43;
	letter-spacing: -0.05em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	svg {
		width: 60px;
		height: 60px;
		fill: #999;
		margin-bottom: 17px;
	}

	.main {
		color: #666;
		font-size: 1rem;
	}

	.sub {
		color: #666;
		font-size: 0.875rem;
		white-space: nowrap;
	}
}
