@charset "UTF-8";
@import "../../../../sass/utils/var";

/***
  * 마이페이지 > 비밀번호 변경
*/

.p__management-pw .c__card,
.p__account-pw .c__card {
	width: 560px;
	height: auto;
	padding: 40px;

	.f__group {
		transition: padding-bottom 0.25s ease-in;

		&:not(.has-error) {
			padding-bottom: 10px;
		}
	}

	.f__label {
		width: 130px;
		font-weight: 300;
		color: #333;
	}

	.btn-wrap {
		text-align: center;
		margin-top: 20px;

		.btn__solid {
			width: 108px;
		}
	}
}
