@charset "UTF-8";
@import "src/assets/sass/utils/var";
/**
* switch radio
*/

.c__switch-radio {
    width: 100%;
    display: flex;
    border: 1px solid $primary-color;
    flex: 1;
    cursor: pointer;
    border-radius: 4px;

    &.angled {
        // 기본보다 좀 더 각진 친구는 angled라는 className을 주면 됩니다.
        border-radius: 2px;
    }

    li {
        width: 100%;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;

        &:not(:last-child) {
            border-right: 1px solid $primary-color;
        }

        &.active {
            background: $primary-color;
            color: #fff;
        }
    }
}
