@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * full calendar custom
*/

.fc-daygrid-event {
	margin: 0 !important;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0 !important;
	border: none !important;
}

.fc-day-today {
	background: none !important;

	.fc-daygrid-day-number {
		font-size: 16px;
		font-weight: 700;
		background: $primary-color;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		color: #fff !important;
	}
}
.fc-daygrid-day-top {
	justify-content: center;
	margin-top: 7px;
}
.fc-daygrid-day-number {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	padding: 0 !important;
	
	font : {
		family: "Poppins", sans-serif;
		size: 16px;
	}
}

.fc-daygrid-day-events {
	width: 100%;
}

.fc .fc-daygrid-day-frame {
	display: flex;
	flex-direction: column;
	max-height: 110px;
}

.fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-events {
	margin-top: auto;
}

.fc-col-header-cell {
	height: 50px;
	vertical-align: middle !important;

	* {
		color: #333;
		font : {
			family: "Poppins", "Noto Sans Kr", sans-serif;
			size: 18px;
			weight: 500;
		}
	}
}


.fc-header-toolbar {
	position: relative;
	.fc-toolbar-title {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
