@charset "UTF-8";

@mixin keywordList {
	margin-top: 12px;

	.keyword {
		min-width: 40px;
		height: 21px;
		padding: 0 10px;
		color: #595959;
		font-size: 0.75rem;
		line-height: 4.58;
		letter-spacing: -0.05em;
		background-color: #f4f4f4;
		border-radius: 10px;
		margin-top: 4px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 4px;
		}

		&.active {
			background-color: #e0eef9;
			color: #195ea9;
		}
	}
}

//---pageHeader with tabMenu------//
@mixin headerWithTab {
	.l__pageHeader {
		margin-bottom: 10px;
	}

	.divider {
		display: none;
	}

	.pageHeader-btn-group {
		top: 74px;
		z-index: 1;

		@media screen and (max-width: 768px) {
			top: 64px;
		}
	}

	.c__tabMenu {
		margin-bottom: 30px;

		&Item {
			@media screen and (max-width: 890px) {
				font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (890 - 768))) !important;

				&:not(:last-child) {
					margin-right: 10px !important;
				}
			}
		}
	}
}

//---스위치 라디오------//
@mixin switchRadio {
	.c__radio-wrap {
		padding: 3px;
		background-color: #f8f8f8;
		border-radius: 4px;
	}

	.c__radio {
		flex: 1;
		height: 100%;
		color: #999;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&:not(:last-child) {
			margin-right: 2px;
		}

		.checkmark {
			width: 100%;
			height: 29px;
			border: none;
			position: absolute;
			margin: 0;

			&:after {
				width: inherit;
				height: inherit;
				border-radius: 4px;
				background-color: #fff;
			}
		}

		input:checked ~ .radio-label {
			color: #333;
			font-weight: 500;
		}
	}

	.radio-label {
		z-index: 1;
	}
}

//TODO mixin 정리 필요

//---말줄임------//
@mixin ellipsis {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

//---스크롤------//
@mixin customScroll($direction, $invisible: false) {
	-ms-overflow-style: none;

	&::-webkit-scrollbar-corner {
		background: transparent;
	}

	/*!-------------------세로 스크롤*/
	@if ($direction == scroll-y) {
		overflow-x: hidden;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 10px;
			transition: 0.3s;
		}

		&::-webkit-scrollbar-button {
			height: 3px;
			background: transparent;
		}

		&::-webkit-scrollbar-track {
			border-radius: 8px 10px 10px 8px;
			background-clip: padding-box;
			border: 2px solid transparent;
			border-right-width: 3px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #ccc;
			border-radius: 8px 10px 10px 8px;
			height: 40%;
			transition: 0.3s;
			background-clip: padding-box !important;
			border: 2px solid transparent;
			border-right-width: 3px;

			&:hover {
				background: #999;
			}
		}
	}

	/*!-------------------가로 스크롤*/
	@if ($direction == scroll-x) {
		overflow-x: auto;
		overflow-y: hidden;

		&::-webkit-scrollbar {
			height: 10px;
			transition: 0.3s;
		}

		&::-webkit-scrollbar-button:horizontal {
			width: 1px;
			background: transparent;
		}

		&::-webkit-scrollbar-track:horizontal {
			background: #dbdcde;
			background-clip: padding-box;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			border-top: 3px solid transparent;
			border-bottom: 2px solid transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb:horizontal {
			background: #b2b4b9;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			width: 40%;
			transition: 0.3s;
			background-clip: padding-box !important;
			border-top: 3px solid transparent;
			border-bottom: 2px solid transparent;

			&:hover {
				background: #999;
			}
		}
	}

	/*!-------------------마우스 오버 시에 나타나는 스크롤*/
	@if $invisible {
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			//background: #8d8d8d;
		}

		/*!-------------------마우스 오버 가로 스크롤*/
		@if ($direction == scroll-x) {
			&::-webkit-scrollbar {
				height: 6px;
			}

			&::-webkit-scrollbar-track {
				border-radius: 6px;
				background: #eee;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background-color: #d7d7d7;
				border-radius: 6px;

				&:hover {
					background: #999;
				}
			}
		}

		/*!-------------------마우스 오버 세로 스크롤*/
		@if ($direction == scroll-y) {
			&::-webkit-scrollbar {
				width: 8px;
				transition: 0.3s;
			}

			&::-webkit-scrollbar-button {
				height: 3px;
				background: transparent;
			}

			&::-webkit-scrollbar-track {
				border-radius: 5px;
				background: transparent;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background-color: transparent;
				border-radius: 5px;
				height: 5px;
			}
		}

		&:hover {
			transition: 0.3s;

			&::-webkit-scrollbar-track:hover {
				background: #eee;
				background-clip: padding-box;
				border: 2px solid transparent;
			}

			&::-webkit-scrollbar-thumb {
				transition: 0.3s;
				background: #d7d7d7;
				background-clip: padding-box;
				border: 2px solid transparent;

				&:hover {
					background: #999;
					background-clip: padding-box;
					border: 2px solid transparent;
				}
			}
		}
	}
}

//---Form > 필수항목 *표시 위치 표시------//
@mixin markPosition($position) {
	$markPositionValues: (
		"front": before,
		"back": after,
	);
	$pseudoSelector: map-get($markPositionValues, $position);

	.f__label:#{$pseudoSelector} {
		display: inline-block;
		content: "\002A";
		color: $error-color;
		margin-left: 4px;
		height: 9px;
		line-height: 1;
	}
}

//모달 100vh 벗어날 경우 스크롤
@mixin modalScroll {
	max-height: 100vh;
	overflow-y: auto;
	padding-top: 80px;
	padding-bottom: 80px;
	align-items: flex-start;
}

//---flex layout------//
//@mixin flex($direction, $IsWrap:true, $inline:false ){
//  @if($inline == inline){
//    display: inline-flex;
//  }
//  @else if($inline == false){
//    display: flex;
//  }
//  @if($IsWrap == nowrap){
//    flex-wrap: nowrap;
//  }
//  flex-direction: $direction;
//}
