@charset "UTF-8";

/***
  * 카드 컴포넌트
*/

//카드 공통
.c__card,
.c__card-fit {
	width: 100%;
	background: #fff;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
}

//카드 높이 = 컨텐츠
.c__card {
	height: min-content;
}

//카드 높이 = 화면 전체
.c__card-fit {
	height: 100%;
	max-height: $fitCardHeight;
	overflow-y: auto;
}

//카드 리스트
.c__cardRow {
	width: calc(100% + 20px); //1801 ~ 1920 4박스, 기본
	height: auto;
	margin: 0 -15px;

	.c__card {
		display: inline-block;
		width: calc(25% - 20px);
		height: 300px;
		margin: 0 10px 20px;
		vertical-align: middle;

		//1921 ~ 4박스 이상
		@media screen and (min-width: 1921px) {
			max-width: 350px;
		}

		//1024 ~ 1250 3박스
		@media screen and (max-width: 1250px) and (min-width: 1024px) {
			width: calc(33.3% - 20px);
		}

		//~ 1024 2박스
		@media screen and (max-width: 1024px) {
			width: calc(50% - 20px);
		}
	}
}

.c__card-fit {
	padding: 20px;
}
