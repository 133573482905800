@charset "UTF-8";

.c__spin {
	display: inline-block;
	width: 2em;
	height: 2em;
	border: 3px solid rgba(#ccc, 0.3);
	border-radius: 50%;
	border-top-color: $border-color;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
