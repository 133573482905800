@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 인사 > 근태 > 내 스케줄
*/
/*---상단 progress bar 섹션---*/
.p__schedule .my-schedule {
	.schedule-summary {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		width: 100%;
		flex-wrap: wrap;
	}

	//주 00시간 프로그래스바
	.schedule-status-section {
		height: 100%;
		display: flex;
		padding: 30px;

		@media screen and (max-width: 768px) {
			padding: 25px 20px;
		}

		.schedule-status {
			width: calc(100% / 3);
			min-width: 175px;
			height: 50px;

			&:not(:last-child) {
				margin-right: 55px;

				@media screen and (max-width: 768px) {
					margin-right: 12px;
				}
			}
		}
	}

	.schedule-status {
		.title-wrap {
			display: flex;
			align-items: center;
			margin-bottom: 12px;

			@media screen and (max-width: 768px) {
				flex-direction: column;
				align-items: normal;
			}
		}

		.title {
			height: 23px;
			color: #333;
			font-weight: 500;
			font-size: 1rem;
			line-height: 23px;
			white-space: nowrap;
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;

			@media screen and (max-width: 768px) {
				margin-bottom: 15px;
			}
		}

		.time {
			color: #666;
			font-size: 1.25rem;
			line-height: 16px;
			white-space: nowrap;
			margin-left: 12px;

			@media screen and (max-width: 768px) {
				font-size: 18px;
				margin-left: 0;
			}

			b {
				font-weight: 500;
				font-size: 1.5rem;

				@media screen and (max-width: 768px) {
					font-size: 21px;
				}
			}
		}

		.progress-step-bar {
			overflow: hidden;
		}
	}

	.progress-step-bar {
		width: 100%;
		height: 14px;
		border-radius: 8px;
		position: relative;
		background: #e8e8e8;

		.progress {
			height: inherit;
			border-radius: 9px;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			//주 정규근무 시간
			&.expect {
				background: linear-gradient(91.46deg, #4adede 17.88%, #00c7ff 88.22%);
			}

			//주 초과근무 시간
			&.overtime {
				background: linear-gradient(90.75deg, #f8d90f 34.3%, #fe7a15 83.96%);
			}

			//주 휴가사용 시간
			&.holiday {
				background: linear-gradient(90.96deg, #7be495 28.52%, #56c596 67.55%);
			}
		}
	}

	//출근휴게시간
	.work-schedule-card {
		width: 115px;
		height: 140px;
		margin: 0 30px 0 0;
		flex-shrink: 0;

		.title {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 40px;
			border-bottom: 1px solid #d7d7d7;
			color: #333;
			letter-spacing: -0.06em;
		}

		.time-wrap {
			text-align: center;
			width: 100%;
			height: calc(100% - 40px);
			padding: 18px 12px 12px;
			position: relative;

			> svg {
				margin: 0 auto 12px;
			}

			.time {
				font-size: 20px;
				letter-spacing: -0.06em;

				&.work {
					font-family: "Poppins", sans-serif;
					font-weight: 300;
				}
			}
		}

		.btn-setting {
			width: 24px;
			height: 24px;
			position: absolute;
			bottom: 6px;
			right: 6px;

			> svg {
				fill: $primary-color;
			}
		}
	}
}

/*---하단 카드---*/
.p__schedule {
	@include headerWithTab;

	.week-schedule-section {
		padding: 24px 30px 42px;
	}

	.top-control-wrap {
		@include switchRadio;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 20px;
		border-bottom: 1px solid $border-color;

		@media screen and (max-width: 768px) {
			align-items: unset;
		}

		.date-wrap {
			display: flex;
			align-items: center;

			@media screen and (max-width: 768px) {
				flex-direction: column;
			}

			.week-period {
				font-family: "Poppins", sans-serif;
				color: #666;
				font-size: 1.25rem;
				font-weight: 500;
				white-space: nowrap;
				margin-right: 13px;

				@media screen and (max-width: 768px) {
					margin-bottom: 12px;
				}
			}

			.btn-wrap {
				display: flex;
				align-items: center;
			}

			.prev-btn,
			.next-btn {
				width: 35px;
				height: 35px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $primary-color;
				border-radius: 2px;
				transition: 0.3s;

				svg {
					fill: $primary-color;
				}

				&:hover {
					background-color: $primary-color;

					svg {
						fill: #fff;
					}
				}
			}

			.next-btn {
				margin: 0 6px 0 4px;
				transform: rotate(180deg);
			}

			.btn-today {
				width: 70px;
				height: 35px;
				border-radius: 2px;
			}
		}

		.wrap {
			width: calc(100% / 2 + 66px);
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width: 768px) {
				flex-direction: column;
				align-items: flex-end;
			}
		}

		.c__radio-wrap {
			width: 150px;
			height: 35px;

			@media screen and (max-width: 768px) {
				order: 2;
			}

			.radio-label {
				font-family: "Poppins";
				font-weight: 600 !important;
				padding: 0;
			}
		}

		.schedule-wrap {
			text-align: right;

			.rest-period {
				color: #666;
				line-height: 20px;
				letter-spacing: -0.06em;

				b {
					color: $primary-color;
					font-weight: 500;
					letter-spacing: -0.06em;
					margin-left: 6px;
				}
			}

			.schedule-type-wrap {
				display: flex;
				margin-top: 10px;

				@media screen and (max-width: 768px) {
					display: none;
				}

				.vacation-name {
					height: 45px;
					font-size: 0.75rem;
					line-height: 17px;
					display: inline-flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					border-radius: 8px;
					padding: 0 8px;

					&:not(:last-child) {
						margin-right: 6px;
					}

					&:before {
						width: 8px;
						height: 8px;
						margin: 0 0 4px;
					}

					//-- 근무
					&.work {
						background: rgba(63, 182, 248, 0.05);
					}
					//-- 휴가
					&.annual {
						background: rgba(3, 219, 192, 0.05);
					}
					//-- 외근
					&.businessTrip {
						background: rgba(159, 119, 235, 0.05);
					}
					//-- 재택
					&.remoteWork {
						background: rgba(255, 112, 146, 0.05);
					}
					//-- 초과
					&.overtime {
						background: rgba(252, 198, 9, 0.05);
					}
				}
			}
		}
	}
}

.p__schedule .schedule-table {
	&.default-wrap .td:not(:last-child) {
		border-right: 1px solid $border-color;
	}

	// default, detail 공통
	.row {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $border-color;
	}

	.row .date {
		min-width: 135px;
		width: 9.44%;
		color: #666;
		line-height: 20px;
		padding-left: 20px;

		.today-badge {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 18px;
			font-weight: 500;
			font-size: 0.625rem;
			border-radius: 4px;
			border: 1px solid $primary-color;
			color: #fff;
			background: $primary-color;
		}

		&.today {
			color: #333;
			font-weight: 500;
		}

		&.sat {
			color: #3d83f9;
		}

		&.sun {
			color: #f93d3d;
		}
	}

	.row .schedule-type {
		width: 100%;
		padding: 15px 30px;

		li {
			line-height: 30px;
			color: #333;
			text-transform: capitalize;

			&:not(:last-child) {
				margin-right: 20px;
			}
		}

		.day-off {
			color: #999;
		}

		&:not(:last-child) {
			border-left: 1px solid $border-color;
		}
	}

	.row .modify-wrap {
		min-width: 56px;
		width: 3.92%;
		justify-content: center;

		.btn-modify {
			width: 30px;
			height: 30px;
			border: 1px solid #999;
			border-radius: 4px;
			transition: 0.3s;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				fill: #999;
			}

			&:hover {
				border-color: $primary-color;
				background: $primary-color;

				svg {
					fill: #fff;
				}
			}
		}
	}

	// detail
	.row .progress-step-bar {
		height: 12px;
		margin: 0 10px;
		display: flex;

		> li {
			flex-shrink: 0;
			position: relative;
			height: inherit;
			border-radius: unset;

			&:first-child {
				border-top-left-radius: 9px;
				border-bottom-left-radius: 9px;
			}

			&:last-child {
				border-top-right-radius: 9px;
				border-bottom-right-radius: 9px;
			}

			.time-badge-wrap {
				width: 100%;
				height: 26px;

				&.hide {
					&:hover,
					&:focus {
						cursor: pointer;

						.time-badge {
							width: 100px;
							opacity: 1;
							z-index: 99;
							transition: 0.3s ease-in-out;
						}
					}
				}
			}

			.time-badge {
				width: calc(100% - 20px);
				max-width: 150px;
				min-width: 100px;
				height: 26px;
				color: #666;
				font-size: 12px;
				border: 1px solid #e4e4e4;
				border-radius: 12px;
				background: #fff;
				box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;

				@media screen and (max-width: 1247px) {
					width: 18.2%;
				}

				@media screen and (max-width: 768px) {
					display: none;
				}
			}
		}
	}

	.td {
		width: 100%;
		min-height: 60px;
		display: flex;
		align-items: center;
		flex-flow: wrap;
	}
}

/*---스케줄 수정, 등록모달---*/
.vacation-setting-modal .modal-content {
	//$modalMaxHeight: calc(100vh - 70px - 102px - 50px);
	//max-height: $modalMaxHeight;

	.plus-btn {
		margin-bottom: 20px;
	}

	.flexible-time-wrap {
		display: flex;
		align-items: center;
		width: 100%;
		height: 75px;
		padding: 0 62px 0 30px;
		background: #f8f8f8;
		border-bottom: 1px solid #d7d7d7;

		.f__label {
			width: 190px;
			margin-right: 18px;
		}
	}

	.schedule-form-wrap {
		//@include customScroll(scroll-y);
		//max-height:calc(#{$modalMaxHeight} - 75px - 60px);
		padding: 30px;
	}

	.schedule-row {
		&:not(:last-child) {
			margin-bottom: 10px;
		}

		.f__feedback {
			position: relative;
		}

		&.has-error {
			.c__datePicker,
			.f__item:not(:disabled):not([readonly]) {
				border-color: $error-color;
			}
		}
	}

	.schedule-form {
		display: flex;
		align-items: flex-start;

		.c__select {
			width: 190px;
			margin-right: 18px;
		}

		.f__control-wrap {
			flex: 1;
			display: flex;

			.c__input {
				width: 160px;
			}

			.dash {
				margin: 0 6px;
				line-height: 35px;
			}

			.react-datepicker__input-container::after {
				background-image: url("../../../../img/ico-time.svg");
			}
		}

		.f__control > .f__feedback {
			display: none !important;
		}

		.btn-round-delete {
			margin-top: 8px;
			margin-left: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.3s;

			svg {
				width: 20px;
				height: 20px;
				fill: #999;
			}

			&:hover svg {
				fill: $primary-color;
			}
		}
	}
}

/*---기본출근시간 일괄변경 모달---*/
.default-workTime-modal {
	.modal-content {
		padding: 20px;
		background: #f8f8f8;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		border-radius: 8px;
	}

	.modal-header {
		height: 50px;
		padding: 0 5px 0 15px;

		.modal-title {
			font-size: 0.875rem;
			font-weight: 500;
			letter-spacing: -0.06em;
		}

		.modal-close {
			color: #9a9a9a;

			svg {
				width: 10px;
				height: 10px;
			}
		}
	}

	.c__radio {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 40px;
		padding: 0 15px;
		background: #fff;
		border: 1px solid #d7d7d7;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		transition: 0.3s ease-in-out;

		&.selected {
			border: 1px solid $primary-color;
			background: #f5fbff;
		}

		&:hover {
			background: #f5fbff;
		}

		&:not(:last-child) {
			margin-bottom: 3px;
		}

		.checkmark {
			width: 22px;
			height: 22px;
			margin-right: 0;
			background: #fff;

			&:after {
				width: 10px;
				height: 8px;
				background: url("../../../../img/icon-check.svg");
			}
		}
	}

	.modal-footer {
		height: 60px;

		> button {
			width: 72px;
			height: 30px;
			font-size: 12px;
			margin: 15px 0;

			&:first-child:not(:last-child) {
				margin-right: 6px;
			}
		}
	}
}

//datepicker disable 수정
.regular-work-time li.react-datepicker__time-list-item--disabled {
	display: none !important;
}
