@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 이용약관, 개인정보처리방침
*/

.p__policy {
	background: {
		image: url("../../../img/bg-login.jpg");
		position: center;
		size: cover;
		repeat: no-repeat;
	}

	.c__card {
		width: 800px;
		height: 760px;
		flex-flow: column;
	}

	.terms-card-head {
		height: 90px;
		min-height: 91px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid $border-color;
		margin-bottom: 35px;

		.head-title {
			font-size: 24px;
			font-weight: 500;
			color: #333;
			letter-spacing: -0.075em;
		}
	}

	.tabMenu-wrap {
		padding: 0 6px 50px 60px;

		.c__tabMenu {
			width: calc(100% - 60px);

			.c__tabMenuItem {
				font-size: 1rem;
				font-weight: 400;
			}
		}

		.article {
			@include customScroll(scroll-y);

			overflow-y: auto;
			width: 100%;
			max-height: 535px;
			padding: 0 54px 0 14px;
			white-space: pre-wrap;
			line-height: 1.57;
			font-size: 0.875rem;
			font-weight: 300;
			letter-spacing: -0.025em;
			color: #666;

			b {
				font-weight: 500;
			}

			table {
				width: 100%;
				th {
					text-align: center;
					background-color: #e8e8e8;
					font-weight: 400;
				}
			}

			table,
			th,
			td {
				border: 1px solid #666;
				border-collapse: collapse;
				padding: 10px 5px;
			}
		}
	}
}

// 채널톡 위치
.p__policy .channel-bot {
	position: fixed;
	bottom: 80px;
}
