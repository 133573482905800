@charset "UTF-8";
@import "../utils/var";

#user.channel-bot {
	width: fit-content;
	height: initial;
	position: fixed;
	bottom: 75px;
	left: unset;
	right: 40px;
}

.channel-bot {
	min-width: 130px;
	height: 130px;
	flex-shrink: 0;
	position: sticky;
	left: calc(100% - 158px);
	bottom: 40px;
	z-index: 1200;
	cursor: default !important;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.bubble {
		position: absolute;
		top: -60px;
		right: 1px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 43px;
		background: #fff;
		box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
		border-radius: 50px;
		color: #333;
		letter-spacing: -0.025em;
		transition: 0.3s ease-in-out;

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 0;
			height: 0;
			border: 7px solid transparent;
			border-top-color: #fff;
			border-bottom: 0;
			margin-left: 23px;
			margin-bottom: -7px;
		}
	}

	.ico-bot {
		width: 60px;
		height: 60px;
		background: $primary-color;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.13);
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			cursor: pointer;
		}
	}
}

// 로그인 후 대시보드
.p__newMain #user.channel-bot {
	position: sticky;
	left: calc(100% - 95px);
	bottom: 40px !important;
	margin-bottom: 8px;
}

// 로그인
.p__login .channel-bot {
	position: fixed;
	bottom: 80px;
}
