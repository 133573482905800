@charset "UTF-8";
@import "../../sass/utils/var";

.payment-banner {
	height: 60px;
	background-color: #1b36b0;
	padding: 17px 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.main-desc {
		color: #feca40;
		font-size: 18px;
		font-weight: 500;
		letter-spacing: -1.08px;
		display: flex;
		align-items: center;

		&::after {
			content: "";
			width: 1px;
			height: 20px;
			display: inline-block;
			background-color: #fff;
			margin: 0 16px;
		}
	}

	.sub-desc {
		color: #fff;
		letter-spacing: -0.84px;
	}

	.btn-more {
		height: 30px;
		color: #0078cb;
		font-size: 12px;
		background-color: #fff;
		margin-left: 16px;
		padding: 0 16px;
	}
}
