@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

.invite-modal {
	.c__noData {
		height: 500px;
	}

	.modal-content {
		display: flex;
		flex: 1;

		.team-checkbox {
			width: calc(100% - 300px);

			.checkbox-header {
				display: flex;
				padding: 10px 20px;
				align-items: center;
				justify-content: space-between;

				.c__search {
					width: 100%;
				}
			}
			.checkbox {
				margin-right: 6px;
			}
			.member-item {
				padding: 13px 20px;
			}
		}

		.team-select {
			min-width: 300px;
			border-left: 1px solid #e4e4e4;

			.select-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 45px;
				margin: 0 16px;
				border-bottom: 1px solid #d7d7d7;

				.select-member,
				.select-member span {
					font-weight: 500;

					span {
						font-weight: 700;
						color: $primary-color;
					}
				}
			}
			.profile-img {
				margin-left: 16px;
			}
			.btn-delete {
				color: $primary-color;
			}
			.btn-close {
				width: 30px;
				margin-right: 16px;
			}
		}

		.member-list {
			@include customScroll(scroll-y);
			max-height: 554px;
			min-height: 554px;

			.member-item {
				display: flex;
				align-items: center;
				position: relative;
				height: 60px;

				&:hover {
					background-color: #f3f9fd;
					cursor: pointer;
				}

				// 클릭되었을 때
				&.active {
					background-color: #f3f9fd;
					border-color: $primary-color;
				}
			}
			.profile-img {
				width: 40px;
				height: 40px;
				margin-right: 8px;
				border-radius: 50%;
				flex-shrink: 0;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 50px;
				}
			}
			.profile-name {
				font-size: 1rem;
				color: #333;
				margin-right: 4px;
			}
			.profile-position,
			.profile-email {
				color: #999;
				font-size: 0.75rem;
			}
			.profile-email {
				margin-top: 6px;
			}
			.c__dropdown {
				top: calc(100% - 40px);
				right: 15px;
			}
		}
	}
}
