@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * new 랜딩페이지 > 헤더
*/

.landing-header {
	width: 100%;
	height: 70px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 9;

	@media screen and (max-width: 1024px) {
		height: 128px;
	}

	.wrap {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.landing-header .logo-wrap {
	display: flex;
	align-items: center;
	cursor: pointer;

	.img-wrap {
		width: 111px;
		height: 36px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	span {
		font-family: Jost;
		font-size: 20px;
		font-weight: 300;
		line-height: 1.5;
		letter-spacing: -0.5px;
		color: #313267;
		display: flex;
		align-items: center;

		&:before {
			content: "";
			width: 1px;
			height: 18px;
			margin: 0 10px;
			background: #7b7b8e;
			display: inline-block;
		}
	}
}

.landing-header .nav {
	height: 70px;
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);

	&-item,
	&-item a {
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 700;
		letter-spacing: -0.45px;
		color: $landing-color;
		transition: 0.3s;
		white-space: nowrap;

		@media screen and (max-width: 1400px) {
			font-size: 16px;
		}

		@media screen and (max-width: 1024px) {
			display: none;
		}

		&:not(.disabled):hover {
			color: $primary-color;
			cursor: pointer;
		}

		&.selected {
			color: $primary-color;
		}

		&.disabled {
			opacity: 0.35;
			cursor: not-allowed;
		}
	}

	&-item {
		margin: 0 20px;

		@media screen and (max-width: 1400px) {
			margin: 0 1vw;
		}
	}
}

.landing-header .right-wrap {
	display: flex;
	align-items: center;
	
	.translation-wrap {
		margin-right: 24px;
	}
}

.landing-header .btn-login {
	width: 90px;
	height: 40px;
	border-radius: 6px;
	color: $landing-color;
	border: 1px solid $landing-color;
	position: relative;
	transition: 0.3s ease-in-out;

	@media screen and (max-width: 1024px) {
		display: none;
	}

	&:not(:disabled):hover,
	&:not(:disabled).active {
		color: #fff;
		background-color: $landing-color;
		cursor: pointer;
	}

	.nav-dropdown {
		width: 90px;
		position: absolute;
		top: 45px;
		left: 0;
		border-radius: 5px;
		background: #fff;
		box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
		z-index: 99;

		.dropdown-link {
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 15px;
			font-weight: 400;
			letter-spacing: -0.05em;
			color: #666;

			&:first-child {
				border-bottom: 1px solid #e8e8e8;
			}

			&:not(:disabled):hover {
				color: $landing-color;
			}
		}
	}
}

.landing-header .login-wrap {
	@media screen and (max-width: 1024px) {
		display: flex;
		align-items: center;
	}
}

.landing-header .btn-start {
	width: 134px;
	height: 40px;
	background: $landing-color;
	border-radius: 6px;
	color: #fff;
	position: relative;
	transition: 0.3s ease-in-out;
	margin-left: 8px;

	@media screen and (max-width: 1024px) {
		width: 200px;
		height: 60px;
		font-size: 24px;
		letter-spacing: -0.025em;
	}
}

.landing-header .btn-menu {
	display: none;
	fill: $landing-color;

	@media screen and (max-width: 1024px) {
		display: flex;

		.menu-text {
			display: none;
			//display: flex;
			//align-items: center;
			//font-family: Poppins;
			//font-size: 32px;
			//font-weight: 500;
			//letter-spacing: -1.28px;
			//color: #   333;
		}
		svg {
			margin-left: 40px;
		}
	}
}

.landing-sidebar {
	height: 0;
	transform: translateX(100%);
	overflow-x: hidden;

	@media screen and (max-width: 1024px) {
		width: 71.579vw; //680
		height: 100vh;
		background: #000;
		position: fixed;
		right: 0;
		top: 0;
		padding: 14.737vw 10.526vw;
		z-index: 1300;
		transition: 0.5s;

		&.active {
			transform: translateX(0);
		}

		&.inactive {
			transform: translateX(100%);
		}
	}

	.btn-menu {
		position: absolute;
		top: 50px;
		right: 48px;

		svg {
			fill: #fff;
		}
	}

	.nav {
		margin: 17.97% 0;

		a {
			display: inline-block;
			margin-bottom: 56px;
		}

		.nav-item {
			color: #fff;
			width: fit-content;
			font-size: 48px;
			font-weight: 500;
			letter-spacing: -0.025em;
			cursor: pointer;

			&:not(:last-child) {
				margin-bottom: 56px;
			}
		}
	}

	.site-group {
		.btn-site {
			color: #fff;
			font-size: 36px;
			font-weight: 500;
			background: #1a1a1a;
			border-radius: 6px;
			width: 100%;
			height: 100px;

			&:first-child {
				margin-bottom: 32px;

				&:hover {
					background: $primary-color;
				}
			}

			&:last-child:hover {
				background: #6b44ff;
			}
		}
	}
}
