@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 마이페이지 > 관리자메뉴 > 미인증
*/

.p__admin-business .c__card.uncertified {
	.card-top {
		display: flex;
		justify-content: space-between;
		padding-top: 40px;
		padding-right: 40px;
	}

	.content {
		padding-top: 70px;

		> dt {
			color: #999;
		}
	}
}
