@charset "UTF-8";
@import "../utils/var";

/***
  * 라운드 체크 박스 커스텀
  	<RoundChkBoxIcon /> svg와 사용
*/

.round-checkbox {
	width: 23px;
	display: inline-block;
	position: relative;
	margin: 0 auto;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		/* Hide the browser's default checkbox */
		position: absolute;
		cursor: pointer;
		height: 0;
		width: 0;

		&:after {
			content: "";
			width: 20px;
			height: 20px;
			border: 1px solid #d9d9d9;
			border-radius: 50%;
			display: inline-block;
		}

		&:checked:after {
			border: none;
		}

		&:checked ~ svg {
			/* When the checkbox is checked*/

			fill: $primary-color !important;
		}

		&:not(:checked) ~ svg {
			opacity: 0;
		}
	}

	&:hover input:not(:checked):after {
		border-color: $primary-color;
		transition: 0.3s;
	}
}
