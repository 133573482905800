@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";
/***
  * 마이페이지 > 권한설정 > 라이센스(요금안내)
*/

$pro-color: #6b44ff;
$enterprise-color: #210080;

.payment-guide-wrap {
	max-width: 1230px;
	padding: 35px 50px 50px;
	margin: 0 auto 30px;

	@media screen and (max-width: 1240px) {
		padding: 30px;
	}
}

// 상단 제목
.payment-guide-wrap .title-wrap {
	@include switchRadio;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $border-color;
	padding-bottom: 12px;
	margin-bottom: 40px;

	@media screen and (max-width: 1210px) {
		padding-bottom: 10px;
		margin-bottom: 20px;
	}

	h3 {
		color: #333;
		font-size: 1.375rem;
		line-height: 32px;
		letter-spacing: -0.06em;

		@media screen and (max-width: 1210px) {
			font-size: calc(18px + (22 - 18) * ((100vw - 768px) / (1210 - 768)));
		}
	}

	.c__radio-wrap {
		width: 198px;
		height: 46px;

		@media screen and (max-width: 1210px) {
			width: calc(126px + (198 - 126) * ((100vw - 768px) / (1210 - 768)));
			height: calc(34px + (46 - 34) * ((100vw - 768px) / (1210 - 768)));
		}

		.checkmark {
			height: 100%;
		}

		.radio-label {
			font-size: 1rem;

			@media screen and (max-width: 1210px) {
				font-size: calc(12px + (16 - 12) * ((100vw - 768px) / (1210 - 768)));
			}
		}
	}
}

// 요금 방식 카드
.payment-guide-wrap .payment-option {
	display: flex;
	margin-bottom: 50px;

	@media screen and (max-width: 1210px) {
		flex-direction: column;
		margin-bottom: 40px;
	}

	.c__card {
		width: calc(100% / 3 - 10px);
		height: 511px;
		text-align: center;
		padding: 40px;
		box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.05);
		border: 1px solid $border-color;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		align-content: center;

		@media screen and (max-width: 1240px) {
			padding: calc(20px + (40 - 20) * ((100vw - 768px) / (1240 - 768)))
				calc(30px + (40 - 30) * ((100vw - 768px) / (1240 - 768)));
		}

		@media screen and (max-width: 1210px) {
			width: 100%;
			height: 158px;
			flex-direction: row;
			align-items: center;
		}

		&:not(:last-child) {
			margin-right: 40px;

			@media screen and (max-width: 1240px) {
				margin-right: 20px;
			}

			@media screen and (max-width: 1210px) {
				margin-right: 0;
				margin-bottom: 16px;
			}
		}

		.top-wrap {
			flex: 1;
			display: flex;
			flex-direction: column;

			@media screen and (max-width: 1210px) {
				min-width: 300px;
				align-items: flex-start;
				border-right: 1px solid #d7d7d7;
				margin-right: 20px;
				padding-right: 20px;
				flex-flow: wrap;
				flex-direction: row;

				.price-wrap {
					display: none;
				}
			}
		}

		.bottom-wrap .price-wrap {
			display: none;
		}

		.bottom-wrap {
			@media screen and (max-width: 1210px) {
				width: 20%;
				min-width: 148px;

				.price-wrap {
					display: block;

					.payment-price {
						font-size: 12px;
					}

					span {
						font-size: 18px;
						line-height: 16px;
						margin: 0 2px;
					}

					.discount-wrap {
						margin-bottom: 0;
					}
				}

				.btn__solid {
					width: 80px !important;
					height: 30px !important;
					font-size: 12px;
					margin-top: 16px;
				}
			}
		}

		.payment-intro {
			line-height: 20px;
			letter-spacing: -0.06em;
			margin: auto 0;
			white-space: nowrap;

			@media screen and (max-width: 1210px) {
				font-size: calc(10px + (14 - 10) * ((100vw - 768px) / (1210 - 768)));
				order: 2;
				text-align: left;
				font-weight: 300;
				//font-size: 10px;
				line-height: 14px;
				margin: 6px 0;
				width: 100%;

				br {
					display: none;
				}
			}
		}

		.divider {
			content: "";
			width: 100%;
			height: 1px;
			background: #d7d7d7;
			display: inline-block;
			margin: 0 auto;

			@media screen and (max-width: 1210px) {
				display: none;
			}
		}

		.payment-desc {
			font-size: 1rem;
			line-height: 23px;
			letter-spacing: -0.06em;
			color: #333;
			white-space: nowrap;

			@media screen and (max-width: 1210px) {
				line-height: 18px;
			}

			@media screen and (max-width: 1210px) {
				width: 100%;
				order: 4;
				padding: 0 !important;
				text-align: left;
				font-weight: 400;
				font-size: calc(12px + (16 - 12) * ((100vw - 768px) / (1210 - 768)));
				line-height: 18px;
			}
		}

		.btn__solid {
			width: 100%;
			height: 50px;
			font-size: 1rem;
			line-height: 23px;
			letter-spacing: -0.06em;
		}

		// 컬러설정
		@each $option, $color, $value in (free, $primary-color), (pro, $pro-color),
			(enterprise, $enterprise-color)
		{
			&.#{$option} {
				.payment-type,
				.payment-price span,
				.payment-intro {
					color: $color;
				}

				.btn__solid {
					background: $color;
					color: #fff;
				}

				// 이용중
				&.using {
					background: $color;

					h4,
					span,
					p {
						color: #fff;
					}

					// 이용중 세일 말풍선
					.balloon-sale {
						fill: #fff !important;
					}

					.sale-phrase {
						color: $pro-color !important;
					}

					.btn__solid {
						background: #fff;
						color: $color;
						cursor: initial;

						&:hover {
							background: #fff;
						}
					}
				}
			}
		}

		&.free .btn__solid:hover {
			background: #0090e0;
		}

		&.pro .btn__solid:hover {
			background: #4a2eb3;
		}

		&.enterprise .btn__solid:hover {
			background: #180150;
		}
	}

	.payment-type {
		font-family: "Poppins";
		font-weight: 500;
		font-size: 2rem;
		line-height: 48px;

		@media screen and (max-width: 1210px) {
			font-size: 22px;
			line-height: 22px;
		}
	}

	.payment-limit {
		font-size: 1rem;
		line-height: 23px;
		letter-spacing: -0.06em;
		color: #999;
		margin: 4px 0 16px;

		@media screen and (max-width: 1210px) {
			font-size: 10px;
			line-height: 12px;
			margin: 0 0 0 6px;
			padding-left: 6px;
			border-left: 1px solid #e4e4e4;
			align-self: center;
		}
	}

	.payment-price {
		font-size: 1.125rem;
		color: #333;
		white-space: nowrap;

		&:nth-child(2) {
			@media screen and (max-width: 1210px) {
				margin-top: 8px;
			}
		}

		span {
			font-weight: 500;
			font-size: 1.75rem;
			line-height: 41px;
			margin: 0 6px;

			&.disabled {
				color: #b2b2b2;
			}
		}
	}

	// 할인표시
	.discount-wrap {
		position: relative;
		margin-bottom: 22px;
		margin-top: -8px;

		@media screen and (max-width: 1210px) {
			margin-top: 0;
		}

		.balloon-sale {
			fill: $pro-color;
			position: absolute;
			top: -45px;
			right: 0;

			@media screen and (max-width: 1210px) {
				width: 28px;
				height: 28px;
				top: -22px;
				right: -11px;
			}
		}

		.sale-phrase {
			font-family: "Poppins";
			font-weight: 500;
			font-size: 1rem;
			line-height: 15px;
			text-align: center;
			color: #fff;
			position: absolute;
			top: -27px;
			right: 7px;

			@media screen and (max-width: 1210px) {
				font-size: 8px;
				top: -16px;
				right: -7px;
				letter-spacing: -0.04em;
			}
		}

		.arrow-sale {
			position: absolute;
			top: 55%;
			left: 9%;

			@media screen and (max-width: 1210px) {
				top: 50%;
			}
		}
	}

	.free .payment-desc {
		margin: auto 0;
	}

	.pro {
		position: relative;
		// 프로모션 종료표시
		&-motion {
			background-color: $pro-color;
			color: #fff;
			line-height: 14px;
			letter-spacing: -0.06em;
			padding: 8px 12px;
			border-radius: 0 0 10px 10px;
			position: absolute;
			top: 0;
			left: calc(50% - 40px);
			transform: translateX(calc(-50% - (-40px)));

			&.using {
				background-color: #fff;
				color: $pro-color;
			}

			@media screen and (max-width: 1210px) {
				left: 0;
				transform: translateX(30px);
				font-size: 0.75em;
				line-height: 12px;
			}
		}
	}

	.pro .payment-desc {
		margin: auto 0;

		@media screen and (max-width: 1210px) {
			br:first-child {
				display: none;
			}
		}
	}

	.enterprise .payment-intro br:first-child {
		display: block;
	}

	.enterprise .payment-desc {
		margin: auto 0;

		@media screen and (max-width: 1210px) {
			br:first-child {
				display: none;
			}
		}
	}
}

//  상세비교 테이블
.payment-guide-wrap .payment-detail-table {
	border-bottom: none;

	.th,
	.td {
		@media screen and (max-width: 1240px) {
			height: 40px;
			line-height: 40px;
		}
	}

	.td {
		color: #333;
	}

	.tr {
		border-bottom: none;

		.item-detail {
			flex: 1;
			min-width: 126px;
			padding-left: 0;
			text-align: left;
			line-height: 1.3;
			display: flex;
			align-items: center;

		}

		.item-free {
			flex: 3;
			min-width: 140px;
			text-align: center;
   display: flex;
   justify-content: center;
   margin-top: 15px;
		}

		.item-pro {
			flex: 3;
			min-width: 140px;
			text-align: center;
   display: flex;
   justify-content: center;
   margin-top: 15px;
		}

		.item-enterprise {
			flex: 3;
			min-width: 142px;
			text-align: center;
   display: flex;
   justify-content: center;
   margin-top: 15px;
		}
	}

	// 컬러설정
	@each $option, $color, $value in (free, $primary-color), (pro, $pro-color),
		(enterprise, $enterprise-color)
	{
		.item-#{$option} {
			color: $color;

			svg {
				fill: $color;

				@media screen and (max-width: 1240px) {
					width: 16px;
					height: 12px;
				}
			}
		}
	}

	.thead {
		background: unset;
		border-top: unset;
		border-bottom: 1px solid $border-color;

		.th {
			line-height: 40px;
		}

		.item-detail {
			font-size: 1.125rem;
			letter-spacing: -0.06em;

			@media screen and (max-width: 1240px) {
				font-size: calc(14px + (18 - 14) * ((100vw - 768px) / (1240 - 768)));
			}
		}

		.item-free,
		.item-pro,
		.item-enterprise {
			font-family: "Poppins";
			font-weight: 500;
			font-size: 1.5rem;

			@media screen and (max-width: 1240px) {
				font-size: calc(14px + (24 - 14) * ((100vw - 768px) / (1240 - 768)));
			}
		}
	}

	//tbody
	.tbody {
		.tr:hover {
			background: unset;
			cursor: initial;

			.td {
				color: #333;
			}
		}

		.item-detail {
			font-size: 1rem;
			letter-spacing: -0.06em;

			@media screen and (max-width: 1240px) {
				font-size: calc(12px + (16 - 12) * ((100vw - 768px) / (1240 - 768)));
			}
		}

		.c__tooltip-btn {
			margin-left: 8px;

			@media screen and (max-width: 1240px) {
				margin-left: 4px;
			}

			svg {
				fill: $primary-color;

				@media screen and (max-width: 1240px) {
					width: 12px;
					height: 12px;
				}
			}

			.c__tooltip {
				height: auto;
				top: -5px;
				left: 23px;
				transform: unset;
				text-align: left;

				&:after {
					top: 20%;
					left: -10px;
					border-color: transparent #c0e9ff transparent transparent;
				}

				&:before {
					top: 20%;
					left: -9px;
					border-color: transparent #eef9ff transparent transparent;
				}
			}
		}
	}
}

//  구독취소 버튼
.license-reply-button {
	width: 100%;
	max-width: 1230px;
	margin: 0 auto 50px;
	display: flex;
	justify-content: right;

	.reply-button {
		line-height: 20px;
		letter-spacing: -0.06em;
		color: #999;
		border-bottom: 1px solid #999;
		transition: 0.3s;

		&:not(:disabled):hover {
			color: $primary-color;
			border-color: $primary-color;
		}
	}
}
