@charset "UTF-8";
@import "src/assets/sass/utils/var";

//---기업 > 기업정보 상세,수정 공통 스타일---//

.c__card {
	//-----헤더
	.head-wrap {
		margin-bottom: 24px;

		//로고
		.company-logo .logo-wrap {
			width: 220px;
			height: 80px;
			margin-left: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 7px;
			border: 1px solid $border-color;
			background-color: #fafafa;
			font-weight: 300;
			letter-spacing: -0.7px;
			color: #999;
			transition: 0.3s ease-in-out;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			> span {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				width: 100%;
				height: 100%;
				font-weight: 300;
				position: absolute;
				cursor: pointer;
				transition: 0.3s ease-in-out;

				&:after {
					content: "";
					position: relative;
					bottom: -3px;
					left: 0;
					right: 0;
					display: inline-block;
					width: 80px;
					height: 1px;
					transition: 0.3s ease-in-out;
				}
			}
		}
	}

	.date {
		color: #999;
		font-size: 0.75rem;
		font-weight: 300;
		line-height: 1.67;
	}

	.company-name {
		color: #333;
		font-size: 2.8125rem;
		font-weight: 700;
		letter-spacing: -0.06em;
		text-align: center;

		@media screen and (max-width: 1200px) {
			margin-bottom: 32px;
			font-size: calc(40px + (44 - 40) * ((100vw - 768px) / (1200 - 768)));
		}
	}
}

.data-form {
	position: relative;

	section:not(:last-child) {
		margin-bottom: 40px;
	}

	.section-title {
		font-size: 1.25rem;
		//font-weight: 500;
		letter-spacing: -0.05em;
		color: #333;
		//font-size: 1.625rem;
		font-weight: 600;
		//letter-spacing: -.025em;
		border-bottom: 2px solid #333;
		padding-bottom: 13px;
	}
}

//기본정보
.input-group-section .c__form {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #ccc;

	.f__group.keyword-wrap,
	.f__group.c__addressGroup {
		width: 100%;
	}

	.f__group {
		width: 50%;
		min-height: 50px;
		padding: 7px 10px;

		&:not(:last-child) {
			border-bottom: 1px solid #ccc;
		}

		@media screen and (max-width: 1200px) {
			width: 100%;
		}
	}

	.f__label {
		width: 143px;
		font-weight: 500;
		letter-spacing: -0.025em;
		color: #333;
	}
}

// 사업자등록번호 인증여부
.registration-num svg {
	margin-left: 7px;
}

//기업개요 / 공개여부
.company-desc .section-title,
.private-setting .section-title {
	margin-bottom: 20px;
}

//공개여부
.private-setting {
	.switch {
		margin: 0 0 17px 10px;
	}

	.public-notice {
		margin-left: 12px;
	}

	// 아니오
	.private {
		color: #999;
		line-height: 1.38;
		.warning {
			color: $error-color;
		}
	}

	// 예
	.public {
		color: $primary-color;
	}
}

//기업정보 모달푸터 공통
.p__company-info .modal-footer {
	height: 80px;

	button {
		margin-top: 20px;
		width: 76px;
	}
}
