@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 팀원 > 설정 > 근무유형, 수당지급
*/

/*---근무유형, 수당지급 공통---*/
//----- card-wrap
.p__vacation .type-card-wrap,
.p__hr-setting .type-card-wrap {
	margin-top: 30px;
}

.p__vacation .bonus-card-wrap,
.p__hr-setting .bonus-card-wrap {
	margin-top: 5px;
}

//----- list
.p__vacation .card-wrap .type-card,
.p__hr-setting .card-wrap .type-card {
	max-width: 332px;
	height: 140px;
	border-radius: 8px;
	position: relative;
	cursor: pointer;

	.c__dropdown-wrap {
		width: 40px;
		height: 50px;
		z-index: 9;

		.c__dropdown {
			left: 23px;
			top: 27px;
		}

		.btn-dropdown {
			width: inherit;
			height: inherit;
		}
	}

	.content {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		//height: inherit;
		padding: 30px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.type {
		display: flex;
		justify-content: space-between;

		span {
			color: #333;
			font-weight: 500;
			font-size: 1.125rem;
			line-height: 20px;
			letter-spacing: -0.06em;

			&.main-tag {
				width: 58px;
				height: 24px;
				color: #fff;
				font-size: 0.75rem;
				letter-spacing: -0.06em;
				background: $primary-color;
				border-radius: 4px;
				padding: 6px 8px;
				display: inline-flex;
				align-items: center;
			}
		}
	}

	.time-wrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: -4px;
	}

	.week-time {
		color: #666;
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 20px;
		margin: 4px 6px 0 0;
		white-space: nowrap;
	}

	.total-time {
		display: inline-block;
		width: fit-content;
		min-height: 20px;
		line-height: 19px;
		padding: 0 6px;
		margin-top: 4px;
		color: $primary-color;
		font-size: 0.75rem;
		background: #eef9ff;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		word-break: break-word;
	}
}

/*---수당지급 항목 제목---*/
.p__vacation .type-title,
.p__hr-setting .type-title {
	color: #666;
	font-weight: 500;
	line-height: 29px;
	font-size: 1.25rem;
	margin: 20px 0 20px 14px;
}

/*---근무유형 추가하기 모달---*/
.type-add-modal {
	height: auto;

	.f__group:not(:last-child) {
		margin-bottom: 10px;
	}

	.f__label {
		width: 104px;
		min-width: 104px;
		line-height: 20px;
		margin-right: 95px !important;
	}
}

.type-add-modal .modal-content {
	@include switchRadio;
	@include customScroll(scroll-y);
	height: 400px;
	overflow-y: auto;
	max-height: 600px;
	padding: 30px;

	// 출퇴근시간 타임피커
	.commuting-time-group {
		position: relative;
		margin-top: 10px;

		.f__label {
			align-items: flex-start;
			line-height: 35px;
		}

		.plus-btn {
			position: absolute;
			right: 0;
			top: 0;
			width: 35px;
			height: 35px;
			background: #fff;
			border: 1px dashed $border-color;
			color: #d7d7d7;
			cursor: pointer;

			&:hover,
			&:focus {
				color: $primary-color;
				border-color: $primary-color;
			}

			svg {
				fill: currentColor;
				margin-right: 0;
				transition: none;
			}
		}
	}

	.commuting-time-group .row {
		display: flex;
		align-items: center;
		flex: 1;

		&:first-of-type.btn-round-delete {
			visibility: hidden;
		}

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		.f__group {
			flex: 1;
			max-width: 130px;
			margin-bottom: 0;
		}

		.dash {
			width: 18px;
			text-align: center;
		}

		.react-datepicker__input-container::after {
			background-image: url("../../../../img/ico-time.svg");
		}

		button {
			width: 39px;
			margin-left: 4px;

			&.btn-round-delete {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #999;
				cursor: pointer;

				&:hover {
					color: $primary-color;
				}

				svg {
					width: 20px;
					height: 20px;
					fill: currentColor;
				}
			}
		}
	}

	// 휴게시간 타임피커
	.rest-time-group .f__control-wrap {
		display: flex;
		align-items: center;

		.dash {
			margin: 0 4px 0 4px;
		}

		.react-datepicker__input-container::after {
			background-image: url("../../../../img/ico-time.svg");
		}
	}

	// 요일 checkbox 버튼
	.dateCheckbox {
		align-items: unset;

		.f__control {
			padding: 2px;
			background-color: #eef8ff;
			border-radius: 4px;

			&.has-error {
				margin-bottom: 21px;
			}
		}

		.checkbox-group {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
		}

		.checkbox {
			flex: 1;
			height: 100%;
			color: #999;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			&:not(:last-child) {
				margin-right: 2px;
			}
		}

		.checkbox-custom {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		.checkmark {
			width: 100%;
			height: 100%;
			position: absolute;
			margin-right: 0;
			background: transparent;
			border: none;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
		}

		.checkbox-label {
			color: #c1c1c1;
			font-size: 0.875rem;
			z-index: 1;
		}

		.checkbox-custom input:checked {
			& ~ .checkmark {
				background-color: #fff;
			}

			& ~ .checkbox-label {
				color: $primary-color;
			}
		}
	}

	.phrase-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 55px;
		background: #eef9ff;
		border: 1px solid $primary-color;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		margin-top: 20px;
		font-size: 0.875rem;
		font-weight: 400;
	}

	.week-time {
		color: #333;
		margin-right: 4px;
	}

	.total-time {
		color: $primary-color;
	}
}

.alert-workType {
	p {
		color: #333;
		font-weight: 500;
		font-size: 1rem;
		margin-bottom: 5px;
	}
	span {
		color: #666;
	}
}
