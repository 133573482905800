@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 기업 > 투자정보
*/

/*---투자정보 초기화면---*/
.p__company-invest .invest-initial {
	max-width: 1000px;
	padding: 60px 82px 70px 89px;
	display: flex;
	align-items: center;

	.bg-wrap {
		width: 421px;
		height: 355px;
		margin-right: 104px;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.phrase-wrap {
		h3,
		i {
			color: $primary-color;
			font-size: 2.5rem;
			font-weight: 200;
			letter-spacing: -0.05em;
		}

		.main-phrase {
			color: #333;
			font-size: 1.25rem;
			font-weight: 500;
			line-height: 1.5;
			letter-spacing: -0.05em;
			padding-left: 14px;
			margin: 26px 0 20px 8px;
			border-left: 2px solid $primary-color;
		}

		.sub-phrase {
			color: #666;
			font-weight: 300;
			line-height: 1.71;
			letter-spacing: -0.05em;
			margin-bottom: 35px;
		}

		.btn__solid {
			width: 102px;
		}
	}
}

/*---투자정보 보기, 수정 공통---*/
.p__company-invest .invest-view,
.p__company-invest .invest-edit {
	section:not(:last-child) {
		margin-bottom: 40px;
	}

	.section-title {
		font-size: 1.625rem;
		font-weight: 600;
		letter-spacing: -0.05em;
		line-height: 1.5;
		color: #333;
		border-bottom: 2px solid #333;
		padding-bottom: 13px;
	}

	.f__label {
		width: 153px;
		color: #333;
		font-weight: 500;
		letter-spacing: -0.025em;
	}

	// 단위
	.unit-wrap {
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		.unit {
			font-size: 0.75rem;
			font-weight: 300;
			line-height: 1.43;
			letter-spacing: -0.025em;
			text-align: right;
			color: #999;
			align-self: flex-end;
			margin-bottom: -5px;
		}
	}
}

/*---투자정보 보기---*/
.p__company-invest .invest-view {
	padding: 35px 60px 0 60px;

	@media screen and (max-width: 1024px) {
		padding: 30px 40px 0 40px;
	}

	.c__form {
		display: flex;
		flex-wrap: wrap;

		.f__group {
			width: 50%;
			padding: 7px 10px;
			border-bottom: 1px solid #ccc;

			@media screen and (max-width: 1200px) {
				width: 100%;
			}
		}

		.f__control {
			color: #333;
			font-weight: 300;
			line-height: 35px;
		}
	}

	.no-content {
		color: #999;
		font-weight: 300;
		letter-spacing: -0.025em;
		padding: 15px 0 40px;
	}

	//FixME 투자연혁
	.invest-history {
		display: flex;
		flex-wrap: wrap;
	}
	.yearly-history {
		margin: 0 15px 30px;
		padding: 0;
	}

	.year {
		margin: 12px 0;
	}

	.history-content li {
		margin-bottom: 0;
		line-height: 1.71;
		font-weight: 300;
		color: #666;
	}
}

/*---투자정보 수정---*/
.p__company-invest .invest-edit {
	padding: 35px 60px 60px;

	.company-invest-form {
		display: flex;
		flex-wrap: wrap;

		.f__group {
			width: 50%;
			min-height: 50px;
			height: auto;
			border-bottom: 1px solid $border-color;
			padding: 7px 10px;
			align-items: flex-start;

			@media screen and (max-width: 1200px) {
				width: 100%;
			}
		}

		.f__label {
			height: 35px;
		}
	}

	.invest-history-section {
		.plus-btn {
			width: 162px;
			height: 35px;
			border: 1px dashed $border-color;
			color: #bfbfbf;
			margin: 10px 0;
			font-weight: 500;
			letter-spacing: -0.35px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;

			&:not(:disabled):hover,
			&:not(:disabled):active {
				color: $primary-color;
				border-color: $primary-color;

				svg {
					fill: $primary-color;
				}
			}

			svg {
				fill: #bfbfbf;
			}
		}

		.f__control-wrap {
			width: 100%;
			display: flex;
			padding-bottom: 10px;

			.f__control {
				margin-right: 5px;

				&.date-picker {
					width: 162px;
					flex: none;

					@media screen and (max-width: 1024px) {
						width: calc(90px + (162 - 90) * ((100vw - 768px) / (1024 - 768)));
					}
				}

				&.round {
					max-width: 132px;

					@media screen and (max-width: 1024px) {
						min-width: 88px;
					}
				}
			}
		}

		.btn-round-delete {
			color: #999;
			transition: 0.3s ease-in-out;

			&:not(:disabled):hover,
			&:not(:disabled):active {
				color: $primary-color;
			}

			svg {
				fill: currentColor;
			}
		}
	}
}
