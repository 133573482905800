@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 마이페이지 > 권한설정 > 라이센스(결제)
*/

.p__license-purchase {
	.c__card {
		max-width: 900px;
		padding: 40px;
		margin: 0 auto;
		display: flex;

		@media screen and (max-width: 1080px) {
			padding: 40px 30px 40px 20px;
		}

		@media screen and (max-width: 925px) {
			padding: 40px 30px;
			align-items: center;
			flex-direction: column;
		}

		.form-title {
			color: #333;
			font-size: 1.5rem;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: -0.06em;
			padding-bottom: 32px;
		}

		// 구매상품
		.buy-product {
			margin-bottom: 28px;

			dt {
				color: #333;
				letter-spacing: -0.06em;
				padding: 0 0 16px;
				font-weight: 500;
				font-size: 18px;
			}

			dd {
				color: $primary-color;
				font-size: 28px;
				font-weight: 500;
				line-height: 28px;
				letter-spacing: -0.06em;

				@media screen and (max-width: 1024px) and (min-width: 925px) {
					font-size: 24px;
				}
			}
		}
	}

	//---- 상단 안내문구
	.purchase-top-msg {
		margin-bottom: 40px;
		text-align: center;
		word-break: keep-all;

		dt,
		dd {
			font-size: 2rem;
			font-weight: 300;
			line-height: 32px;
			letter-spacing: -0.06em;

			@media screen and (max-width: 925px) {
				font-size: 24px;
			}
		}

		dt {
			color: $primary-color;
			margin-bottom: 16px;

			@media screen and (max-width: 925px) {
				margin-bottom: 8px;
			}
		}

		dd {
			color: #333;
		}
	}

	//---- 결제정보, 결제확인 form
	.confirm-form .f__group,
	.purchase-form .f__group {
		margin-bottom: 28px;

		.f__label {
			padding: 0 0 10px;
		}
	}

	.f__control.has-error .checkmark {
		border-color: $error-color;
	}

	//---- 결제정보
	.purchase-info {
		max-width: 460px;
		display: flex;
		flex-flow: column;
		padding-right: 40px;
		border-right: 1px dotted $border-color;

		@media screen and (max-width: 1080px) {
			padding-right: 20px;
		}

		@media screen and (max-width: 925px) {
			width: 100%;
			max-width: inherit;
			padding-right: 0;
			padding-bottom: 40px;
			margin-bottom: 40px;
			border-right: none;
			border-bottom: 1px dotted $border-color;
		}

		// 환불정책
		.refund-policy {
			width: 100%;
			display: flex;
			align-items: flex-start;
			flex-flow: column;
			padding: 25px 20px;
			margin-top: auto;
			border-radius: 5px;
			background: #fafafa;
			white-space: nowrap;

			@media screen and (max-width: 1080px) and (min-width: 925px) {
				padding: 25px 10px 25px 14px;
			}
		}

		.refund-title {
			color: #333;
			letter-spacing: -0.06em;

			@media screen and (max-width: 925px) {
				font-size: 16px;
			}
		}

		.refund-content {
			color: #333;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: -0.06em;
			margin: 8px 0;

			@media screen and (max-width: 925px) {
				font-size: 14px;
			}

			b {
				font-size: inherit;
				font-weight: inherit;
				color: $primary-color;
			}
		}

		.btn-detail {
			color: $primary-color;
			font-size: 12px;
			font-weight: 300;
			line-height: 20px;
			letter-spacing: -0.06em;
			border-bottom: 1px solid $primary-color;

			@media screen and (max-width: 925px) {
				font-size: 14px;
			}

			&:not(:disabled):hover {
				color: $hover-color;
				border-color: $hover-color;
			}
		}
	}

	//---- 결제확인
	.purchase-confirm {
		width: 360px;
		padding-left: 40px;

		.f__label {
			font-weight: 500;
			font-size: 18px;
			letter-spacing: -0.06em;
		}

		@media screen and (max-width: 1080px) {
			flex: 1;
			padding-left: 20px;
		}

		@media screen and (max-width: 925px) {
			width: 100%;
			padding-left: 0;
		}

		// 최종결제 금액 - 수정 후 코드
		.item-sum {
			border-bottom: 1px solid #d7d7d7;
			padding: 6px 0 12px;

			.item-top {
				div {
					display: flex;
					justify-content: space-between;
				}
				dt,
				dd {
					font-size: 14px;
					color: #333;
					letter-spacing: -0.06em;
				}
				b {
					font-weight: 500;
				}
			}
			.item-bottom {
				div {
					margin-top: 8px;
					display: flex;
					justify-content: space-between;
				}
				dt,
				dd {
					font-size: 12px;
					color: #999;
					letter-spacing: -0.06em;
				}
			}
		}
		.confirm-form .price {
			margin-top: 12px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			dt,
			dd,
			b {
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				color: #333;
				letter-spacing: -0.06em;
			}

			b {
				font-size: 24px;
				line-height: 24px;
				color: $primary-color;
			}
		}

		// 동의 chk
		.purchase-agree {
			display: flex;
			align-items: center;
			margin: 62px 0 20px;

			.checkbox-custom {
				align-items: flex-start;
			}

			.checkmark {
				margin-right: 6px;

				@media screen and (max-width: 1024px) and (min-width: 925px) {
					width: 14px;
					min-width: 14px;
					height: 14px;
					min-height: 14px;
				}
			}

			.checkbox-label {
				min-width: initial;
				font-size: 14px;
				font-weight: 300;
				line-height: 20px;
				letter-spacing: -0.06em;

				@media screen and (max-width: 1024px) and (min-width: 925px) {
					font-size: 12px;
				}
			}
		}

		// 결제하기 btn
		.btn-wrap {
			display: flex;
			align-items: center;

			button {
				width: 50%;
				height: 50px;

				&:first-child {
					margin-right: 8px;
				}
			}
		}
	}
}

.refund-policy-modal .modal-content {
	padding: 30px;

	.refund-phrase {
		color: #333;
		line-height: 24px;
		letter-spacing: -0.04em;
		margin-bottom: 20px;

		b {
			color: $primary-color;
		}
	}

	.desc-box {
		min-height: 150px;
		padding: 20px;
		background: #eef9ff;
		border: 1px solid $primary-color;
		border-radius: 4px;

		.refund-item,
		dl {
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}

		.refund-item {
			p,
			dt,
			dd {
				font-weight: 300;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: -0.06em;
				color: #333;
			}

			p {
				margin-bottom: 4px;
			}

			dt {
				padding-left: 16px;
			}

			dd {
				padding-left: 34px;
			}

			b {
				color: $primary-color;
				font-size: inherit;
				font-weight: 500;
				margin-left: 4px;
			}
		}
	}
}
