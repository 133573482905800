@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * IR - IR 기본정보
*/

/*---공통---*/
.basicInfo-view,
.basicInfo-edit {
	padding: 35px 60px 60px;

	// 헤더 날짜
	.date {
		color: #999;
		font-size: 0.75rem;
		font-weight: 300;
		line-height: 1.67;
	}

	// 정보 입력 Form
	.input-group-section {
		margin-bottom: 34px;

		.section-title {
			border-bottom: 2px solid #333;
			padding-bottom: 13px;
		}

		.c__form {
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid $border-color;

			.f__group {
				width: 50%;
				padding: 7px 10px;

				&:not(:last-child) {
					border-bottom: 1px solid $border-color;
				}

				@media screen and (max-width: 1024px) {
					width: 100%;
				}
			}

			.f__label {
				min-width: 143px;
				color: #333;
				font-weight: 500;
				letter-spacing: -0.025em;
			}
		}
	}

	// 서명란
	.seal-area {
		width: 391px;
		border-bottom: 2px solid #333;
		padding-top: 118px;
		margin-left: auto;
		position: relative;

		.seal-label {
			color: #999;
			font-size: 1rem;
			font-weight: 300;
			font-style: italic;
			line-height: 1.4;
			letter-spacing: -0.028em;
			margin-bottom: 10px;
			display: inline-block;
		}

		// 서명
		.seal-uploader {
			width: 236px;
			height: 122px;
			object-fit: contain;
			position: absolute;
			right: 0;
			bottom: 12px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			z-index: 9;

			.upload-preview-actions {
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;

				button {
					color: #d7d7d7;
				}
			}
		}
	}
}

/*---VIEW---*/
.basicInfo-view {
	// 헤더 타이틀
	.ir-title {
		color: #333;
		font-size: 2.8125rem;
		font-weight: 300;
		line-height: 1.45;
		letter-spacing: -0.1em;
		margin-bottom: 55px;

		b {
			font-size: 2.8125rem;
			font-weight: 700;
		}
	}

	.input-group-section .f__control {
		font-weight: 300;
		line-height: 1.3;
	}

	// sub-section
	.sub-section {
		align-items: flex-start;

		&:not(:first-child) .f__control {
			min-height: 50px;
			border-bottom: 1px solid $border-color;
			padding: 7px 10px;
			align-items: center;
		}

		.f__control li {
			color: #333;
			font-weight: 300;
			line-height: 1.3;
		}

		.attraction-name {
			font-size: 1.125rem;
			font-weight: 600 !important;
			letter-spacing: -0.025em;
			flex: 1;
			padding: 0 10px 0 20px;

			@media screen and (max-width: 1024px) {
				font-size: calc(16px + (18 - 16) * ((100vw - 768px) / (1024 - 768)));
			}
		}

		.attraction-price {
			//font-size: 1.625rem;
			font-size: 24px;
			font-weight: 600 !important;
			text-align: right;
			letter-spacing: -0.05em;
			flex: 1;
			word-break: break-word;

			@media screen and (max-width: 1024px) {
				font-size: calc(20px + (24 - 20) * ((100vw - 768px) / (1024 - 768)));
			}
		}
	}
}

.basicInfo-edit {
	// 사업자등록번호
	.f__control-wrap {
		display: flex;
		flex-wrap: wrap;
	}

	.ir-title {
		padding-bottom: 24px;
		border-bottom: 1px solid #d7d7d7;
		margin-bottom: 56px;
		transition: 0.3s;

		.c__input {
			height: 40px;
			line-height: 40px;
			font-size: 40px;
			font-weight: 500;
			padding: 0;
			border: 0;
			letter-spacing: -0.15rem;

			&::placeholder {
				font-weight: 500 !important;
			}

			&:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus,
			&:not(:disabled):not(.disabled):not([readonly]):not(.readonly):active {
				box-shadow: none;
			}
		}

		&:not(.disabled):hover {
			border-color: #0D9488;
		}
	}

	// 정보불러오기 버튼
	.btn__api {
		width: 125px;
		height: 35px;
		margin-left: 6px;
		border-radius: 2px;
	}

	section#investment {
		border-bottom: 1px solid $border-color;
	}

	.c__textarea {
		@include customScroll(scroll-y);
	}

	.f__group.dynamic-input {
		margin-bottom: 0;

		.unit-wrap .inline-unit {
			margin-bottom: 7px;
		}

		.sub-section-wrap {
			flex-direction: column;
		}

		$margin: 6px;
		$icon-width: 15px;

		$calc-margin: calc(#{$margin} * 2 - #{$icon-width});

		.row-wrap {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
			margin-bottom: 4px;

			.f__control {
				flex-direction: column;
			}

			.date {
				width: calc(27% - #{$calc-margin});
				// min-width: 128px;
				margin-right: $margin;
			}
			.content {
				width: calc(50% - #{$calc-margin});
				margin-right: $margin;
			}
			.value {
				width: calc(23% - #{$calc-margin});
				margin-right: $margin;
			}
			button {
				width: 15px;
			}
		}
	}
}
