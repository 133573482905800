@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * 404, 500 에러 페이지
*/

.p__error {
	height: 100vh;
	background-color: #f8f8f8;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.phrase-wrap {
		text-align: center;
		margin: 80px 0 40px;

		h3 {
			color: $primary-color;
			font-size: 2rem;
			margin-bottom: 16px;
		}

		p {
			color: #666;
			font-size: 1.375rem;
			line-height: 1.5;
			text-align: center;
		}
	}

	.btn-wrap button {
		height: 50px;
		font-size: 1rem;
		letter-spacing: -0.06em;
		padding: 17px 30px;

		&:first-child {
			margin-right: 10px;
		}
	}
}
