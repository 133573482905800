@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * new 랜딩페이지
*/

.p__newMain {
	@import "src/assets/sass/pages/landing/serviceInfo/landingHeader";
	@import "src/assets/sass/pages/landing/serviceInfo/landingFooter";
}

.p__newMain {
	width: 100%;

	.wrap {
		margin: 0 auto;
		padding: 0 30px;
	}

	section {
		overflow-x: hidden;

		@media screen and (max-width: 1024px) {
			.animate__animated {
				animation: unset !important;
			}
		}
	}
}

.p__newMain.active {
	overflow-x: unset;
}

//---- 섹션
.p__newMain #top-section {
	//height: 66.667vw; // 1280
	height: 74.667vw;
	overflow: hidden;
	position: relative;
	top: 70px;
	border-radius: 20px;
	background: {
		image: url("../../../../img/newLanding/bg-landing.gif");
		repeat: no-repeat;
		size: cover;
		position: 100%;
	}

	@media screen and (max-width: 1740px) and (min-width: 1024px) {
		height: 100%;
		min-height: 70.931vw;
		//min-height: 57.031vw;
		background-image: url("../../../../img/newLanding/t-bg-landing.png");
		background-position: left;
	}

	@media screen and (max-width: 1024px) {
		height: 100%;
		min-height: 170.667vw;
		margin-top: 60px;
		background-image: url("../../../../img/newLanding/m-bg-landing.png");
	}

	.img-wrap {
		position: absolute;
		top: calc(11.333vw - 97px);
		left: 100%;
		animation: test 7s linear 1s infinite;

		@media screen and (max-width: 1024px) {
			top: calc(21.333vw - 130px);
		}
	}

	.gif-wrap {
		position: absolute;
		top: calc(50% + 70px);
		left: 56%;
		transform: translate(-56%, -50%) scale(0.8);

		@media screen and (max-width: 1740px) and (min-width: 1024px) {
			left: 60%;
			transform: translate(-60%, -40%);
		}

		@media screen and (max-width: 1024px) {
			top: 75%;
			left: 60%;
			transform: translate(-60%, -50%) scale(1.2);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.scroll-section {
		height: inherit;
		min-height: inherit;

		@media screen and (max-width: 1024px) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	.content-wrap {
		width: fit-content;
		height: 33.646vw;
		margin: 11.333vw 0 0 11.198vw;

		@media screen and (max-width: 1024px) {
			height: initial;
			text-align: center;
			margin: 21.333vw auto 0;
		}

		.content {
			.title {
				font-size: 60px;
				font-weight: 700;
				line-height: 1.2;
				letter-spacing: -0.025em;
				color: #fff;
				white-space: nowrap;

				@media screen and (max-width: 1024px) {
					font-size: 68px;
				}
			}

			.btn-wrap {
				margin-top: 28px;
				white-space: nowrap;

				@media screen and (max-width: 1024px) {
					margin-top: 8.267vw;
				}

				button {
					width: 160px;
					height: 50px;
					border-radius: 6px;
					border: 1px solid transparent;
					background: transparent;

					@media screen and (max-width: 1024px) {
						width: 32vw;
						height: 10.667vw;
					}

					&:first-child {
						margin-right: 10px;

						@media screen and (max-width: 1024px) {
							margin-right: 2.667vw;
						}
					}

					&.btn__solid {
						background: #fff;
						color: $primary-color;

						&:hover {
							background: darken(#fff, 6%);
						}
					}

					&.btn__outline {
						backdrop-filter: blur(3.5px);
						border-color: #fff;
						color: #fff;

						&:hover {
							background: #fff;
							color: $primary-color;
						}
					}

					span {
						font-size: 18px;
						font-weight: 700;
						letter-spacing: -0.45px;

						@media screen and (max-width: 1024px) {
							font-size: 3.733vw;
						}
					}
				}
			}
		}
	}

	.dashboard-img {
		width: 85.333vw;
		border-radius: 20px;
		overflow: hidden;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		margin: 0 auto;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);

		@media screen and (max-width: 1740px) and (min-width: 1024px) {
			transform: translate(-50%, 11%);
		}

		@media screen and (max-width: 1024px) {
			bottom: -30vw;
		}

		img {
			width: 100%;
			border-radius: 20px;
			object-fit: contain;
		}
	}
}

.p__newMain .title-section {
	min-height: 829px;
	padding: 0 5.208vw;
	position: relative;

	@media screen and (max-width: 950px) {
		min-height: 760px;
	}

	.title-wrap {
		width: fit-content;
		min-width: 866px; // 866
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@media screen and (max-width: 950px) {
			min-width: 615px;
		}
	}

	h1 {
		font-size: 132px;
		letter-spacing: -6.6px;
		color: $landing-color;
		white-space: nowrap;
		display: flex;
		align-items: center;
		transition: 0.3s;

		@media screen and (max-width: 1300px) and (min-width: 950px) {
			font-size: calc(122px + (132 - 122) * ((100vw - 950px) / (1300 - 950)));
		}

		@media screen and (max-width: 950px) {
			font-size: 110px;
			letter-spacing: -5.53px;
		}

		b {
			font-size: 134px;
			letter-spacing: -6.7px;
			margin-left: 1.042vw;

			@media screen and (max-width: 1300px) and (min-width: 950px) {
				font-size: calc(124px + (134 - 124) * ((100vw - 950px) / (1300 - 950)));
			}

			@media screen and (max-width: 950px) {
				font-size: 115px;
				letter-spacing: -5.78px;
			}
		}

		&:not(:last-child) {
			margin-bottom: 35px;

			@media screen and (max-width: 950px) {
				margin-bottom: 46px;
			}
		}

		&:first-child {
			align-items: flex-end;
			justify-content: flex-start;

			@media screen and (max-width: 950px) {
				position: relative;
			}
		}

		&:nth-child(2) {
			justify-content: center;
		}

		&:last-child {
			justify-content: flex-end;
		}
	}

	.ico-wrap {
		width: 186px;
		height: 186px;
		margin-left: 1.875rem;
		overflow: hidden;
		flex-shrink: 0;

		@media screen and (max-width: 950px) {
			width: 120px;
			height: 120px;
			position: absolute;
			right: 0;
			top: -1.875rem;
		}

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}
}

.p__newMain .accordion-section {
	.accordion-wrap {
		display: flex;
		align-items: center;
		//justify-content: center;
		justify-content: space-between;
		gap: 3.125vw;
		margin-bottom: 8.333vw; // 160

		@media screen and (max-width: 1240px) {
			gap: 0;
			justify-content: center;
		}

		//&:first-child {
		//	margin-bottom: 8.333vw; // 160
		//
		//	//.img-wrap {
		//	//	margin-right: 3.125vw;
		//	//}
		//}

		&.odd {
			flex-direction: row-reverse;
		}

		&:last-child {
			margin-bottom: 9.167vw; // 176

			@media screen and (max-width: 1240px) {
				margin-bottom: 10.417vw; // 200
			}

			//.img-wrap {
			//	margin-left: 3.125vw;
			//}
		}

		.img-wrap {
			position: relative;
			width: 56.25vw; // 960
			height: 37.188vw; // 714
			flex-shrink: 0;
			overflow: hidden;
			border-radius: 20px;
			box-shadow: 0 5px 2px 0 rgba(0, 0, 0, 0.03), 0 7px 11px 0 rgba(0, 0, 0, 0.03),
				0 15px 25px 0 rgba(0, 0, 0, 0.03), 0 35px 40px 0 rgba(0, 0, 0, 0.03),
				0 70px 90px 0 rgba(0, 0, 0, 0.04);

			@media screen and (max-width: 1240px) {
				display: none;
			}

			> img {
				width: 100%;
				height: 100%;
				border-radius: 20px;
			}

			&.apply {
				box-shadow: none;
				overflow: visible;

				> img {
					width: 85.1%;
					height: auto;
					box-shadow: 0px 5px 2px 0 rgba(0, 0, 0, 0.03),
						0px 7px 11px 0 rgba(0, 0, 0, 0.03), 0px 15px 25px 0 rgba(0, 0, 0, 0.03),
						0px 35px 40px 0 rgba(0, 0, 0, 0.03), 0px 70px 90px 0 rgba(0, 0, 0, 0.04);
				}
			}

			.sub-img {
				width: 43.5%;
				height: auto;
				position: absolute;
				bottom: 0;
				right: 0;
				border-radius: 20px;
				overflow: hidden;
				box-shadow: 0px 5px 2px 0 rgba(0, 0, 0, 0.03), 0px 7px 11px 0 rgba(0, 0, 0, 0.03),
					0px 15px 25px 0 rgba(0, 0, 0, 0.03), 0px 35px 40px 0 rgba(0, 0, 0, 0.03),
					0px 70px 90px 0 rgba(0, 0, 0, 0.04);

				img {
					width: 100%;
				}
			}
		}
	}

	// accordion 메뉴
	.accordion-menu {
		width: 100%;
		max-width: 680px; // 680

		@media screen and (max-width: 1240px) {
			max-width: 87.2vw;
		}

		.title {
			font-size: 50px;
			font-weight: 700;
			letter-spacing: -2.5px;
			color: $landing-color;
			margin-bottom: 1.667vw; // 32
			padding-left: 1.563vw; // 30
			white-space: nowrap;

			@media screen and (max-width: 1300px) and (min-width: 1240px) {
				font-size: calc(40px + (50 - 40) * ((100vw - 1240px) / (1300 - 1240)));
			}

			@media screen and (max-width: 1240px) {
				line-height: 1.61;
				letter-spacing: -2.5px;
				margin-bottom: 80px;
				text-align: center;
				padding-left: 0;
			}
		}

		.accordion {
			display: flex;
			align-items: center;
			flex-direction: column;

			.active + .accordion-item {
				border-top: none !important;
			}

			.accordion-item {
				width: 100%;
				max-width: 33.333vw; // 640
				height: 100%;
				min-height: 70px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				cursor: pointer;
				padding: 0 1.042vw 0 1.25vw; //padding: 0 20px 0 24px;

				@media screen and (max-width: 1240px) {
					min-height: 108px;
					max-width: 80vw;
				}

				&:not(.active) {
					border-top: 1px solid #d7d7d7;
				}

				&:first-child {
					border-top: none;
				}

				&:last-child {
					border-bottom: 1px solid #d7d7d7;
				}

				&.disabled {
					opacity: 0.5;
					cursor: not-allowed;
				}

				.accordion-head {
					height: 100%;
					font-size: 24px;
					font-weight: 500;
					letter-spacing: -1.18px;
					color: #222;
					display: flex;
					align-items: center;
					justify-content: space-between;

					@media screen and (max-width: 1300px) and (min-width: 1240px) {
						font-size: calc(
							20px + (24 - 20) * ((100vw - 1240px) / (1300 - 1240))
						) !important;
					}

					@media screen and (max-width: 1240px) {
						font-size: 32px !important;
						letter-spacing: -1.6px;
					}

					svg {
						fill: #141652;
						opacity: 0.3;
					}
				}
			}

			// 열림상태
			.accordion-item.active {
				max-width: 51.042vw; // 680
				border-radius: 12px;
				box-shadow: 0px 5px 2px 0 rgba(0, 0, 0, 0.03), 0px 7px 11px 0 rgba(0, 0, 0, 0.03),
					0px 15px 25px 0 rgba(0, 0, 0, 0.03), 0px 35px 40px 0 rgba(0, 0, 0, 0.03),
					0px 70px 90px 0 rgba(0, 0, 0, 0.04);
				padding: 35px 40px;

				@media screen and (max-width: 1300px) and (min-width: 1240px) {
					padding: 25px 30px;
				}

				@media screen and (max-width: 1240px) {
					padding: 60px;
					max-width: 87.2vw; // 654
				}

				.accordion-head {
					font-size: 30px;
					font-weight: 500;
					letter-spacing: -1.5px;
					color: $landing-color;
					margin-bottom: 24px;

					@media screen and (max-width: 1240px) {
						font-size: 32px;
						letter-spacing: -1.6px;
						margin-bottom: 40px;
					}

					svg {
						transform: rotate(180deg);
						opacity: unset;
					}
				}

				.accordion-content {
					font-size: 20px;
					font-weight: 300;
					line-height: 1.43;
					letter-spacing: -0.5px;
					color: #333;
					display: -webkit-box;
					overflow: hidden;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;

					@media screen and (max-width: 1240px) {
						font-size: 28px;
					}
				}

				.img-wrap {
					display: none !important;

					@media screen and (max-width: 1240px) {
						width: 100%;
						height: 46.933vw;
						margin: 0 auto 40px;
						display: block !important;
						box-shadow: none;
						border-radius: 12px;

						&.apply > img {
							box-shadow: none;
						}

						> img {
							border-radius: 12px;
							border: 1px solid #d7d7d7;
						}
					}
				}

				@media screen and (max-width: 1240px) {
					.sub-img {
						box-shadow: none;
						border-radius: 12px;
						border: 1px solid #d7d7d7;
					}
				}
			}

			.accordion-item {
				.active {
					display: block;
				}

				.inactive {
					display: none;
				}
			}
		}

		.btn-accordion-more {
			font-size: 18px;
			letter-spacing: -0.9px;
			color: #999;
			display: block;
			margin-left: auto;
			padding-top: 16px;

			@media screen and (max-width: 1240px) {
				font-size: 28px;
				letter-spacing: -1.12px;
			}
		}
	}
}

.p__newMain #community-section {
	width: 100%;
	background: {
		image: url("../../../../../../src/assets/img/newLanding/bg-dao-on.png");
		repeat: no-repeat;
		size: 100%;
		position: top;
		color: #000;
	}
	padding-bottom: 8.333vw;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	animation: blink-effect 3.4s ease-in-out infinite;

	&.isScrolled {
		border-radius: 40px;
		width: calc(100% - 50px);
	}

	@media screen and (max-width: 950px) {
		min-height: 1624px;
		background: {
			image: url("../../../../../../src/assets/img/newLanding/mobile-bg-dao-on.png");
		}
	}

	&:before {
		content: "";
		height: 74%;
		background: {
			image: url("../../../../../../src/assets/img/newLanding/bg-line.png");
			repeat: no-repeat;
			size: 100% 100%;
			position: center center;
		}
		display: inline-block;
		position: absolute;
		bottom: 0;

		@media screen and (max-width: 950px) {
			background-image: url("../../../../../../src/assets/img/newLanding/mobile-bg-line.png");
		}
	}

	.community-wrap {
		height: 100%;
		margin: 0 16.146vw;
		padding-top: 13.438vw;
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@media screen and (max-width: 1650px) {
			margin: 0 10%;
		}

		@media screen and (max-width: 1330px) {
			margin: 0 8%;
		}

		@media screen and (max-width: 950px) {
			padding-top: 82.316vw;
			align-items: center;
		}
	}

	.top {
		display: inline-flex;
		flex-direction: column;
		margin-bottom: 13.333vw;
		z-index: 5;

		@media screen and (max-width: 950px) {
			align-items: center;
		}

		.main-desc {
			color: #fff;
			font-size: 40px;
			font-weight: 700;
			line-height: 1.2;
			letter-spacing: -1px;
			white-space: nowrap;

			@media screen and (max-width: 1440px) {
				font-size: calc(28px + (40 - 28) * ((100vw - 768px) / (1440 - 768)));
			}

			@media screen and (max-width: 950px) {
				font-size: 50px;
				line-height: 1.28;
				letter-spacing: -1.25px;
				text-align: center;
			}
		}

		.sub-desc {
			color: #f8f8f8;
			font-size: 20px;
			font-weight: 300;
			line-height: 1.6;
			letter-spacing: -0.5px;
			margin: 32px 0 40px;
			white-space: nowrap;

			@media screen and (max-width: 1440px) {
				font-size: calc(14px + (20 - 14) * ((100vw - 768px) / (1440 - 768)));
				margin: 22px 0 30px;
			}

			@media screen and (max-width: 950px) {
				font-size: 28px;
				line-height: 1.43;
				letter-spacing: -0.7px;
				margin: 35px 0 64px;
				text-align: center;
			}
		}

		button {
			width: 200px;
			height: 50px;
			border-radius: 6px;

			@media screen and (max-width: 950px) {
				width: 280px;
				height: 80px;
			}

			span {
				font-size: 18px;
				font-weight: 700;
				letter-spacing: -0.45px;

				@media screen and (max-width: 950px) {
					font-size: 28px;
					letter-spacing: -0.7px;
				}

				&.lang {
					display: none;
				}
			}
		}
	}

	.bottom {
		text-align: center;

		h2 {
			font-size: 40px;
			font-weight: 700;
			line-height: 1.2;
			letter-spacing: -1px;
			text-align: center;
			color: #fff;
			margin-bottom: 80px;

			@media screen and (max-width: 1300px) {
				font-size: calc(30px + (40 - 30) * ((100vw - 768px) / (1300 - 768)));
			}

			@media screen and (max-width: 950px) {
				font-size: 50px;
			}
		}

		.card-wrap {
			width: calc(100% + 20px);
			display: flex;
			align-items: stretch;
			justify-content: center;
			flex-wrap: wrap;

			&.lang {
				height: 946px;
			}

			.benefit-item {
				width: calc((100% / 4) - 20px);
				height: auto;
				margin: 0 10px 20px;
				min-height: 120px;
				background: #454a57;
				border-radius: unset;
				display: inline-flex;
				align-items: center;
				padding: 30px;
				z-index: 1;
				cursor: pointer;
				transition: 0.3s;

				@media screen and (max-width: 1750px) and (min-width: 950px) {
					padding: calc(20px + (30 - 20) * ((100vw - 950px) / (1750 - 950)));
				}

				@media screen and (max-width: 1440px) {
					width: calc((100% / 3) - 20px);
				}

				@media screen and (max-width: 950px) {
					width: calc((100% / 2) - 20px);
					flex-direction: column;
					align-items: flex-start;
					padding: 30px 40px;
					height: auto;

					&.inactive {
						display: none !important;
					}

					&.active {
						display: flex;
					}
				}

				&:hover {
					background: #531cef;

					.benefit-num {
						color: #fff;
					}
				}
			}

			.benefit-num {
				font-family: Jost;
				font-size: 36px;
				font-weight: 300;
				line-height: 0.83;
				letter-spacing: -0.9px;
				color: #a4a9bd;
				margin-right: 20px;
				transition: 0.3s;

				@media screen and (max-width: 1750px) and (min-width: 950px) {
					font-size: calc(28px + (36 - 28) * ((100vw - 950px) / (1750 - 950)));
					margin-right: 8px;
				}

				@media screen and (max-width: 950px) {
					margin-bottom: 30px;
				}
			}

			.benefit-desc {
				font-size: 20px;
				line-height: 1.5;
				letter-spacing: -0.5px;
				color: #fff;
				white-space: nowrap;
				text-align: left;

				@media screen and (max-width: 1750px) and (min-width: 950px) {
					font-size: calc(16px + (20 - 16) * ((100vw - 950px) / (1750 - 950)));
				}

				@media screen and (max-width: 950px) {
					font-size: 26px;
				}
			}
		}
	}

	.btn-collapse {
		width: 20%;
		font-size: 22px;
		font-weight: 300;
		letter-spacing: -1.1px;
		color: #f8f8f8;
		margin-top: 60px;
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;

		&.close svg {
			transform: rotate(180deg);
		}

		svg {
			opacity: 0.5;
			fill: #fff;
			margin-top: 15px;
		}

		@media screen and (max-width: 950px) {
			display: inline-flex;
		}
	}
}

.p__newMain .roadmap-section {
	height: 864px;

	@media screen and (max-width: 1300px) {
		height: 1894px;
	}

	.wrap {
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.roadmap-title {
			font-size: 40px;
			font-weight: 700;
			letter-spacing: -1px;
			color: $landing-color;

			@media screen and (max-width: 950px) {
				font-size: 46px;
			}
		}

		.roadmap-box {
			width: calc(100% - 13.542vw);
			margin: 100px auto;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (max-width: 1300px) {
				display: none;
			}
		}

		.roadmap-line.horiz {
			width: 100%;
			height: 240px;
			margin-left: 0;
			display: flex;
			justify-content: space-around;
			position: relative;

			@media screen and (max-width: 1300px) {
				display: none;
			}

			// 수평
			&:after {
				content: "";
				width: 100%;
				height: 8px;
				display: inline-block;
				background: $landing-color;
				border-radius: 4px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				animation: fadeInBar 1s ease-in-out;
				z-index: -1;
			}

			.bar-item {
				width: calc(100% / 6);
			}

			.bar-item:nth-child(2),
			.bar-item:nth-child(5) {
				.bar-wrap {
					margin-bottom: -15px;
				}
			}

			.animate__animated {
				position: relative;

				&:before {
					content: "";
					width: 20px;
					height: 20px;
					display: inline-block;
					border: solid 5px #141652;
					background-color: #fff;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 9;
				}

				.bar-item {
					height: 100%;
				}
			}

			.container {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;

				&.even {
					transform: rotate(180deg);

					> div {
						transform: rotate(180deg);
					}

					.bar {
						transform: rotate(180deg);
					}

					.bar-wrap,
					.destination {
						display: flex;
						align-items: center;
						flex-direction: column-reverse;

						&:before {
							transform: translateY(2px);
						}
					}
				}
			}

			.bar-year {
				margin-bottom: 20px;
				font-size: 20px;
				font-weight: 700;
				line-height: 1.5;
				letter-spacing: -0.5px;
				color: $landing-color;
			}

			.bar-wrap {
				margin-bottom: 15px;
			}

			.bar {
				width: 2px;
				height: 60px;
				display: inline-block;
				background: linear-gradient(to top, #12aaff, $landing-color);
			}

			.destination {
				white-space: nowrap;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 18px;
				font-weight: 500;
				line-height: 1.67;
				letter-spacing: -0.45px;
				color: #12aaff;

				&:before {
					content: "";
					width: 14px;
					height: 14px;
					border-radius: 50%;
					background: $primary-color;
					display: inline-block;
					transform: translateY(-2px);
				}

				br {
					display: none;
				}
			}
		}

		.roadmap-line.vertical {
			margin: 80px 0;
			position: relative;
			height: 1240px;
			flex-direction: column;
			justify-content: space-around;
			display: none !important;

			@media screen and (max-width: 1300px) {
				height: 1240px;
				display: flex !important;
			}

			&:before {
				content: "";
				width: 8px;
				height: 1240px;
				border-radius: 4px;
				background-color: $landing-color;
				display: block;
				position: absolute;
				left: 50%;
				//animation: fadeInBar 1s ease-in-out;
			}

			.bar-item {
				position: relative;

				&:before {
					content: "";
					width: 20px;
					height: 20px;
					display: inline-block;
					border: solid 5px $landing-color;
					background-color: #fff;
					border-radius: 50%;
					position: absolute;
					top: 0;
					left: 47%;
				}
			}

			.container {
				position: relative;
				left: 50%;

				&:before {
					content: "";
					width: 20px;
					height: 20px;
					border-radius: 50%;
				}

				&.odd {
					text-align: left;
					padding-left: 50px;

					@media screen and (max-width: 950px) {
						padding-left: calc(35px + (50 - 35) * ((100vw - 320px) / (980 - 320)));
					}
				}

				&.even {
					left: -50%;
					text-align: right;
					padding-right: 50px;

					@media screen and (max-width: 950px) {
						padding-right: calc(35px + (50 - 35) * ((100vw - 320px) / (980 - 320)));
					}
				}
			}

			.bar-year {
				font-size: 32px;
				font-weight: 700;
				line-height: 0.94;
				letter-spacing: -0.8px;
				color: $landing-color;
				margin-bottom: 22px;
			}

			.destination {
				font-size: 28px;
				font-weight: 500;
				line-height: 1.2;
				letter-spacing: -1.12px;
				color: #12aaff;

				br {
					display: block;
				}
			}
		}

		.roadmap-desc {
			font-size: 18px;
			font-weight: 300;
			line-height: 1.67;
			letter-spacing: -0.45px;
			color: $landing-color;
			word-break: keep-all;

			br:first-child {
				display: none;
			}

			@media screen and (max-width: 996px) {
				width: calc(100% - 70px);
				font-size: calc(22px + (24 - 22) * ((100vw - 1023px) / (996 - 768)));
				line-height: 1.5;
				letter-spacing: -0.96px;

				br:first-child {
					display: block;
				}
			}

			b {
				font-size: inherit;
				font-weight: 400;
				border-radius: 7px;
				background-color: #fff837;
			}
		}
	}
}

//---- 모달
// 서비스 데모
.video-intro-modal .modal-container {
	width: 60.417vw !important;
	max-width: 1120px;

	@media screen and (max-width: 950px) {
		width: 91.146vw !important;
	}

	.modal-content {
		padding: 20px;

		@media screen and (max-width: 950px) {
			padding: 14px;
		}
	}

	.video-item {
		width: 100%;
		height: 0;
		padding-top: calc((630 / 1120) * 100%);
		position: relative;

		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

// NFT 컬렉션 모달
.nft-collection-modal .modal-content {
	padding: 88px 65px 78px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.img-wrap {
		width: 337px;
		height: 250px;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.main-desc {
		color: #333;
		font-size: 40px;
		font-weight: 300;
		margin: 55px 0 32px;

		b {
			font-size: inherit;
			font-weight: 700;
			color: $primary-color;
		}
	}

	.sub-desc {
		color: #333;
		font-size: 20px;
		font-weight: 300;
		text-align: center;
		letter-spacing: -0.025em;
		line-height: 1.3;
	}
}

// 프로 라이센스 무료로 시작하기 모달
.pro-free-modal {
	.modal-content {
		min-height: 184px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;

		.title,
		.title b {
			font-size: 1rem;
			line-height: 24px;
			letter-spacing: -0.06em;
			color: #333;
			margin-top: 16px;

			b {
				color: $primary-color;
				font-weight: 500;
			}
		}
	}

	.btn__solid:last-child:not(:first-child) {
		width: 160px;
	}
}
