@charset "UTF-8";

//컬러 color
// $primary-color: #00a4ff;
// $primary-dark-color: #0078cb;
$primary-color: #0D9488;
$primary-dark-color: #14b055;
$primary-700: #0f766e;
$primary-950: #042f2e;
$hover-color: darken($primary-color, 9%);
$active-color: lighten($primary-color, 8%);


// PERSONA COLOR
// Default
$persona-white: #FFFFFF;
$persona-black: #080809;

// Grey
$persona-grey-50: #F9FAFB;
$persona-grey-100: #F3F4F6;
$persona-grey-200: #E5E7EB;
$persona-grey-300: #D1D5DB;
$persona-grey-400: #9CA3AF;
$persona-grey-500: #6B7280;
$persona-grey-600: #4B5563;
$persona-grey-700: #374151;
$persona-grey-800: #1F2937;
$persona-grey-900: #111827;
$persona-grey-950: #030712;

// Teal
$persona-teal-50: #F0FDFA;
$persona-teal-100: #CCFBF1;
$persona-teal-200: #99F6E4;
$persona-teal-300: #5EEAD4;
$persona-teal-400: #2DD4BF;
$persona-teal-500: #14B8A6;
$persona-teal-600: #0D9488;
$persona-teal-700: #0F766E;
$persona-teal-800: #115E59;
$persona-teal-900: #134E4A;
$persona-teal-950: #042F2E;

// Blue
$persona-blue-50: #E8F4FF;
$persona-blue-100: #BAE0FF;
$persona-blue-200: #91CAFF;
$persona-blue-300: #69B1FF;
$persona-blue-400: #4A96FF;
$persona-blue-500: #1677FF;
$persona-blue-600: #0958D9;
$persona-blue-700: #003EB3;
$persona-blue-800: #002C8C;
$persona-blue-900: #082973;
$persona-blue-950: #172554;

// Yellow
$persona-yellow-50: #FFF8E6;
$persona-yellow-100: #FFF1B8;
$persona-yellow-200: #FFE58F;
$persona-yellow-300: #FFD86A;
$persona-yellow-400: #FFC53D;
$persona-yellow-500: #FAAD14;
$persona-yellow-600: #D48B08;
$persona-yellow-700: #AD6800;
$persona-yellow-800: #874D00;
$persona-yellow-900: #713F12;
$persona-yellow-950: #4A2801;

// Red
$persona-red-50: #FEF2F2;
$persona-red-100: #FEE2E2;
$persona-red-200: #FECACA;
$persona-red-300: #FCA5A5;
$persona-red-400: #FB7171;
$persona-red-500: #EF4444;
$persona-red-600: #DC2828;
$persona-red-700: #B91C1C;
$persona-red-800: #991B1B;
$persona-red-900: #7F1D1D;
$persona-red-950: #450A0A;

// Green
$persona-green-50: #F0FDF4;
$persona-green-100: #DCFCE7;
$persona-green-200: #BBF7D0;
$persona-green-300: #86EFAC;
$persona-green-400: #4ADE80;
$persona-green-500: #22C55E;
$persona-green-600: #16A34A;
$persona-green-700: #15803D;
$persona-green-800: #166534;
$persona-green-900: #14532D;
$persona-green-950: #052E16;

$error-color: #f44;

$border-color: #d7d7d7;
$landing-color: #141652;

$text-sub:#666666;
//layout 높이 패딩
$topNavHeight: 80px;
$footerHeight: 70px;
$contentPadding: 40px;
$contentBottomPadding: 27px;
$fitCardHeight: calc(
	100vh - #{$topNavHeight} - #{$contentPadding} - #{$contentBottomPadding} - #{$footerHeight}
);

//휴가
$annual: #03dbc0;
$health: #ffd74a;
$pregnancy: #0b7782;
$multi-pregnancy: #95d0d5;
$spouse: #ff429d;
$family-care: #ffa370;
$sterility: #71357c;
$sick: #f44;
$others: #4780ff;

//근태
$work: #3fb6f8;
$overtime: #fcc609;
$businessTrip: #9f77eb;
$remoteWork: #ff7092;
$day-off: #e8e8e8;

.vacation-name,
.annual,
.vacation-name-bar.annual,
.health,
.pregnancy,
.multi-pregnancy,
.spouse,
.family-care,
.sterility,
.sick,
.others,
.work,
.vacation-name-bar.work,
.excess,
.overtime,
.vacation-name-bar.excess,
.vacation-name-bar.overtime,
.outside,
.businessTrip,
.vacation-name-bar.businessTrip,
.homeWork,
.remoteWork,
.vacation-name-bar.remoteWork,
.day-off,
.vacation-name-bar.day-off {
	//border: 1px solid red !important;
}

/*
휴가 키워드

연차 annual #03dbc0
보건 health  #ffd74a
출산휴가  pregnancy  #0b7782
츨산다태아  multi-pregnancy  #95d0d5
배우자출산 spouse  #ff429d
가족 돌봄 휴가  family-care  #ffa370
난임 치료 휴가  sterility  #71357c
병가  sick  #f44
기타휴가 others #4780ff




근태 키워드


근무 work  #3fb6f8
연차 annual   #03dbc0
초과근무 overtime #fcc609
외근 businessTrip #9f77eb
재택 remoteWork  #ff7092
기타 day-off  #e8e8e8

*/
