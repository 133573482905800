@charset "UTF-8";
@import "../../../../sass/utils/var";
@import "../../../../sass/utils/mixin";

/***
  * 세무 공통 스타일
*/

/*---페이지 헤더 버튼---*/
.p__tax-dashboard .btn-company-list {
  min-width: 109px;
  letter-spacing: -0.7px;
}

.btn-okceo {
  min-width: 140px;
  letter-spacing: -0.7px;
}

.p__tax-dashboard {
  /*---카드 전체 레이아웃---*/
  .c__cardRow {
    width: initial;
    max-width: 1490px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    grid-gap: 10px;
  }
  
  .c__card {
    width: initial;
    height: 100%;

    margin: 0;
    transition: 0.3s;

    @media screen and (min-width: 1921px){
      max-width: initial;
    }
  }

  // 카드 내부 컨텐츠
  .card-content {
    padding: 10px 20px 20px;

    .summary-detail {
      margin-top: 5px;
      text-align: right;
      color: #1890ff;
      cursor: pointer;
    }
  }

  /*---카드 헤더---*/
  .card-head {
    height: 57px;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-title {
      font-size: 1rem;

      span {
        font-size: 0.75rem;
        font-weight: 300;
        margin-left: 6px;
      }
    }
  }

  .extra-condition {
    display: flex;
    align-items: center;

    .c__select {
      //width: 85px;
      width: 90px;
      margin-right: 9px;
    }

    .divider {
      display: inline-block;
      width: 1px;
      height: 35px;
      margin: 0 10px 0 4px;
      background: #e8ebf1;
    }

    // 버튼 hover
    .btn-plus:hover {
      border-color: #58afff;
      color: #58afff;
    }

    .btn-minus:hover {
      border-color: #ff6e6f;
      color: #ff6e6f;
    }

    //.btn-detail {
    //	svg {
    //		width: 19px;
    //		height: 16px;
    //	}
    //
    //	&:hover {
    //		color: #727cf5;
    //		border-color: #727cf5;
    //
    //		svg {
    //			fill: #727cf5;
    //		}
    //	}
    //}
  }

  // 버튼
  .extra-condition .btn__outline {
    height: 35px;
    color: #999;
    font-size: 0.75rem;
    border-color: #ccc;
    padding: 0 15px;
    white-space: nowrap;
    transition: 0.3s;

    &:not(:last-child) {
      width: 100px;
      margin-right: 6px;
    }

    svg {
      fill: #ccc;
      margin: 0;
      transition: 0.3s;
    }
  }

  /*---전체 금액 확인---*/
  .total-wrap {
    height: 53px;
    border-bottom: 1px solid $border-color;
    padding: 10px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    text-align: right;

    &-title {
      font-size: 1.875rem;
      font-weight: 300;
    }

    .vat {
      color: #333;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.67;
    }

    .amount {
      font-size: 1.625rem;
      font-weight: 700;

      i {
        font-size: 0.75rem;
        font-weight: inherit;
        font-style: normal;
        margin-left: 3px;
      }
    }
  }

  // 바 차트
  .bar-chart {
    width: 193px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &:first-child {
      margin-right: 10px;
    }

    .amount {
      font-weight: 700;
      color: #333;
      display: flex;
      align-items: baseline;
      flex-wrap: wrap-reverse;
      word-break: break-word;

      i {
        font-size: 0.75rem;
        font-style: normal;
      }
    }

    .label {
      font-weight: 700;
      color: #333;
      margin-bottom: 17px;
    }

    .bar {
      height: 10px;
      border-radius: 5px;
      background-color: #e3e3e3;
      margin-bottom: 5px;
      position: relative;

      .bar-state {
        max-width: 193px;
        display: block;
        height: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 5px;
      }
    }

    &[color="plus"] {
      .bar-state {
        //width: 70%;
        background: #1890ff;
      }

      .bar-percent {
        color: #1890ff;
      }
    }

    &[color="minus"] {
      .bar-state {
        //width: 70%;
        background: #fe6567;
      }

      .bar-percent {
        color: #fe6567;
      }
    }

    //퍼센트 수치 / 바 색상 밑에 위치
    .bar-percent {
      width: 100%;
      max-width: 193px;
      font-size: 1rem;
      font-weight: 700;
      text-align: right;
    }
  }
}
