@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/*---기업정보(보기, 쓰기) 공통---*/
.p__company-info {
	@import "./common";
}

/***
  * 기업 > 기업정보 > 등록수정 페이지
*/
.p__company-info .company-info-edit {
	padding: 60px 60px 80px;

	@media screen and (max-width: 768px) {
		padding: 60px 30px 80px;
	}

	.company-name {
		margin-top: 18px;
	}

	//로고
	.company-logo {
		text-align: right;
	}
	.company-logo .logo-wrap {
		//로고 등록 전
		&.before {
			> span {
				color: #999;

				&:after {
					background: #999;
				}
			}

			&:hover {
				border-color: $primary-color;

				> span {
					color: $primary-color;

					&:after {
						background: $primary-color;
					}
				}
			}
		}

		//로고 등록 후
		&.after {
			> span {
				opacity: 0;
				background: rgba(0, 0, 0, 0.65);
				color: #fff;

				&:after {
					background: #fff;
				}
			}

			&:hover > span {
				opacity: 1;
			}
		}
	}

	//기본정보
	.input-group-section .c__form {
		.f__control-wrap {
			display: flex;
			flex-wrap: wrap;
		}

		.btn__api {
			width: 125px;
			height: 35px;
			margin-left: 6px;
		}

		.sales-input-group {
			.f__label > span {
				font-size: 0.75rem;
				font-weight: 300;
				line-height: 1.43;
				letter-spacing: -0.025em;
				text-align: right;
				color: #999;
				margin-left: 5px;
			}

			.f__control:last-child {
				max-width: 125px;
				margin-left: 5px;
			}
		}

		.keyword-wrap {
			.f__control-wrap {
				display: flex;

				.f__control {
					flex: 1;
				}

				.notice {
					display: block;
					width: 100%;
					color: #999;
					padding-top: 7px;
				}
			}

			.has-error + .notice {
				color: $error-color;
			}
		}
	}

	//기업개요
	.company-desc {
		.section-title:after {
			content: "\002A";
			color: red;
			margin-left: 4px;
			vertical-align: top;
			transform: translateY(4px);
			display: inline-block;
		}

		.f__control {
			height: auto;
		}
	}

	//주요제품
	.info-product {
		.c__form {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;
		}

		.f__group {
			width: 50%;
			padding: 7px 10px;
		}

		.btn-write-product {
			color: $primary-color;
			position: absolute;
			right: 0;

			&:before {
				content: "\001A";
				color: inherit;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
