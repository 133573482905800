@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * 토글
*/

.c__toggle {
	display: flex;
	align-items: center;

	input {
		cursor: pointer;
		display: none;
	}

	.slider {
		position: relative;
		display: inline-block;
		width: 42px !important;
		height: 24px !important;
		border-radius: 24px;

		.background {
			position: absolute;
			height: inherit;
			width: inherit;
			border-radius: inherit;
			background-color: #d7d7d7;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.ball {
			position: absolute;
			content: "";
			height: 14px;
			width: 14px;
			left: 5px;
			bottom: 5px;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			border-radius: 14px;
		}
	}

	.toggle-label {
		font-size: 15px;
		margin-left: 10px;
		padding-bottom: 3px;
	}

	input:checked + .slider > .ball {
		-webkit-transform: translateX(18px);
		-ms-transform: translateX(18px);
		transform: translateX(18px);
	}

	input:checked + .slider > .background {
		background-color: $primary-color;
	}
}
