@charset "UTF-8";
@import "src/assets/sass/utils/var";

.single-uploader.round {
	.upload-preview img {
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
	}

	> div {
		overflow: hidden;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
	}
}

//업로드 input
.uploader-select {
	position: relative;
	width: inherit;
	height: inherit;
	order: 1;
	border: 1px dashed $border-color;
	background-color: #fafafa;
	color: #d7d7d7;
	cursor: pointer;
	transition: 0.3s ease-in-out;

	&:hover {
		border-color: $primary-color;

		.uploader-actions {
			color: $primary-color;
		}
	}

	input {
		position: absolute;
		width: inherit;
		height: inherit;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		cursor: pointer;
	}

	svg {
		width: 20%;
		height: 20%;
		fill: currentColor;
		margin-bottom: 7px;
	}

	.uploader-actions {
		width: inherit;
		height: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #A8A8A8;
		font-size: 0.75rem;
		font-weight: 300;
		transition: 0.3s;
	}
}

//업로드 프리뷰
.upload-preview {
	position: relative;
	width: inherit;
	height: inherit;
	border: 1px solid $border-color;
	padding: 3px;
	z-index: 1;
	transition: 0.3s ease-in-out;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.upload-preview-actions {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		gap: 6px;
		top: 3px;
		left: 3px;
		right: 0;
		bottom: 0;
		width: calc(100% - 6px);
		height: calc(100% - 6px);
		background: rgba(0, 0, 0, 0.5);
		z-index: 2;
		opacity: 0;
		transition: 0.3s ease-in-out;
		cursor: pointer;
		-webkit-border-radius: inherit;
		-moz-border-radius: inherit;
		border-radius: inherit;

		> button {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			color: rgba(#fff, 0.6);
			transition: 0.3s ease-in-out;

			&:not(:disabled):hover,
			&:not(:disabled):active, {
				color: #fff;

				svg {
					fill: #fff;
				}
			}

			svg {
				fill: #d7d7d7;
				transition: 0.3s ease-in-out;
			}

			> a {
				color: inherit;
			}

			.simple-icon-trash {
				font-size: 1.05rem;
			}
		}
	}

	&:hover .upload-preview-actions {
		opacity: 1;
	}
}

//이미지 업로드 실패
.uploader-select.error {
	&:not(:hover) {
		color: $error-color;
		border-color: $error-color;
	}

	.simple-icon-trash {
		font-size: 1.25rem;
		color: inherit;
		margin-bottom: 7px;
	}

	.error-msg {
		color: inherit;
	}
}

//멀티업로드
.multiple-uploader {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: -7px;

	.uploader-select {
		margin-bottom: 7px;
	}

	.upload-preview {
		border: 1px solid $border-color;
		background-color: #fafafa;
		color: #d7d7d7;
		margin-right: 7px;
		margin-bottom: 7px;
	}
}

// 댓글 이미지 업로드 (커뮤니티 추가 컴포넌트)
.comment-uploader {
	.uploader-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.upload-preview {
		display: flex;
		padding: 0;
		border: 0;
		border-radius: 4px;
		background-color: #fff;
		margin-right: 7px;
		margin-bottom: 7px;

		img {
			border-radius: 4px;
			object-fit: cover;
		}
	}

	.uploader-actions {
		width: 150px;
		display: flex;
		align-items: center;
		position: relative;
		border-radius: 4px;
		transition: 0.3s;
		font-family: "Poppins", sans-serif;
		color: #666;
		font-weight: 300;
		font-size: 12px;
		line-height: 12px;

		&:hover {
			color: $primary-color;

			svg {
				fill: $primary-color;
			}
		}

		input {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			cursor: pointer;

			&::-webkit-file-upload-button {
				cursor: pointer;
			}
		}

		svg {
			transition: 0.3s;
			fill: #666;
			margin-right: 8px;
		}
	}

	.upload-preview-actions {
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		border-radius: 4px;
		background: rgba(0, 0, 0, 0.4);
		align-items: flex-start;
		justify-content: flex-end;

		> button {
			width: 16px;
			height: 16px;
			border-radius: 16px;
			background: rgba(0, 0, 0, 0.6);
			margin: 3px;

			&:not(:disabled):hover svg,
			&:not(:disabled):active svg {
				fill: #fff;
			}

			svg {
				width: 6px;
				height: 6px;
				fill: rgba(255, 255, 255, 0.8);
				transition: 0.3s ease-in-out;
			}
		}
	}
}
