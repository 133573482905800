.unpaid {
    background-color: #FFFBE6;

    .c__selection-item span {
        color: #FAAD14;
    }
}

.paid {
    background-color: #F0FDF4;

    .c__selection-item span {
        color: #22C55E;
    }
}

.overdue {
    background-color: #FEF2F2;
    height: 35px;
    color: #EF4444;
    font-size: 0.875rem;
    padding: 0 12px;
}