@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/*---주주명부 VIEW, EDIT 공통---*/
.p__shareholder-list {
	@import "./common";
}

/***
  * 주주 > 주주명부 > EDIT
*/

/*---주주명부(쓰기)---*/
.shareholder-edit-card {
	padding: 40px 60px 60px;

	.section-title {
		margin-bottom: 27px;
	}

	.react-datepicker-wrapper {
		width: 160px;
	}

	.form-label {
		color: #333;
		font-weight: 500;
		line-height: 1.5;
		letter-spacing: -0.025em;
		padding-bottom: 16px;
		border-bottom: 2px solid #333;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		.plus-btn {
			margin-bottom: 0;
		}
	}

	.f__group {
		min-height: 49px;
		padding-top: 7px;
		border-bottom: 1px solid #ccc;

		.f__control.has-error {
			margin-bottom: 0;
		}

		.f__control-wrap {
			display: flex;
			flex-direction: row;
		}

		.f__label {
			width: 100%;
			max-width: 143px;
		}
	}

	.shareholder-card-info .f__group {
		padding: 7px 10px;
	}
}

// 테이블
.shareholder-form-table {
	padding-top: 52px;
	margin-bottom: 95px;
	border-bottom: 2px solid #000;

	.thead {
		height: 61px;
	}

	.tbody {
		.tr {
			height: 52px;
		}

		.tr:last-child {
			border-bottom: none !important;
			height: 52px;
		}

		.td {
			margin-right: 5px;
			border-bottom: none;
		}

		.f__control.has-error {
			margin-bottom: 0;
		}
	}

	.form-label {
		position: relative;
	}

	.shareholder-total input[readonly] {
		color: #333;
		text-align: right;
		font-size: 1.125rem;
		font-weight: 500;
	}

	.equity-ratio input[readonly] {
		color: #333;
		text-align: center;
		font-weight: 300;
	}

	.btn-round-delete {
		margin-right: 13px;

		svg {
			fill: #999;
		}
	}
}

@media screen and (max-width: 1200px) {
	.shareholder-form-table .shareholder-table {
		@include customScroll(scroll-x);

		.tr {
			width: max-content;
			padding: 8px 0;
		}

		.thead {
			width: max-content;
			padding: 22px 0 17px 0;

			.th {
				display: inline-block;
				text-align: center;
			}

			.shareholder-price {
				margin-left: 5px;
			}
			.shareholder-stock {
				margin-left: 10px;
			}
			.equity-ratio {
				margin-left: 10px;
			}
		}

		.shareholder-info {
			width: 360px;
			min-width: 360px;

			.shareholder-name {
				width: 152px;
				min-width: 152px;
				margin-right: 5px;
			}

			.shareholder-number {
				width: 188px;
				min-width: 188px;
			}
		}

		.shareholder-address {
			width: 322px;
			min-width: 322px;
		}

		.shareholder-price {
			width: 196px;
			min-width: 196px;

			.form-control {
				text-align: right;
			}
		}

		.shareholder-stock {
			width: 126px;
			min-width: 126px;

			.form-control {
				text-align: right;
			}
		}

		.shareholder-total {
			width: 196px;
			min-width: 196px;

			.f__control {
				text-align: right;
				font-size: 1.125rem;
				font-weight: 500;
			}
		}

		.equity-ratio {
			width: 92px;
			min-width: 92px;

			.form-control {
				text-align: center;
			}
		}
	}
}

// 주주명부 쓰기 테이블 간격
.shareholder-edit-card {
	.shareholder-info {
		width: 26.67%;
		padding-left: 10px;
		display: flex;
		justify-content: center;

		.shareholder-name {
			width: 56.35%;
			margin-right: 5px;
		}

		.shareholder-number {
			width: 72.96%;
		}
	}

	.shareholder-address {
		width: 23.85%;
	}

	.shareholder-price {
		width: 15%;
	}

	.shareholder-stock {
		width: 9.8%;
	}

	.shareholder-total {
		width: 15%;
	}

	.equity-ratio {
		width: 7.3%;
	}
}

/*---서명설정하기 모달---*/
.modal-sign {
	.modal-content {
		max-height: 478px;
		padding: 20px;
		background: #f8f8f8;
		overflow-y: auto;
	}

	.btn-wrap {
		display: flex;
		justify-content: flex-end;
		gap: 8px;

		button {
			width: fit-content;
			min-width: 84px;
			padding: 0 14px;
		}

		svg {
			transform: rotate(45deg);
			width: 10px;
			height: 10px;
		}
	}

	// 서명 데이터 없을 때
	.no-data {
		width: 100%;
		color: #666;
		font-weight: 300;
		border-radius: 4px;
		background-color: #fff;
		padding: 20px;
		margin: 20px auto 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 22px;
			height: 22px;
			fill: #fd7e14;
			margin-right: 7px;
		}
	}

	// 서명 카드
	.c__card {
		min-width: 200px;
		max-width: 345px;
		height: 130px;
		padding: 0;
		margin: 10px;
		border: 1px solid transparent;
		border-radius: 15px;
		display: inline-block;
		position: relative;
		transition: 0.3s;

		&.active {
			border-color: $primary-color;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

			.img-wrap {
				opacity: 1;
			}
		}

		.signature {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			.btn-remove {
				opacity: 0;
				padding: 10px 15px;
				transition: 0.3s ease-in-out;
				position: absolute;
				top: 0;
				right: 0;

				svg {
					fill: #9a9a9a;
				}
			}

			&:hover .btn-remove {
				opacity: 1;
			}
		}

		.img-wrap {
			width: 75%;
			height: 75%;
			opacity: 0.25;
			overflow: hidden;
			transition: 0.3s;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.modal-seal-notice {
		color: #666;
		font-weight: 300;
		line-height: 1.43;
		padding: 20px 0 10px;
		display: block;

		.btn-ghost {
			height: 36px;
			font-weight: 300;
			line-height: 1.2;
			border-bottom: 1px solid $primary-color;
			margin: 4px 0 0 17px;
			transition: 0.3s;

			&:hover {
				color: $primary-color;
			}
		}
	}
}

.pageHeader-btn-group {
	display: flex;
	align-items: center;

	.plus-btn {
		margin-right: 6px;
	}
}
