@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";
/***
  * datePicker
*/

// 체크해야 할 사항
// 1. .f__control에 z-index: 1 설정되어있어 캘린더 가려짐
.f__control {
	z-index: unset !important;
}
// 2. next, previous 버튼 사이즈 변경 (svg 사용여부)
// 3. Form 내부에 있는 datePicker가 아닌경우 (<input type=date/>로 사용된 경우) 디자인 적용 안되어있음
// 4. 월, 연도 select arrow 표시 디자인 다름

//------- 임시로 z-index 설정
//.f__group .f__control {
//	z-index: unset;
//}

#datepicker-portal {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1200;
}

.c__datePicker {
	position: relative;
	padding-left: 28px;
}

.datePicker-group {
	position: relative;
	z-index: 1;
	width: 230px;
}

.datePicker-group .f__control-wrap {
	display: flex;
	align-items: center;
	width: 100%;

	.dash {
		display: inline-block;
		margin: 0 3px;
		padding-bottom: 3px;
		color: #666;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	input {
		width: inherit;
		height: 35px;
		padding-right: 20px;
		color: #333;

		font: {
			family: "Poppins", "Noto Sans KR", sans-serif;
			size: 0.8125rem;
			weight: 300;
		}

		&:not(:disabled):hover {
			cursor: pointer;
		}
	}
}

.react-datepicker__input-container {
	position: relative;

	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		left: 0;
		width: 22px;
		height: 35px;

		background: {
			image: url("../../../assets/img/ico-calendar.svg");
			position: right;
			size: 0.75rem;
			repeat: no-repeat;
		}
	}
}

// 드롭다운 캘린더와 datePicker 인풋 사이 간격
.react-datepicker-popper[data-placement^="bottom"] {
	padding-top: 8px;
}

//툴팁 삼각형 제거
.react-datepicker__triangle {
	display: none;
}

// 드롭다운 캘린더 스타일
.react-datepicker {
	color: #666;
	border-radius: 2px;
	border-color: #e3e4e5;
}

// 드롭다운 헤더
.react-datepicker__header {
	border-color: #e3e4e5;
	background-color: #f8f8f8;
	border-top-left-radius: 2px;
	padding: 0;

	&:not(.react-datepicker__header--has-time-select) {
		border-top-right-radius: 2px;
	}

	&__dropdown {
		padding: 15px 0;
	}
}

.react-datepicker-year-header {
	padding: 15px 0;
	font-size: 14px;
	font-weight: 500;
	color: #333;
}

// 월별, 연도 표시
.react-datepicker__month-select,
.react-datepicker__year-select {
	@include customScroll(scroll-y);
	width: 70px;
	height: 28px;
	border-radius: 2px;
	border: 1px solid $border-color;
	font-size: 12px;
	font-weight: 300;
	font-family: "Poppins", "Noto Sans KR", sans-serif;
	color: #333;
}

// 요일 표시
.react-datepicker__day-names {
	height: 38px;
	background-color: #fff;
	border-top: solid 1px #e3e4e5;
	margin-bottom: 0;
	padding: 2px 0;

	.react-datepicker__day-name {
		width: 30px;
		height: 30px;
		font-size: 12px;
		font-weight: 500;
		color: #0078cb;
		margin: 1px;
	}
}

// 이전, 다음 버튼
.react-datepicker__navigation {
	width: 28px;
	height: 28px;
	transform: translateY(50%);

	&--previous {
		left: 0;
		margin-left: 9px;
	}

	&--next {
		right: 0;
		margin-right: 9px;
	}
}

.react-datepicker__navigation-icon {
	&:before {
		border-width: 2px 2px 0 0;
	}

	&--previous:before {
		right: 0;
	}

	&--next:before {
		left: 0;
	}
}

.month-calendar .react-datepicker__navigation {
	transform: unset;
	top: 12px;
}

// 드롭다운 날짜표시
.react-datepicker__month {
	margin: 2px;

	.react-datepicker__month-text--keyboard-selected {
		color: #fff;
		background-color: $primary-color;
		border-radius: 4px;
	}

	.react-datepicker__month-text--today {
		font-weight: 500;
		background: #fff;

		&:not(.react-datepicker__day--disabled) {
			color: $primary-color;
		}
	}
}

// 일별
.react-datepicker__day {
	width: 30px;
	height: 30px;
	font-size: 12px;
	font-family: "Poppins", "Noto Sans KR", sans-serif;
	line-height: 30px;
	border-radius: 50%;
	margin: 1px;
	transition: 0.3s;

	&:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected) {
		color: #333;
	}

	&:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):hover {
		width: 30px;
		height: 30px;
		color: $primary-color;
		background-color: rgba(152, 213, 255, 0.2);
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
	}

	// 현재 날짜 표시
	&.react-datepicker__day--today {
		font-weight: 500;
		background: #fff;

		&:not(.react-datepicker__day--disabled) {
			color: $primary-color;
		}
	}

	// 선택된 날짜
	&.react-datepicker__day--selected,
	&.react-datepicker__day--keyboard-selected {
		color: #fff !important;
		background-color: $primary-color;
		border-radius: 4px;
	}
}

.react-datepicker__month-text {
	width: 74px;
	height: 26px;
	line-height: 26px;
	font-size: 12px;
	color: #666;
}

.react-datepicker__month-text:hover,
.react-datepicker__month-text.react-datepicker__month--selected:hover {
	color: $primary-color;
	background-color: rgba(152, 213, 255, 0.2);
	border-radius: 4px;
}

// 월별
.react-datepicker__month-wrapper {
	display: flex;
}

// 다른 달의 날짜
.react-datepicker__day--outside-month {
	color: #999 !important;
}

//time-picker 에서 disabled 된 시간 없애기
.half-day-vacation .react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
	display: none;
}
.time-vacation .exclude-time {
	display: none;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list {
	@include customScroll(scroll-y);
	height: auto;
	max-height: calc(195px + (1.7rem / 2));
}

.react-datepicker--time-only {
	width: 173px;

	.react-datepicker__time-container {
		width: 100%;
	}
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
	width: 100% !important;
	text-align: left;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li {
	line-height: 19.5px;
	color: #666;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background: #f8f8f8;
	color: #333;
	font-weight: 400;
	transition: 0.25s ease-in;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:not(.react-datepicker__time-list-item--disabled):hover {
	background: #f8f8f8;
	color: #333;
	font-weight: 400;
	transition: 0.25s ease-in;
}

.react-datepicker__header--time {
	border: none;
}
