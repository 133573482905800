@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 팀원 > 팀원정보 > 팀원 클릭 시 모달 (상세-수정 공통)
*/

.teamInfo-modal {
	@include modalScroll;
	padding: 0;
	align-items: center;

	.c__loader {
		padding: 0;
	}

	.modal-container {
		height: 80vh;
		min-width: 800px;
	}

	.modal-header, .modal-footer {
		border: none;
	}

	.modal-header {
		.modal-title {
			font-weight: 700;
		}
	}

	.modal-footer > button {
		margin: 24px 0;
	}

	.modal-body {
		height: calc(100% - 70px);
	}

	.modal-content {
		display: flex;
		height: calc(100% - 90px);
		padding: 0 32px;
		gap: 20px;
	}

	.c__tabMenu {
		white-space: nowrap;
	}

	.c__tabMenuItem {
		font-weight: 400;

		&:not(:last-child) {
			margin-right: 2.5%;
		}
	}

	.tabInfo-area {
		height: 100%;
		padding-left: 12px;
		flex: 1;

		> section,
		> .scroll-wrap > section {
			&:not(:last-child) {
				margin-bottom: 30px;
			}

			.section-title {
				display: inline-block;
				margin-bottom: 9px;
				color: #333;
				font-size: 1rem;
				font-weight: 500;
				letter-spacing: -0.05em;
				line-height: 1;
				position: relative;
				z-index: 1;
			}
		}
	}

	.scroll-wrap {
		@include customScroll(scroll-y);
		width: calc(100% + 10px);
		height: calc(100% - 80px);
		padding-right: 6px;
	}
}

//프로필 영역 상세, 수정 공통
.teamInfo-profile {
	min-width: 220px;
	width: 28%;
	height: 100%;
	text-align: center;
	padding: 24px 24px 0 24px;
	background-color: #f1f4f6;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 16px;

	// 이름
	.name {
		color: #333;
		font-size: 1.25rem;
		letter-spacing: -0.05em;
		margin-top: 13px;
		margin-bottom: 11px;
		font-weight: 700;
		text-align: left;

		.position {
			margin-left: 9px;
			color: #666;
			font-size: 0.75rem;
			font-weight: 300;
			letter-spacing: -0.05em;
		}
	}
	

	// 연락 아이콘
	.contact {
		align-items: center;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;

			svg {
				width: 18px;
				height: 18px;
				fill: #666;
			}

			span {
				font-size: 14px;
				word-break: break-word;
				text-align: left;
			}

			&:not(:last-child) {
				margin-bottom: 16px;
			}

			&:not(:disabled):hover svg {
				fill: $primary-color;
				transition: 0.3s;
			}

			&:not(:disabled):hover {
				color: $primary-color;
				transition: 0.3s;
			}
		}
	}

	// 아이콘 공통
	:not(.upload-loading)svg {
		height: 18px;
		fill: #c1c3c5;
		transition: 0.3s;
		cursor: pointer;

		&:not(:disabled):hover {
			fill: #d7d7d7;
		}
	}
}
