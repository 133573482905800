@charset "UTF-8";
@import "src/assets/sass/utils/var";
.p__admin-business .c__card {
	margin-bottom: 50px;
}
/**
  * 마이페이지 > 관리자메뉴 > 사업자인증 공통
*/

.p__admin-business {
	.c__card {
		max-width: 720px;
		height: 382px;
		display: flex;
		flex-direction: column;
	}

	.card-top {
		flex: 1;
		padding: 40px 40px 0;
	}

	.content {
		width: fit-content;

		> dt {
			font-size: 30px;
			font-weight: 300;
			letter-spacing: -1.5px;
			margin-bottom: 16px;
		}

		> dd {
			font-size: 14px;
			font-weight: 300;
			letter-spacing: -0.35px;
			line-height: 1.4em;
			margin-top: -0.2em;
			margin-left: 2px;
		}
	}

	.biz-num {
		padding-top: 25px;
		display: inline-flex;
		align-items: center;

		b {
			font-size: 14px;
			font-weight: 400;
			letter-spacing: -0.35px;
			color: #000;
			margin-right: 21px;

			&:before {
				content: "";
				display: inline-block;
				width: 2px;
				height: 2px;
				background: #000;
				margin-right: 6px;
				vertical-align: middle;
			}
		}

		span {
			font-size: 14px;
			font-weight: 300;
			letter-spacing: -0.35px;
			color: #333;
		}
	}

	.card-bottom {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-top: auto;
		width: 100%;
		height: 100px;
		border-top: 1px solid $border-color;

		button {
			width: 108px;

			&:not(:last-child) {
				margin-right: 6px;
			}
		}
	}
}
