@charset "UTF-8";
@import "src/assets/sass/utils/var";

.c__tooltip-btn {
	position: relative;

	&:hover .c__tooltip {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.c__tooltip {
	position: absolute;
	top: -40px;
	left: 50%;
	transform: translateX(-50%);
	width: fit-content;
	height: 30px;
	color: $primary-color;
	font-size: 0.75rem;
	font-weight: 300;
	border: solid 1px #c0e9ff;
	border-radius: 4px;
	background-color: #eef9ff;
	padding: 0 7px;
	white-space: nowrap;
	cursor: default;
	display: none;

	&:after {
		content: "";
		position: absolute;
		top: 100%;
		border-width: 5px;
		border-style: solid;
		border-color: #c0e9ff transparent transparent transparent;
	}

	&:before {
		content: "";
		position: absolute;
		top: 95%;
		border-width: 5px;
		border-style: solid;
		border-color: #eef9ff transparent transparent transparent;
		z-index: 1;
	}

	// error 컬러
	&.error {
		color: $error-color;
		background: #ffecec;
		border: 1px solid rgba(255, 68, 68, 0.1);

		&:after {
			border-color: rgba(255, 68, 68, 0.1) transparent transparent transparent;
		}

		&:before {
			border-color: #ffecec transparent transparent transparent;
		}
	}
}
