@charset "UTF-8";

/***
  * font-family: 'Poppins', sans-serif;
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

/***
  * font-family: 'Noto Sans KR', sans-serif;
*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

/***
  * font-family: myriad-pro-semi-condensed,sans-serif;
  * font-weight: 900;
  * font-style: normal;
*/
@import url("https://use.typekit.net/kyr0wmc.css");

/***
  * font-family: 'Exo', sans-serif;
*/
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400&family=Exo:wght@300;400;500;600&display=swap");

/***
  * font-family: 'Roboto', sans-serif;
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/***
  * font-family: 'yg-jalnan', sans-serif;
*/
@font-face {
	font-family: "yg-jalnan";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}

/***
  * font-family: 'NanumSquare' , sans-serif ;
*/
@import url("https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css");

/***
  * font-family: 'Jost' , sans-serif ;
*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap");
