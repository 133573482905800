@charset "UTF-8";

.translation-wrap {
	position: relative;
 padding: 8px 20px;
 border:  solid #042F2E 2px;
 border-width: 1;
 border-radius: 6px;
 background-color: white;
 cursor: pointer;
	.translation-text {
		display: flex;
		align-items: center;
		color: #666;
		letter-spacing: -0.84px;
		cursor: pointer;
		transition: 0.3s;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.translation-list {
		min-width: 145px;
		position: absolute;
		left: -20px;
		top: calc(100% + 14px);
		padding: 14px 10px;
		border-radius: 14px;
		border: 1px solid var(--gray-gray-19, #e8e8e8);
		background: #fff;
		box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);

		> li {
			height: 40px;
			display: flex;
			align-items: center;
			padding-left: 36px;
			position: relative;
			color: #666;
			letter-spacing: -0.84px;
			transition: 0.3s ease-in-out;

			svg {
				width: 14px;
				height: 10px;
				position: absolute;
				left: 12px;
				fill: $primary-color !important;
				display: none;
			}

			&:hover {
				color: $primary-color;
				cursor: pointer;
			}

			&.active {
				color: $primary-color;

				svg {
					display: block;
				}
			}
		}
	}
}

.translation-wrap-admin {
	position: relative;
 padding: 8px 20px;
 border:  solid rgb(247, 244, 244) 2px;
 border-width: 1;
 border-radius: 6px;
 background-color: white;
 margin-right: 10px;
 cursor: pointer;
	.translation-text {
		display: flex;
		align-items: center;
		color: #666;
		letter-spacing: -0.84px;
		cursor: pointer;
		transition: 0.3s;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.translation-list {
		min-width: 145px;
		position: absolute;
		left: -20px;
		top: calc(100% + 14px);
		padding: 14px 10px;
		border-radius: 14px;
		border: 1px solid var(--gray-gray-19, #e8e8e8);
		background: #fff;
		box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);

		> li {
			height: 40px;
			display: flex;
			align-items: center;
			padding-left: 36px;
			position: relative;
			color: #666;
			letter-spacing: -0.84px;
			transition: 0.3s ease-in-out;

			svg {
				width: 14px;
				height: 10px;
				position: absolute;
				left: 12px;
				fill: $primary-color !important;
				display: none;
			}

			&:hover {
				color: $primary-color;
				cursor: pointer;
			}

			&.active {
				color: $primary-color;

				svg {
					display: block;
				}
			}
		}
	}
}
