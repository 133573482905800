@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * alert
*/

//모달 마스크 포함
.c__alert {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	z-index: 1200;
	transition: 0.3s;
	display: none;
	overflow: hidden;

	&.toggleOpen {
		display: flex;
		opacity: 1;
		animation-name: fadeIn;
		animation-duration: 0.3s;
	}

	&.toggleClose {
		opacity: 0;
		animation-delay: 0.5s;
		animation-name: fadeOut;
		animation-duration: 0.3s;

		.modal-container {
			animation-name: zoomOut;
			animation-duration: 0.2s;
		}
	}

	&.show-mask {
		background: rgba(#000, 0.7);
	}
}

//마스크 제외 내용 영역
.alert-container {
	display: flex;
	flex-direction: column;
	border-radius: 13px;
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
	background-color: #fff;
	position: relative;
	animation-name: zoomIn;
	animation-duration: 0.3s;
}

//alert 내용 부분
.alert-body {
	display: flex;
	flex: 1;
	padding-top: 30px;

	> svg {
		width: 60px;
		height: 60px;
	}

	.alert-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		white-space: pre;
	}

	.desc-box {
		width: 350px;
		padding: 20px;
		font-size: 0.75rem;
		line-height: 17px;
		letter-spacing: -0.06em;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

// alert 세로
.alert-body.vertical {
	align-items: center;
	flex-direction: column;

	> svg {
		margin-bottom: 20px;
	}

	.alert-content {
		flex: 1;
		text-align: center;
		margin-bottom: 24px;
		font-size: 1rem;
		line-height: 23px;
		letter-spacing: -0.025em;
		color: #333;
	}
}

// alert 가로
.alert-body.horiz {
	flex-flow: wrap;
	justify-content: center;
	padding-top: 45px;

	.alert-content {
		width: calc(100% - 80px - 60px);
		height: 60px;
		padding-left: 20px;

		h3 {
			color: #333;
			font-size: 18px;
			font-weight: 500;
			line-height: 26px;
			letter-spacing: -0.06em;
			margin-bottom: 4px;
		}

		p {
			color: #666;
			line-height: 20px;
			letter-spacing: -0.06em;

			&.warning-phrase {
				color: #f44;
			}
		}
	}
}

// desc 박스 컬러
.desc-box {
	height: fit-content;

	&.error {
		color: #f44;
		background: rgba(255, 68, 68, 0.05);
	}

	&.info {
		color: #000;
		background: rgba(254, 202, 64, 0.1);
	}
}

//푸터
.alert-footer {
	text-align: center;
	width: 100%;
	margin-top: auto;
	border-top: 1px solid $border-color;

	> button {
		width: 108px;
		height: 42px;
		margin: 20px 0;

		&:first-child:last-child {
			width: 86px;
		}

		&:first-child:not(:last-child) {
			margin-right: 8px;
		}
	}
}

//모달 애니메이션
@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(0.8);
	}
	to {
		-webkit-transform: scale(1);
	}
}

@keyframes zoomIn {
	from {
		transform: scale(0.8);
	}
	to {
		transform: scale(1);
	}
}

@keyframes zoomOut {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0.8);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeOut {
	from {
		display: flex;
	}
	to {
		display: none;
	}
}
