@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * IR - 주주현황
*/

.shareholder-view,
.shareholder-edit {
	padding: 60px;

	.section-title {
		padding-bottom: 13px;
	}

	.scroll-wrap {
		@include customScroll(scroll-x);
	}

	/*---테이블 공통---*/
	.shareholder-table {
		width: 100%;
		min-width: max-content;
		border-top: 2px solid #333;
		border-bottom: 2px solid #333;

		.tr {
			min-height: 49px;
			display: flex;
			align-items: center;
			color: #333;
			border-bottom: 1px solid #e5e5e5;
			padding: 7px 10px;

			&:not(.c__form) {
				align-items: center;
			}

			&.thead {
				height: 49px;
			}

			&.tfoot {
				min-height: 80px;
				text-align: center;
			}

			&.c__form {
				min-height: 49px;
				height: auto;
				align-items: flex-start;
			}
		}

		.td.shareholder-amount {
			text-align: right;
		}

		.td.shareholder-ratio {
			text-align: center;
		}
	}

	// thead
	.thead .th {
		font-weight: 500;
		text-align: center;
	}

	.tfoot .td {
		padding: 0 20px;

		&:first-child {
			width: 512px;
			//width: 38.26%;
			font-size: 1rem;
			font-weight: 500;
			letter-spacing: -0.025em;
		}

		&:last-child {
			flex: 1;
		}
	}

	.tfoot .shareholder-amount,
	.tfoot .shareholder-ratio {
		color: $primary-color;
		font-size: 1.5rem;
		font-weight: 500;
	}

	// 셀 간격
	.shareholder-name {
		width: 500px;
		//width: 38.26%;
	}

	.shareholder-amount {
		width: 400px;
		//width: 15%;
	}

	.shareholder-ratio {
		width: 120px;
		//min-width: 8.54%;
	}

	.shareholder-remark {
		width: 410px;
		//width: 36.1%;
	}
}

/*---주주현황 view---*/
.shareholder-view {
	.tbody .td {
		padding: 0 10px;
		font-weight: 300;
	}

	.tbody .shareholder-amount {
		font-size: 1.125rem;
		font-weight: 500;
	}

	.no-content {
		width: 100%;
		padding: 45px 0;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		font-size: 1.125rem;
		font-weight: 300;
		line-height: 1.5;
		letter-spacing: -0.05em;
		text-align: center;
		color: #333;
	}
}

/*---주주현황 edit---*/
.shareholder-edit {
	.section-title {
		position: relative;
	}

	// 상단 대쉬버튼
	.btn-group-wrap {
		position: absolute;
		bottom: 10px;
		right: 0;
		display: flex;
		align-items: center;

		.dashed-btn {
			border-color: #666;
			color: #666;
		}

		button:first-child {
			margin-right: 16px;
		}
	}

	// tbody
	.tbody .td {
		padding: 7px 2.5px;
	}

	.btn-round-delete {
		width: 35px;
		padding: 10px 14px 10px 4px;

		svg {
			fill: #999;
			transition: 0.3s;

			&:hover {
				fill: $primary-color;
			}
		}
	}

	.tbody input::placeholder {
		font-weight: 300;
		color: #333;
	}

	.shareholder-amount input::placeholder {
		font-size: 1.125rem;
		font-weight: 500;
		text-align: right;
	}

	.shareholder-ratio input::placeholder {
		text-align: center;
	}
}
