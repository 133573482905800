@charset "UTF-8";
@import "../../../sass/utils/var";
@import "../../../sass/utils/mixin";

/**
  * 커뮤니티  >  게시판  >  상세보기
**/

.p__community-community,
.p__community-notice {
	// 목록 버튼
	.community-list-btn {
		width: 100%;
		max-width: 1000px;
		margin: 24px auto 0;
		display: flex;
		justify-content: flex-end;

		.btn__solid {
			width: 80px;
			border-radius: 2px;
		}
	}
}

//---- 게시판 글
.community-detail-card {
	max-width: 1000px;
	margin: 0 auto 24px;
	padding: 40px;

	// 프로필 이미지
	.profile-img {
		margin-right: 16px;
		border-radius: 50%;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50px;
		}
	}

	//-- 게시글 section
	.content-section {
		.content-title {
			font-weight: 500;
			font-size: 1.625rem;
			line-height: 38px;
			color: #333;
			margin-bottom: 13px;
			word-break: break-word;
		}

		// 프로필
		.content-profile {
			display: flex;
			align-items: center;

			.profile-img {
				width: 60px;
				height: 60px;
			}
		}
		.profile-info {
			dt {
				margin-bottom: 4px;

				span {
					font-size: 1rem;
					line-height: 23px;
					letter-spacing: -0.06em;
				}
				.profile-name {
					font-weight: 500;
					color: #333;
					margin-right: 4px;
				}
				.profile-position {
					font-weight: 300;
					color: #999;
				}
			}
			dd {
				span {
					font-weight: 300;
					font-size: 0.875rem;
					line-height: 20px;
					color: #666;
				}

				.profile-state {
					position: relative;
					margin-left: 11px;

					&::before {
						content: "";
						width: 3px;
						height: 3px;
						border-radius: 10px;
						background: #b2b2b2;
						position: absolute;
						top: 50%;
						left: -7px;
						transform: translateY(-50%);
					}
				}
			}
		}

		// 게시글
		.content-text {
			font-weight: 300;
			font-size: 0.875rem;
			line-height: 28px;
			letter-spacing: -0.06em;
			color: #333;
			word-break: break-word;

			p {
				img:not([width]) {
					width: 100%;
				}
			}

			h1 {
				font-size: 2.2em;
			}

			h2 {
				font-size: 1.6em;
			}

			h3 {
				font-size: 1.3em;
			}

			ol {
				margin-left: 12px;
				list-style: decimal;
			}

			ul {
				margin-left: 20px;
				list-style: disc;
			}

			strong {
				font-weight: bold !important;
			}
			blockquote {
				border-left: 4px solid #ccc;
				margin-bottom: 5px;
				margin-top: 5px;
				padding-left: 16px;
			}
			img {
				cursor: default !important;
			}
		}

		// 첨부파일
		.content-file {
			.file-title {
				font-weight: 400;
				font-size: 1rem;
				letter-spacing: -0.06em;
				color: #333;
				margin: 40px 0 16px;

				svg {
					margin-right: 4px;
				}
			}
			.file-item {
				height: 50px;
				display: flex;
				padding: 7px 15px 7px 7px;
				align-items: center;
				border: 1px solid #e4e4e4;
				border-radius: 2px;
				transition: 0.3s;
				margin-bottom: 4px;

				&:hover {
					background: rgba(0, 164, 255, 0.05);
					border: 1px solid #0D9488;
					cursor: pointer;

					.file-download svg {
						fill: $primary-color;
					}
				}
			}
			.file-icon {
				width: 36px;
				height: 36px;
				background: #f3f6f9;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 12px;

				svg {
					fill: $primary-color;
				}
			}

			.file-contents {
				flex: 1;

				.file-text {
					font-weight: 400;
					font-size: 0.875rem;
					letter-spacing: -0.06em;
					line-height: 14px;
					color: #333;
					margin-bottom: 4px;
				}
				.file-data {
					font-weight: 300;
					font-size: 0.75rem;
					color: #b2b2b2;
				}
			}
			.file-download {
				svg {
					transition: 0.3s;
					fill: #666;
				}
			}
		}
	}

	// 댓글 section
	.comment-section {
		border-top: 1px solid #d7d7d7;
		padding-top: 24px;
		margin-top: 24px;

		// @이름 - tag style
		.comment-tag {
			font-weight: 500;
			font-size: 1rem;
			color: $primary-dark-color;
			margin-right: 4px;
		}

		// 댓글 제목
		.comment-title {
			display: flex;
			align-items: center;
			margin-bottom: 8px;

			span {
				font-weight: 500;
				font-size: 1rem;
				line-height: 1.125em;
				letter-spacing: -0.03em;
				color: #333;
			}
			.num {
				font-weight: 700;
				color: $primary-color;
				margin-left: 4px;
			}
			svg {
				fill: $primary-color;
				margin-right: 4px;
			}
		}

		// 댓글 form
		.comment-box {
			background: #fff;
			border: 1px solid #d7d7d7;
			border-radius: 10px;
			padding: 20px 24px 16px;

			// 댓글입력칸 높이
			.c__form {
				height: 100%;

				.f__group:first-child {
					height: calc(100% - 38px);
					margin-bottom: 6px;

					.c__textarea {
						height: 76px;
					}
				}
			}

			.f__group:first-child .f__control-wrap {
				width: 100%;
				display: flex;
			}

			.f__group:last-child .f__control-wrap {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.btn__solid {
					padding: 0 19.5px;
					height: 32px;

					&:last-child {
						margin-left: 6px;
						align-self: flex-end;
					}
				}
			}

			// 댓글 text area 수정
			.c__textarea {
				padding: 0;
				border: none;
				min-height: auto;

				&::placeholder {
					font-weight: 300;
				}
				&:not(:disabled):not([readonly]) {
					&:hover,
					&:focus,
					&:active {
						border: none;
						box-shadow: none;
					}
				}
			}
		}

		// 댓글 List
		.comment-list {
			margin-top: 24px;
		}
		.comment-item {
			border-bottom: 1px solid #e4e4e4;
			list-style: none;

			&:first-child {
				border-top: 1px solid #e4e4e4;
			}

			.btn-dropdown {
				height: auto;
				padding: 21.5px 16px;
			}
			.c__dropdown {
				top: 36px;
				right: 10px;
			}
			.comment-edit {
				padding-bottom: 16px;
			}
			&.active {
				background: #fbfcfd;
			}
		}

		// 답글 List
		.reply-list {
			background: #fff;
		}
		.reply-item {
			padding-left: 56px;

			.comment-view {
				border-top: 1px solid #e4e4e4;
			}
			&.active {
				background: #fbfcfd;
			}
		}

		// 댓글 View
		.comment-view {
			padding: 16px 0 16px 16px;
			display: flex;
			position: relative;

			.profile-img {
				width: 40px;
				height: 40px;
			}
			.comment-edit {
				padding-bottom: 0;
			}
		}

		.comment-info {
			width: 100%;

			.comment-name span,
			.comment-text,
			.comment-text span {
				font-size: 1rem;
				line-height: 24px;
				letter-spacing: -0.06em;
			}

			.comment-name {
				font-size: 1rem;
				font-weight: 500;
				color: #333;
				line-height: 24px;
				margin-bottom: 4px;
			}
			.comment-position {
				font-weight: 300;
				color: #999;
				margin-left: 4px;
			}
			.comment-text,
			.comment-text span:not(.comment-tag) {
				white-space: pre-wrap;
				font-weight: 300;
				color: #333;
				word-break: break-word;
				margin-right: 20px;
			}

			.comment-date {
				margin-top: 16px;

				.date {
					font-family: "Poppins", sans-serif;
					margin-right: 16px;
					color: #666;
				}
				.date,
				.reply-button span {
					font-weight: 300;
					font-size: 0.875rem;
					line-height: 14px;
				}
				.reply-write {
					color: #333;
				}
				.reply-cancel {
					color: #ff4444;
				}
			}

			.comment-img {
				display: flex;
				flex-flow: wrap;

				.img-item {
					width: 120px;
					height: 120px;
					margin-top: 8px;

					&:not(:last-child) {
						margin-right: 8px;
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 4px;
					}
				}
			}
		}
		.edit-button {
			font-weight: 300;
			font-size: 0.875rem;
			line-height: 14px;
			letter-spacing: -0.06em;
			color: #666;
			position: absolute;
			top: 0;
			right: 0;
			padding: 5px 4px;
			margin-top: 16px;

			&:hover {
				color: red;
			}
		}
	}
}

// 이전글, 다음글
.community-move-card {
	max-width: 1000px;
	margin: 0 auto;
	padding: 12px 40px;

	.move-item:last-child:not(:first-child) {
		height: 43px;
		border-top: 1px solid #d7d7d7;
		padding-top: 8px;
		margin-top: 8px;
	}

	.move-item {
		display: flex;
		align-items: center;
		height: 35px;
		cursor: pointer;
		position: relative;

		.prev-icon,
		.next-icon {
			display: flex;
			align-items: center;
			margin-right: 14px;

			svg {
				fill: #666;
			}
		}
		.prev-icon {
			transform: rotate(180deg);
		}

		.move-title,
		.move-text {
			font-size: 0.875rem;
			letter-spacing: -0.06em;
		}

		.move-title {
			color: #333;
			font-weight: 500;
			margin-right: 35px;
			white-space: nowrap;
		}
		.move-text {
			@include ellipsis;

			width: calc(100% - 100px);
			font-weight: 400;
			color: #666;
			line-height: 22px;

			&:hover {
				color: $primary-color;
			}
		}
	}
}
