@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 투자 - 온라인심사 상세페이지
*/

.process-total-wrap {
	/*-----상단 진행단계 카드-----*/
	.process-step-wrap {
		width: 100%;
		height: 160px;
		padding: 30px 30px 30px 27px;
		margin-bottom: 30px;

		h5 {
			color: #333;
			font-size: 1rem;
			font-weight: 500;
			letter-spacing: -0.025em;
			margin-bottom: 20px;
		}
	}

	.progress-step-bar {
		width: 100%;
		height: 7px;
		border: 1px solid $border-color;
		border-radius: 7px;
		position: relative;
		background: transparent;

		&:after {
			content: "";
			width: 0;
			height: 7px;
			border-radius: 7px;
			background: $primary-color;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}

		.step {
			display: flex;
			flex-flow: column;
			align-items: center;
			position: absolute;
			top: -7.5px;

			&:first-child {
				left: 14.89362%;
			}

			&:nth-child(2) {
				left: 48.22695%;
			}

			&:last-child {
				left: 81.56028%;
			}
		}

		// 번호
		i {
			width: 20px;
			height: 20px;
			color: #999;
			font-size: 0.6875rem;
			font-weight: 300;
			font-style: normal;
			line-height: 19px;
			border: 1px solid $border-color;
			border-radius: 50%;
			margin-bottom: 10px;
			background-color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
		}

		.status {
			color: #333;
			font-size: 1rem;
			letter-spacing: -0.075em;
		}
	}

	//----- 말풍선
	.step-tooltip {
		width: 160px;
		height: 85px;
		border: solid 1px $border-color;
		border-radius: 13px;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 50%;
		top: 31px;
		transform: translateX(-50%);
		transition: 0.5s ease-in-out;
		opacity: 0;

		&:after {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -6.5px;
			border-style: solid;
			border-color: #fff transparent;
			border-width: 0 6.5px 7px;
			z-index: 9;
			/* reduce the damage in FF3.0 */
			display: block;
			width: 0;
		}

		&:before {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -8px;
			border-style: solid;
			border-color: $border-color transparent;
			border-width: 0 8px 9px;
			z-index: 9;
			/* reduce the damage in FF3.0 */
			display: block;
			width: 0;
		}

		dt {
			color: $primary-color;
			font-size: 1.125rem;
			font-weight: normal;
			line-height: 1;
			letter-spacing: -0.075em;
			margin-bottom: 11px;
		}

		dd {
			color: #999;
			font-size: 0.75rem;
			font-weight: 300;
			line-height: 1;
		}
	}

	//----- 진행상태
	.progress-step-bar {
		// 요청까지
		&.waiting {
			&:after {
				width: 15.60283%;
			}

			.step:first-child {
				i {
					color: $primary-color;
					border: 1px solid $primary-color;
				}

				.step-tooltip {
					opacity: 1;
				}
			}
		}

		// 심사까지
		&.confirm {
			&:after {
				width: 49.29078%;
			}

			.step {
				&:first-child,
				&:nth-child(2) {
					i {
						color: $primary-color;
						border: 1px solid $primary-color;
					}
				}

				&:first-child .step-tooltip:hover {
					opacity: 1;
					cursor: pointer;
				}

				&:nth-child(2) .step-tooltip {
					opacity: 1;
				}
			}
		}

		// 완료까지
		&.complete {
			&:after {
				width: 100%;
			}

			.step {
				i {
					color: $primary-color;
					border: 1px solid $primary-color;
				}

				&:first-child .step-tooltip:hover {
					opacity: 1;
					cursor: pointer;
				}

				&:last-child .step-tooltip {
					opacity: 1;
				}
			}
		}

		// 반려까지
		&.reject {
			&:after {
				width: 49.29078%;
				background: $error-color;
			}

			.step {
				&:first-child,
				&:nth-child(2) {
					.status {
						color: $error-color;
					}
					i {
						color: $error-color;
						border: 1px solid $error-color;
					}
				}

				&:first-child .step-tooltip:hover {
					opacity: 1;
					cursor: pointer;
				}

				&:nth-child(2) .step-tooltip {
					opacity: 1;
					dt {
						color: $error-color;
					}
				}
			}
		}
	}

	/*-----하단 카드-----*/
	//----- 카드 row
	.c__cardRow {
		width: auto;
		display: flex;
		align-items: flex-start;
		transition: margin-left 0.5s ease-in-out;

		.c__card {
			width: 940px;
			min-width: 940px;
			min-height: 462px;
			margin-bottom: 0;
			position: relative;
			z-index: 0;

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				border-radius: 13px;
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
	}

	@media screen and (max-width: 1140px) {
		.c__cardRow {
			width: 100% !important;
			margin-left: 0 !important;
		}

		@for $i from 1 through 4 {
			.card-step#{$i} {
				transform: translateX(calc(-100% * #{$i - 1} - 30px * #{$i - 1}));
			}
		}

		.c__card {
			width: 100% !important;
			min-width: 100% !important;
		}
	}

	//----- 카드 애니메이션 & 카드 그림자 그라데이션
	$cardWidth: 940px;
	$cardMargin: 30px;

	@for $i from 1 through 4 {
		.card-step#{$i} {
			margin-left: calc(
				(-#{$cardWidth} - #{$cardMargin}) *#{$i - 1} + ((100% - #{$cardWidth}) / 2)
			);

			.c__card {
				&:nth-of-type(#{$i}):after {
					z-index: -1 !important;
				}

				//다음카드
				&:nth-of-type(n + #{$i + 1}):after {
					background: linear-gradient(to right, #fff 30%, #f8f8f8 60%);

					animation-fill-mode: forwards;
					animation-duration: 0.55s;
					animation-name: forward;
					animation-timing-function: ease;
				}

				//이전 카드
				&:nth-of-type(-n + #{$i - 1}):after {
					background: linear-gradient(to left, rgba(#fff, 0.5) 5%, #f8f8f8 60%);

					animation-fill-mode: backwards;
					animation-duration: 0.55s;
					animation-name: forward;
					animation-timing-function: ease;
				}
			}

			//최초 페이지 들어오면 다음카드 애니메이션 제거
			&.animation-paused .c__card:not(:nth-of-type(#{$i})):after {
				animation: none;
			}
		}
	}

	//----- message 카드
	.message {
		padding: 40px 50px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				color: #333;
				font-size: 1.25rem;
				letter-spacing: -0.05em;
			}
		}

		.dropdown-wrap {
			position: relative;
			height: 40px;

			.img-wrap {
				width: 40px;
				height: 40px;
				overflow: hidden;
				border-radius: 50%;
				flex-shrink: 0;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: contain;
				}
			}

			.c__dropdown-wrap {
				width: calc(100% + 14px);
				height: 100%;

				.btn-dropdown {
					width: 100%;
					height: 100%;
					opacity: 0;
				}
			}

			.judge-list-wrap {
				min-width: 176px;
				top: 43px;
				right: -40px;
				padding-top: 0;
			}

			.judge-list-wrap .top {
				width: 100%;
				height: 40px;
				padding: 0 18px;
				border-bottom: 1px solid #e5e5e5;
				display: flex;
				justify-content: space-between;

				span {
					color: #333;
					letter-spacing: -0.025em;
				}

				.num {
					color: $primary-color;
				}
			}

			.judge-list-wrap li {
				height: auto;
				display: flex;
				align-items: center;
				padding: 5px 3px 5px 16px;

				.img-wrap {
					width: 50px;
					height: 50px;
					margin-right: 20px;
				}

				dt {
					color: #000000a6;
					text-align: left;
					font-size: 0.75rem;
					letter-spacing: -0.025em;
					margin-bottom: 5px;
				}

				dd {
					color: #555;
					letter-spacing: -0.025em;
				}
			}

			.judge-summary-list {
				display: flex;
				align-items: center;

				.img-wrap {
					margin-left: -14px;
				}
			}
		}

		.text {
			min-height: 100px;
			color: #666;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.05em;
			margin: 20px 0 30px;
			white-space: pre-line;
			word-break: keep-all;
			word-wrap: break-word;
		}

		.vc {
			line-height: 1;
			letter-spacing: -0.025em;

			dt {
				color: #666;
				font-size: 0.75rem;
				margin-bottom: 9px;
			}

			dd {
				color: #333;
				font-size: 1rem;
			}
		}
	}

	//----- info 카드
	.info .form-ir-send {
		width: 70%;

		.f__group {
			padding-bottom: 10px;
			align-items: center;

			&.required {
				align-items: flex-start;
			}
		}

		// 입력(초기화면) ir정보 없을 때
		.ir-none {
			color: #999;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.8px;

			.empty {
				color: $primary-color;
				padding-bottom: 3px;
				border-bottom: 1px solid $primary-color;
				margin-left: 5px;
			}
		}

		// 파일 업로드
		.c__fileUploader .file-preview {
			flex: 1;
		}
	}

	//----- accept 카드
	.accept {
		.submit-info {
			width: calc(100% - 152px);
			border-top: 1px solid #333;

			li {
				height: 40px;
				align-items: center;
				border-bottom: 1px solid $border-color;
				padding: 0 10px 0 12px;
			}

			.f__label {
				color: #333;
				font-weight: 500;
				letter-spacing: -0.05em;
			}

			.f__control {
				color: #333;
				font-weight: 300;
				text-align: right;
				letter-spacing: -0.025em;

				a {
					color: #00f;
					font-size: inherit;
					font-weight: inherit;
					letter-spacing: inherit;
					display: inline-block;
					position: relative;

					&:after {
						content: "";
						position: relative;
						top: 3px;
						display: block;
						width: 100%;
						height: 1px;
						background: #00f;
						opacity: 0;
						transition: 0.3s;
					}

					&:hover:after {
						opacity: 1;
					}
				}
			}
		}

		.card-bottom {
			border-bottom-left-radius: 13px;
			border-bottom-right-radius: 13px;

			h5 {
				color: #6fb6de;
				font-size: 1.5rem;
				font-weight: 700;
				line-height: 1;
				letter-spacing: -0.05em;
				margin-bottom: 12px;
			}

			p {
				color: #666;
				font-weight: 300;
				line-height: 1;
			}
		}
	}

	//----- proceeding 카드
	.proceeding {
		padding: 70px 50px 40px;

		.sub {
			margin-bottom: 20px;
		}
	}

	//----- complete 카드
	.c__card.complete {
		padding: 40px 40px 20px;

		.sub {
			margin-bottom: 35px;
		}

		.content {
			height: 115px;
			border-top: 1px solid $border-color;
			overflow: hidden;
		}

		// 심사역 개별 평가
		.commentary {
			height: 140px;
			padding: 20px 20px 23px 10px;
			display: flex;
			align-items: flex-start;

			.img-wrap {
				width: 40px;
				min-width: 40px;
				height: 40px;
				border: 1px solid #e8e8e8;
				border-radius: 50%;
				margin-right: 15px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.judge {
				color: #999;
				font-weight: 500;
				letter-spacing: -0.05em;
				margin-bottom: 12px;

				b {
					color: #333;
					font-size: 1rem;
					font-weight: 500;
					letter-spacing: -0.05em;
					margin-left: 5px;
				}
			}

			.text {
				color: #666;
				font-weight: 300;
				line-height: 1.43;
				letter-spacing: -0.35px;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				word-break: keep-all;
				white-space: pre-wrap;
			}
		}

		// 총평
		.total-commentary-wrap {
			width: 100%;
			margin: 50px 0 20px;
			position: relative;
			background: #f8f8f8;

			.img-wrap {
				width: 50px;
				height: 50px;
				border: 1px solid #e8e8e8;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					background-color: #fff;
					object-fit: contain;
				}
			}
		}

		.total-commentary {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 30px;

			.vc {
				color: $primary-color;
				font-size: 1rem;
				font-weight: 500;
				line-height: 1;
				letter-spacing: -0.05em;
				margin: 10px 0 16px;
			}

			.text {
				color: #333;
				font-weight: 300;
				line-height: 1.43;
				letter-spacing: -0.025em;
				white-space: pre-wrap;
			}
		}

		// 닫기, 펼치기 버튼 커스텀
		.btn-collapse {
			width: 70px;
			color: $primary-color;
			font-size: 0.8125rem;
			font-weight: 300;
			line-height: 1.85;
			letter-spacing: -0.05em;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;

			svg {
				fill: $primary-color;
				margin-top: 5px;
			}
		}

		// 닫힌 상태 버튼
		.btn-arrow-open {
			display: flex;
		}

		.btn-arrow-close {
			display: none;
		}
	}

	//----- reject 카드
	.reject {
		display: flex;
		flex-direction: column;
		align-items: center;

		.content {
			color: #333;
			font-size: 1.25rem;
			font-weight: 300;
			text-align: center;
			line-height: 1.4;
			letter-spacing: -0.05em;
			padding-top: 15px;
			margin-bottom: 26px;
			display: block;

			i {
				font-style: normal;
				font-size: inherit;
				font-weight: inherit;
				color: $error-color;
			}
		}

		.img-wrap {
			width: 128px;
			margin-bottom: 20px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		button {
			width: 122px;
		}
	}

	//----- complete 카드 펼친 상태
	.complete.active {
		height: auto;

		.content {
			height: auto;
		}

		.commentary {
			height: auto;
			transition: 0.3s;

			&:not(:disabled):hover,
			&:not(:disabled):focus {
				background: #f8f8f8;
			}
		}

		.commentary-text .text {
			overflow: visible;
			display: block;
		}

		// 펼친 상태 버튼
		.btn-arrow-open {
			display: none;
		}

		.btn-arrow-close {
			display: flex;

			svg {
				transform: rotate(-180deg);
			}
		}
	}

	//----- info, accept 카드 공통
	.info,
	.accept {
		padding-top: 40px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.top {
			display: flex;
			flex-flow: column;
			align-items: center;
		}

		.phrase {
			font-size: 1.25rem;
			text-align: center;
			line-height: 1.4;
			letter-spacing: -0.05em;
			color: #333;
			margin: 12px 0 30px 0;

			b {
				font-size: 1.25rem;
				color: $primary-color;
			}
		}
	}

	//----- proceeding, complete 카드 공통
	.proceeding,
	.complete {
		.top {
			text-align: center;
			padding-top: 15px;
		}

		.percent {
			color: $primary-color;
			font-size: 6.875rem;
			font-weight: 100;
			font-family: "Poppins", sans-serif;
			line-height: 80px;
			letter-spacing: -0.04em;
			margin-bottom: 30px;

			i {
				font-weight: 300;
				font-style: normal;
				font-family: inherit;
				line-height: 1;
				letter-spacing: -0.04em;
				margin-left: 17px;
			}
		}

		.main {
			color: $primary-color;
			font-size: 1.25rem;
			font-weight: 300;
			line-height: 1;
			letter-spacing: -0.04em;
			margin-bottom: 19px;
		}

		.sub {
			color: #999;
			font-size: 1rem;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.05em;
		}
	}

	//----- card-bottom 공통
	.card-bottom {
		width: 100%;
		height: 128px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-top: 1px solid #e8e8e8;
	}

	//----- 요청상태
	.waiting-step,
	.reject-step {
		.c__card {
			padding: 40px 50px;
		}

		.card-bottom {
			padding: 30px 0 0;

			p {
				color: #333;
				font-size: 1.125rem;
				letter-spacing: -0.05em;
			}
		}

		.card-btn-group {
			padding-top: 20px;

			button {
				width: 122px;
				letter-spacing: 0.1em;
				margin-right: 10px;
			}
		}
	}

	//----- 심사상태
	.confirm-step .judge-list {
		width: 100%;
		border-top: 1px solid $border-color;
		padding-top: 40px;
		display: flex;
		justify-content: center;
		align-items: center;

		li {
			width: 60px;
			height: 60px;
			margin: 0 5px;
			border-radius: 50%;
			overflow: hidden;
			position: relative;
			border: 2px solid transparent;
			filter: grayscale(100%);

			&:before {
				content: "";
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: hsla(0, 0%, 100%, 0.4);
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.complete-judge {
			transition: 0.3s ease-in-out;
			border-color: $primary-color;
			filter: grayscale(0);
			cursor: pointer;

			&:before {
				background: inherit;
			}
		}
	}

	//----- 푸터 페이지네이션
	.tab-footer {
		width: 100%;
		display: flex;
		align-items: center;
		padding-top: 30px;
		margin-top: auto;
		justify-content: space-between;

		.prev-btn,
		.next-btn {
			display: inline-flex;
			align-items: center;

			> span {
				font-size: 0.875rem;
				font-weight: 300;
				letter-spacing: -0.05em;
				color: #afafaf;
				transition: 0.3s;
			}

			&:not(:disabled):hover {
				> span {
					color: $primary-color;
				}

				.round-arrow-icon {
					border-color: $primary-color;
					fill: $primary-color;
				}
			}

			&:disabled {
				opacity: 1;

				> span {
					color: #d7d7d7;
				}

				.round-arrow-icon {
					fill: #d7d7d7;
					border-color: $border-color;
				}
			}
		}

		.round-arrow-icon {
			margin: 0 10px;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #b0b0b0;
			fill: #b0b0b0;
			transition: 0.3s;
		}

		.next-btn .round-arrow-icon {
			transform: rotate(180deg);
		}
	}
}
