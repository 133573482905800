@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

.p__case {
	@import "src/assets/sass/pages/landing/case/caseLayout";
	@import "src/assets/sass/pages/landing/case/caseHeader";
	@import "src/assets/sass/pages/landing/case/caseFooter";
}

/***
  * 고객사례
*/

.p__case {
	/*---공통---*/
	.l__layout {
		width: 1200px;
		margin: 0 auto;
		padding: 0 20px;

		@media screen and (max-width: 1216px) {
			width: calc(304px + (1200 - 304) * ((100vw - 320px) / (1216 - 320)));
		}
	}

	.section-title {
		text-align: left;
		font-size: 2rem;
		font-weight: 700;
		letter-spacing: -0.05em;
		color: #333;
		padding-bottom: 0;
	}

	.section-desc {
		font-weight: 500;
		letter-spacing: -0.05em;
		line-height: 1.36;
		color: #666;
		margin-top: 8px;

		@media screen and (max-width: 767px) {
			letter-spacing: -0.075em;
		}
	}

	.c__cardRow {
		width: calc(100% + 30px);
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		padding: 15px 0;

		.c__card {
			width: calc(33.33333% - 30px);
			overflow: hidden;
			margin: 15px;
			height: auto;

			@media screen and (max-width: 1023px) {
				width: calc(50% - 30px);
			}

			@media screen and (max-width: 480px) {
				width: 100%;
			}

			.card-img,
			.card-title {
				cursor: pointer;
			}

			.card-img {
				width: 100%;
				height: 0;
				overflow: hidden;
				position: relative;

				img {
					width: 100%;
					max-width: 100%;
					height: 100%;
					object-fit: cover;

					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
		}
	}

	//버튼(더보기)
	.btn__more {
		width: 100%;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba(0, 164, 255, 0.5);
		font-size: 1rem;
		font-weight: 400;
		letter-spacing: -0.05em;
		color: $primary-color !important;

		svg {
			width: 14px;
			height: 8px;
			margin-right: 9px;
			fill: rgba(0, 164, 255, 0.4);
			transform: rotate(180deg) translateY(-25%);
		}

		&:not(:disabled):hover {
			background: rgba(0, 164, 255, 0.4);
			color: #fff;

			svg {
				fill: #fff;
			}
		}
	}

	/*---상단 배경---*/
	.top-case-section {
		display: inherit;
		height: auto;
		padding: 0;
		background-repeat: no-repeat;
		background-image: url("../../../../../../src/assets/img/bg-case.png");
		background-size: 100% 100%;
		background-position: center center;
	}

	// bg
	.bg {
		height: 480px;
		display: flex;
		align-items: center;
		justify-content: center;
		//background-image: url("../../../img/bg-case.png");

		background: {
			repeat: no-repeat;
			size: cover;
			position: center;
		}

		@media screen and (max-width: 767px) {
			height: calc(440px + (480 - 440) * ((100vw - 320px) / (767 - 320)));
		}

		&-text {
			text-align: center;
			word-break: keep-all;
			font-size: 2.25rem;
			font-weight: 300;
			letter-spacing: -0.05em;
			line-height: 1.39;
			color: #fff;

			@media screen and (max-width: 767px) {
				padding-top: 70px;
				font-size: calc(22px + (36 - 22) * ((100vw - 320px) / (767 - 320)));
			}
		}

		b {
			font-size: inherit;
			font-weight: 600;
		}
	}

	// bottom
	.bottom {
		padding: 20px 0;
		background: $primary-color;

		.l__layout {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width: 1023px) {
				flex-flow: column;
				justify-content: center;
			}
		}

		&-text {
			word-break: keep-all;
			font-size: 1.125rem;
			letter-spacing: -0.05em;
			line-height: 1.25;
			color: #fff;

			@media screen and (max-width: 1023px) {
				padding-bottom: 20px;
				text-align: center;
			}

			@media screen and (max-width: 767px) {
				font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (767 - 320)));
			}
		}
	}

	//버튼(기업등록하기)
	.btn-company {
		width: fit-content;
		min-width: 130px;
		height: 40px;
		padding: 0 10px;
		border-radius: 20px;
		border: 2px solid #fff;
		font-weight: 700;
		color: #fff;

		&:not(:disabled):hover {
			background: #fff;
			color: $primary-color;
		}
	}

	/*---투자라운드 사례---*/
	.vc-section {
		padding: 60px 0;
		background-color: unset;

		.c__card {
			position: relative;
			border-radius: 13px;

			.card-img {
				padding-top: calc((230 / 350) * 100%);
			}

			.card-logo {
				width: 45px;
				height: 45px;
				position: absolute;
				right: 20px;
				bottom: calc(120px - (45px / 2));
				overflow: hidden;
				border-radius: 50%;
				box-shadow: 0 3px 5px 0 rgba(35, 31, 32, 0.08);
				z-index: 1;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.card-text {
				height: 120px;
				padding: 20px 26px;
				display: flex;
				flex-flow: column;
				justify-content: center;
			}

			.title {
				@include ellipsis;
				max-height: 22px;
				font-size: 1.125rem;
				font-weight: 600;
				letter-spacing: -0.08em;
				line-height: 1.23;
				color: #333;
			}

			.name {
				padding-top: 6px;
				letter-spacing: -0.075em;
				line-height: 1.43;
				color: #999;
			}
		}
	}

	/*---스타트업 이용 사례---*/
	.startup-section {
		padding: 60px 0 80px;
		background: #f8fbfe;
		transition: 0.3s;

		&.active {
			background: #fff;
		}

		.c__card {
			border-radius: 5px;

			.card-top {
				padding: 18px 20px;
			}

			.card-bottom {
				padding: 14px 17px;
				border: 1px solid #f8f8f8;
			}

			.card-img {
				padding-top: calc((230 / 400) * 100%);
			}

			.card-text {
				height: 170px;
			}

			.title {
				font-size: 1.125rem;
				font-weight: 600;
				letter-spacing: -0.05em;
				color: $primary-color;
			}

			.explain {
				max-height: 44px;
				display: inline-block;
				display: -webkit-box;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				margin-top: 13px;
				letter-spacing: -0.025em;
				line-height: 1.58;
				color: #666;
			}

			.tag {
				width: max-content;
				height: 25px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				margin: 2px;
				padding: 0 7px;
				border: 1px solid $border-color;
				text-align: center;
				font-size: 0.75rem;
				color: #999;
			}
		}
	}
}

//--- 사례 모달 헤더 제거
.vc-case-modal .modal-header,
.startup-case-modal .modal-header {
	height: 60px;
	border-bottom: none;

	.modal-title {
		display: none;
	}

	.modal-close {
		margin-left: auto;
	}
}

//--- 투자라운드 사례 모달
.vc-case-modal .modal-content {
	padding: 0 70px 60px 60px;
	display: flex;

	@media screen and (max-width: 1200px) {
		flex-flow: column;
	}

	.img-wrap {
		width: 540px;
		min-width: 540px;
		//height: 385px;
		margin-right: 40px;

		img {
			width: 100%;
			//height: 100%;
		}

		@media screen and (max-width: 1200px) {
			margin-right: 0;
			margin-bottom: 40px;
		}

		@media screen and (max-width: 767px) {
			width: calc(228px + (540 - 228) * ((100vw - 320px) / (767 - 320)));
			min-width: calc(228px + (540 - 228) * ((100vw - 320px) / (767 - 320)));
			height: calc(163px + (385 - 163) * ((100vw - 320px) / (767 - 320)));
			margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (767 - 320)));
		}
	}

	.content {
		padding: 0 10px;
	}

	.title {
		word-break: keep-all;
		font-size: 1.75rem;
		font-weight: 700;
		letter-spacing: -0.025em;
		line-height: 1.29;
		color: #333;

		@media screen and (max-width: 767px) {
			font-size: calc(24px + (28 - 24) * ((100vw - 320px) / (767 - 320)));
		}
	}

	.company {
		display: flex;
		align-items: center;
		padding-top: 17px;
	}

	.logo {
		width: 45px;
		min-width: 45px;
		height: 45px;
		overflow: hidden;
		margin-right: 15px;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.name {
		font-size: 1rem;
		font-weight: 500;
		letter-spacing: -0.05em;
		line-height: 1.38;
		color: #333;
	}

	.url a {
		font-weight: 300;
		letter-spacing: -0.025em;
		line-height: 1.43;
		color: #999;
	}

	.desc {
		padding: 15px 0 26px;
		word-break: keep-all;
		font-weight: 300;
		letter-spacing: -0.025em;
		line-height: 1.58;
		color: #666;

		@media screen and (max-width: 767px) {
			font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (767 - 320)));
		}
	}

	.apply {
		padding: 12px 10px;
		border: {
			top: 1px solid $border-color;
			bottom: 1px solid $border-color;
		}

		dl {
			display: flex;
			align-items: center;
			min-height: 30px;
		}

		dt,
		dd {
			font-size: 1rem;
			letter-spacing: -0.05em;
			line-height: 1.25;

			@media screen and (max-width: 767px) {
				font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (767 - 320)));
			}
		}

		dt {
			min-width: 45px;
			font-weight: 300;
			color: #666;
		}

		dd {
			font-weight: 500;
			letter-spacing: -0.05em;
			color: #333;

			&.place {
				color: #999;
			}
		}
	}
}

//--- 스타트업 이용 사례 모달
.startup-case-modal {
	.top {
		display: flex;
		padding: 0 60px 40px;

		@media screen and (max-width: 1200px) {
			flex-flow: column;
		}
	}

	.content {
		padding: 0 60px 56px;
		background: #fafafa;
		border-top: 1px solid $border-color;
		border-bottom-left-radius: 13px;
		border-bottom-right-radius: 13px;
	}

	.top,
	.content {
		@media screen and (max-width: 767px) {
			padding-left: calc(30px + (60 - 30) * ((100vw - 320px) / (767 - 320)));
			padding-right: calc(30px + (60 - 30) * ((100vw - 320px) / (767 - 320)));
		}
	}

	//slide
	.slide {
		width: 540px;
		min-width: 540px;
		margin-right: 50px;

		@media screen and (max-width: 1200px) {
			margin-right: 0;
		}

		@media screen and (max-width: 767px) {
			width: 100%;
			min-width: 100%;
		}

		.img-wrap {
			width: 100%;
			//height: 375px;

			img {
				width: 100%;
				//height: 100%;
				object-fit: cover;
			}
		}

		.slick-dots li button:before {
			font-size: 1.875rem;
		}
	}

	.company {
		padding: 19px 0;
	}

	.logo {
		width: 169px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.title {
		padding-top: 26px;
		word-break: keep-all;
		font-size: 1.75rem;
		font-weight: 700;
		letter-spacing: -0.06em;
		line-height: 1.43;
		color: #000;

		@media screen and (max-width: 767px) {
			font-size: calc(24px + (28 - 24) * ((100vw - 320px) / (767 - 320)));
		}

		b {
			font-weight: inherit;
			font-size: inherit;
		}

		.orange {
			font-size: inherit;
			color: #ed1c24;
		}

		.sky {
			color: #f6921e;
		}

		.blue {
			color: #0068b7;
		}
	}

	.desc {
		padding-top: 20px;
		word-break: keep-all;
		font-weight: 300;
		letter-spacing: -0.022em;
		line-height: 1.71;
		color: #333;

		@media screen and (max-width: 767px) {
			font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (767 - 320)));
		}
	}

	.company-explain,
	.service-explain {
		word-break: keep-all;
		font-weight: 300;
		letter-spacing: -0.025em;
		line-height: 1.72;
		color: #333;

		@media screen and (max-width: 767px) {
			font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (767 - 320)));
		}
	}

	.service-text {
		&:not(:first-child) {
			padding-top: 18px;
		}

		dt {
			font-size: 1rem;
			letter-spacing: -0.05em;
			color: #000;
		}

		dd {
			padding-top: 8px;
			font-weight: 300;
			letter-spacing: -0.025em;
			line-height: 1.25;
			color: #666;
		}
	}
}
