@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * FAQ 탭
*/

.rounding-tabs-wrap .faq-tab {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 20px;

	.c__loader {
		flex: 1;
		padding-top: 0;
	}

	.c__noData {
		height: 100%;
		flex: 1;
	}

	.rounding-faq {
		width: 100%;
		height: auto;
		border-top: 1px solid #e8e8e8;
	}

	.faq-card {
		border-bottom: 1px solid #e8e8e8;
		border-left: 1px solid #e8e8e8;
		border-right: 1px solid #e8e8e8;

		&-header {
			height: 50px;
			box-sizing: border-box;
			background-color: #fbfbfb;

			.faq-toggle-btn {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				padding-left: 24px;
				color: #666;
				font-size: 1rem;
				font-weight: 300;
				line-height: 1.42;
				letter-spacing: -0.05em;
				transition: 0.3s;

				svg {
					transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
					fill: #666;
					transform: rotate(270deg);
					margin-right: 17px;
				}
			}
		}

		&-body {
			width: 940px;
			height: 100%;
			margin-left: auto;
			margin-right: auto;
			display: none;

			@media screen and (max-width: 1200px) {
				width: 94.95%;
			}

			.card-answer {
				padding: 16px 20px;
				border-radius: 5px;
				background-color: #fdfdff;
				border: 1px solid rgba(174, 226, 255, 0.6);
				margin-bottom: 22px;

				&:last-child {
					margin-bottom: 20px;
				}

				&-line {
					color: #666;
					font-weight: 300;
					line-height: 1.86;
					letter-spacing: -0.025em;
					list-style: none;
					word-break: break-all;

					&:before {
						width: 17px;
						height: auto;
						content: "\25aa";
						display: inline-block;
						margin-left: -5px;
						text-align: left;
						color: #b9b8ba;
					}
				}
			}
		}

		&.card-open {
			.faq-card-header {
				background-color: #fff;

				.faq-toggle-btn {
					color: $primary-color;

					svg {
						fill: $primary-color;
						transform: rotate(0);
					}
				}
			}

			.faq-card-body {
				display: block;
			}
		}
	}
}
