@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 페이지 상단 페이지 설명
*/

.pageIntro {
	display: flex;
	align-items: center;
	margin-bottom: 30px;

	img,
	svg {
		width: 70px;
		height: 70px;
		margin-right: 13px;
		fill: $primary-color;
	}

	.content {
		white-space: nowrap;

		h3 {
			color: $primary-color;
			font-size: 1.5rem;
			line-height: 1.08;
			letter-spacing: -0.06em;

			b {
				font-size: 1.5rem;
				font-weight: 700;
			}
		}

		p {
			color: #333;
			font-weight: 200;
			line-height: 1.43;
			letter-spacing: -0.05em;
			margin-top: 6px;
		}
	}
}
