@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * 로그인/회원가입/비밀번호 찾기 form 공통 스타일
*/

.auth-form {
	.f__label {
		font-size: 1rem;
		color: #333;
	}

	.f__control {
		&:not(.has-error) {
			margin-bottom: 16px;
		}

		&.has-error {
			margin-bottom: 36px;
		}
	}

	.f__item {
		height: 45px;
		border-radius: 8px;
	}

	.f__feedback {
		position: absolute;
		font-size: (0.875rem, 2,5vw, 1rem);
	}
}
