@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 팀원 > 팀원정보 > 조직도 설정 > 2단계 모달
*/

&.c__modal.show-mask {
	background: rgba(0, 0, 0, 0.4);
}

.modal-header {
	height: 60px;
	padding: 20px 5px 20px 20px;
	border-bottom: none;

	.modal-title {
		font-weight: 500;
		color: #25232a;
	}
}

.modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 20px;
}

.modal-footer {
	border-top: none;

	> button {
		width: 80px;
		margin: 20px 0;
	}
}
