@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * IR - 주요연혁
*/

/*---공통---*/
.history-view,
.history-edit {
	padding: 30px;
}

/*---VIEW---*/
.history-view {
	display: flex;
	flex-wrap: wrap;

	.yearly-history {
		width: 426px;
		margin: 0 15px 30px;
		padding: 0 15px;
	}

	.history-content li {
		margin-bottom: 4px;
		line-height: 1.42;
	}
}

/*---EDIT---*/
.history-edit {
	.title {
		color: #333;
		font-size: 1.625rem;
		font-weight: 600;
		line-height: 1.5;
		letter-spacing: -0.05em;
		padding-bottom: 20px;
	}

	.left-wrap {
		width: 100%;
	}

	.dashed-btn {
		margin-bottom: 15px;
	}

	// 좌우 분할 (d-flex)
	.history-register-wrap {
		display: flex;
		justify-content: space-between;

		.history-input {
			min-width: 335px;
			margin-right: 4%;
		}

		.yearly-history {
			width: 35%;
			min-width: 350px;
			max-width: calc(100% - 350px);
			margin: 70px 0 0 0;
			padding: 0;

			> div {
				display: flex;
			}
		}
	}

	// 연혁 등록
	.sub-section-wrap {
		display: flex;

		.datepicker {
			width: 162px;
			min-width: 162px;
		}

		.content {
			width: 100%;
		}

		.btn-round-delete {
			justify-content: center;
			padding: 10px 0 10px 2px;
		}
	}

	.year {
		line-height: 1;
		margin-top: -0.15em;
		margin-bottom: 0;
	}

	.form-data-group {
		padding-bottom: 0;

		&:last-of-type {
			border-color: #e8e8e8;
		}

		.history-content {
			margin-bottom: 0.2em;

			li {
				line-height: 1.2;
				min-height: 40px;
			}
		}
	}
}
