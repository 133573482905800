@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 상세정보 탭
*/

.rounding-tabs-wrap {

	.detail-info-tab {
		padding: 30px;
		color: #333;
		background: #f8f8f8;
		border-radius: 8px;
		word-break: break-all;

		img {
			width: 100%;
		}

		.round-info-img img {
			width: 100%;
			height: 100%;
		}
	}

	// 첨부파일
	.content-file {
		.file-title {
			font-weight: 400;
			font-size: 1rem;
			letter-spacing: -0.06em;
			color: #333;
			margin: 20px 0 16px;

			svg {
				margin-right: 4px;
			}
		}
		.file-item {
			height: 50px;
			display: flex;
			padding: 7px 15px 7px 7px;
			align-items: center;
			border: 1px solid #e4e4e4;
			border-radius: 2px;
			transition: 0.3s;
			margin-bottom: 4px;

			&:hover {
				background: rgba(0, 164, 255, 0.05);
				border: 1px solid #0D9488;
				cursor: pointer;

				.file-download svg {
					fill: $primary-color;
				}
			}
		}
		.file-icon {
			width: 36px;
			height: 36px;
			background: #f3f6f9;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 12px;

			svg {
				fill: $primary-color;
			}
		}

		.file-contents {
			flex: 1;

			.file-text {
				font-weight: 400;
				font-size: 0.875rem;
				letter-spacing: -0.06em;
				line-height: 14px;
				color: #333;
				margin-bottom: 4px;
			}
			.file-data {
				font-weight: 300;
				font-size: 0.75rem;
				color: #b2b2b2;
			}
		}
		.file-download {
			svg {
				transition: 0.3s;
				fill: #666;
			}
		}
	}
}
