@charset "UTF-8";
@import "../../sass/utils/var";

.pagination {
	height: 62px;
	border-top: 1px solid $border-color;
	display: flex;
	align-items: center;
	justify-content: center;

	> button {
		width: 30px;
		height: 30px;
		color: #7d7d7d;
		font-weight: 300;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:not(:last-child) {
			margin-right: 12px;
		}
	}

	.btn-num {
		&:not(:disabled):hover {
			color: $primary-color;
		}

		&.active {
			border-radius: 50%;
			color: $primary-color;
			border: 1px solid $primary-color;
		}
	}

	.last-btn,
	.next-btn {
		transform: rotate(180deg);
	}

	@each $side in prev, next {
		.#{$side}-btn {
			border: 1px solid $primary-color;
			background-color: $primary-color;

			svg {
				fill: #fff;
			}

			&:not(:disabled):hover {
				background-color: #fff;

				svg {
					fill: $primary-color;
				}
			}
		}
	}

	@each $edge in first, last {
		.#{$edge}-btn {
			border: 1px solid $primary-color;

			svg {
				fill: $primary-color;
			}

			&:not(:disabled):hover {
				background-color: $primary-color;

				svg {
					fill: #fff;
				}
			}
		}
	}
}
