@charset "UTF-8";
@import "../utils/var";
/***
  * 스위치
*/

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 42px !important;
	height: 23px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
	outline: none !important;

	&:disabled + .slider {
		cursor: default;
	}
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #d7d7d7;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 5px;
	bottom: 5px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: $primary-color;
}

input:focus + .slider {
	//box-shadow: 0 0 1px $primary-color;
}

input:checked + .slider:before {
	-webkit-transform: translateX(18px);
	-ms-transform: translateX(18px);
	transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 23px;
}

.slider.round:before {
	border-radius: 50%;
}
