@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * radio
*/

.c__radio-wrap {
	display: flex;
	align-items: center;

	.c__radio:not(:last-child) {
		margin-right: 1.78em;
	}
}

.c__radio {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	height: 1.3em;
	line-height: 1.3em;
	font-size: 0.875rem;
	color: #333;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;

	* {
		font-size: 1em;
	}

	&.readOnly {
		input {
			cursor: not-allowed;

			&:checked ~ .checkmark {
				border-color: #E4E4E4;
			}
		}

		.checkmark {
			background-color: #F8F8F8;
			border: 1px solid #E4E4E4;

			&:after {
				background: #E4E4E4;
			}
		}
}

	//라디오에 hover 했을 때 선택할 라디오 표시
	&:not(.readOnly):hover input ~ .checkmark {
		border-color: $primary-color;
	}

	&:not(.readOnly) {
		cursor: pointer;

		input {
			cursor: pointer;
		}
	}

	//기본 태그 스타일 제거
	input {
		position: absolute;
		left: 0;
		opacity: 0;
		width: calc(1em + 2px);
		height: calc(1em + 2px);

		//선택 완료 했을 때 보더 컬러 바꿔주기
		&:checked ~ .checkmark {
			border-color: $primary-color;
		}

		//선택 완료 했을 때 선택완료표식 보여주기
		&:checked ~ .checkmark:after {
			display: block;
		}
	}

	//라디오 모양 (클릭하는 동그라미)
	.checkmark {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(1em + 2px);
		height: calc(1em + 2px);
		flex-shrink: 0;
		background-color: transparent;
		border: 1px solid #bbb;
		border-radius: 50%;
		// margin: 2px 0.4em 0 0;

		//선택완료표식
		&:after {
			content: "";
			display: none;
			width: 42.857142%;
			height: 42.857142%;
			border-radius: 50%;
			background: $primary-color;
			flex-shrink: 0;
		}
	}

	.radio-label {
		padding-left: 10px;
	}
}
