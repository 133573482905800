@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/pages/common/modalGetTeam"; //팀정보불러오기 모달

/***
  * 기업 > 주요인력
*/

/*---헤더 버튼---*/
.p__company-member .pageHeader-btn-group {
	display: flex;
	align-items: center;

	.dashed-btn {
		margin-right: 6px;

		@media screen and (max-width: 824px) {
			width: 120px;
			align-items: center;
		}
	}
}

/*---주요인력 보기---*/
.p__company-member .company-member-view {
	.c__card {
		height: 368px;
		position: relative;
		margin-bottom: 30px;
		padding: 30px 40px;
	}

	.member-img {
		width: 160px;
		height: 160px;
		overflow: hidden;
		margin: 0 auto 30px;
		border-radius: 50%;
		flex-shrink: 0;

		@media screen and (max-width: 768px) {
			width: 140px;
			height: 140px;
		}

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	dl {
		display: flex;
		flex-flow: column;
	}

	.member-name {
		order: 2;
		font-size: 1.25rem;
		font-weight: 400;
		line-height: 1.45;
		letter-spacing: -0.025em;
		color: #000;
		margin-bottom: 10px;
	}

	.member-position {
		order: 1;

		span {
			font-size: 0.8125rem;
			font-weight: 300;
			line-height: 1.42;
			color: #666;

			&:first-child {
				margin-right: 3px;
			}
		}
	}

	.member-career {
		@include customScroll(scroll-y);
		height: 60px;
		order: 3;
		font-weight: 300;
		line-height: 1.43;
		color: #000;
		word-wrap: break-word;
	}
}

/*---주요인력 수정---*/
.p__company-member .company-member-edit {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;

	.c__card {
		height: initial;
		min-height: 416px;
		padding: 30px 30px 20px;
		border: 1px solid transparent;
		position: relative;
		cursor: pointer;
		transition: 0.3s;

		&:hover {
			border: 1px solid $primary-color;
		}
	}

	.f__group {
		padding-bottom: 10px;
	}

	.member-img .f__control {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}

	.f__control-wrap {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		& > .f__control {
			width: calc(50% - 2.5px);
			flex: none;
		}
	}

	.f__control.has-error {
		margin-bottom: auto;
	}

	.c__textarea {
		min-height: 86px;
	}

	// 추가 카드
	.member-add-card {
		svg {
			fill: #9c9c9c;
			width: 60px;
			height: 60px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: 0.3s;
		}

		&:hover svg {
			fill: $primary-color;
		}
	}
}
