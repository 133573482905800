@charset "UTF-8";
@import "src/assets/sass/utils/var";

$correctColor: #369303;

//팀원 정보 모달 > 기본정보 탭 > 인사정보 수정

//----공통
.teamInfo-personal.edit, .teamInfo-payment.edit{
	@import "basicInfoCommon";

	margin-bottom: 10px;

	position: relative;

	.f__group {
		margin-bottom: 8px;

		&:not(:last-of-type):not(.has-error) {
			padding-bottom: 0;
		}
	}

	.f__img-btn {
		margin-bottom: 0;
	}

	.label-btn {
		height: 26.3px;
	}
}

.teamInfo-personal.edit .email .f__control {
	color: #333;
	line-height: 35px;
}

.teamInfo-personal.edit .address-group {
	> div {
		flex: 1;

		> .c__input {
			margin-bottom: 10px;
		}
	}

	.f__control {
		position: relative;
		z-index: 1;
		height: 35px;
	}

	.zipCode {
		width: 175px;
		margin-right: 10px;
	}

	.btn__zipCode {
		height: 35px;
		padding: 0 16px;
		font-size: 0.75rem;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		margin-bottom: 10px;
	}

	&.has-error .c__input {
		border-color: $error-color;
	}
}

.teamInfo-payment.edit .account-input-group {
	.f__control-wrap {
		display: flex;
		align-items: center;
	}

	.bank-list {
		flex-shrink: 0;
		max-width: 170px;
		margin-right: 10px;
	}

	.btn__verification {
		flex-shrink: 0;
		height: 35px;
		padding: 0 16px;
		margin-left: 9px;
		font-size: 0.75rem;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}
}

.teamInfo-personal.edit .personal-num .c__input {
	width: 170px;
}
