@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 마이페이지 > 플러그인 > 전체 플러그인
*/

.p__plugin-list .c__card {
	width: calc(33.3% - 30px); //1701 ~ 1920 3박스, 기본

	height: auto;
	padding: 20px;
	cursor: pointer;
	display: flex;
	flex-flow: column;
	justify-content: space-between;

	//1921 ~ 3박스 이상
	@media screen and (min-width: 1921px) {
		max-width: 480px;
	}

	//1025 ~ 1700 2박스
	@media screen and (max-width: 1700px) and (min-width: 1024px) {
		width: calc(50% - 30px);
	}

	// ~ 1024 1박스
	@media screen and (max-width: 1024px) {
		width: calc(100% - 30px);
	}

	.top {
		display: flex;
		margin-bottom: 20px;

		.detail-wrap {
			width: 100%;
		}
	}

	.bottom {
		padding-top: 20px;
		text-align: right;
		border-top: 1px solid $border-color;

		.loading {
			background: #fff;
		}

		.unused {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 108px;
			height: 40px;
			line-height: 40px;
			-webkit-border-radius: 25px;
			-moz-border-radius: 25px;
			border-radius: 25px;
			color: #fff;
			font-weight: 500;
			letter-spacing: -0.05em;
			background: #999;
		}

		button {
			width: 108px;
			line-height: 40px;
			font-family: "Poppins", sans-serif;

			&:not(.circle) {
				padding-bottom: 0;
			}
		}
	}
}

.p__plugin-list {
	.c__cardRow {
		display: flex;
		flex-flow: wrap;
	}

	.img-wrap {
		width: 128px;
		height: 128px;
		margin-right: 20px;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.title-wrap {
		display: flex;
		justify-content: space-between;
		align-content: flex-start;
		margin-bottom: 16px;

		h3 {
			color: #333;
			font-size: 1.625rem;
			font-weight: 300;
			letter-spacing: -0.05em;
		}

		span {
			font-size: 1rem;
			font-weight: 600;
			color: #666;
		}

		//유료
		.price {
			color: $primary-color;
		}

		//무료
		.price-free {
			color: #666;
		}
	}

	.plugin-detail {
		p {
			color: #333;
			font-size: 0.875rem;
			font-weight: 300;
			letter-spacing: -0.06em;
			line-height: 1.43;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			word-break: keep-all;
		}
		&.tag p {
			-webkit-line-clamp: 2;
		}
	}
	.plugin-tag {
		margin-top: 16px;
		display: flex;
		flex-flow: wrap;
		align-items: center;
		line-height: 14px;

		span {
			font-weight: 400;
			font-size: 12px;
			color: #666;
			background: #e8e8e8;
			border-radius: 2px;
			padding: 4.5px 6px;
			margin-top: 6px;

			&:not(:last-child) {
				margin-right: 6px;
			}
		}
	}
}
