@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 인사 > 근태 > 팀원 스케줄
*/

.p__schedule .team-schedule {
	padding: 40px 30px 66px 30px;

	.fc-daygrid-event-harness {
		margin-top: 2px !important;
	}
}

.calendar-events {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 26px;
	padding: 0 2px 0 10px;

	&:hover {
		cursor: pointer;
	}

	&.vacations {
		background: #e9faf7;
		&:before,
		dd {
			background: #58c1b5;
		}
	}
	&.businessTrip {
		background: #ebf0fc;
		&:before,
		dd {
			background: #8a86fb;
		}
	}
	&.remoteWork {
		background: #ffe4eb;
		&:before,
		dd {
			background: #ff7092;
		}
	}
	&.overTime {
		background: #fffcf3;
		&:before,
		dd {
			background: #fca909;
		}
	}

	&:before {
		content: "";
		width: 2px;
		height: 20px;
		position: absolute;
		left: 0;
	}

	dt {
		font-size: 0.875rem;
		font-weight: 500;
		color: #333;
	}

	dd {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 20px;
		padding: 0 6px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		color: #fff;
		font : {
			family: "Exo", sans-serif;
			size: 12px;
			weight: 600;
		}
	}
}

//-------------------캘린더 디테일 모달
.calendar-detail-modal {
	//TODO 임시
	.modal-close {
		position: absolute;
		top: 10px;
		right: 10px;
	}
	.modal-body {
		padding: 0 20px 0 30px;
	}

	.detail-header {
		display: flex;
		height: 125px;
		padding-top: 32px;
		border-bottom: 1px solid $border-color;

		.type-color {
			display: inline-block;
			width: 20px;
			height: 20px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			margin-top: 8px;
			margin-right: 16px;

			&.vacations {
				background: #03dbc0;
			}
			&.businessTrip {
				background: #9f77eb;
			}
			&.remoteWork {
				background: #ff7092;
			}
			&.overTime {
				background: #fcc609;
			}
		}

		dt {
			line-height: 1.5;
			font-size: 1.5rem;
			font-weight: 500;
			color: #333;
			margin-bottom: 4px;
		}

		dd {
			font-size: 1rem;
			font-weight: 300;
			line-height: 1.5;
			color: #999;
		}
	}

	.detail-list {
		@include customScroll(scroll-y);
		min-height: 354px;
		padding-top: 6px;
		padding-right: 14px;
	}

	.detail-list li {
		display: flex;
		align-items: center;
		width: 100%;
		height: 30px;
		margin: 16px 0;

		.img-wrap {
			width: 30px;
			height: 30px;
			margin-right: 6px;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
			}
		}

		.name {
			font-size: 1rem;
			color: #333;
			margin-right: 10px;
			line-height: 1;
		}

		.desc {
			font-size: 0.75rem;
			color: #666;
			line-height: 1;
		}

		.badge-wrap {
			display: flex;
			align-items: center;
			margin-left: auto;
		}

		.badge {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 20px;
			line-height: 1;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
			padding: 0 4px;
		}

		.vacation-hour {
			margin-right: 6px;
			background: #03dbc0;
			font-size: 0.625rem;
			font-weight: 600;
			color: #fff;
		}

		.confirm {
			font-size: 0.75rem;
			font-weight: 400;

			&.waiting {
				background: #e8e8e8;
				color: #999;
			}

			&.done {
				background: #e0eef9;
				color: #195ea9;
			}
		}
	}
}

//-------------------------------------
//fullcalendar custom
.p__schedule {
	// focus 디자인 없애기
	.fc .fc-button-primary:focus {
		box-shadow: unset !important;
	}

	// 이전 이후 버튼
	.fc-prev-button,
	.fc-next-button {
		width: 35px;
		height: 35px;
		background: #fff;
		border: 1px solid $primary-color;
		border-radius: 2px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s;

		span {
			color: $primary-color;
			transition: 0.3s;
		}

		&:hover {
			border-color: transparent;
			background: $primary-color !important;

			span {
				color: #fff !important;
			}
		}
	}

	.fc-next-button {
		margin-left: 4px !important;
	}

	// 오늘버튼
	.fc-Today-button,
	.fc-Today-button:disabled {
		width: 70px;
		height: 35px;
		border-radius: 2px;
		background: #fff;

		&:not(:disabled):hover {
			color: #fff !important;
			border-color: transparent;
			background: #A8A8A8 !important;
		}
	}

	.fc-Today-button {
		color: #A8A8A8;
		border: 1px solid #A8A8A8;
	}

	.fc-Today-button:disabled {
		color: $border-color;
		border: 1px solid $border-color;
		opacity: 1;
	}

	.fc-filter-button,
	.fc-search-button {
		width: 35px;
		height: 35px;
		border: 1px solid $primary-color;
		border-radius: 4px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;

		&:hover {
			border-color: transparent !important;
		}
	}

	//필터버튼
	.fc-filter-button {
		background: $primary-color;

		&:hover {
			background: #0078cb !important;
		}
	}

	//검색버튼
	.fc-search-button {
		background: #fff;
		margin-left: 4px !important;

		svg {
			fill: $primary-color;
			transition: 0.3s;
		}

		&:hover {
			background: $primary-color !important;

			svg {
				fill: #fff !important;
			}
		}
	}

	// 일요일
	.fc-day-sun .fc-daygrid-day-number,
	.fc-day-sun .fc-col-header-cell-cushion {
		color: #f92525;
	}
}
