@charset "UTF-8";
@import "src/assets/sass/utils/var";

$correctColor: #369303;

//팀원 정보 모달 > 기본정보 탭 > 인사정보 수정

//----공통
.teamInfo-hr.edit {
	@import "basicInfoCommon";

	.f__group:not(:last-of-type):not(.has-error) {
		margin-bottom: 8px;
		padding-bottom: 0;
	}

	.c__radio-wrap {
		justify-content: space-between;
		margin-right: 10%;
		
		.c__radio {
			margin-right: 0;
		}
	}

	.won-currency {
		.f__control {
			position: relative;
			
			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				left: 0;
				width: 24px;
				height: 41px;

				background: {
					image: url("../../../../../../img/won.png");
					position: right;
					size: 0.875rem;
					repeat: no-repeat;
				}
			}

			.c__input {
				padding-left: 28px;
			}
		}
	}
}

//---- 근무 종료일
.teamInfo-hr.edit .work-end-wrap .checkbox {
	margin-top: 10px;

	.checkbox-label {
		font-size: 0.875rem;
	}

	.checkmark {
		width: 18px;
		height: 18px;
		border-radius: 6px;
		margin-right: 8px;

		&::after {
			left: 4px;
		}
	}
}

//----급여
.teamInfo-hr.edit .unit {
	position: absolute;
	right: 11px;
}

.teamInfo-hr.edit .working-status {
	> div {
		flex: 1;
	}

	.c__radio-wrap {
		height: 35px;
		margin-bottom: 6px;
	}
}
