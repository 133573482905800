@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * IR - 전체 공통 스타일
*/

.p__ir-detail {
	@include headerWithTab;

	/*---섹션 제목---*/
	.section-title {
		color: #333;
		font-size: 1.625rem;
		font-weight: 600;
		line-height: 1.5;
		letter-spacing: -0.05em;
	}
}

.ir-view,
.ir-edit {
	height: 100%;
	display: flex;
	flex-direction: column;

	/*---두 문단으로 나누기---*/
	.sub-section {
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: 1024px) {
			flex-direction: column;
		}

		&:not(:first-child) .f__label {
			border-bottom: 1px solid #ccc;
		}

		> .f__group {
			width: calc(50% - 15px);
			margin-bottom: 27px;

			&:first-child {
				margin-right: 15px;
			}

			&:last-child {
				margin-left: 15px;
			}

			@media screen and (max-width: 1024px) {
				width: 100%;

				&:first-child {
					margin-right: 0;
				}

				&:last-child {
					margin-left: 0;
				}
			}
		}

		.f__label {
			color: #333;
			font-weight: 500;
			line-height: 1.5;
			letter-spacing: -0.025em;
			padding: 0 0 11px !important;
		}

		.c__textarea {
			height: 183px;
			padding: 16px 33px 20px 20px;
		}
	}

	// sub-section
	.sub-section-wrap {
		align-items: flex-start;
		padding-bottom: 5px;

		> *:not(:last-child) {
			margin-right: 5px;
		}
	}

	/*---dashed 버튼---*/
	.dashed-btn {
		width: fit-content;
		min-width: 162px;
		padding: 0 10px;
		border-color: #c1c1c1;
		color: #999;
		margin-top: 10px;
		white-space: nowrap;

		svg {
			fill: #999;
		}

		&:hover {
			color: $primary-color !important;
			border-color: $primary-color !important;

			svg {
				fill: $primary-color !important;
			}
		}
	}

	/*---라운드 삭제 버튼---*/
	.btn-round-delete {
		height: 35px;

		svg {
			fill: #999;
			transition: 0.3s;

			&:hover {
				fill: $primary-color;
			}
		}
	}

	/*---단위: 천원---*/
	.unit-wrap {
		display: flex;
		justify-content: space-between;
		position: relative;

		.dashed-btn {
			margin-bottom: 5px;
		}

		// 단위만 표시되었을 때
		.unit {
			color: #999;
			font-size: 0.8125rem;
			font-weight: 300;
			line-height: 1.43;
			letter-spacing: -0.025em;
			right: 0;
			margin-bottom: -5px;
			align-self: flex-end;
		}

		// dashed-btn과 단위가 한 라인에 있을 때
		.inline-unit {
			color: #999;
			align-self: flex-end;
			margin: 0 32px 5px 0;
		}
	}

	/*---푸터 이동버튼---*/
	.tab-footer {
		width: 100%;
		padding-top: 30px;
		margin-top: auto;
		display: flex;
		align-items: center;

		// 버튼 공통
		.prev-btn-group,
		.next-btn-group {
			color: #999;
			font-weight: 300;
			letter-spacing: -0.05em;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:not(:disabled):hover {
				color: $primary-color;

				.prev-btn,
				.next-btn {
					border-color: $primary-color;
				}

				svg {
					fill: $primary-color;
				}
			}
		}

		.prev-btn,
		.next-btn {
			border-color: #b0b0b0;

			svg {
				fill: #b0b0b0;
			}
		}

		// 이전버튼
		.prev-btn-group {
			margin-right: auto;

			.prev-btn {
				margin-right: 10px;
			}
		}

		// 다음 버튼
		.next-btn-group {
			margin-left: auto;

			.next-btn {
				margin-left: 10px;

				svg {
					transform: rotate(180deg);
				}
			}
		}
	}
}

.ir-view .sub-section .f__control {
	display: flex;
}

//FixME 임시스타일
.dynamicFields {
	display: flex;
	flex-direction: column;
	padding-bottom: 5px;
	border-bottom: 1px solid $border-color;

	&:not(:last-of-type) {
		margin-bottom: 30px;
	}

	.f__label {
		color: #333;
		font-weight: 500;
		letter-spacing: -0.025em;
		padding-bottom: 11px;
		border-bottom: 1px solid $border-color;
	}

	.f__control {
		flex-direction: column;

		.c__form {
			width: 100%;
			display: flex;
		}
	}
}
