@charset "UTF-8";
@import "../../../sass/utils/var";
@import "../../../sass/utils/mixin";

/***
  * VC정보 > 정보 리스트 보기 페이지
*/

.p__vc-info .c__noData {
	margin-top: 30px;
	height: 100%;
}
/*---상단 검색 카드---*/
.p__vc-info .search-card {
	padding: 30px;
	margin-bottom: 30px;

	// 투자사, 투자기업 전체 수
	.info-title {
		color: $primary-color;
		font-size: 1.5rem;
		font-weight: 300;
		letter-spacing: -0.06em;
		padding-bottom: 16px;

		b {
			font-size: inherit;
			font-weight: 700;
		}

		.divider {
			width: 1px;
			height: 24px;
			margin: 0 15px 0 20px;
			background: rgba(0, 164, 255, 0.5);
			vertical-align: middle;
			display: inline-block;
		}
	}

	//검색창
	.search-from {
		width: 100%;

		.row {
			display: flex;
		}

		.btn-search {
			width: 72px;
			min-width: 72px;
			border-radius: 4px;
			margin-left: 10px;
		}
	}

	.vc-partner-select {
		margin-bottom: 10px;
		min-height: 40px;

		.search-wrapper {
			min-height: 40px;
		}
	}

	.vc-investor-select {
		max-width: 275px;
		margin-right: 10px;
		height: 40px;
	}

	.search-input {
		height: 40px;
		flex: 1;
	}

	// 자동완성
	/*.auto-complete-wrap {
		width: 100%;
		position: relative;

		.dropdown-menu {
			width: 100%;
			position: absolute;
			max-height: 250px;
			overflow: auto;
			list-style: none;
			outline: none;
			border: 1px solid $border-color;
			margin: 1px;
			padding: 0;
			color: rgba(0, 0, 0, 0.65);
			line-height: 1.5;
			background-color: #fff;
			border-radius: 4px;
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		}

		.dropdown-menu-item {
			color: #666;
			display: flex;
			align-items: center;
			padding: 9px 8px;
			font-weight: 300;
			line-height: 22px;
			white-space: nowrap;
			text-overflow: ellipsis;
			cursor: pointer;
			overflow: hidden;
			position: relative;
			transition: 0.3s;

			&:hover,
			&.active {
				background-color: rgba(0, 164, 255, 0.15);
			}

			span {
				width: 50%;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				padding: 0 8px;
			}

			.search-company-name {
				min-width: 120px;
			}
		}
	}*/
}

/*---하단 카드 리스트---*/
.p__vc-info {
	//----- 초기화면
	.noData {
		width: 100%;
		height: 292px;
		color: #666;
		font-size: 1.5rem;
		font-weight: 300;
		line-height: 1.25;
		letter-spacing: -0.05em;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		margin: 0 15px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	//----- 리스트 카드
	.vc-info-list .c__card {
		max-width: calc(100% / 5 - 20px);
		height: 240px;
		padding: 20px;

		@media screen and (max-width: 2560px) {
			max-width: calc(100% / 3 - 20px);
		}

		@media screen and (max-width: 1920px) {
			width: calc(100% / 3 - 20px);
			max-width: calc(100% / 3 - 20px);
			padding: 16px 20px;
		}

		@media screen and (max-width: 1320px) {
			width: calc(100% / 2 - 20px);
			max-width: calc(100% / 2 - 20px);
			padding: 16px 20px;
		}

		@media screen and (max-width: 1024px) {
			min-height: 153px;
			height: auto;
		}

		.btn-auth {
			width: 100%;
		}
	}

	//----- 카드 내부 상단
	.card-top {
		display: flex;
		text-align: left;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid $border-color;

		@media screen and (max-width: 1024px) {
			padding-bottom: 16px;
			margin-bottom: 16px;
		}

		// 로고이미지
		.img-wrap {
			width: 125px;
			min-width: 125px;
			height: 125px;
			margin-right: 23px;
			border-radius: 15px;
			border: 1px solid #e8e8e8;
			overflow: hidden;
			cursor: pointer;

			@media screen and (max-width: 1024px) {
				width: 70px;
				min-width: 70px;
				height: 70px;
				margin-right: 12px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	// 기업정보
	.company-data {
		padding-top: 8px;
		overflow: hidden;

		.company-name {
			@include ellipsis;
			color: #333;
			font-size: 1.375rem;
			font-weight: 300;
			letter-spacing: -0.05em;
			line-height: 1.23;
			margin-bottom: 10px;
			cursor: pointer;

			@media screen and (max-width: 1024px) {
				font-size: calc(14px + (22 - 14) * ((100vw - 768px) / (1024 - 768)));
				margin-bottom: 4px;
			}
		}

		.company-info {
			color: #666;
			font-size: 1rem;
			font-weight: 300;
			line-height: 1.375;
			letter-spacing: -0.025em;

			@media screen and (max-width: 1024px) {
				font-size: calc(12px + (16 - 12) * ((100vw - 768px) / (1024 - 768)));
			}
		}

		.company-tag {
			display: flex;
			align-items: center;
			margin: 10px 0;

			@media screen and (max-width: 1024px) {
				margin: 4px 0;
			}

			svg {
				width: 15px;
				min-width: 15px;
				height: 14px;
				margin-right: 8px;
				fill: #ccc;

				@media screen and (max-width: 1024px) {
					width: 11px;
					min-width: 11px;
					height: 10px;
					margin-right: 6px;
				}
			}

			.tag-text {
				@include ellipsis;
				color: #999;
				font-weight: 300;
				line-height: 1.21;

				@media screen and (max-width: 1024px) {
					font-size: calc(9px + (14 - 9) * ((100vw - 768px) / (1024 - 768)));
				}
			}
		}

		.company-url {
			@include ellipsis;
			height: 16px;
			font-size: 0.75rem;
			font-weight: 300;
			line-height: 1.17;
			color: $primary-color;
			cursor: pointer;
			transition: 0.2s;

			@media screen and (max-width: 1024px) {
				font-size: calc(9px + (12 - 9) * ((100vw - 768px) / (1024 - 768)));
			}

			&:hover {
				color: #0086d9;
			}
		}
	}

	//----- 카드 내부 하단
	.card-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		// 포트폴리오 개수
		.company-portfolio {
			color: #666;
			font-weight: 300;
			line-height: 1.25;
			letter-spacing: -0.025em;
			display: flex;
			align-items: center;

			@media screen and (max-width: 1024px) {
				font-size: calc(10px + (14 - 10) * ((100vw - 768px) / (1024 - 768)));
			}

			svg {
				width: 24px;
				height: 24px;
				fill: $primary-color;
				margin-right: 6px;

				@media screen and (max-width: 1024px) {
					width: 12px;
					height: 12px;
				}
			}

			b {
				color: $primary-color;
				font-weight: 300;
				font-family: "Roboto", sans-serif;
				margin-left: 4px;

				@media screen and (max-width: 1024px) {
					font-size: calc(10px + (14 - 10) * ((100vw - 768px) / (1024 - 768)));
				}
			}
		}

		// 기업로고 이미지
		.logo-group {
			display: flex;
			align-items: center;
			position: absolute;
			top: -10px;
			right: 0;

			@media screen and (max-width: 1024px) {
				top: -5px;
			}

			li {
				border-radius: 50%;
				box-shadow: 0 5px 13px 0 rgba(35, 31, 32, 0.08);

				&:not(:last-child) {
					margin-left: -10px;
				}
			}

			.img-wrap {
				width: 40px;
				min-width: 40px;
				height: 40px;
				overflow: hidden;
				flex-shrink: 0;

				@media screen and (max-width: 1024px) {
					width: 25px;
					min-width: 25px;
					height: 25px;
				}

				&:not(:first-child) {
					margin-left: -10px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.company-plus-btn {
				width: 24px;
				min-width: 24px;
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $primary-color;
				cursor: pointer;

				svg {
					font-size: 0.75rem;
					fill: #fff;
				}
			}
		}
	}

	// 드롭다운 메뉴
	.c__dropdown {
		min-width: 190px;
		align-items: unset;
		padding-bottom: 5px;
		top: 40px;
		right: 0;
		margin: 0 4px 5px 12px;
		padding-top: 2px;
		overflow-y: auto;

		.detail-list {
			@include customScroll(scroll-y);
			max-height: 143px;
			margin: 0 4px 5px 12px;
			padding-top: 2px;
			overflow-y: auto;
		}
	}

	// 드롭다운 메뉴 제목
	.c__dropdown .dropdown-title {
		width: 100%;
		height: 39px;
		color: #999;
		font-weight: 300;
		line-height: 1.25;
		letter-spacing: -0.05em;
		border-bottom: 1px solid #e8e8e8;
		padding: 0 14px;
		margin-bottom: 6px;
		display: flex;
		align-items: center;

		.num {
			color: #333;
			font-weight: 300;
			line-height: 1.25;
			letter-spacing: 0;
			margin-left: auto;
		}
	}

	// 드롭다운 리스트 항목
	.c__dropdown .c__dropdownItem {
		height: 39px;
		padding: 0;
		margin-bottom: 6px;
		display: flex;
		align-items: center;

		&:not(:last-child) {
			border-bottom: none;
		}

		&:not(.disabled):hover {
			cursor: unset;
			background: none;
		}

		.img-wrap {
			width: 39px;
			height: 39px;
			margin-right: 10px;
			border-radius: 50%;
			border: 1px solid #e8e8e8;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		dd {
			color: #333;
			font-size: 0.75rem;
			font-weight: 300;
		}
	}
}
