@charset "UTF-8";
@import "../../../../sass/utils/var";

/***
  * 마이페이지 > 알람 > 전체알람
*/
.p__alarm-list {
	.c__card {
		padding: 36px 40px 40px 40px;

		@media screen and (max-width: 1200px) {
			padding: calc(30px + (40 - 30) * ((100vw - 768px) / (1200 - 768)));
		}
	}

	/*---전체알람 리스트 레이아웃---*/
	.alarm-thead,
	.alarm-tr {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e8e8e8;

		> div {
			color: #333;
			line-height: 1.5;
			letter-spacing: -0.05em;
		}
	}

	.alarm-thead {
		padding-bottom: 17px;
		text-align: center;

		> div {
			font-size: 1.125rem;

			@media screen and (max-width: 1200px) {
				font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1200 - 768)));
			}
		}
	}

	.alarm-tr {
		padding: 11px 0;

		> div {
			font-size: 1rem;
			font-weight: 300;

			@media screen and (max-width: 1200px) {
				font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
			}
		}
	}

	/*---전체알람 리스트 목록---*/
	.alarm-number {
		width: 4.864532019704433%; /*79/1624*/
		min-width: 57px;
		text-align: center;
		margin-right: 22px;
	}

	.alarm-type {
		width: 7.635467980295567%; /*124/1624*/
		min-width: 145px;
		text-align: center;
		padding: 0 20px;

		span {
			font-weight: 300;
			font-size: 1rem;
			line-height: 1.5;
			letter-spacing: -0.05em;

			@media screen and (max-width: 1200px) {
				font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
			}
		}

		.online-exam {
			color: $primary-color;
		}

		.portfolio-link {
			color: #ffba00;
		}
	}

	.alarm-content {
		flex: 1;

		.alarm-link {
			color: #333;
			font-size: 1rem;
			font-weight: 300;
			transition: 0.3s;
			&:hover {
				color: #0D9488;
			}
		}
	}

	.alarm-table .alarm-date {
		width: 11.51477832512315%; /*187/1624*/
		min-width: 155px;
		text-align: center;
	}

	.alarm-status {
		width: 85px;
		display: flex;
		justify-content: center;

		.custom-badge {
			width: fit-content;
			min-width: 65px;
			height: 30px;
			padding: 0 8px;
			color: #fff;
			font-size: 1rem;
			font-weight: 300;
			line-height: 30px;
			letter-spacing: -0.05em;
			border-radius: 50px;
			background-color: $primary-color;
			text-align: center;
			cursor: pointer;

			@media screen and (max-width: 1200px) {
				font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
			}
		}

		// 읽음
		.alarm-read {
			background-color: #d7d7d7;
			cursor: default;
		}
	}
}

.p__alarm-list .no-content {
	width: 100%;
	height: 110px;
	line-height: 1.5;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.125rem;
	font-weight: 300;
	letter-spacing: -0.05em;
	color: #333;
}
