@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * dropdown
*/

.c__dropdown-wrap {
	position: absolute;
	height: 100%;
	top: 0;
	right: 0;
}

.center {
	display: flex;
}

.c__dropdown {
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: max-content;
	border-radius: 6px;
	border: 1px solid $border-color;
	background-color: #fff;
	position: absolute;
	top: calc(100% + 5px);
	display: none;
	overflow: hidden;
	transition: 0.3s;
	z-index: 1250;

	&.visible {
		display: flex;
		opacity: 1;
		animation-name: fadeIn;
		animation-duration: 0.3s;
	}

	&.right {
		right: 4px;
	}
}

.btn-dropdown {
	display: flex;
	transition: 0.3s ease-in-out;

	> span {
		display: inline-block;
		width: 3px;
		height: 3px;
		border-radius: 50%;
		background: #cecece;
	}

	&:hover > span,
	&.visible > span {
		background: #595959;
	}

	//세로
	&.vertical {
		padding: 2px 8px;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> span:not(:last-child) {
			margin-bottom: 2px;
		}
	}

	//가로
	&.horiz {
		padding: 5px 10px;
		> span {
			margin: 0 2px 1px;
		}
	}
}

.c__dropdownItem {
	width: 100%;
	height: 30px;
	line-height: 1;
	padding: 8px 15px 8px 17px;
	font-size: 0.875rem;
	font-weight: 300;
	letter-spacing: -0.05em;
	color: #bbb;
	text-align: center;
	transition: 0.3s ease-in-out;

	&:not(:last-child) {
		border-bottom: 1px solid $border-color;
	}

	&:not(.disabled):hover {
		cursor: pointer;
		background-color: #f8f8f8;
		color: #333;
	}

	&.disabled {
		opacity: 0.3;
		cursor: not-allowed;
	}
}
