@charset "UTF-8";
@import "../../../../sass/utils/var";
@import "../../../../sass/utils/mixin";

/***
  * 팀원 > 휴가관리
*/

/*---전체 카드 공통---*/
.p__vacation-management .hr-manage-cardRow {
	display: flex;
	align-items: flex-start;

	.c__card {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		overflow: hidden;
	}

	.member-list-card {
		max-width: 360px;
		min-width: 360px;
		min-height: 734px;
		margin-right: 30px;
	}

	.profile {
		width: 36px;
		height: 36px;
		margin-right: 8px;
		border-radius: 50%;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;
		}
	}
}

/*---좌측 카드---*/
.p__vacation-management .member-list-card .top {
	padding: 20px 18px 16px 20px;
	border-bottom: 1px solid $border-color;

	.wrap {
		display: flex;
		align-items: center;
	}

	.c__select {
		width: 109px;
		margin-right: 4px;
		border-radius: 8px;
		font-size: 0.875rem;
	}

	.c__search {
		width: 209px;

		.c__input {
			border-radius: 8px;
			padding-right: 30px;
		}

		.btn-search {
			width: 30px;
		}
	}

	.keyword-list {
		@include keywordList;
		display: flex;
		flex-wrap: wrap;
		gap: 8px;

		.keyword {
			line-height: inherit;
			border-radius: 8px;
			height: 28px;
			margin: 0;
			font-size: 0.875rem;
			background-color: white;
			border: 1px solid #E5E7EB;

			&:not(:last-child) {
				margin: 0;
			}

			&.active {
				background-color: #CCFBF1;
				border-color: #0D9488;
				color: #042F2E;
			}
		}
	}
}

.p__vacation-management .member-list-card .member-list {
	min-height: 545px;
	padding: 12px 0;

	.row {
		height: 52px;
		padding: 8px 20px;
		display: flex;
		align-items: center;
		cursor: pointer;

		&.active {
			border-left: 3px solid $primary-color;
			padding-left: 17px;
		}

		&.active,
		&:hover {
			background: #f3f9fd;
		}

		.name {
			color: #333;
			font-size: 16px;
			max-width: 140px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;


			.standby {
				color: $primary-color;
				font-size: 14px;
			}

			.withdraw {
				color: #f44;
				font-size: 14px;
			}
		}

		.email,
		.position {
			color: #999;
			font-size: 12px;
		}

		.position {
			max-width: 70px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 8px;

		.info {
			width: 100%;
		}

		.info-wrap {
			display: flex;
			align-items: center;
			margin-bottom: 4px;
			width: 100%;
			gap: 4px;
		}

		.working-status span {
			width: fit-content;
			font-size: 12px;
			border-radius: 4px;
			padding: 3px 5px;
			display: inline-block;
			white-space: nowrap;

			&.working {
				background: #eafcf1;
				color: #148d45;
			}

			&.absence {
				background: #f0f0f0;
				color: #333;
			}

			&.quit {
				background: #ffdbdb;
				color: #f44;
			}
		}
	}
}

.p__vacation-management .member-list-card .tab-footer {
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	background: #fff;
	border-bottom-right-radius: 13px;
	border-bottom-left-radius: 13px;
	border-top: 1px solid $border-color;

	.prev-btn,
	.next-btn {
		display: inline-flex;
		align-items: center;

		> span {
			color: #999;
			margin: 0 14px;
			transition: color 0.3s ease-in-out;
		}

		&:not(:disabled):hover {
			> span {
				color: $primary-color;
			}

			.round-arrow-icon {
				border-color: $primary-color;
				fill: $primary-color;
			}
		}

		&:disabled {
			opacity: 1;
			> span {
				color: #d7d7d7;
			}
			.round-arrow-icon {
				fill: #d7d7d7;
				border-color: $border-color;
			}
		}
	}

	.round-arrow-icon {
		width: 38px;
		height: 38px;
		fill: #999;
		border: 1px solid #999;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		transition: 0.3s ease-in-out;
	}

	.next-btn .round-arrow-icon {
		transform: rotate(180deg);
	}
}

@media screen and (max-width: 1320px) {
	.p__vacation-management .hr-manage-cardRow {
		flex-direction: column;

		.member-list-card {
			height: 370px;
			width: 100%;
			max-width: initial;
			min-width: initial;
			margin-bottom: 30px;

			.member-list {
				@include customScroll(scroll-y);
				max-height: 270px;
			}
		}

		.search-area {
			width: 100% !important;
		}
	}
}
