@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * Empty
*/

//최초 화면
.page-init {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.main {
		font-size: 1.5rem;
		letter-spacing: -1.2px;
		color: #333;
		margin-bottom: 20px;
		text-align: center;
	}

	.sub {
		font-weight: 300;
		letter-spacing: -0.35px;
		color: #666;
		margin-bottom: 30px;
	}

	.btn__solid {
		height: 50px;
		padding: 0 35px;
		margin-bottom: 40px;
		font-size: 1rem;
	}

	img {
		margin-right: 80px;
	}
}
