@charset "UTF-8";
@import "src/assets/sass/utils/var";

//팀원 정보 모달 > 구성원 삭제 탭
.teamInfo-delete.edit .phrase {
	font-weight: 350;
	line-height: 16px;
	letter-spacing: -0.05em;
	margin: 36px 0 16px;
}

.teamInfo-delete.edit .desc-wrap {
	min-height: 146px;
	background: rgba(255, 68, 68, 0.1);
	padding: 24px 22px;
	border-radius: 8px;

	p {
		color: #f44;
		font-size: 0.75rem;
		font-weight: 300;
		line-height: 22px;
		letter-spacing: -0.06em;
		padding-left: 12px;
		text-indent: -12px;

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
}
