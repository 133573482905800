@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 팀원 > 팀원정보 > list
*/

.p__hr-team {
	overflow-x: hidden;

	.l__pageHeader {
		margin-bottom: 12px;
	}
}

.p__teamInfoList {
	width: 100%;
	display: flex;

	/*---양쪽 카드 공통---*/
	.c__card-fit {
		padding: 0;

		.card-top {
			height: 60px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #e3e4e5;
		}
	}

	// 카드 내부 스크롤 생성
	.org-list,
	.filter-info {
		overflow-y: auto;

		@include customScroll(scroll-y);

		&::-webkit-scrollbar-track {
			background: none;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #ccc;
		}
	}

	.org-list {
		height: calc(100vh - 100px - 70px - 41px - 40px - 60px - 68px);
	}

	.filter-info {
		padding-bottom: 20px;
		height: calc(100vh - 100px - 70px - 41px - 40px - 60px - 68px);
	}

	.filter-info-body {
		padding: 0 20px;
	}

	/*---왼쪽 카드---*/
	.org-card {
		flex-shrink: 0;
		width: 360px;
		margin-right: 20px;
		position: relative;
		overflow: visible;

		//초기화면
		.c__noData {
			height: calc(100% - 60px);
			font-size: 1rem;

			svg {
				width: 60px;
				height: 60px;
			}
		}

		// 상단
		.card-top {
			padding: 23px 20px 18px;

			.company {
				color: #0074B5;
				font-size: 1rem;
				font-weight: 500;
				line-height: 1.63;
			}

			.btn__setting {
				height: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;
				color: $primary-color;

				svg {
					width: auto;
					height: 12px;
					fill: $primary-color;
				}

				span {
					font-size: 0.875rem;
				}
			}
			//.btn__setting > svg {
			//	width: 20px;
			//	height: 20px;
			//	fill: #c1c1c1;
			//}
		}
	}

	.rst__tree {
		padding-left: 20px;
	}

	.scroll-wrap {
		@include customScroll(scroll-y);
		height: calc(100vh - 390px);
	}

	.org-wrap {
		padding: 16px;
	}

	.org-overall-bar {
		color: #595959;
		border: 1px solid #e3e4e5;
		height: 50px;
		display: flex;
		padding: 10px 12px 10px 16px;
		position: relative;
		align-items: center;
		border-radius: 4px;
		justify-content: space-between;
		background-color: #f8f8f8;

		&:hover {
			cursor: pointer;
		}

		&.selected {
			border: 2px solid #0d9488;
			background-color: #f0fdfa;

			.org-name {
				color: #042f2e;
			}

			.org-number {
				color: #042f2e;
				background: #ccfbf1;
			}
		}

		.org-name {
			color: #878296;
			font-size: 0.9375rem;
			font-weight: 600;
		}

		.org-number {
			background-color: #E5E7EB;
			border-radius: 4px;
			color: #6B7280;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 600;
			min-width: 26px;
			padding: 2px 6px;
		}
	}

	// 하단 조직 리스트
	.org-list {
		padding: 20px;
	}

	/*---오른쪽 카드---*/
	.filter-card {
		max-width: 1070px;

		// 상단
		.card-top {
			padding: 12px 20px;
			gap: 16px;
			height: auto;
			border: none;

			.search-area {
				display: flex;
				flex: 1;
				background-color: #f9fafb;
				border: 1px solid #d1d5db;
				border-radius: 8px;
				overflow: hidden;

				// 검색버튼
				.btn-search {
					width: 38px;
					height: 38px;
					margin-right: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #f8f8f8;
					border-radius: 50px;

					svg {
						fill: #878296;
					}
				}

				input {
					width: 100%;
					border: 0;
					font-size: 0.875rem;
					outline: none;
					background: inherit;

					&::placeholder {
						color: #878296;
					}
				}
			}

			// 근무상태 Select
			.status-option {
				width: 140px;
				border-radius: 8px;
				height: 40px;
				
				.c__selection-item span, .c__select-dropdown-item{
					font-size: 0.875rem;
				}

				.ant-select-selection--single {
					border: 0;
				}

				.ant-select-open .ant-select-selection,
				.ant-select-focused .ant-select-selection,
				.ant-select-selection:focus,
				.ant-select-selection:active {
					box-shadow: none;
				}

				.ant-select-selection__placeholder {
					color: #878296;
				}
			}
		}

		//검색결과 없을때
		.c__noData {
			padding-top: 180px;

			> svg {
				width: 100px;
				height: 100px;
				fill: #999;
				margin-bottom: 40px;
			}

			p:first-of-type {
				font-size: 1.25rem;
				font-weight: normal;
				line-height: 1;
				letter-spacing: -1px;
				color: #666;
				margin-bottom: 15px;
			}

			p:last-of-type {
				font-size: 0.875rem;
				font-weight: 300;
				letter-spacing: -0.35px;
				color: #666;
				line-height: 1;
			}
		}

		.filter-info-header {
			padding: 0 20px;

			.profile-row {
				background-color: #f3f4f6;
				border-radius: 8px 8px 0 0;
				border-top: 1px;
				border-color: #E5E7EB;
				border-style: solid;
				height: 42px;

				&> div {
					color: #374151;
					font-weight: 500;
					font-size: 0.75rem;
				}
			}

		}
	}

	// 팀원 정보 리스트
	.profile-row {
		width: 100%;
		height: 56px;
		color: #878296;
		font-size: 0.875rem;
		border-width: 0 1px 1px;
		border-color: #E5E7EB;
		border-style: solid;
		display: flex;
		align-items: center;
		cursor: pointer;
		position: relative;
		padding: 0 4px;

		&:hover:not(:disabled) {
			background-color: #f8f8f8;
		}

		&:last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		> * {
			flex-shrink: 0;
		}

		.profile-img {
			width: 60px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;

			.img-wrap {
				width: 35px;
				height: 35px;
				position: relative;

				.c__tooltip-btn {
					position: absolute;
					top: 0;
					right: -4px;

					.c__tooltip {
						top: 23px;
						left: 140px;
						z-index: 100;

						&:after {
							top: -10px;
							left: 16px;
							border-color: transparent transparent rgba(255, 68, 68, 0.1) transparent;
						}

						&:before {
							top: -9px;
							left: 16px;
							border-color: transparent transparent #ffecec transparent;
						}
					}
				}

				img {
					width: 100%;
					height: 100%;
					-webkit-border-radius: 50px;
					-moz-border-radius: 50px;
					border-radius: 50px;
					object-fit: cover;
				}
			}
		}

		> div {
			font-size: inherit;
			padding: 0 10px;
		}

		.profile-name {
			min-width: 178px;
			position: relative;
			flex: 1;

			> p {
				align-items: center;

				> span {
					width: fit-content;
					display: inline-block;
					font-size: 1rem;
					font-weight: 500;
					line-height: 1.3;
				}
			}

			.name {
				@include ellipsis;
				max-width: 140px;
				width: auto;
				margin-right: 6px;
				font-size: 0.875rem;
				flex: 1;
				margin-bottom: 2px;
			}

			.role {
				font-size: 0.75rem;
				font-weight: 300;

				&.admin {
					color: #0D9488;
				}

				&.manager {
					color: #D48806;
				}
			}

			.standby {
				color: $primary-color;
				letter-spacing: -0.05em;
			}

			.withdraw {
				color: $error-color;
				letter-spacing: -0.05em;
			}

			.birth-img-wrap {
				position: absolute;
				right: 5px;
				top: 50%;
				transform: translateY(-50%);
				width: 18px;
				height: 19px;
				flex-shrink: 0;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.profile-team {
			@include ellipsis;
			max-width: 124px;
			min-width: 100px;
			flex: 1;
			// width: calc((162 / 1070) * 100%);
		}

		.profile-position {
			@include ellipsis;
			// width: calc((150 / 1070) * 100%);
			max-width: 124px;
			min-width: 80px;	
			flex: 1		
		}

		.profile-role {
			@include ellipsis;
			// width: calc((150 / 1070) * 100%);
			width: 90px;
		}

		.profile-joinDate {
			width: 108px;
			font-weight: 300;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.profile-email {
			@include ellipsis;
			flex: 1;
			min-width: 100px;
			font-weight: 300;

			// &:before {
			// 	content: "";
			// 	height: 11px;
			// 	width: calc(20px + (29 - 20) * ((100vw - 1200px) / (1920 - 1200)));
			// 	max-width: 29px;
			// 	min-width: 20px;
			// 	display: inline-block;
			// 	background: {
			// 		image: url("../../../../assets/img/ico_mail.png");
			// 		repeat: no-repeat;
			// 	}
			// }
		}

		.profile-status {
			width: fit-content;
			min-width: 47px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		.team-active {
			width: 100px;
			padding-right: 24px;

			.c__dropdown-wrap {
				top: 1px;
			}

			.status-text {
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: inline-block;
				font-size: 0.75rem;
				font-weight: 300;
				width: fit-content;
				padding: 0 4px;
				border-radius: 4px;
			}

			.btn-dropdown {
				margin: 0;
			}
		}

		.status-box {
			width: fit-content;
			max-width: 100%;
			min-width: 37px;
			height: 21px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		// 근무상태 표시
		.waiting {
			color: #0D9488;
			background-color: #F0F9FF;
		}
		.on {
			color: #22C55E;
			background-color: #F0FDF4;
		}
		.leave {
			color: black;
			background-color: #F8F8F8;
		}
		.resign {
			color: #ff5656;
			background-color: #ffdbdb;
		}

		.birthday {
			color: #ff63a1;
			background-color: #ffe5ef;
		}
	}

	//  퇴직자 표시
	.resign-row > .profile-name,
	.resign-row > .profile-joinDate,
	.resign-row > .profile-team,
	.resign-row > .profile-position,
	.resign-row > .profile-email {
		text-decoration: line-through;
	}
}

//반응형
.p__teamInfoList {
	@media screen and (max-width: 1700px) {
		.profile-row .profile-joinDate {
			display: none;
		}
	}

	@media screen and (max-width: 1550px) {
		.profile-row .profile-name {
			@include ellipsis;
			width: auto;
			min-width: 140px;

			> p {
				padding-right: 5px;
				flex-direction: column;
				align-items: flex-start;

				.name {
					font-size: 0.875rem;
				}

				.standby {
					font-size: 0.75rem;
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		flex-direction: column;

		.org-card {
			height: 300px;
			width: 100%;
			margin-bottom: 30px;

			.scroll-wrap {
				height: 225px;
			}
		}

		.filter-card {
			max-width: 100%;
			height: calc(100vh - 100px - 70px - 40px - 27px - 52px - 30px - 300px - 30px);
		}

		.filter-info {
			height: calc(100% - 60px);
		}
	}
}
