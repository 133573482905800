@charset "UTF-8";

/***
  * 전체 index 파일
  * sass/index.
  scss
*/

//base
@import "base/base";
@import "base/typography";
@import "../fonts/simple-line-icons/css/simple-line-icons.css";

//utils
@import "utils/var";
@import "utils/mixin";

//공통 컴포넌트 스타일
@import "component/card";
@import "component/button";
@import "component/breadcrumb";
@import "component/dropdown-bak";
@import "component/switch";
@import "component/noData";
@import "component/tooltip";

@import "component/input";
@import "component/form";
@import "component/radio";
@import "component/select";
@import "component/toggle";
@import "component/textarea";
@import "component/spinner";
@import "component/badge";
@import "component/round-checkbox";
@import "component/address";
@import "component/empty";
@import "component/table";
@import "component/checkbox";
@import "component/multi-select";
@import "component/pagination";
@import "component/tag";
@import "component/search";
@import "component/channel";
@import "component/quill";
@import "component/switchRadio";
@import "component/transLanguage";
@import "component/paymentBanner";

@import "src/assets/sass/component/error";
@import "component/history"; //임시

//@import "component/modal-bak"; //임시
//@import "component/modal"; //임시

//@import "component/modal";
//@import "component/noContent";
//@import "component/tabMenu";
//@import "component/table";
//@import "component/select";
//@import "component/dataEntry/checkbox";
//@import "component/tooltip";
//@import "component/dataEntry/radio";
//@import "component/upload";
//@import "component/noContent";

//vender-extenstion
//@import "./vender-extension/custom.slick";
//@import "./vender-extension/ant-table";
//@import "./vender-extension/ant-pagination";
@import "vendors-extensions/datepicker";

//레이아웃
@import "layout/layout";
@import "layout/topNav";
@import "layout/sideBar";
@import "layout/footer";
@import "layout/pageIntro";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @link https://utopia.fyi/type/calculator?c=320,18,1.2,1240,20,1.25,8,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
:root {
 /* Step -2: 12.5px → 12.8px */
 --step--2: clamp(0.7813rem, 0.7747rem + 0.0326vi, 0.8rem);
 /* Step -1: 15px → 16px */
 --step--1: clamp(0.9375rem, 0.9158rem + 0.1087vi, 1rem);
 /* Step 0: 18px → 20px */
 --step-0: clamp(1.125rem, 1.0815rem + 0.2174vi, 1.25rem);
 /* Step 1: 21.6px → 25px */
 --step-1: clamp(1.35rem, 1.2761rem + 0.3696vi, 1.5625rem);
 /* Step 2: 25.92px → 31.25px */
 --step-2: clamp(1.62rem, 1.5041rem + 0.5793vi, 1.9531rem);
 /* Step 3: 31.104px → 39.0625px */
 --step-3: clamp(1.944rem, 1.771rem + 0.8651vi, 2.4414rem);
 /* Step 4: 37.3248px → 48.8281px */
 --step-4: clamp(2.3328rem, 2.0827rem + 1.2504vi, 3.0518rem);
 /* Step 5: 44.7898px → 61.0352px */
 --step-5: clamp(2.7994rem, 2.4462rem + 1.7658vi, 3.8147rem);
 /* Step 6: 53.7477px → 76.2939px */
 --step-6: clamp(3.3592rem, 2.8691rem + 2.4507vi, 4.4684rem);
 /* Step 7: 64.4973px → 95.3674px */
 --step-7: clamp(4.0311rem, 3.36rem + 3.3555vi, 5.9605rem);
 /* Step 8: 77.3967px → 119.2093px */
 --step-8: clamp(4.8373rem, 3.9283rem + 4.5448vi, 7.4506rem);
}

@layer base {
 h1 {
   @apply text-4xl font-bold;
 }
 h2 {
   @apply text-2xl font-bold;
 }
 h3 {
   @apply text-xl font-bold;
 }
 h4 {
  @apply text-base font-bold;
}
}