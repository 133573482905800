@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

//팀원 정보 모달 > 경력정보 탭
.teamInfo-career.edit {
	.career-history {
		margin-top: 24px;
	}

	.title-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 21px;
	}

	.btn__add {
		height: 35px;
		padding: 0 11px;
		font-size: 0.75rem;
		letter-spacing: -0.025em;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;

		svg {
			fill: currentColor;
			width: 11px;
			height: 11px;
			margin-top: 2.5px;
		}
	}

	.career-input-group {
		width: 100%;
		margin-bottom: 10px;
		padding: 14px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		border-radius: 6px;
		background: #f1f4f6;
		position: relative;
	}

	.btn__close {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 15px;
		position: absolute;
		top: 0;
		right: 0;
		color: #9a9a9a;
		z-index: 9;

		&:not(:disabled):hover,
		&:not(:disabled):active {
			color: $primary-color;
		}

		svg {
			width: 8px;
			height: 8px;
			fill: currentColor;
		}
	}

	.row {
		display: flex;
		width: 100%;
	}

	.career-form {
		display: flex;
		flex-wrap: wrap;
	}

	.f__group:not(:last-child) {
		margin-bottom: 9px;
	}

	//회사이름 input
	.company-name {
		width: calc(100% - 230px);
		padding-right: 6px;
	}

	.datePicker-group {
		padding-right: 30px;
	}

	.textarea-wrap {
		width: 100%;
	}

	.c__textarea {
		@include customScroll(scroll-y);
		min-height: 65px;
		overflow-y: auto;
	}
}
