@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * 마이페이지 > 서명 관리 (개인서명/법인인감)
*/

//서명 카드
.p__management-signature .c__card,
.p__account-signature .c__card,
.p__admin-signature .c__card {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 248px;
	padding: 20px;
	position: relative;
	border: 1px solid transparent;
	transition: 0.3s ease-in-out;

	&:hover {
		border-color: $primary-color;
	}

	//활성화 상태
	&.active .img-wrap {
		opacity: 1;
	}

	.img-wrap {
		width: 100%;
		height: 155px;
		overflow: hidden;
		opacity: 0.25;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.date {
		position: absolute;
		left: 20px;
		bottom: 20px;
		font-size: 0.75rem;
		font-weight: 300;
		line-height: 1.42;
		letter-spacing: -0.025em;
		color: #ccc;
	}

	.c__dropdown-wrap {
		height: auto;
		top: 12px;
		right: 10px;

		.c__dropdown {
			right: 0;
		}

		.btn-dropdown {
			padding: 10px;
			margin-top: -2.5px;
		}
	}

	.c__badge {
		top: 15px;
		right: 35px;
	}
}
