@charset "UTF-8";
@import "src/assets/sass/utils/var";

.c__search {
	width: 220px;
	position: relative;

	.c__input {
		width: inherit;
		height: 35px;
		border-radius: 2px;
		padding-right: 40px;
	}

	.btn-search {
		width: 40px;
		height: 35px;
		position: absolute;
		top: 0;
		right: 0;

		svg {
			width: 18px;
			height: 18px;
			fill: #d7d7d7;
			transition: 0.3s;
		}

		&:hover svg {
			fill: $primary-color;
		}
	}

	// 둥근모양
	&.circle .c__input {
		border-radius: 17px;
	}
}
