@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * 커뮤니티 > 내가쓴글 > 내가속한 커뮤니티 모달
**/

.MyPost-modal {
	.c__noData {
		height: 100%;
	}

	.modal-list {
		@include customScroll(scroll-y);

		padding: 10px 16px 0 20px;
		background: #f8f8f8;
		height: 460px;

		.modal-item {
			width: 100%;
			height: 80px;
			background: #fff;
			border: 1px solid #e4e4e4;
			border-radius: 4px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 16px;
			margin-bottom: 8px;
		}
		.modal-title {
			display: flex;
			align-items: center;
		}
		.modal-img {
			width: 40px;
			height: 40px;
			border: 1px solid #d7d7d7;
			border-radius: 8px;
			margin-right: 12px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px;
			}
		}
		.title {
			@include ellipsis;

			width: auto;
			max-width: 500px;
			font-size: 1rem;
			line-height: 22px;
			letter-spacing: -0.06em;
			color: #333;
			margin-right: 10px;
		}
		.member {
			font-family: "Inter", sans-serif;
			display: flex;
			align-items: center;
			font-size: 0.75rem;
			color: rgba(0, 0, 0, 0.4);

			svg {
				margin-right: 2px;
			}
		}
		.badge-btn {
			svg {
				fill: #ccc;
			}
			&.active svg {
				fill: #0D9488;
			}
		}
	}
}
