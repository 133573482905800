@charset "UTF-8";
@import "../../../../sass/utils/var";
@import "../../../../sass/utils/mixin";

/***
  * 관리자 메뉴 > 권한설정
*/

.p__admin-auth .admin-auth-cardRow {
	display: flex;
	align-items: flex-start;
}

//-----List
.p__admin-auth {
	//----- view
	.role-member-card {
		width: 300px;
		height: 600px;
		margin-right: 30px;
	}

	// 검색
	.role-member-card .top {
		padding: 26px 20px 15px;

		// 검색칸
		.search-area {
			width: 260px;
			position: relative;
		}

		.c__input {
			height: 38px;
			border-radius: 6px;
		}

		.btn-search {
			width: 40px;
			height: 38px;
			position: absolute;
			right: 0;
			top: 0;

			&:hover svg {
				fill: $primary-color;
			}

			svg {
				width: 14px;
				height: 14px;
				fill: #ccc;
				transition: 0.3s;
			}
		}

		// 키워드
		.keyword-list {
			@include keywordList;
		}
	}

	//  멤버 리스트
	.role-member-card .role-member-list {
		height: 420px;
		border-top: 1px solid #e8e8e8;
		border-bottom: 1px solid #e8e8e8;

		.role-member {
			position: relative;
			height: 60px;
			cursor: pointer;
			border-left: 3px solid transparent;

			&:hover {
				background-color: #f3f9fd;
			}

			// 클릭되었을 때
			&.active {
				background-color: #f3f9fd;
				border-color: $primary-color;
			}
		}

		.role-member-content {
			height: 100%;
			display: flex;
			align-items: center;
		}

		.role-member-img {
			width: 40px;
			height: 40px;
			margin: 0 13px 0 20px;
			border-radius: 50%;
			flex-shrink: 0;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.role-member-info {
			> dt {
				display: inline-flex;
				align-items: center;
				margin-bottom: 7px;
			}

			.name {
				display: inline-block;
				color: #333;
				font-weight: 300;
				letter-spacing: -0.05em;
				margin-right: 5px;
				line-height: 1;
			}

			.role {
				display: inline-block;
				color: #999;
				font-size: 0.625rem;
				font-weight: 300;
				line-height: 1;

				// 담당별 색깔 구분
				&.admin {
					color: $primary-color;
				}
				&.manager {
					color: #369303;
				}
			}

			.email {
				display: block;
				color: #999;
				font-size: 0.625rem;
				font-weight: 300;
			}
		}

		.btn-dropdown {
			padding-right: 18px;
		}

		.c__dropdown {
			top: calc(100% - 20px);
			right: 20px;
		}
	}

	// 페이지네이션
	.tab-footer {
		width: 100%;
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;
		background: #fff;
		border-bottom-right-radius: 13px;
		border-bottom-left-radius: 13px;

		.prev-btn,
		.next-btn {
			display: inline-flex;
			align-items: center;

			> span {
				font-size: 12px;
				font-weight: 300;
				color: #999;
				margin: 0 14px;
				transition: color 0.3s ease-in-out;
			}

			&:not(:disabled):hover {
				> span {
					color: $primary-color;
				}

				.round-arrow-icon {
					border-color: $primary-color;
					fill: $primary-color;
				}
			}

			&:disabled {
				opacity: 1;
				> span {
					color: #d7d7d7;
				}
				.round-arrow-icon {
					fill: #d7d7d7;
					border-color: $border-color;
				}
			}
		}

		.round-arrow-icon {
			width: 28px;
			height: 28px;
			fill: #999;
			border: 1px solid #999;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			transition: 0.3s ease-in-out;
		}

		.next-btn .round-arrow-icon {
			transform: rotate(180deg);
		}
	}
}

//----권한 상세
.p__admin-auth {
	.role-table-card {
		max-width: 1140px;
		min-height: 600px;
		padding: 25px;
	}

	.chk-area {
		width: 140px;
		justify-content: center;
	}

	.round-checkbox.disabled,
	input[type="checkbox"]:disabled,
	.switch input:disabled + .slider {
		cursor: not-allowed;
	}
}

//권한 상세 table - thead
.p__admin-auth .role-table .thead {
	width: 100%;
	height: 80px;
	display: flex;
	border: 1px solid $border-color;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	background-color: #f9fafa;

	> .th {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			border-right: 1px solid $border-color;
		}
	}

	.selected-member {
		flex: 1;
		padding: 0 20px;
	}

	.member-img {
		width: 40px;
		height: 40px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		margin-right: 11px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.member-info dt {
		color: #333;
		font-weight: 300;
		letter-spacing: -0.05em;
		line-height: 1.5;
	}

	.member-info dd {
		color: #999;
		font-size: 0.625rem;
		font-weight: 300;
		line-height: 1.5;
	}

	.chk-area {
		flex-wrap: wrap;

		.auth-type {
			height: 40px;
			display: inline-flex;
			align-items: center;

			> p {
				font-size: 1rem;
				letter-spacing: -0.05em;
				text-align: center;
			}
		}

		.c__tooltip-btn {
			margin-left: 3px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				fill: $primary-color;
				cursor: pointer;
			}
		}

		.switch-wrap {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			width: 100%;
			border-top: 1px solid $border-color;
		}
	}
}
//권한 상세 table - tbody
.p__admin-auth .role-table .tbody {
	border-right: 1px solid $border-color;
	border-left: 1px solid $border-color;

	.tr {
		display: flex;
		border-bottom: 1px solid $border-color;
	}

	.td {
		display: flex;
		align-items: center;
		min-height: 40px;

		&:not(:last-child) {
			border-right: 1px solid $border-color;
		}
	}

	.role-colgroup-title {
		width: 150px;
		justify-content: center;
		color: #333;
		font-size: 1rem;
		letter-spacing: -0.05em;
		flex-shrink: 0;
	}

	.role-colgroup-wrap {
		flex: 1;
		flex-direction: column;
	}

	.role-colgroup {
		display: flex;
		align-items: stretch;

		&:not(:last-child) {
			border-bottom: 1px solid #ebedee;
		}
	}

	.role-info {
		display: flex;
		align-items: stretch;
		flex: 1;
	}

	.page-name {
		width: 160px;
		color: #333;
		letter-spacing: -0.05em;
		padding-left: 14px;
	}

	.page-desc {
		flex: 1;
		color: #999;
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: -0.05em;
		line-height: 1.5em;
		padding: 7px 14px;
		border-right: 1px solid $border-color;
	}

	.td.chk-area {
		transition: color 0.25s ease-out;
		color: #d9d9d9;

		&.active {
			color: $primary-color;
		}

		svg {
			fill: currentColor;
		}
	}
}

//----반응형
.p__admin-auth {
	@media screen and (max-width: 1200px) {
		.admin-auth-cardRow {
			flex-direction: column;
		}

		.role-member-card {
			width: 100%;
			margin-bottom: 30px;

			.top .search-area {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 1600px) {
		.role-table .tbody {
			.role-colgroup-title {
				min-width: 80px;
				width: 15%;
			}

			.role-info {
				flex-direction: column;
			}

			.page-name {
				width: auto;
			}

			.page-desc {
				margin-top: -10px;
			}
		}

		.role-table .chk-area {
			width: 100px;
		}
	}

	@media screen and (max-width: 1300px) {
		.role-table .td.page-desc {
			display: none !important;
		}
	}
}
