@charset "UTF-8";
@import "src/assets/sass/utils/mixin";

/***
  * 마이페이지 > 라이센스 > 결제(공통스타일)
*/

// 헤더
.p__management-payment,
.p__admin-payment {
	@include headerWithTab;
}

.p__management-payment,
.p__admin-payment {
	.c__card {
		max-width: 1230px;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	.invoice-card {
		max-width: 100%;
		padding: 20px 24px 24px;

		.invoice-card-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 20px;

			.title {
				font-size: 1.125rem;
				font-weight: 600;
			}

			.c__select {
				border-radius: 8px;
				width: 128px;
				height: 40px;
				font-size: 0.875rem;
			}

			.th, .td {
				width: 100%;
			}
		}
	}
}
