@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * new 랜딩페이지 > 푸터
*/

.landing-footer {
	width: 100%;

	.wrap {
		height: 100%;
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}
}

.landing-footer .subscribe {
	height: 226px;
	background: {
		image: url("../../../../img/newLanding/bg-subscribe.png");
		repeat: no-repeat;
		size: 100% 100%;
		position: center center;
	}
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;

	@media screen and (min-width: 1920px) {
		background: {
			size: cover;
			position: center right;
			color: $landing-color;
		}
	}

	@media screen and (max-width: 950px) {
		height: 540px;
		background: {
			image: url("../../../../img/newLanding/mobile-bg-subscribe.png");
			size: 100% 100%;
			position: center center;
		}
	}

	.img-subscribe {
		position: absolute;
		bottom: -1px;
		left: 216px;
		z-index: 0;

		@media screen and (max-width: 1440px) {
			left: 0;
		}

		.img-cloud {
			position: absolute;
			animation: cloud-jump 2s ease-in-out infinite;

			&.cloud01 {
				top: 226px;
				right: 13px;
			}

			&.cloud02 {
				bottom: 0;
				left: -96px;
				z-index: 1;
				animation-delay: 0.15s;
			}

			&.cloud03 {
				bottom: 0;
				left: -167px;
				z-index: -1;
			}

			&.cloud04 {
				bottom: 0;
				right: -190px;
				z-index: -2;
				animation-delay: 0.4s;
			}
		}
	}

	.subscribe-content {
		text-align: right;
		padding-right: 17.969vw;
		display: flex;
		transition: 0.3s;

		@media screen and (max-width: 1700px) {
			padding-right: 2vw;
		}

		@media screen and (max-width: 950px) {
			flex-direction: column;
			justify-content: flex-end;
			padding-right: 9.375%;
		}

		dl:first-child {
			margin-right: 5.469vw; // 105
			transition: 0.3s;

			@media screen and (max-width: 1130px) {
				margin-right: 2.344vw;
			}

			@media screen and (max-width: 950px) {
				margin-right: 0;
				margin-bottom: 46px;
			}
		}

		dt {
			font-size: 24px;
			font-weight: 500;
			letter-spacing: -0.6px;
			color: #ffe037;
			margin-bottom: 16px;

			@media screen and (max-width: 1130px) {
				font-size: calc(18px + (24 - 18) * ((100vw - 768px) / (1130 - 768)));
				margin-bottom: 16px;
			}

			@media screen and (max-width: 950px) {
				font-size: 28px;
			}
		}

		dd {
			font-size: 18px;
			line-height: 1.44;
			letter-spacing: -0.45px;
			color: #fff;

			@media screen and (max-width: 1130px) {
				font-size: calc(14px + (18 - 14) * ((100vw - 768px) / (1130 - 768)));
			}

			@media screen and (max-width: 950px) {
				font-size: 20px;
			}
		}
	}

	.apply-wrap {
		margin-left: 7.552vw;
		transition: 0.3s;

		@media screen and (max-width: 1130px) {
			margin-left: 2.344vw;
		}

		@media screen and (max-width: 950px) {
			display: flex;
			align-items: center;
			flex-direction: column;
			margin: 56px 0 0;
		}

		.img-wrap {
			width: 214px;
			height: 42px;
			margin-bottom: 25px;
			flex-shrink: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		button {
			width: 232px;
			height: 58px;
			background: #000;

			span {
				font-size: 20px;
				font-weight: 700;
				line-height: 1.2;
				letter-spacing: -0.5px;

				@media screen and (max-width: 1130px) {
					font-size: calc(16px + (20 - 16) * ((100vw - 768px) / (1130 - 768)));
				}
			}

			@media screen and (max-width: 950px) {
				width: 252px;
				height: 64px;

				span {
					font-size: 26px;
					font-weight: 500;
					line-height: 0.92;
					letter-spacing: -0.65px;
				}
			}
		}
	}
}

.landing-footer .footer-top {
	height: 85px;
	border: {
		top: 1px solid #d7d7d7;
		bottom: 1px solid #d7d7d7;
	}
	background: #fff;
	z-index: 9;
	position: relative;

	@media screen and (max-width: 768px) {
		height: initial;
		padding-top: 30px;
	}

	.wrap {
		align-items: center;

		@media screen and (max-width: 768px) {
			align-items: flex-start;
			padding: 0 48px;
		}
	}

	.img-wrap {
		width: 113px;
		height: 34px;
		flex-shrink: 0;

		@media screen and (max-width: 768px) {
			width: 129px;
			height: 40px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.nav {
		display: flex;
		align-items: center;

		@media screen and (max-width: 768px) {
			width: 100%;
			justify-content: space-between;
		}

		.nav-list,
		.sns-list {
			display: flex;
			align-items: center;

			li {
				height: 50px;
				padding: 0 10px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.3s;

				@media screen and (max-width: 768px) {
					height: 80px;
				}
			}
		}

		.nav-list {
			margin-right: 15px;

			.nav-item {
				color: #333;
				font-size: 16px;
				letter-spacing: -0.4px;
				white-space: nowrap;

				@media screen and (max-width: 768px) {
					font-size: 24px;
					font-weight: 500;
					letter-spacing: -0.6px;

					&:first-child {
						padding-left: 0;
					}
				}

				&:not(.disabled):hover {
					color: $landing-color;
				}

				&.disabled {
					opacity: 0.35;
					cursor: not-allowed;
				}
			}
		}

		.sns-list .nav-item.disabled {
			opacity: 0.35;
			cursor: not-allowed;
		}

		.sns-list .nav-item:last-child {
			@media screen and (max-width: 768px) {
				padding-right: 0;
			}

			svg {
				fill: #979797;
			}
		}
	}
}

.landing-footer .footer-bottom {
	height: 137px;
	padding: 25px 0;

	@media screen and (max-width: 768px) {
		height: initial;
		padding: 40px 48px;

		.wrap {
			padding: 0;
		}
	}

	.footer-content {
		.address {
			font-size: 16px;
			letter-spacing: -0.4px;

			@media screen and (max-width: 768px) {
				font-size: 20px;
				letter-spacing: -0.5px;
			}

			b {
				font-size: inherit;
				font-weight: 700;
			}
		}

		.num {
			margin: 14px 0 30px;
			letter-spacing: -0.35px;

			@media screen and (max-width: 768px) {
				margin: 12px 0 22px;
				font-size: 18px;
				letter-spacing: -0.45px;
			}

			span:not(:first-child):before {
				content: "";
				width: 1px;
				height: 14px;
				margin: 0 10px;
				background: #ccc;
				display: inline-block;
			}
		}

		.btn-group {
			display: none;

			a {
				@media screen and (max-width: 768px) {
					display: flex;
					margin-bottom: 32px;
					font-size: 18px;
					font-weight: 300;
					letter-spacing: -0.45px;
				}
			}
		}

		.copyright {
			font-weight: 300;
			letter-spacing: -0.35px;
			color: #231f20;

			@media screen and (max-width: 768px) {
				font-size: 16px;
				letter-spacing: -0.4px;
			}
		}
	}

	.btn-group {
		display: flex;

		@media screen and (max-width: 768px) {
			display: none;
		}

		a {
			height: 20px;
			font-weight: 300;
			letter-spacing: -0.35px;
			color: #999;
			transition: 0.3s;

			&:not(:disabled):hover {
				color: $landing-color;
			}

			&:last-child:before {
				content: "";
				width: 1px;
				height: 14px;
				margin: 0 10px;
				background: #ccc;
				display: inline-block;
			}
		}
	}
}
