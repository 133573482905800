@charset "UTF-8";
@import "../../../../sass/utils/var";

/**
  * IR - 모달 전체
*/

/*---사업자등록 미인증 상태 모달---*/
.modal-register-business {
	.dialog-text {
		line-height: 1.57;
		margin-top: 4px;
	}

	.btn-ghost {
		width: fit-content;
		font-weight: 300;
		color: $primary-color;
		border-bottom: 1px solid $primary-color;
		text-align: left;
		line-height: 1.57;
	}
}

/*---편집상태, 사업자등록 미인증상태 모달 공통--*/
.modal-edit-status,
.modal-register-business {
	.alert-body {
		flex-direction: row;
		position: relative;
		padding-top: 40px;
	}

	svg,
	.alert-content {
		margin-bottom: auto;
	}

	svg {
		margin-left: 30px;
		margin-right: 20px;
	}

	.alert-content {
		text-align: left;
		margin-right: 30px;

		.dialog-title {
			font-size: 18px;
			font-weight: 500;
			line-height: 1;
			letter-spacing: -0.05em;
			padding-bottom: 7px;
		}

		.dialog-text {
			color: #666;
			font-weight: 300;
			line-height: 1.3;
			letter-spacing: -0.05em;
		}
	}

	.alert-footer {
		position: absolute;
		bottom: 0;
	}
}

/*---암호설정, 암호변경 모달 공통---*/
.modal-pw-setting,
.modal-pw-change {
	.modal-content {
		padding: 60px 0;
		display: flex;
		justify-content: center;
	}

	.lock-input {
		position: relative;

		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			right: 10px;
			width: 10px;
			height: 35px;
			background-image: url("../../../../img/ico-lock.png");
			background-position: 100%;
			background-size: 10px;
			background-repeat: no-repeat;
			z-index: 1;
		}
	}

	.f__label {
		width: 100px;
	}

	.f__control {
		width: 208px;
	}
}

/*---암호변경, 암호 초기화 모달 공통---*/
.modal-pw-change,
.modal-pw-reset {
	.modal-content {
		padding: 60px 0;
		display: flex;
		justify-content: center;

		&-text {
			color: rgba(0, 0, 0, 0.65);
		}
	}
}

/*---주주명부 불러오기 모달---*/
.modal-shareholder-upload {
	.modal-content {
		padding: 25px 28px 15px;
	}

	.f__label {
		height: 21px;
		line-height: unset;
		margin-bottom: 7px;
	}
}
