@charset "UTF-8";
@import "../../../../sass/utils/var";

/***
  * 관리자 메뉴 > 로고등록
*/

.p__admin-logo {
	.c__card {
		width: 640px;
		height: 585px;

		@media screen and (max-width: 1023px) {
			width: 100%;
		}
	}

	//----- 상단
	.logo-content {
		height: 484px;
		padding: 60px 60px 39px;

		@media screen and (max-width: 1023px) {
			padding: calc(40px + (60 - 40) * ((100vw - 768px) / (1023 - 768)))
				calc(40px + (60 - 40) * ((100vw - 768px) / (1023 - 768)))
				calc(19px + (39 - 19) * ((100vw - 768px) / (1023 - 768)));
		}
	}

	.logo-content-title {
		color: #333;
		font-size: 1.875rem;
		line-height: 1.5;
		letter-spacing: -0.05em;
		margin-bottom: 8px;
	}

	.logo-content-limit {
		color: #333;
		font-weight: 300;
		line-height: 1.43;
		letter-spacing: -0.025em;
		margin-bottom: 28px;
	}

	.logo-uploader-group {
		display: flex;
		justify-content: space-between;
	}

	.logo-type {
		color: #333;
		font-size: 1rem;
		line-height: 1.5;
		letter-spacing: -0.05em;
		margin-bottom: 20px;

		b {
			color: $primary-color;
			font-weight: 300;
			line-height: 1;
			letter-spacing: -0.025em;
			padding-top: 4px;
			margin-left: 4px;
		}
	}

	.logo-uploader {
		width: 240px;
		height: 240px;
		border: 1px solid #e8e8e8;
		background-color: #fafafa;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	// 정사각형 로고
	.square .single-uploader {
		width: 160px;
		height: 160px;
	}

	// 직사각형 로고
	.rectangle .single-uploader {
		width: 220px;
		height: 85px;
	}

	.upload-preview {
		padding: 7px;
		border-color: #e8e8e8;
		background-color: #fff;
	}

	.uploader-wrap {
		width: 100%;
		height: 100%;
	}

	//----- 하단
	.business-bottom {
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid $border-color;

		button {
			width: 76px;

			&:first-child:last-child {
				width: 108px;
			}

			&:first-child:not(:last-child) {
				margin-right: 6px;
			}
		}
	}

	/*---입력---*/
	.uploader-select {
		background-color: #fff;
	}
}
