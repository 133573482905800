@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 투자 - 설정
*/

.p__invest-setting {
	.c__card {
		max-width: 776px;

		@media screen and (max-width: 1023px) {
			width: 100%;
			padding-left: calc(40px + (50 - 40) * ((100vw - 768px) / (1023 - 768)));
			padding-right: calc(40px + (50 - 40) * ((100vw - 768px) / (1023 - 768)));
		}
	}

	section {
		height: 150px;
		padding: 32px 50px;
		display: flex;
		align-items: center;

		&:not(:last-child) {
			border-bottom: 1px solid #ccc;
		}
	}

	// 섹션제목
	.section-title {
		min-width: 138px;
		padding-right: 1em;
		font-size: 1rem;
		font-weight: 500;
		line-height: 60px;
		align-self: flex-start;

		@media screen and (max-width: 1023px) {
			min-width: calc(108px + (138 - 108) * ((100vw - 768px) / (1023 - 768)));
			font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1023 - 768)));
		}
	}

	// 라운드 체크버튼
	.checkbox-btn-group {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	//----- 미체크시
	.checkbox-custom {
		width: 264px;
		height: 60px;
		padding: 21px 20px 21px 46px;
		border: 1px solid #f2f2f2;
		border-radius: 2px;
		background-color: #f2f2f2;
		position: relative;
		transition: 0.3s;

		@media screen and (max-width: 1023px) {
			width: calc(194px + (264 - 194) * ((100vw - 768px) / (1023 - 768)));
			font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1023 - 768)));
		}

		&:not(:last-child) {
			margin-right: 10px;
		}

		&:hover {
			border-color: $hover-color;

			.checkmark {
				border-color: $hover-color;
			}
		}

		.checkmark {
			width: 18px;
			height: 18px;
			border: 1px solid $border-color;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 23px;
			transform: translateY(-50%);
			transition: 0.3s;
		}
	}

	//----- 체크시
	.checkbox-custom.checked {
		box-shadow: 0 0 5px 0 rgba(24, 144, 255, 0.2);
		border-color: $primary-color;
		background-color: #fff;
	}

	input:checked ~ .checkmark {
		/* When the checkbox is checked, add a blue background */
		background-color: $primary-color;

		&::after {
			width: 8px;
			height: 8px;
			border: 1px solid $primary-color;
			border-radius: 50%;
			background: #fff;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) !important;
			transition: none;
		}
	}

	.checkbox-label {
		font-weight: 400;
		letter-spacing: -0.05em;
	}

	// 캡션
	.certified-phrase {
		font-size: 0.8125rem;
		font-weight: 300;
		line-height: 20px;
		color: #999;
		padding: 6px 0 0;
	}
}
