@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

.org-add-modal {
	@import "src/assets/sass/pages/hr/modal/common/secondDepthModal";
}

/***
  * 팀원 > 팀원정보 > 조직도 설정 > 조직 추가 / 조직명 수정
*/

.org-add-modal {
	.f__group {
		width: 100%;
	}

	.f__control {
		height: 38px;

		> svg {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			fill: #bebbc9;
			margin: 0 6px 0 11px;
		}
	}

	.c__input {
		width: 100%;
		height: inherit;
		padding-left: 30px;
		border-radius: 4px;
		border: solid 1px #d8d6de;
		background-color: #f8f8f8;
		font-size: 0.875rem;
		font-weight: 500;
		letter-spacing: -0.35px;
		color: #333139;

		&::placeholder {
			font-size: 0.875rem;
			font-weight: 300;
			letter-spacing: -0.35px;
			color: #878296 !important;
		}
	}

	.notice {
		display: inline-block;
		padding-top: 9px;
		font-size: 0.75rem;
		font-weight: 300;
		color: #878296;
	}
}
