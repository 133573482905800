@charset "UTF-8";
@import "../../../sass/utils/var";

/***
  * VC정보 > 데이터 없음
*/

.p__vc-info {
	//----- 선호투자유형
	.basic-info-wrap .invest-type-chart .no-content {
		width: 100%;
		height: 220px;
		color: #ccc;
		font-weight: 300;
		line-height: 1.5;
		letter-spacing: -0.05em;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.detail-info-wrap {
		//----- 라운드 공지
		.no-rounding-card {
			width: 316px;
			min-height: 314px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
			display: inline-flex !important;
			flex-direction: column !important;
			align-items: center !important;
			justify-content: center !important;
			cursor: initial !important;

			@media screen and (max-width: 1023px) {
				min-height: 235px !important;
			}

			svg {
				width: 83px;
				height: 80px;
				fill: #f8f8f8;
				margin-bottom: 30px;
			}

			.phrase {
				color: #bfbfbf;
				font-family: "Dotum", sans-serif;
				letter-spacing: -0.1em;
			}
		}

		//----- 투자조합, 포트폴리오, 관련뉴스
		.no-content {
			width: 100%;
			height: 110px;
			color: #a8a8a8;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.05em;
			padding: 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		//----- 포트폴리오
		.portfolio-map .no-content {
			height: inherit;
			font-size: 1rem;
			letter-spacing: -0.025em;
			border-bottom: none;
		}

		//----- 관련뉴스
		.relation-news .no-content {
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
}
