.org-chart-container {
	background-color: transparent !important;
	user-select: none;
	.node {
		& > rect {
			stroke: #d3d3d3;
		}
	}
	.group {
		& > rect {
			fill: #f3f3f3;
			rx: 10;
			ry: 10;
			stroke-width: 1;
			stroke: #d3d3d3;
		}
		& > text {
			font-size: 18px;
			fill: #8f8f8f;
			text-anchor: middle;
		}
	}
	.company {
		& > rect {
			fill: #f3f3f3;
		}
		& > circle {
			stroke: #d3d3d3;
		}
		& > text {
			height: 90px;
			fill: #8f8f8f;
			transform: translateY(10px);
		}
	}
}
