@charset "UTF-8";

/***
  * sass/base.scss
*/

body * {
	font-family: "Noto Sans KR", sans-serif;
	font-size: 1rem;
	font-weight: 400;
 word-break: keep-all;
}
