@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 관리자 메뉴 > 팀원초대
*/

// 팀원 초대 리스트
.p__admin-team .c__card {
	padding: 39px 40px 40px;

	@media screen and (max-width: 1200px) {
		padding: calc(19px + (39 - 19) * ((100vw - 768px) / (1200 - 768)))
			calc(20px + (40 - 20) * ((100vw - 768px) / (1200 - 768)));
	}
}

.invite-table {
	position: relative;
}

.invite-table > ul {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #ccc;
}

.invite-table .thead {
	padding-bottom: 22px;
	text-align: center;

	li {
		color: #333;
		font-weight: 500;
		letter-spacing: -0.025em;

		@media screen and (max-width: 1200px) {
			font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
		}
	}
}

.invite-table .tr {
	height: 80px;
	text-align: center;
	transition: 0.3s;

	&:hover {
		background-color: #fbfbfb;
	}

	.td {
		font-size: 0.875rem;
		font-weight: 300;
		line-height: 1.5;
		color: #666;
		text-align: center;
		transition: 0.5s;

		@media screen and (max-width: 1200px) {
			font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
		}

		svg {
			fill: #b3b3b3;
		}
	}
}

// 넓이
.team-num {
	width: 6.4%; //94
}

.team-name {
	width: 18.24%; //268
}

.team-mail {
	min-width: 23.7%; //348
	margin-right: 3.4%; //50
	flex: 1;
}

.team-role {
	width: 14.4%; //212
}

.connect-chk {
	width: 6.4%; //94
}

.team-date {
	width: 10.3%; //152
	min-width: 74px;
}

.team-active {
	width: 9.05%; //133
	position: relative;
	&.active {
		color: $primary-color !important;
	}

	.btn-dropdown {
		margin-right: 6px;

		@media screen and (max-width: 1200px) {
			margin-right: 0;
			padding: 0;
		}
	}
}

/*---모달 공통---*/
.modal-team-invite {
	height: 100%;

	.modal-header {
		height: 80px;

		.modal-title {
			font-size: 1.25rem;
			font-weight: 300;
			line-height: 1.2;
			letter-spacing: -0.05em;
			color: #333;
		}
	}

	// 버튼
	.btn__solid {
		width: 108px;
		height: 42px;
	}
}

/*---팀원초대 모달---*/
.modal-team-invite .invite-form {
	@include customScroll(scroll-y);
	height: 326px;
	padding: 24px 30px 0 30px;

	.c__loader {
		padding-top: 0;
	}

	.dashed-btn {
		margin-bottom: 10px;
	}

	.f__group:not(:last-child) {
		margin-right: 6px;
	}

	.f__feedback {
		word-break: keep-all;
	}

	.team-invite-name,
	.team-invite-password {
		width: 160px;
	}

	.team-invite-mail {
		min-width: 150px;
		flex: 1;
	}

	// 라운드 마이너스 아이콘
	.btn-round-delete {
		height: 35px;
		margin-left: 4px;

		svg {
			fill: #9c9c9c;
			transition: 0.3s;

			&:hover {
				fill: $primary-color;
			}
		}
	}
}

/*---팀원초대완료 모달---*/
.modal-team-invite .invite-result {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 20px 0;

	svg {
		width: 121px;
		height: 122px;
		fill: #d7d7d7;
	}

	.title {
		font-size: 1.125rem;
		letter-spacing: -0.025em;
		margin: 20px 0;
	}

	.notice-wrap {
		display: block;
		width: 401px;
		border: 1px solid #f57d7d;
		padding: 12px 14px 14px 13px;
		margin: 0 auto;
		background-color: #fdedec;
		border-radius: 4px;
		font-size: 0.75rem;
		font-weight: 300;
		line-height: 1.68;
		letter-spacing: -0.025em;
		color: #333;
	}
}

/*---초대 메일 재전송---*/
.modal-resend-mail .modal-content {
	padding: 30px;

	.main-desc {
		color: #333;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 4px;
	}

	.sub-desc {
		color: #666;
	}

	.f__group-row .c__form {
		width: 100%;
		margin-top: 24px;
		display: flex;
		gap: 8px;

		.team-invite-mail {
			width: 100%;
		}

		.team-invite-name .f__control,
		.team-invite-password .f__control {
			width: 160px;
		}
	}
}

/*---초대 메일 재전송 확인모달---*/
.modal-resend-success {
	.main-desc {
		font-size: 16px;
	}

	.sub-desc {
		color: #666;
		line-height: 1.4;
		margin-top: 8px;
	}
}


.team-add-modal-header-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px;
	h3 {
		font-size: 14px;
		font-weight: 700;
	}

	.team-add-modal-header-btns-wrapper {
		display: flex;
		align-items: center;
		gap:4px;
	}
}

.team-add-modal-sub-wrapper {
	padding: 2px 30px 0px;
	h4 {
		font-size: 16px;
		margin-bottom: 16px;
	}

	p {
		font-size: 12px;
		color:#a8a8a8;
	}
}

.team-add-modal-content-wrapper {
    padding:34px 42px 0px;
	display: flex;
	flex-direction: column;
	gap:4px;

	.team-add-modal-title {
		font-weight: 500;
		width: 100%;
		text-align: start;
		line-height: 24px;
		font-size:16px;
	};
	
	.team-add-modal-description {
		color:$text-sub;
		font-size: 14px;
	};
};

