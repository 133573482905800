@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 마이페이지 > 라이센스 > 결제이력 > 리스트 > 상세내역
*/

//---- 영수증 wrap
.receipt-section {
  display: flex;
  flex-flow: wrap;

  .receipt-wrap {
    width: 100%;
    display: flex;
    flex-flow: column;
    position: relative;
    min-width: 460px;
    max-width: 460px;
    min-height: 690px;
    padding: 30px 43px 0 30px;
    margin-right: 20px;

    background: {
      image: url("../../../../../img/receipt-paper.png");
      position: 0 0;
      size: 100%;
      repeat: no-repeat;
    }

    //---- 결제 내역 헤더
    .receipt-header {
      padding: 30px 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed #999;

      .date {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 22px;
        letter-spacing: -0.06em;
        color: #999;
      }

      .state {
        width: fit-content;
        height: 38px;
        text-align: center;
        line-height: 38px;
        font-weight: 400;
        font-size: 0.875rem;
        padding: 0 10px;
        letter-spacing: -0.1em;
        white-space: nowrap;

        &.complete {
          border: 1px solid $primary-color;
          color: $primary-color;
        }

        &.fail {
          border: 1px solid $error-color;
          color: $error-color;
        }
      }
    }

    //---- 결제 내역 제목
    .receipt-title {
      padding: 30px 0;
      border-bottom: 1px solid #666;

      .title {
        font-weight: 500;
        font-size: 1.625rem;
        line-height: 22px;
        letter-spacing: -0.06em;
        color: #666;
        margin-bottom: 16px;
      }

      .price {
        letter-spacing: -0.06em;
        color: #333;
        font-size: 1.375rem;
        font-weight: 500;

        b {
          word-break: break-all;
          font-family: "Poppins", sans-serif;
          font-size: 3.125rem;
          font-weight: 500;
          line-height: 50px;
        }

        &.fail b {
          text-decoration-line: line-through;
        }
      }
    }

    //---- 결제 내역
    .receipt-content {
      padding: 30px 0;

      dl {
        padding: 9px 0;
        display: flex;
        justify-content: space-between;
        font-size: 0.875rem;
        color: #333;

        &:nth-child(4) dt {
          min-width: 100px;
        }
      }

      dt {
        min-width: 138px;
        font-weight: 500;
        letter-spacing: -0.06em;
        line-height: 18px;
      }

      dd {
        font-weight: 400;
        line-height: 16px;
        word-break: break-all;

        &.num {
          font-family: "Poppins", sans-serif;
        }

        &.fail {
          color: $error-color;
        }

        &.complete {
          color: $primary-color;
        }

        span {
          color: #999;
        }
      }
    }

    .btn-group {
      width: calc(100% - 20px);
      text-align: center;
      position: absolute;
      bottom: 58px;
      left: 0;

      .btn__solid {
        padding: 0 27.5px;

        &:first-child:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
