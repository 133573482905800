@charset "UTF-8";
@import "src/assets/sass/utils/var";

.p__findPw {
	@import "src/assets/sass/pages/auth/common/authForm";
}

/***
  * 비밀번호찾기
*/

/*---비밀번호찾기 공통---*/
.p__findPw {
	background: {
		image: url("../../../img/bg-login.jpg");
		position: center;
		size: cover;
		repeat: no-repeat;
	}

	.c__card {
		width: 682px;
	}

	.phrase-wrap {
		> h3 {
			color: #333;
			font-size: 1.5rem;
			font-weight: 500;
			letter-spacing: -0.075em;
			margin: 30px 0 16px 0;
		}

		> p {
			color: #666;
			font-size: 1rem;
			font-weight: 300;
			letter-spacing: -0.05em;
			margin-bottom: 19px;
		}
	}

	.btn-wrap {
		display: flex;
		justify-content: center;
		align-items: center;

		.btn__solid {
			width: 120px;
			height: 50px;
			font-size: 1.125rem;

			&[color="primary"]:disabled {
				background: #d7d7d7;
				opacity: 1;
			}
		}
	}

	.card-bottom-alarm {
		color: #666;
		text-align: center;
		font-weight: 300;
		letter-spacing: -0.025em;
		padding-top: 30px;

		a {
			border-bottom: 1px solid $primary-color;
			color: $primary-color;
			font-weight: 300;
		}
	}
}

/*---비밀번호찾기 인증---*/
.p__findPw .section-mailCheck {
	min-height: 428px;
	padding: 70px 79px 80px 80px;

	.f__group {
		margin-bottom: 24px;
	}

	.btn__solid:not(:last-child) {
		margin-right: 10px;
	}
}

/*---비밀번호찾기 비밀번호이메일링---*/
.p__findPw .section-mailSend {
	min-height: 572px;
	padding: 80px 80px 53px 80px;

	.phrase-wrap {
		text-align: center;

		svg {
			width: 140px;
			height: 140px;
			fill: #d7d7d7;
			margin: 41px 0 31px 0;
		}

		p {
			color: #333;
			font-size: 1.5rem;
			letter-spacing: -0.05em;
			line-height: 1.5;
			margin-bottom: 30px;
			white-space: pre;
		}
	}
}

/*---비밀번호찾기 입력---*/
.p__findPw .section-change {
	min-height: 531px;
	padding: 70px 78px 80px 80px;

	.btn-wrap {
		margin-top: 24px;
	}

	.btn__solid:not(:last-child) {
		margin-right: 10px;
	}
}

/*---비밀번호찾기 완료---*/
.p__findPw .section-complete {
	min-height: 469px;
	padding: 80px;

	.phrase-wrap {
		text-align: center;

		h3 {
			font-size: 2.5rem;
			font-weight: 700;
			letter-spacing: -0.025em;
			margin: 70px 0 19px 0;
		}

		p {
			font-size: 1.125rem;
			letter-spacing: -0.025em;
			line-height: 1.67;
			margin-bottom: 38px;
		}
	}

	.btn__solid {
		width: 130px !important;
	}
}

// 채널톡 위치
.p__findPw .channel-bot {
	position: fixed;
	bottom: 80px;
}
