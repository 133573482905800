@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * 커뮤니티 > 공지사항
**/

.p__community-notice {
	//--- 공지사항 게시판
	.p__notice-view {
		// 공지사항 게시판 높이 스타일
		.community-card-table,
		.community-album-table {
			@media screen and (max-width: 1200px) {
				min-height: 761px;

				.c__noData {
					height: 761px;
				}
			}
		}
	}
	.c__noData {
		@media screen and (max-width: 1200px) {
			height: 761px;
		}
	}

	// 공지사항 게시판 상세보기
	.community-detail-card {
		.content-profile {
			border-bottom: 1px solid #d7d7d7;
			margin-bottom: 24px;
			padding-bottom: 24px;
		}
	}
}
