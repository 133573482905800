@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 인사 > 근태 > 모달
*/

.apply-vacation-modal, .vacation-modal {
	.modal-header {
		border: none;
	
		.modal-title {
			font-weight: 700;
		}
	}
}

/*---휴가신청 모달---*/
.apply-vacation-modal .modal-content {
	@include customScroll(scroll-y);
	height: 486px;
	padding: 4px 28px 20px;

	.warning-phrase {
		width: 100%;
		padding: 20px;
		background: #ffecec;
		color: #ff4444;
		font-size: 0.75rem;
		letter-spacing: -0.06em;
		line-height: 17px;
		margin-bottom: 10px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}

	.vacation-bar {
		// height: 80px;
		background: white;
		border: 1px solid #e4e4e4;
		border-radius: 12px;
		padding: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&.disabled {
			cursor: not-allowed;
			opacity: 0.3;

			.day-status {
				color: #333;
				background: #d7d7d7;
			}
		}

		&:not(.disabled):hover,
		&:not(.disabled):active {
			background: #f5fbff;
			border: 1px solid $primary-color;
		}
	}

	.day-status {
		min-width: 42px;
		padding: 2px 8px;
		font-weight: 400;
		font-size: 0.875rem;
		color: $primary-950;
		background-color: #F0FDFA;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.minus {
			color: #f44;
			background: #fdd;
		}
	}

	.color {
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin-right: 8px;
		flex-shrink: 0;
	}
}

.apply-vacation-modal .vacation-item {
	display: flex;
	align-items: center;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;

	.vacation-badge {
		display: flex;
		gap: 8px;
		margin-left: 16px;
	}

	> span:not(.unpaid-badge) {
		margin-right: 8px;
	}

	.unpaid-badge {
		font-weight: 500;
		padding: 4px 8px;
		height: auto;
	}

	.public-holiday {
		width: fit-content;
		color: #f7377b;
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 17px;
		background-color: #fce4ec;
		border-radius: 4px;
		padding: 4px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.apply-vacation-modal .vacation-status {
	display: flex;
	gap: 12px;
}

//----- 휴가관리 > 휴가 현황 모달
.apply-vacation-modal.personal-chk .vacation-bar:hover,
.apply-vacation-modal.personal-chk .vacation-bar:active {
	background: #fafafa !important;
	border: 1px solid #e4e4e4 !important;
	cursor: default;
}

/*---휴가신청 - 연차, 병가, 출산(유산·사산)휴가---*/
//---- 모달헤더 툴팁
.vacation-modal .modal-header .c__tooltip-btn {
	margin-left: 8px;

	svg {
		fill: #999;
	}

	&:hover svg {
		fill: $primary-color;
	}

	.c__tooltip {
		height: auto;
		line-height: 17px;
		text-align: left;
		padding: 8px;
		top: 23px;
		left: -7px;
		transform: unset;
		z-index: 1;

		&:before {
			top: -7%;
			left: 10px;
			border-color: transparent transparent #eef9ff transparent;
		}

		&:after {
			top: -8%;
			left: 10px;
			border-color: transparent transparent #c0e9ff transparent;
		}
	}
}

//---- 모달컨텐츠
.vacation-modal .modal-content {
	@include customScroll(scroll-y);
	max-height: 554px;

	.color {
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		margin-right: 8px;
		flex-shrink: 0;
	}

	.f__label {
		color: #333;
		padding: 0 0 10px !important;
	}

	.f__group {
		flex: 1;
	}

	// 시작일 종료일
	.datePicker-wrap {
		display: flex;
		padding: 10px 0 20px;
		margin: 0 30px;
		border-bottom: 1px solid $border-color;
		margin-bottom: 10px;
	}

	.paidOnly {
		//display: block;
		//width: 100%;
	}

	// 시작시간, 종료시간
	.timePicker-wrap {
		display: flex;
		padding: 0 30px 10px;
		background: rgba(248, 248, 248, 0.3);

		.react-datepicker__input-container::after {
			background-image: url("../../../../../img/ico-time.svg");
		}
	}

	.datePicker-wrap .dash,
	.timePicker-wrap .dash {
		padding: 30px 16px 0;
	}

	//시간반차 경고문구
	.warning-phrase {
		width: 100%;
		padding: 0 30px;
		color: #ff4444;
		font-size: 0.75rem;
		letter-spacing: -0.06em;
		margin: -4px 0 10px 0;
		line-height: 17px;
	}

	// 휴가종류 선택
	.vacation-name {
		padding: 10px 30px;
		background: rgba(248, 248, 248, 0.3);
		align-items: center;
		gap: 10px;


		.f__label {
			width: 130px;
			padding-bottom: 0 !important;
		}
	}

	.checkbox {
		font-size: 0.875rem;
		padding: 0 30px 17px;
		margin-top: -5px;
		background: #f8f8f8;

		.checkbox-label {
			font-size: 0.875rem;
			font-weight: 400;
		}
	}

	// 메모
	.memo-textarea {
		padding: 20px 30px;
	}

	.c__file-upload-form {
		.f__group {
			padding: 10px 30px;
		}
		a {
			color: #000;
		}

		.c__fileUploader {
			.file-preview {
				height: 40px;
				border-radius: 8px;

				svg {
					height: 14px;
					width: 14px;
					margin-top: 1px;
					margin-right: 4px;
				}

				.btn-delete {
					margin-left: 4px;
				}
			}

			.file-label {
				height: 40px;
				border-radius: 8px;
				margin-left: 12px;
				padding: 4px 8px;
				font-size: 0.875rem;
			}
		}
	}
}

//휴가 설정 모달 안내메세지
.c__file-upload-form .submit,
.vacation-modal .submit {
	padding: 0 30px 10px;
	color: #f44;
	font-size: 0.75rem;
	line-height: 17px;
	letter-spacing: -0.06em;
	margin-top: -6px;

	&.later {
		color: #999;
	}
}

//---- 파일 업로드
.c__file-upload-form .file-phrase {
	font-size: 0.75rem;
	letter-spacing: -0.06em;
	padding: 0 30px;
	line-height: 21px;
	margin: -6px 0 10px;

	&.post {
		color: #999;
	}

	&.pre {
		color: #f44;
	}
}

/*---연차초과 Alert 모달---*/
.overtime-use-modal,
.annual-excess-modal {
	.alert-content b {
		color: #f44;
	}
}

.annual-vacation-form {	
	.c__input, .c__select {
		height: 40px;
		border-radius: 8px;
	}

	.react-datepicker__input-container::after {
		height: 38px;
	}

	.c__selection-item span, .c__select-dropdown-item, .f__label {
		font-size: 0.875rem;
	}

	.f__group .f__control .c__textarea {
		border-radius: 8px;
		background-color: #F9FAFB;
		color: #6B7280;
		font-size: 0.875rem;
		padding: 10px 12px;
		height: 200px;
	}
}

.vacation-modal {
	.modal-footer {
		border: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 30px;

		button {
			margin: 8px 0 28px;
			width: auto;
			padding: 8px 24px;
		}
	}
}

body:has(.annual-vacation-form) {
	.react-datepicker__time-list-item {
		font-size: 0.875rem;
	}
}