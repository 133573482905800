@charset "UTF-8";

/***
  * 근태 > 항목별 구분 마크 색상
*/

.p__vacation,
.p__schedule,
.vacation-setting-modal,
.alert-modal-wrap,
.vacation-chk-card,
.vacation-manage-modal {
	// 휴가 항목 구분 마크
	.vacation-name:before {
		content: "";
		width: 8px;
		height: 8px;
		display: inline-block;
		border-radius: 50%;
		margin-right: 8px;
		flex-shrink: 0;
		//background: red !important;
	}

	// 연차
	.annual:before,
	.vacation-name-bar.annual {
		background-color: #03dbc0;
	}

	// 보건
	.health:before {
		background-color: #ffd74a;
	}

	// 출산휴가
	.pregnancy:before {
		background-color: #0b7782;
	}

	// 출산휴가 (다태아)
	.multi-pregnancy:before {
		background-color: #95d0d5;
	}

	// 배우자 출산휴가
	.spouse:before {
		background-color: #ff429d;
	}

	// 가족 돌봄 휴가
	.family-care:before {
		background-color: #ffa370;
	}

	// 난임 치료 휴가
	.sterility:before {
		background-color: #71357c;
	}

	// 병가
	.sick:before {
		background-color: #f44;
	}

	// 이외 휴가
	.others:before {
		background-color: #4780ff;
	}

	//----- 근무 유형
	// 근무
	.work:before,
	.vacation-name-bar.work {
		background-color: #3fb6f8;
	}

	// 연차
	.excess:before,
	.overtime:before,
	.vacation-name-bar.excess,
	.vacation-name-bar.overtime {
		background-color: #fcc609;
	}

	// 외근
	.outside:before,
	.businessTrip:before,
	.vacation-name-bar.businessTrip {
		background-color: #9f77eb;
	}

	// 재택
	.homeWork:before,
	.remoteWork:before,
	.vacation-name-bar.remoteWork {
		background-color: #ff7092;
	}

	.day-off:before,
	.vacation-name-bar.day-off {
		background-color: #e8e8e8;
	}
}

.p__vacation .vacation-name,
.p__vacation-management .vacation-name{
	// 연차
	&.annual {
		color: #0d9488;
	}

	// 보건
	&.health {
		color: #ffd74a;
	}

	// 출산휴가
	&.pregnancy {
		color: #0b7782;
	}

	// 출산휴가 (다태아)
	&.multi-pregnancy {
		color: #95d0d5;
	}

	// 배우자 출산휴가
	&.spouse {
		color: #ff429d;
	}

	// 가족 돌봄 휴가
	&.family-care {
		color: #ffa370;
	}

	// 난임 치료 휴가
	&.sterility {
		color: #71357c;
	}

	// 병가
	&.sick {
		color: #f44;
	}

	// 이외 휴가
	&.others {
		color: #4780ff;
	}

	//----- 근무 유형
	// 근무
	&.work {
		color: #3fb6f8;
	}
}