@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 외부 링크 상세 페이지
*/

// 탭
.p__round-external {
	@import "./src/assets/sass/pages/invest/common/tabs/detail";
	@import "./src/assets/sass/pages/invest/common/tabs/faq";
	@import "./src/assets/sass/pages/invest/common/tabs/inquiry";
}

.p__round-external {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.l__nav {
		position: relative;
	}
	.l__main {
		max-height: initial;
	}
	.c__card {
		width: 1200px;
		margin: 0 auto;
		padding: 60px;

		@media screen and (max-width: 1400px) {
			width: 100%;
			padding: calc(40px + (60 - 40) * ((100vw - 768px) / (1400 - 768)));
		}
	}
}

// 상단
.p__round-external .rounding-main {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		margin-bottom: 30px;
	}
}

.p__round-external .rounding-main-header {
	border-bottom: 1px solid #d7d7d7;
	padding: 24px 0;
	margin-bottom: 24px;

	.rounding-title {
		color: #333;
		font-weight: 500;
		font-size: 24px;
		line-height: 1.2em;
		letter-spacing: -0.06em;
		margin-bottom: 14px;

		@media screen and (max-width: 1400px) {
			margin-bottom: 8px;
		}
	}

	.info-wrap {
		margin-bottom: 10px;

		.host-name {
			color: #999;
			font-size: 1rem;
			letter-spacing: -0.05em;
			padding-right: 10px;
			display: inline-block;

			@media screen and (max-width: 1400px) {
				font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1400 - 768)));
			}
		}

		.host-site {
			display: inline-block;

			a {
				color: #999;
				font-weight: 300;
				letter-spacing: -0.025em;

				@media screen and (max-width: 1400px) {
					font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1400 - 768)));
				}

				&:hover {
					color: $primary-color;
				}
			}
		}
	}

	.rounding-status {
		color: $primary-color;
		font-size: 1.75rem;
		font-weight: 300;
		line-height: 1;
		letter-spacing: -0.05em;
		margin-bottom: 14px;

		@media screen and (max-width: 1400px) {
			font-size: calc(26px + (28 - 26) * ((100vw - 768px) / (1400 - 768)));
			margin-bottom: 8px;
		}
	}

	.rounding-update {
		color: #666;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.025em;
		white-space: nowrap;

		@media screen and (max-width: 1400px) {
			font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1400 - 768)));
		}
	}
}

// 좌측
.p__round-external .rounding-summary-left {
	width: 100%;
	padding-right: 30px;

	@media screen and (max-width: 1200px) {
		padding-right: calc(14px + (30 - 14) * ((100vw - 768px) / (1200 - 768)));
	}

	@media screen and (max-width: 1024px) {
		padding-right: 0;
	}

	.rounding-img {
		width: 100%;
		height: 0;
		padding-top: calc((540 / 900) * 100%);
		position: relative;
		overflow: hidden;

		img {
			max-width: 100%;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
		}
	}

	.rounding-host-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		margin-top: 24px;

		.rounding-host {
			display: flex;
			align-items: center;
			justify-content: center;

			.rounding-host-logo {
				width: 50px;
				height: 50px;
				margin-right: 18px;
				border-radius: 50%;
				border: 1px solid #d7d7d7;
				background: #d7d7d7;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: cover;
				}
			}

			.host-name {
				color: #666;
				font-size: 22px;
				line-height: 22px;
				letter-spacing: -0.06em;
				margin-bottom: 10px;
				display: inline-block;

				@media screen and (max-width: 1400px) {
					font-size: calc(20px + (22 - 20) * ((100vw - 768px) / (1400 - 768)));
					margin-bottom: calc(4px + (10 - 4) * ((100vw - 768px) / (1400 - 768)));
				}
			}

			.host-email {
				color: #999;
				font-weight: 300;
				font-size: 18px;
				line-height: 18px;

				@media screen and (max-width: 1400px) {
					font-size: calc(16px + (18 - 16) * ((100vw - 768px) / (1400 - 768)));
				}
			}
		}

		.share-sns {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px 0 8px;

			@media screen and (max-width: 1400px) {
				padding: 0 0 0 20px;
			}

			li:not(:last-child) {
				margin-right: 10px;

				@media screen and (max-width: 1400px) {
					margin-right: 6px;
				}
			}

			button {
				width: 36px;
				height: 36px;

				@media screen and (max-width: 1400px) {
					width: calc(30px + (36 - 30) * ((100vw - 768px) / (1400 - 768)));
					height: calc(30px + (36 - 30) * ((100vw - 768px) / (1400 - 768)));
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}

// 우측
.p__round-external .rounding-summary-right {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.rounding-summary {
		color: #666;
		padding: 30px;
		background: #fafafa;
		border-radius: 8px;
		font-size: 1.125rem;
		line-height: 28px;
		letter-spacing: -0.06em;
		word-break: keep-all;

		@media screen and (max-width: 1400px) {
			font-size: calc(16px + (18 - 16) * ((100vw - 768px) / (1400 - 768)));
			padding: calc(28px + (30 - 28) * ((100vw - 768px) / (1400 - 768)));
		}
	}

	.rounding-instructions {
		padding: 24px 0;

		@media screen and (max-width: 1024px) {
			padding-bottom: 30px;
		}

		> div {
			display: flex;
			align-items: center;
		}

		dt,
		dd {
			font-size: 1rem;
			letter-spacing: -0.05em;
			display: inline-block;
		}

		dt {
			width: 2em;
			color: #999;
			margin-right: 15px;
		}

		dd {
			font-weight: 300;
			color: #666;
		}

		.period {
			margin-bottom: 13px;
		}
	}

	.btn-status {
		width: 190px;
		height: 50px;
		font-size: 1rem;
		margin: auto auto 10px;
		background: $primary-color;

		&:not(:disabled):hover,
		&:not(:disabled):active {
			background: darken($primary-color, 6%);
		}
	}
}
