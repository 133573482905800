@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * 멀티셀렉트
*/
.optionListContainer {
	//display: block !important;
}

.multiSelectContainer {
	.optionContainer {
		width: 100%;
		display: grid;
		grid-template-columns: auto auto auto auto;
	}

	li.option {
		color: #333 !important;
		background: transparent;
		transition: 0.3s ease-in-out;

		&:hover {
			background: rgba(152, 213, 255, 0.2);
		}
	}

	.searchWrapper {
		min-height: 35px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		padding-bottom: 0;
	}

	.searchWrapper .chip {
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 0 10px;
		line-height: 1;
		color: #333;
		background: #fff;
		border: 1px solid $border-color;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		margin-bottom: 5px;
		transition: 0.3s ease-in-out;

		.custom-close {
			padding: 10px 10px 10px 9px;
		}

		.solid-close-icon {
			width: 8px;
			height: 8px;
			fill: currentColor;
		}

		&:hover {
			cursor: pointer;
			color: $primary-color;
			border-color: $primary-color;
		}
	}

	input.searchBox {
		width: fit-content;
		margin-top: 2px;
		padding-left: 6px;

		&::placeholder {
			font-weight: 300;
			color: #ccc;
		}
	}
}

.f__control.has-error .searchWrapper {
	border-color: $error-color;
}
