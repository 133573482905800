@charset "UTF-8";
@import "../utils/var";

/***
  * 버튼
*/
$btn-type: solid, outline, neumorphic,download,upload;

$btn-color: (
	primary: $primary-color,
	primary-v2: $primary-950,
	red: $error-color,
	gray: #7d7d7d,
	black: #333139,
	orange: #ff9a00,
	light: #f0fdfa,
);

/*---전체 버튼 공통 스타일--*/
button {
	//버튼 disabled
	&:disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}

	//버튼 hover, active
	&:not(:disabled) {
		&:hover,
		&:active {
			cursor: pointer;
			transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
				background-color 0.3s ease-in-out;
		}
	}
}

@each $type in $btn-type {
	//btn 버튼 공통
	.btn__#{$type} {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		font-family: "Noto Sans KR", sans-serif;
		font-size: 0.875rem;
		font-weight: 500;
		letter-spacing: normal;
		vertical-align: bottom;
		-webkit-border-radius: 25px;
		-moz-border-radius: 25px;
		border-radius: 25px;

		//사각형 버튼
		&.angled {
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		//원형 버튼
		&.circle {
			width: 40px;
			height: 40px;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;

			svg {
				width: 25px;
				height: 25px;
			}
		}

		&:not(.circle) {
			padding-bottom: 1px;

			svg {
				width: 20px;
				height: 20px;
				margin-right: 7px;
			}
		}

		svg {
			fill: currentColor;
		}

		//--버튼 색상 스타일
		@each $name, $hex in $btn-color {
			&[color="#{$name}"] {
				//--solid 타입
				@if ($type == solid) {
					background: $hex;
					color: #fff;

					&:not(:disabled):hover,
					&:not(:disabled):active {
						background: darken($hex, 6%);
					}

					&:disabled {
						opacity: 1;
						background: $border-color;
						color: #f8f8f8;
					}
				}

				//--outline 타입
				@if ($type == outline) {
					border: 1px solid $hex;
					color: $hex;

					&:not(:disabled):hover,
					&:not(:disabled):active {
						border-color: $primary-color;
						color: $primary-color;

						//예외처리 - 길어질 경우 분리 필요
						@if $name == primary {
							background: $primary-color;
							color: #fff;
						}
					}
				}
				//--download 타입
				@if ($type == download) {
					border: 1px solid $hex;
					color: $primary-color;
					font-size: 12px;
					padding:2px 8px;
					height: fit-content;
					&:not(:disabled):hover,
					&:not(:disabled):active {
						border-color: $primary-color;
						color: $primary-color;

						//예외처리 - 길어질 경우 분리 필요
						@if $name == primary {
							background: $primary-color;
							color: #fff;
						}
					}
				}
				@if ($type == upload) {
					border: 1px solid $hex;
					color: #fff;
					font-size: 12px;
					background: $primary-color;
					padding:2px 8px;
					height: fit-content;
					&:not(:disabled):hover,
					&:not(:disabled):active {
						border-color: $primary-color;
						color: $primary-color;

						//예외처리 - 길어질 경우 분리 필요
						@if $name == primary {
							background: #fff;
							color: $primary-color;
						}
					}
				}

				//--neumorphic 타입
				@if ($type == neumorphic) {
					color: $hex;
					border: 1px solid transparent;
					box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05) inset,
						0px -1px 7px 0px rgba(0, 0, 0, 0.05) inset;

					&:not(:disabled):hover,
					&:not(:disabled):active {
						background-color: $primary-color;
						border-color: $hex;
						background: transparent;
					}

					svg {
						fill: none;
					}
				}
				
				
			}
		}

		//---페이지 헤더 버튼 스타일
		.pageHeader-btn-group & {
			padding: 0 30px;

			//헤더 버튼 한개 일 때
			&:first-of-type:last-of-type {
				min-width: 100px;
			}

			//헤더 버튼 두개 이상 일 때
			&:not(:first-of-type:last-of-type):not(:last-child) {
				margin-right: 6px;
			}
		}
	}

	.btn__#{$type}_v2 {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		font-family: "Noto Sans KR", sans-serif;
		font-size: 0.875rem;
		font-weight: 500;
		letter-spacing: normal;
		vertical-align: bottom;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		border-radius: 8px;
		padding: 0 12px;

		//사각형 버튼
		&.angled {
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		//원형 버튼
		&.circle {
			width: 40px;
			height: 40px;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;

			svg {
				width: 25px;
				height: 25px;
			}
		}

		&:not(.circle) {
			padding-bottom: 1px;

			svg {
				width: 20px;
				height: 20px;
				margin-right: 7px;
			}
		}

		svg {
			fill: currentColor;
		}

		//--버튼 색상 스타일
		@each $name, $hex in $btn-color {
			&[color="#{$name}"] {
				//--solid 타입
				@if ($type == solid) {
					background: $hex;
					color: #fff;

					&:not(:disabled):hover,
					&:not(:disabled):active {
						background: darken($hex, 6%);
					}

					&:disabled {
						opacity: 1;
						background: $border-color;
						color: #f8f8f8;
					}
				}

				//--outline 타입
				@if ($type == outline) {
					border: 1px solid $hex;
					color: $hex;

					// &:not(:disabled):hover,
					// &:not(:disabled):active {
					// 	border-color: $primary-950;
					// 	color: $primary-950;

					// 	//예외처리 - 길어질 경우 분리 필요
					// 	@if $name == primary-v2 {
					// 		background: $primary-950;
					// 		color: #fff;
					// 	}
					// }
				}
				//--download 타입
				@if ($type == download) {
					border: 1px solid $hex;
					color: $primary-color;
					font-size: 12px;
					padding:2px 8px;
					height: fit-content;
					&:not(:disabled):hover,
					&:not(:disabled):active {
						border-color: $primary-color;
						color: $primary-color;

						//예외처리 - 길어질 경우 분리 필요
						@if $name == primary {
							background: $primary-color;
							color: #fff;
						}
					}
				}
				@if ($type == upload) {
					border: 1px solid $hex;
					color: #fff;
					font-size: 12px;
					background: $primary-color;
					padding:2px 8px;
					height: fit-content;
					&:not(:disabled):hover,
					&:not(:disabled):active {
						border-color: $primary-color;
						color: $primary-color;

						//예외처리 - 길어질 경우 분리 필요
						@if $name == primary {
							background: #fff;
							color: $primary-color;
						}
					}
				}

				//--neumorphic 타입
				@if ($type == neumorphic) {
					color: $hex;
					border: 1px solid transparent;
					box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05) inset,
						0px -1px 7px 0px rgba(0, 0, 0, 0.05) inset;

					&:not(:disabled):hover,
					&:not(:disabled):active {
						background-color: $primary-color;
						border-color: $hex;
						background: transparent;
					}

					svg {
						fill: none;
					}
				}
				
				
			}
		}

		&[color=primary-v2] {
			@if ($type != outline){
				&:not(:disabled):hover,
				&:not(:disabled):active {
					background: $primary-700;
				}
			}
		}

		&[color=light] {
			color: $primary-950;

			&:not(:disabled):hover,
			&:not(:disabled):active {
				background: darken(#f0fdfa, 3%);
			}
		}

		//---페이지 헤더 버튼 스타일
		.pageHeader-btn-group & {
			padding: 0 20px 1px;

			//헤더 버튼 한개 일 때
			&:first-of-type:last-of-type {
				min-width: 100px;
			}

			//헤더 버튼 두개 이상 일 때
			&:not(:first-of-type:last-of-type):not(:last-child) {
				margin-right: 6px;
			}
		}
	}

	//대쉬버튼
	.dashed-btn {
		width: fit-content;
		min-width: 140px;
		height: 35px;
		border-radius: 2px;
		border: 1px dashed #666;
		font-weight: 500;
		letter-spacing: -0.35px;
		padding: 0 10px;
		color: #666;
		transition: 0.3s;

		&:not(:disabled):hover {
			color: $primary-color;
			border: 1px dashed $primary-color;
		}
	}

	.plus-btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s;

		svg {
			margin-right: 8px;
			fill: #666;
			transition: 0.3s;
		}

		&:not(:disabled):hover {
			color: $primary-color;
			border: 1px dashed $primary-color;

			svg {
				fill: $primary-color;
			}
		}

		&:not(:disabled):disabled {
			background: transparent !important;
			border-color: #fafafa;
		}
	}

	// 프린트, pdf 버튼
	.btn-circle {
		width: 40px;
		height: 40px;
		border: 1px solid #716b77;
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-right: 6px;
		transition: 0.3s;

		svg {
			fill: #716b77;
			transition: 0.3s;
		}

		&:not(:disabled):hover {
			border: 1px solid $primary-color;

			svg {
				fill: $primary-color;
			}
		}
	}

	// 닫기버튼
	.btn-close {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		position: absolute;
		right: 0;
		top: 0;
		transition: 0.3s ease-in-out;
		z-index: 9;

		&:hover svg,
		&:focus svg {
			fill: $primary-color;
		}

		svg {
			fill: #9c9c9c;
		}
	}
}

.btn-linked {
	font-size: 0.875rem;
	letter-spacing: -0.35px;
	color: $primary-color;
	position: relative;

	&:after {
		content: "";
		width: 100%;
		height: 1px;
		background: $primary-color;
		position: absolute;
		bottom: -2px;
		left: 0;
	}
}
