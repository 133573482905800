@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

//팀원 정보 모달 > 프로필 수정
//한줄소개
.teamInfo-profile.edit .c__textarea {
	@include customScroll(scroll-y);
	overflow-y: auto;
	width: 100%;
	height: 200px;
	padding: 9px 12px 10px;
	margin-bottom: 22px;
	border-radius: 8px;
	font-size: 0.75rem;
	color: #989898;
	letter-spacing: -0.025em;
}
