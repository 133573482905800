@charset "UTF-8";
@import "src/assets/sass/utils/var";

/***
  * 주주 > 주주명부 > 공통 스타일
*/

/*---VIEW, EDIT 공통---*/
// 제목
.section-title {
	font-size: 1.625rem;
	font-weight: 600;
	letter-spacing: -0.05em;
	line-height: 1.5;
	color: #333;

	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
}

// 플러스 대쉬버튼
.plus-btn {
	border: 1px dashed #bfbfbf;
	color: #bfbfbf;
	transition: 0.3s;
	margin-bottom: 10px;

	svg {
		fill: #bfbfbf;
	}
}

.shareholder-view-card,
.shareholder-edit-card {
	@media screen and (max-width: 1024px) {
		padding: 30px;
	}
}

// 테이블 공통
.shareholder-view-card .shareholder-table,
.shareholder-edit-card .shareholder-table {
	.tr {
		color: #333;
		display: flex;
		align-items: center;
		text-align: center;
	}

	//------ thead
	.thead {
		border-bottom: 1px solid #ccc;

		.th {
			font-weight: 500;
			letter-spacing: -0.025em;
		}
	}

	//------ tbody
	.tbody .tr {
		border-bottom: 1px solid #ccc;

		.td,
		.td span {
			font-weight: 300;
		}
	}
}

// 푸터
.shareholder-footer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	.info-area {
		@media screen and (max-width: 1024px) {
			width: 60%;
		}
	}

	.company-name {
		color: #333;
		font-size: 2rem;
		font-weight: 700;
		line-height: 1.45;
		letter-spacing: -0.1em;
		margin-bottom: 5px;
		word-break: break-word;

		@media screen and (max-width: 1440px) {
			font-size: calc(20px + (32 - 20) * ((100vw - 768px) / (1440 - 768)));
		}
	}

	.company-address {
		color: #333;
		font-size: 1rem;
		font-weight: 300;
		line-height: 2;
		letter-spacing: -0.05em;
		word-break: break-word;

		@media screen and (max-width: 1440px) {
			font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1440 - 768)));
		}
	}

	.seal-area {
		width: 390px;
		border-bottom: 2px solid #333;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-left: 30px;
		position: relative;

		.signatory-position {
			color: #666;
			line-height: 1.42;
			letter-spacing: -0.05em;
		}

		.signatory-name {
			color: #333;
			font-size: 1.75rem;
			font-weight: 500;
			line-height: 1.46;
			letter-spacing: -0.05em;
			margin: 0 10px 10px 0;
			white-space: nowrap;

			@media screen and (max-width: 1440px) {
				font-size: calc(18px + (28 - 18) * ((100vw - 768px) / (1440 - 768)));
			}
		}

		.selected-signature {
			width: 236px;
			height: 122px;
			margin-bottom: 12px;

			@media screen and (max-width: 768px) {
				width: 214px;
				height: 100px;
			}

			img {
				width: 100%;
				height: 100%;
				z-index: 9;
				object-fit: contain;
			}
		}

		// edit에서 보임
		.single-uploader {
			width: 236px !important;
			height: 122px !important;
			margin-bottom: 12px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			@media screen and (max-width: 768px) {
				width: 214px !important;
				height: 100px !important;
			}
		}
	}
}
