@charset "UTF-8";
@import "src/assets/sass/utils/var";

/**
  * 투자 - 라운드 상세 모달 (폼 제외)
*/

// 탭
.round-modal {
	@import "./src/assets/sass/pages/invest/common/tabs/detail";
	@import "./src/assets/sass/pages/invest/common/tabs/faq";
	@import "./src/assets/sass/pages/invest/common/tabs/inquiry";
}

//.round-modal .modal-container {
//	height: calc(100vh - 100px);
//}
//
//.round-modal .modal-content {
//	border: 1px solid #00f;
//	//height: calc(100vh - 100px);
//}

.round-modal {
	overflow: auto;
	align-items: flex-start;
	padding: 60px 0;

	@media screen and (max-width: 768px) {
		padding: 80px 0 200px !important;
	}

	.prev-btn {
		display: flex;
		align-items: center;
		font-size: 1rem;
		color: #333;

		&:hover {
			color: $primary-color;

			svg {
				fill: $primary-color;
			}
		}

		svg {
			width: 12px;
			height: 12px;
			fill: #a0a0a0;
			margin-right: 10px;
			margin-top: 2px;
			transition: 0.3s;
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
	}

	.round-information {
		flex: 1;
		height: 100%;
		padding: 40px;
		display: flex;
		flex-direction: column;

		.rounding-main {
			margin-bottom: 30px;
		}

		.date-wrap {
			width: 170px;
			flex-shrink: 0;
		}
	}

	@media screen and (max-width: 1200px) {
		.modal-container {
			width: calc(560px + (1100 - 560) * ((100vw - 768px) / (1200 - 768))) !important;
		}
	}
}

.round-modal .rounding-main-header {
	margin-bottom: 24px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	.rounding-title {
		color: #333;
		font-size: 1.75rem;
		font-weight: 700;
		line-height: 1;
		letter-spacing: -0.025em;
		margin-bottom: 16px;
	}

	.host-name {
		color: #666;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.05em;
		padding-right: 12px;
		display: inline-block;
	}

	.host-site {
		color: #999;
		font-weight: 300;
		letter-spacing: -0.025em;
		display: inline-block;
	}

	.rounding-status {
		color: $primary-color;
		font-size: 1.75rem;
		font-weight: 300;
		text-align: right;
		line-height: 1;
		letter-spacing: -0.05em;
		margin-bottom: 16px;
	}

	.rounding-update {
		color: #999;
		font-size: 1rem;
		font-weight: 300;
		text-align: right;
		letter-spacing: -0.025em;
	}

	@media screen and (max-width: 1024px) {
		.rounding-title {
			font-size: calc(22px + (28 - 22) * ((100vw - 768px) / (1024 - 768)));
			margin-bottom: calc(0px + (16 - 0) * ((100vw - 768px) / (1024 - 768)));
		}
		.rounding-status {
			height: calc(22px + (28 - 22) * ((100vw - 768px) / (1024 - 768)));
			margin-bottom: calc(8px + (16 - 8) * ((100vw - 768px) / (1024 - 768)));
		}

		.rounding-update {
			font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1024 - 768)));
		}
	}
}

.round-modal .rounding-main-container {
	min-height: 460px;
	padding: 32px 40px 18px 30px;
	background-color: #fafafa;
	border-radius: 8px;
	display: flex;
	flex-flow: wrap;
	justify-content: space-between;

	@media screen and (max-width: 1200px) {
		.rounding-summary-left,
		.rounding-summary-right {
			width: 100% !important;
		}
		.rounding-summary-left {
			padding-right: 0 !important;
		}
	}
}

.round-modal .rounding-main .rounding-summary-left {
	width: 62.5%;
	padding-right: 30px;

	.rounding-img {
		width: 100%;
		height: 0;
		padding-top: calc((360 / 542) * 100%);
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
			max-width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			object-fit: cover;
		}
	}

	.rounding-host-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		margin-top: 20px;

		.rounding-host {
			display: flex;
			align-items: center;
			justify-content: center;

			.rounding-host-logo {
				width: 40px;
				height: 40px;
				margin-right: 14px;
				border: 1px solid #d7d7d7;
				border-radius: 50%;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: cover;
				}
			}

			.host-name {
				color: #666;
				font-size: 1rem;
				letter-spacing: -0.05em;
				display: inline-block;
				margin-bottom: 10px;
			}

			.host-email {
				color: #999;
				font-weight: 300;
				letter-spacing: -0.025em;
			}
		}

		.share-sns {
			display: flex;
			align-items: center;
			justify-content: center;

			li {
				width: 22px;
				height: 22px;

				&:not(:last-child) {
					margin-right: 10px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}

.round-modal .rounding-main .rounding-summary-right {
	width: 37.5%;
	display: flex;
	flex-direction: column;

	.rounding-summary {
		min-height: 36px;
		color: #666;
		font-size: 1rem;
		font-weight: 300;
		line-height: 1.5;
		letter-spacing: -0.025em;
		padding: 10px 0 20px;
		border-bottom: 1px solid $border-color;
		word-break: break-word;
	}

	.rounding-instructions {
		padding: 10px 0 30px;

		> div {
			display: flex;
			align-items: center;
		}

		dt,
		dd {
			font-size: 1rem;
			font-weight: 300;
			line-height: 1;
			letter-spacing: -0.05em;
			display: inline-block;
		}

		dt {
			width: fit-content;
			min-width: 32px;
			color: #999;
			margin-right: 15px;
		}

		dd {
			color: #333;
		}

		.period {
			height: 18px;
			margin-bottom: 16px;
		}
	}

	.apply-agree {
		display: flex;
		align-items: center;
		margin-top: auto;
		margin-bottom: 20px;

		.checkbox-label {
			color: #333;
			font-size: 0.875rem;
			font-weight: 300;
			line-height: 1;
			letter-spacing: -0.04em;
		}

		.btn-ghost {
			width: auto;
			height: auto;
			font-weight: 300;
			text-decoration: underline;
			line-height: 1;
			letter-spacing: -0.025em;

			a {
				width: 100%;
				height: 100%;
				color: $primary-color;
				font-size: inherit;
				font-weight: inherit;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.2s;
			}
		}
	}

	.btn-status {
		width: 208px;
		height: 50px;
		font-size: 1.125rem;
		margin: 0 auto;
	}
	.btn-status.disabled {
		cursor: not-allowed;
		background: #d7d7d7;
		color: #f8f8f8;
	}
}

/**
      * 라운드 상세 모달 - 기업정보 입력 체크 useAlert
    */

.comCheck-alert {
	//.alert-content{
	//
	//}
}
