@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

.p__adminDashboard,
.p__dashboard {
	@import "common/dashboardAlarm";
}

/**
  * 대시보드 - 어드민
*/

.p__adminDashboard .c__card,
.p__dashboard .c__card {
	//width: 1040px;
	margin: 0 auto;

	@media screen and (max-width: 1247px) {
		width: 100%;
	}
}

.p__adminDashboard,
.p__dashboard {
	/*---상단 intro-section---*/
	.intro-section {
		padding: 60px 72px 49px 67px;
		border-bottom: 1px solid #e4e4e4;

		@media screen and (max-width: 1247px) {
			padding: 5.77% 6.730769% !important;
		}

		.intro-phrase-wrap {
			display: flex;
			justify-content: space-between;

			@media screen and (max-width: 1023px) {
				flex-flow: column;
				align-items: center;

				.greeting-wrap {
					order: 2 !important;
					text-align: center;
				}

				.img-wrap {
					order: 1 !important;
					width: 80% !important;
					margin-right: 0;
				}
			}

			// 인사말
			.greeting-wrap {
				order: 1;

				.greeting-title {
					color: #333;
					font-size: 2.875rem;
					letter-spacing: -0.05em;
					display: inline-block;
					margin-top: 34px;
					margin-bottom: 29px;

					@media screen and (max-width: 1247px) {
						font-size: calc(30px + (46 - 30) * ((100vw - 768px) / (1247 - 768)));
					}
				}

				p {
					color: #666;
					font-size: 1rem;
					font-weight: 300;
					line-height: 1.38;
					letter-spacing: -0.05em;
					margin-bottom: 73px;
					text-align: justify;
					white-space: pre-wrap;

					@media screen and (max-width: 1247px) {
						font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1247 - 768)));
						margin-bottom: calc(16.495% - 0.15rem);
					}
				}
			}

			.img-wrap {
				order: 2;
				width: 412px;

				img {
					width: 100%;
					margin-right: 11px;
					flex-shrink: 0;
					object-fit: cover;
				}

				@media screen and (max-width: 1247px) {
					width: 45% !important;
				}

				@media screen and (max-width: 1023px) {
					order: 1;
					width: 80% !important;
				}
			}
		}

		// 퀵팁
		.quick-tip-wrap {
			// 제목
			.quick-tip-title {
				color: #666;
				font-size: 1rem;
				font-weight: 300;
				letter-spacing: -0.05em;
				margin-bottom: 12px;

				b {
					color: $primary-color;
					font-size: 1.125rem;
					font-weight: 500;
					letter-spacing: -0.05em;
				}
			}

			// 퀵팁메뉴
			.quick-tip-menu {
				border: solid 1px #e8e8e8;
				box-shadow: 2.8px 2.8px 0 0 rgba(1, 1, 1, 0.04);
			}

			// 라이센스 있을시
			.menu-check-wrap {
				height: 67px;
				padding: 0 25px 0 18px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				&:not(:last-child) {
					border-bottom: 1px solid #e8e8e8;
				}

				@media screen and (max-width: 1247px) {
					&:last-child {
						padding-top: 10px;
						align-items: flex-start;
						flex-direction: column;
						justify-content: initial;

						.link-caption {
							width: 100%;
							padding-top: 4px;
							display: flex;
							justify-content: flex-end;

							p {
								margin-right: 6px;
							}

							a:before {
								margin-right: 4px;
							}

							p,
							a {
								font-size: calc(
									12px + (14 - 12) * ((100vw - 768px) / (1023 - 768))
								);
							}
						}
					}
				}

				.desc-wrap {
					display: flex;
					align-items: center;
				}

				.menu-number,
				.chk-mark {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					font-size: 1rem;
					letter-spacing: -0.05em;
					margin-right: 23px;

					@media screen and (max-width: 1023px) {
						margin-right: 10px;
					}
				}

				.chk-mark {
					background-color: $primary-color;

					&::before {
						content: "";
						width: 14px;
						height: 11px;
						background-image: url("../../../img/chkMark-icon.png");
						background-repeat: no-repeat;
					}
				}

				.menu-number {
					background-color: #a8a8a8;
				}

				.menu-check {
					color: #333;
					font-size: 1.375rem;
					font-weight: 200;
					letter-spacing: 0.05em;
					white-space: nowrap;

					@media screen and (max-width: 1023px) {
						font-size: calc(
							16px + (22 - 16) * ((100vw - 768px) / (1023 - 768))
						) !important;
					}
				}

				.link-caption {
					text-align: right;

					p {
						color: #999;
						font-weight: 300;
						letter-spacing: -0.05em;
						margin-bottom: 6px;
					}

					a {
						color: #333;
						letter-spacing: -0.05em;

						&:hover {
							cursor: pointer;
						}

						&::before {
							content: "";
							width: 8px;
							height: 9px;
							background-image: url("../../../img/doubleArrow-icon.png");
							background-repeat: no-repeat;
							display: inline-block;
							margin-right: 7px;
						}
					}
				}
			}

			.quick-connect-wrap {
				text-align: right;
				margin-top: 23px;

				span {
					color: #666;
					letter-spacing: -0.05em;
					font-weight: 200;
					margin-right: 10px;
				}

				a {
					color: $primary-color;
					font-size: 1rem;
					letter-spacing: -0.05em;
					border-bottom: 1px solid $primary-color;
					cursor: pointer;

					&:hover {
						color: darken($primary-color, 6%);
					}

					@media screen and (max-width: 1023px) {
						font-size: calc(
							14px + (16 - 14) * ((100vw - 768px) / (1023 - 768))
						) !important;
					}
				}
			}

			// 라이센스 없을시
			&.fail {
				margin-top: 26px;
				.chk-mark {
					background: $error-color;
					font-size: 1rem;
					letter-spacing: -0.06em;
					color: #fff;
					margin-right: 20px;

					&::before {
						content: none;
					}
				}

				.menu-check {
					color: $error-color;
					letter-spacing: -0.06em;
					font-weight: 300;
				}
			}
		}
	}

	/*---하단 article-section---*/
	.article-section,
	.article-section-initial {
		padding: 60px 72px 60px 67px;
		background: #fff;
		//background: linear-gradient(#f8f8f8, #fff 50%);
		border-bottom-left-radius: 13px;
		border-bottom-right-radius: 13px;

		// 제목 공통
		.article-title {
			color: #333;
			font-size: 1.25rem;
			font-weight: 500;
			letter-spacing: -0.05em;
		}

		// 투자 라운드
		.invest-round {
			// empty
			.c__noData {
				background: #fafafa;
				height: 210px;
				margin-top: 23px;
				margin-bottom: 69px;
			}

			// ------슬라이드
			.slick-slider {
				width: calc(100% + 20px);
				margin-left: -10px;
				margin-bottom: 56px;
				padding-top: 23px;

				&.hasArrows .slick-list {
					margin: 0 20px;
				}
			}

			.slick-list {
				padding-bottom: 13px;
			}

			.slick-track {
				margin-left: 0;
			}

			.slick-prev {
				left: 0;

				&::before {
					content: url("../../../img/ico-arrow-left.svg");
				}
			}

			.slick-next {
				right: 0;

				&::before {
					content: url("../../../img/ico-arrow-right.svg");
				}
			}

			.slick-dots li button:before {
				font-size: 0.75rem;
				color: #a8a8a8;
				opacity: 1;
			}

			.slick-dots li.slick-active button:before {
				color: $primary-color;
			}

			.slick-slide {
				padding: 0 10px;

				.round-card {
					width: 100%;
					border-radius: 13px;
					overflow: hidden;
					border: 1px solid #e8e8e8;

					&:not(.empty) {
						cursor: pointer;
					}

					.round-img {
						width: 100%;
						height: 128px;
						position: relative;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						// 지원완료
						.complete-card {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background: rgba(0, 0, 0, 0.7);
							z-index: 1;

							span {
								color: #fff;
								font-size: 1.5rem;
								font-weight: 200;
								letter-spacing: -0.05em;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
					}

					.round-container {
						width: 100%;
						height: auto;
						padding: 14px 13px 15px 15px;
						background: #fff;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						p {
							@include ellipsis;
							color: #333;
							font-size: 1rem;
							font-weight: 300;
							letter-spacing: -0.025em;
							margin-bottom: 13px;
						}

						span {
							margin-left: auto;
							font-size: 1.375rem;
							font-weight: 300;
							letter-spacing: -0.05em;
						}

						.d-day {
							color: $primary-color;
						}

						.deadline {
							color: #999;
						}
					}

					.round-container-prepare {
						width: 100%;
						height: 203px;
						position: relative;
						background-color: #fff;

						p {
							color: #a8a8a8;
							font-size: 0.75rem;
							letter-spacing: -0.1em;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							word-break: keep-all;
						}
					}
				}
			}
		}

		// 활동내역
		.active-state {
			padding-bottom: 54px;

			&-card {
				display: flex;
				border: 1px solid #e8e8e8;
				box-shadow: 2.8px 2.8px 0 0 rgba(1, 1, 1, 0.04);
				background-color: #fff;
				margin-top: 19px;
			}

			&-item {
				width: 100%;
				display: flex;
				flex-flow: column;
				align-items: center;
				padding: 30px 0;

				&:not(:last-child) {
					border-right: 1px solid #e8e8e8;
				}

				.title {
					color: #333;
					font-weight: 300;
					font-size: 1rem;
					letter-spacing: -0.05em;
				}

				.count {
					height: 100%;
					margin-top: 16px;
					color: #666;
					font-size: 1rem;
					font-weight: 300;
					letter-spacing: -0.05em;

					&.no-auth {
						color: #999;
						font-size: 12px;
						padding-top: 8px;
					}

					b {
						font-size: 1.875rem;
						color: $primary-color;
						font-weight: 300;
						margin-right: 5px;
					}
				}
			}
		}

		// 인력증감 추이
		.member-vary-chart {
			&-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.chart-caption {
				display: flex;

				button {
					color: #999;
					font-size: 0.8125rem;
					letter-spacing: -0.01em;
					font-weight: 300;
					display: flex;
					align-items: center;

					&:not(:last-child) {
						margin-right: 18px;
					}

					svg {
						margin-right: 6px;
					}
					&.disabled {
						color: #ddd;
					}
				}

				.entering svg {
					fill: $primary-color;
				}

				.leaving svg {
					fill: #ddd;
				}
			}

			.chart-area {
				padding-top: 22px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.c__noData {
				padding: 30px 0;
				background: #fafafa;
			}
		}

		/*---대시보드 초기화면---*/
		.invest-round-initial,
		.member-vary-initial,
		.recent-alarm-initial {
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				color: #999;
				font-size: 1rem;
				font-weight: 300;
				letter-spacing: -0.05em;
			}
		}

		.invest-round-initial {
			height: 255px;
			flex-flow: column;
			margin-top: 20px;
			border: 1px solid #e8e8e8;

			p {
				line-height: 26px;
				margin-top: 22px;
			}

			.plugin-shortcut {
				margin-left: 3px;
				color: $primary-color;
				font-size: 1rem;
				letter-spacing: -0.05em;
				border-bottom: 1px solid $primary-color;
			}

			svg {
				fill: rgb(204, 204, 204);
			}
		}

		.member-vary-initial {
			height: 196px;
			margin-top: 20px;
			border: 1px solid #e8e8e8;
		}

		.recent-alarm-initial {
			height: 159px;
			border-bottom: 1px solid #f3f3f3;
		}
	}

	//--- 사용자 대시보드
	//.p__userDashboard {
	//	.intro-section {
	//		margin-bottom: 30px;
	//
	//		h3 {
	//			font-size: 46px;
	//			font-weight: 500;
	//			letter-spacing: -0.075em;
	//			margin-bottom: 26px;
	//
	//			@media screen and (max-width: 1023px) {
	//				text-align: left;
	//			}
	//
	//			b {
	//				font-size: inherit;
	//				font-weight: 200;
	//				margin-bottom: 15px;
	//				display: block;
	//			}
	//		}
	//	}
	//
	//	.recent-alarm {
	//		padding: 60px 70px 56px;
	//
	//		@media screen and (max-width: 1247px) {
	//			padding: 5.77% 6.730769% !important;
	//		}
	//
	//		.article-title {
	//			font-size: 1.25rem;
	//			letter-spacing: -1px;
	//		}
	//
	//		.recent-alarm-initial {
	//			min-height: 160px;
	//			background-color: #fff;
	//			display: flex;
	//			justify-content: center;
	//			align-items: center;
	//
	//			p {
	//				color: #999;
	//				font-size: 1rem;
	//				font-weight: 300;
	//				letter-spacing: -0.05em;
	//			}
	//		}
	//	}
	//}


	// ** ----- 2024.05.13 이후 추가
	.p__dashboard_container {
		.c__card {
			margin-bottom: 40px;
			border-bottom: 1px solid #e4e4e4;

			.c__title {
				width: 100%;
				display: flex;
				justify-content: space-between;
				font-size: 1rem;
			}

			.c__tabMenu {
				.c__tabMenuItem {
					line-height: 0.6;
					margin-top: 10px;
				}
			}

			&.dashboard_vacation {
				padding: 30px 30px 40px 30px;
				.c__tabMenu {
					margin-bottom: 0;
				}
				.team-schedule {
					padding: 16px 0 0 0;
				}
				.my-schedule {
					padding: 40px 0 0 0;

					.c__card {
						box-shadow: none;
						border: 1px solid #D7D7D7;
						margin-bottom: 20px;

						&.week-schedule-section {
							border: 0 none;
							padding: 0;
						}
					}
				}
			} // dashboard_vacation
			.my-info {
				padding: 40px 30px;
				display: flex;
				justify-content: space-between;
				gap: 30px;
				.basic-info {
					display: flex;
					align-items: center;
					gap: 24px;
					max-width: 480px;
					min-width: 300px;
					width: 35%;
					flex: 0,75;

					img {
						width: 100px;
						height: 100px;
					}

					.profile-content {
						display: flex;
						flex-direction: column;
						overflow: hidden;
						flex: 1;

						.profile-authority {
							color: $primary-color;
							font-size: 0.875rem;
							margin: 2px 0 8px; 
							line-height: 1.5;
						}

						.profile-name {
							color: #1B1B1B;
							font-weight: 700;
							font-size: 1.25rem;
							margin-bottom: 6px;
							white-space: normal;
							-webkit-line-clamp: 2; 
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.profile-email {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				} // basic-info
				.vacation-info {
					flex: 1;
					.vacation-top {
						height: 36px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 8px;

						.title {
							font-size: 1rem;
							font-weight: 600;

							span {
								font-size: inherit;
								color: #A8A8A8;
							}
						}
					}
					.progress-wrap {
						.status-wrap {
							display: flex;
							gap: 40px;
							margin-bottom: 16px;
							.status-item {
								position: relative;
								padding-left: 12px;
								&:before {
									content: "";
									display: block;
									position: absolute;
									top: 50%;
									left: 0;
									transform: translateY(-50%);
									width: 8px;
									height: 8px;
									border-radius: 100%;
									background: $primary-color
								}
								.count {
									font-weight: 700;
								}
								&.use {
									.count { color: $primary-color }
								}
								&.rest {
									&:before { opacity: 0.2; }
									.count { color: #A8A8A8; }
								}
							}
						} // status-wrap
						.progress-bar {
							height: 14px;
							background: #CCFBF1;
							border-radius: 8px;
							overflow: hidden;

							.progress-bar-active {
								height: 100%;
								background: #14B8A6;
							}
						}
					}

				} // vacation-info
			} // my-info


		} // c__card

		.management_section {
			display: flex;
			gap: 40px;
			width: 100%;
			overflow: hidden;
			@media screen and (max-width: 1330px) {
				flex-direction: column;
				height: auto;
				gap: 0;
			}
			.approval-request {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				max-width: 400px;
				padding: 30px 30px 40px 30px;
				height: 268px;
				@media screen and (max-width: 1330px) {
					max-width: 100%;
				}
				.content {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 148px;
					border: 1px solid #D7D7D7;
					border-radius: 12px;
					.inner-content {
						.number {
							font-size: 3.125rem;
							font-weight: 700;
							letter-spacing: -0.03em;
						}
						.ea {
							margin-left: 8px;
							position: relative;
							bottom: 2px;
						}
					}
				}
			} // approval-request
			.team-management {
				height: 268px;
				padding: 30px 30px 40px 30px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.content {
					display: flex;
					gap: 40px;
					justify-content: space-around;
					.status-item {
						flex: 1;
						height: 148px;
						display: flex;
						border: 1px solid #D7D7D7;
						border-radius: 12px;
						overflow: hidden;
						@media screen and (max-width: 1740px) {
							flex-direction: column;
						}
						.icon-wrap {
							display: inline-flex;
							justify-content: center;
							align-items: center;
							min-width: 152px;
							background: #F8F8F8;
						}
						.status-content {
							flex: 1;
							display: flex;
							justify-content: center;
							align-items: center;
							gap: 20px;

							.name {
								font-size: 1rem;
							}

							.number {
								font-size: 3.125rem;
								font-weight: 700;
								letter-spacing: -0.03em;
								&.active {
									color: $primary-color;
								}
								&.non-active {
									color: #A8A8A8;
								}
							}
						}
					}
				}
			}
		} // management_section

		.my-activity {
			display: flex;
			gap: 18px;
			height: 356px;
			.c__card {
				height: 100%;
				padding: 30px 30px 40px 30px;
				max-width: calc(50% - 9px);
			}
		} // my-activity

	} // p__dashboard_container
}
