@charset "UTF-8";
@import "src/assets/sass/utils/var";

.teamInfo-contract.detail {

    .section-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            margin-right: 4px;
            margin-top: 2px;
        }

        button {
            height: 36px;
            font-size: 0.875rem;
            position: relative;
        }

        .upload-btn {
            .file-input {
                position: absolute;
                width: inherit;
                height: inherit;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                z-index: 2;
            }

            &:hover {
                background-color: white;
                color: $primary-950;
            }
        }
    }

    &.scroll-wrap {
        height: calc(100% - 10px);
    }

    .c__card-contact-wrap {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .c__card {
        height: 164px;
        width: 290px;
        padding: 16px 20px;
        position: relative;
        border: 1px solid #E5E7EB;
        transition: 0.3s ease-in-out;
        box-shadow: none;

        &:hover {
            border-color: $primary-color;
        }

        h5 {
            font-size: 1.125rem;
            font-weight: 600;
            margin-bottom: 12px;
        }

        p {
            font-size: 0.875rem;
            color: #374151;
            font-weight: 400;
        }

        .c__dropdown-wrap {
            height: auto;
            top: 22px;
            right: 10px;
        }

        .c__dropdown {
            right: 0;
            box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);
            border: 0.5px solid #E5E7EB;
            width: 120px;

            .c__dropdownItem {
                text-align: left;
                height: 2.125rem;
                padding: 8px 12px;
                border-bottom: none;
                color: #374151;
                

                &:hover {
                    background-color: #F0FDFA;
                }
            }

            .delete-item {
                color: $error-color;
            }
        }

        .contract-detail {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 40px);
        }

        .contract-detail-row {
            display: flex;
            width: 100%;
            gap: 8px;
            align-items: center;

            svg {
                width: 18px;
                height: 18px;
            }

            .working-type {
                background-color: #F9FAFB;
                padding: 4px 8px;
                width: fit-content;
                border-radius: 8px;

                &.active {
                    background-color: #CCFBF1;
                    color: #0D9488;
                }

                &.inactive {
                    background-color: #FFFBE6;
                    color: #D48806;
                }
            }
        }
    }

}