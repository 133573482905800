@charset "UTF-8";
@import "../../../../sass/utils/var";
@import "../../../../sass/utils/mixin";

/***
  * 팀원 > 휴가관리(우측카드), 모달
*/

/*---상단 헤더---*/
.p__vacation-management .vacation-chk-card .member-info-top {
	background-color: #f8f8f8;
	border-bottom: 1px solid $border-color;
	padding: 22px 30px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.member-info {
		display: flex;
		align-items: center;

		.profile {
			width: 70px;
			height: 70px;
			border: 1px solid $border-color;
			background: #d7d7d7;
		}

		.member-info-detail {
			margin-left: 8px;
			display: flex;
			flex-direction: column;
			gap: 4px;

			.name {
				color: #333;
				font-size: 1.25rem;
				line-height: 24px;
				margin-right: 4px;
				font-weight: 600;

				@media screen and (max-width: 1620px) {
					display: block;
				}
			}

			.position {
				color: #999;
				line-height: 20px;
				font-size: 0.875rem;
			}

			.sum-info {
				letter-spacing: -0.06em;
				color: $primary-color;
				margin-left: 12px;
				border-bottom: 1px solid $primary-color;
			}

			.email {
				color: #999;
				font-size: 0.875rem;
			}
		}
	}

	.vacation-info {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		gap: 20px;

		.sum-wrap {
			display: block;
		}

		.sum-info {
			display: flex;
			align-items: center;
			color: #999;
			letter-spacing: -0.06em;

			&:first-child {
				margin-bottom: 10px;
			}

			svg {
				width: 20px;
				margin-right: 6px;

				path {
					stroke: #0D9488;
				}
			}

			span {
				white-space: nowrap;
				margin-left: 6px;
				color: #374151;

				&.withdraw {
					color: $error-color;
				}
			}
		}
	}

	.btn-group {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.btn__solid_v2 {
			height: 36px;
			min-width: 90px;

			svg {
				width: 14px;
				height: 14px;
			}
		}
	}
}

/*---하단 내역---*/
// 연차현황 추가
.p__vacation-management .status-list .vacation-chk-wrap {
	height: 100%;

	.vacation-chk-card {
		height: calc(100% - 50px);
	}

	.vacation-chk-list {
		min-height: 525px;

		.row {
			display: flex;

			.vacation-tcol {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.user {
				white-space: normal;
				-webkit-line-clamp: 2; 
				-webkit-box-orient: vertical;
			}
		}
		
		.profile {
			margin: 0;
			padding: 0 0.5rem 0 0.75rem;
			width: calc(36px + 1.25rem);
		}

		.user {
			min-width: 130px;
			display: -webkit-box;
			max-width: 100%;
		}

		.company-info {
			min-width: 100px;
		}

		.date {
			min-width: 90px;

			span {
				font-size: inherit;
				font-weight: inherit;
				letter-spacing: inherit;
			}
			
			.date-short {
				display: none;
			}

			@media screen and (max-width: 1620px) and (min-width: 1320px) {
				.date-short {
					display: inline;
				}

				.date-long {
					display: none;
				}
			}
		}

		.term {
			min-width: 90px;
		}

		.status {
			width: 80px;
		}

		.approve {
			width: 142px;
			padding: 0 8px;

			.approve-list-btn {
				display: flex;
				gap: 6px;

				button {
					height: 32px;

					&:has(svg) {
						padding: 0 8px;
					}

					svg {
						width: 14px;
						height: 14px;
						margin: 0;
					}
				}

				.approve-short {
					padding: 0 6px;
					display: none;

					svg {
						fill: none;
						width: 18px;
						height: 18px;

						path {
							stroke: #042F2E;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1620px) and (min-width: 1320px) {
			.vacation-tcol {
				padding: 0 0.5rem;
			}
			
			.date {
				min-width: 85px;
			}

			.term {
				min-width: 85px;
			}
			
			.approve {
				width: 92px;

				.approve-list-btn {
					.approve-short { 
						display: block;
					}
		
					.approve-long {
						display: none;
					}
				}
			}
		}

		@media screen and (max-width: 870px) {
			.profile {
				width: calc(32px + 1.25rem);
				height: 32px;
			}

			.term {
				min-width: 80px;
			}

			.vacation-tcol {
				padding: 0 0.5rem;
			}

			.company-info {
				min-width: 90px;
			}

			.user {
				min-width: 110px;
			}

			.date {
				min-width: 80px;

				.date-short {
					display: inline;
				}

				.date-long {
					display: none;
				}
			}

			.approve {
				width: 92px;

				.approve-list-btn {
					.approve-short { 
						display: block;
					}
		
					.approve-long {
						display: none;
					}
				}
			}
		}

		.status-badge {
			width: fit-content;
			font-size: 14px;
			border-radius: 4px;
			padding: 3px 5px;
			display: inline-block;
			white-space: nowrap;

			&.confirm {
				background: #eafcf1;
				color: #148d45;
			}

			&.waiting {
				background: #f0f0f0;
				color: #333;
			}

			&.reject {
				background: #ffdbdb;
				color: #f44;
			}
		}
	}

	.vacation-chk-header {
		justify-content: flex-start;
	}

	.vacation-chk-info {
		justify-content: flex-start;
	}

	.total-status-badge {
		display: inline-block;
		min-width: 58px;
		padding: 8px 12px;
		border-radius: 8px;
		background: #F0FDFA;
		color: #111827;
		font-size: 14px;
		text-align: center;
		font-weight: 500;
	}
}

.p__vacation-management .vacation-chk-wrap {
	padding: 10px 30px 42px;

	.vacation-chk-tab {
		display: flex;
		align-items: flex-start;

		.btn-detail {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 10px;
			border-bottom: 1px solid #d7d7d7;
			padding-bottom: 8.5px;
		}
	}

	.vacation-chk-card {
		border-radius: 8px;
		margin-top: -10px;

		.vacation-chk-info {
			justify-content: flex-start;
		}

		.c__noData {
			height: 100%;
		}
	}
}

.p__vacation .vacation-chk-info,
.p__vacation-management .vacation-chk-info{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	.title {
		font-weight: 500;
		font-size: 1.125rem;
		line-height: 28px;
		color: #333;
		margin-right: 16px;
	}

	.total-tag {
		width: fit-content;
		font-weight: 500;
		font-size: 0.875rem;
		color: $primary-950;
		background: #f0fdfa;
		border-radius: 4px;
		padding: 8px 12px;
		border-radius: 8px;

		// &.used {
		// 	background-color: #f4f6f8;
		// 	color: #333;
		// }
	}

	.c__select {
		width: 120px;
		margin-left: auto;
		border-radius: 8px;

		.c__selection-item {
			span {
				font-size: 0.875rem;
			}
		}

		.c__select-dropdown-item {
			font-size: 0.875rem;
		}
	}

	.vacation-info {
		white-space: nowrap;
		display: flex;
		align-items: center;

		.sum-wrap {
			display: flex;
		}

		.sum-info {
			display: flex;
			align-items: center;
			color: #374151;
			letter-spacing: -0.06em;
			border: 1px solid #0D9488;
			padding: 8px 12px;
			border-radius: 8px;
			background-color: #F0FDFA;
			font-size: 1rem;
			font-weight: 500;
			line-height: 24px;

			&:first-child {
				margin-right: 16px;
			}

			svg {
				width: 20px;
				margin-right: 6px;
			}

			span {
				white-space: nowrap;
				margin-left: 3px;
				font-weight: 500;

				&.withdraw {
					color: $error-color;
				}
			}
		}
	}

	.vacation-header {
		display: flex;
		align-items: center;
	}
}

// 헤더
.p__vacation .vacation-chk-header,
.p__vacation-management .vacation-chk-header {
	height: 42px;
	border-bottom: 1px solid $border-color;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #E5E7EB;
	color: #374151;
	border-top-left-radius: 6px;
    border-top-right-radius: 6px;

	> div {
		font-weight: 500;
		font-size: 12px;
	}

	.vacation-name {
		&::before {
			display: none;
		}
	}

}

// 리스트
.p__vacation .vacation-chk-list,
.p__vacation-management .vacation-chk-list {
	min-height: 400px;
	position: relative;

	.row {
		height: 50px;
		// padding: 0 20px;
		display: flex;
		align-items: center;
		cursor: pointer;

		// @media screen and (max-width: 1700px) {
		// 	padding: 7px calc(10px + (20 - 10) * ((100vw - 768px) / (1700 - 768)));
		// }

		> div{
			font-size: 0.875rem;
			font-weight: 300;
			letter-spacing: -0.05em;

			&>span {
				font-size: 0.875rem;
			}
		}

		&:nth-child(2n-1) {
			background: rgba(248, 248, 248, 0.8);
		}
	}

	.c__noData {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.p__vacation-management .vacation-list-wrap {
	border: 1px solid #d7d7d7;
    border-radius: 8px;
}

// 간격
.p__vacation .vacation-chk-list,
.p__vacation-management .vacation-chk-list{
	.vacation-name {
		@include ellipsis;
		min-width: 140px;
		padding-right: 4px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.date {
		min-width: 166px;
		text-align: left;

		&.all {
			width: 10%;
			padding: 0 1rem;
			text-align: left;
		}
	}

	.time {
		text-align: left;
		margin-right: auto;
	}

	.permission span {
		width: fit-content;
		min-width: 60px;
		padding: 4px 8px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;

		&.wait {
			color: #999;
			background: #e8e8e8;
		}

		&.complete {
			color: #4a6ea6;
			background: #eff7ff;
		}

		&.reject {
			color: #f44;
			background: rgba(255, 68, 68, 0.1);
		}
	}

	.history {
		flex: 1;
	}

	.vacation-tcol {
		flex: 1;
		padding: 0 1rem;
	}

	@media screen and (max-width: 1620px) {
		.duration {
			display: none;
		}
	}

	@media screen and (max-width: 1620px) and (min-width: 1320px) {
		.vacation-tcol {
			padding: 0 0.75rem;
		}
	}
}

.p__vacation {
	@include headerWithTab;

	.pageHeader-btn-group {
		top: 36px;
	}
}

.p__vacation .c__card {
	padding: 20px 24px 24px;

	.vacation-list-wrap {
		border: 1px solid $border-color;
		border-radius: 8px;
	}

	&.all {
		.date {
			flex: 1;
		}

		.history {
			margin-left: 0;
		}
	}
}

//전체내역 리스트 & 모달
.p__vacation .vacation-chk-list .history,
.p__vacation-management .vacation-chk-list .history,
.vacation-manage-modal.all .vacation-state .history {
	color: #666;
	display: flex;
	align-items: center;

	svg {
		width: 15px;
		height: 15px;
		margin-right: 6px;
		fill: $error-color;
	}

	&.grant {
		color: #4a6ea6;
	}

	&.minus {
		color: $error-color;
	}

	&.plus {
		color: $primary-color;

		svg {
			fill: $primary-color;
		}
	}
}

/*------연차 부여 모달------*/
.annual-grant-form {
	.f__group {
		background: #f8f8f8;
	}

	.f__label {
		margin-right: 30px;
		font-size: 0.875rem;
	}

	.manage-type-wrap {
		padding: 15px 30px 10px;
	}

	.payday-wrap {
		padding: 0 30px;

		.f__control-wrap {
			display: flex;
			align-items: center;

			.f__control:first-child {
				margin-right: 6px;
			}
		}

		.f__feedback {
			display: none;
		}

		.unit {
			top: 50%;
			transform: translateY(-50%);
		}

		.c__input {
			padding-right: 40px;
		}
	}

	.desc {
		color: #999;
		font-size: 0.75rem;
		font-weight: 300;
		line-height: 17px;
		padding: 4px 30px 20px 112px;
		border-bottom: 1px solid $border-color;
		background: #f8f8f8;

		&.error {
			color: $error-color;
		}
	}

	//	메모
	.memo-wrap {
		padding: 10px 30px 20px;

		.f__label {
			margin-bottom: 10px;
		}

		.c__textarea {
			height: 100px;
			border-radius: 8px;
			font-size: 0.875rem;
			padding: 10px 12px;
		}
	}
}

/*------예정된휴가 & 지난휴가 리스트 클릭시 모달------*/
.vacation-manage-modal {
	.modal-header {
		border: none;
	}

	.modal-container {
		min-height: fit-content !important;
	}

	.modal-content {
		padding: 0 30px;
	}

	.modal-title {
		display: flex;
		align-items: center;

		.permission {
			min-width: 42px;
			height: 24px;
			line-height: 24px;
			border-radius: 4px;
			padding: 3px 5px;
			margin-left: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.875rem;

			&.wait {
				color: #999;
				background: #e8e8e8;
			}

			&.auto-approval {
				color: #5272a7;
				background: #e7f4fd;
			}

			&.reject {
				color: #f44;
				background: rgba(255, 68, 68, 0.1);
			}

			&.delete {
				color: #fff;
				background: #f44;
			}

			&.complete {
				color: #5272a7;
				background: #e7f4fd;
			}
		}
	}

	.vacation-state {
		min-height: 55px;
		color: #333;
		padding: 10px 16px;
		display: flex;
		align-items: center;
		border: 1px solid #D7D7D7;
		border-radius: 8px;
		margin-bottom: 4px;

		.time {
			margin-left: 20px;
			background-color: #F0FDFA;
			color: #111827;
			padding: 4px 8px;
			font-size: 0.875rem;
			font-weight: 400;
			border-radius: 8px;
		}
	}

	.modal-footer {
		border: none;
	}
}

/*------관리내역 클릭시 모달------*/
.vacation-manage-modal.all .f__control.history-info {
	margin-bottom: 10px;

	span {
		color: #333;
		font-weight: 300;

		&:not(:last-child)::after {
			content: "";
			width: 1px;
			height: 12px;
			background-color: #d7d7d7;
			display: inline-block;
			margin: 0 8px;
		}
	}
}

.vacation-manage-modal .vacation-manage-form {
	@include switchRadio;
	padding: 5px 0 0;

	.f__label {
		padding-bottom: 10px !important;
		gap: 8px;
	}

	// 파일제출 상태 배지
	&.c__file-upload-form .c__fileUploader {
		margin-bottom: 20px;
	}

	// 승인상태
	.auto-approval {
		font-weight: 300;
		line-height: 20px;
		color: #195ea9;
		margin-bottom: 10px;
	}

	.divided3 {
		padding-bottom: 10px;

		.c__radio-wrap {
			height: 40px;
		}

		.c__radio .checkmark {
			height: 34px;
		}
	}

	.c__textarea {
		min-height: 100px;
		border-radius: 8px;
		font-size: 0.875rem;
		padding: 10px 12px;
	}

	.status-badge {
		padding: 3px 5px;
		font-size: 0.875rem;
	}
}

/*------삭제하기 모달------*/
.manage-delete-modal {
	.alert-content {
		display: flex;
		align-items: center;
		padding: 25px 30px;

		.content-wrap {
			margin-left: 20px;

			h3 {
				color: #333;
				font-weight: 500;
				font-size: 1.125rem;
				line-height: 26px;
				letter-spacing: -0.06em;
			}

			.warning-phrase {
				color: #f44;
				line-height: 20px;
				letter-spacing: -0.06em;
			}
		}
	}
}

/*------연차 상세 현황 모달------*/
.vacation-detail-modal {
	.modal-container {
		max-width: 1020px;
		width: calc(
			calc(768px + (1020 - 768) * ((100vw - 768px) / (1040 - 768))) - 40px
		) !important;
	}

	.modal-header {
		padding: 0 30px 0;
		border-bottom: none;
		margin-bottom: 15px;

		.modal-title {
			font-weight: 500;

			.year-tag {
				background: #333;
				color: #fff;
				height: 30px;
				padding: 8px 11px;
				border-radius: 8px;
				position: relative;
				margin-left: 13px;

				&:before {
					content: "";
					position: absolute;
					left: -6px;
					top: 50%;
					transform: translateY(-50%);
					border-width: 5px 7px 5px 0;
					border-color: transparent #333;
					border-style: solid;
				}

				.join {
					color: #76ebff;
				}

				.accounting {
					color: #fcc609;
				}
			}
		}

		.year-select {
			display: flex;

			.c__select {
				width: 120px;
				margin-right: 10px;
				border-radius: 8px;
			}

			.btn-wrap {
				display: flex;
				align-items: center;
			}

			.prev-btn,
			.next-btn {
				width: 35px;
				height: 35px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $border-color;
				border-radius: 2px;
				transition: 0.3s;

				svg {
					fill: #d7d7d7;
				}

				&:hover {
					border-color: $primary-color;

					svg {
						fill: $primary-color;
					}
				}
			}

			.next-btn {
				transform: rotate(180deg);
				margin-left: 10px;
			}
		}
	}

	.modal-content {
		padding: 0 30px;

		// 자동부여 컬러
		.grant:not(.th) {
			color: #58c1b5 !important;
		}

		// 이월연차 컬러
		.forward:not(.th) {
			color: #ffa200 !important;
		}

		// 자동소멸 컬러
		.disappear:not(.th) {
			color: $error-color !important;
		}

		.top {
			margin-bottom: 30px;

			.vacation-list {
				display: flex;
				justify-content: space-between;
			}

			.vacation-item {
				height: 85px;
				width: calc(100% / 5);
				padding: 0 20px;

				&:not(:last-child) {
					border-right: 1px solid #d7d7d7;
					margin-right: 10px;
				}

				.title {
					font-size: 1rem;
					font-weight: 300;
					letter-spacing: -0.06em;
					color: #666;
					margin-bottom: 9.5px;
				}

				.day {
					font-size: 0.875rem;
					font-weight: 500;
					letter-spacing: -0.06em;
					color: #999;
					text-align: right;

					b {
						font-family: "Poppins", sans-serif;
						font-size: 3rem;
						font-weight: 200;
					}
				}
			}
		}

		.bottom {
			margin-bottom: 8px;

			.date {
				min-width: 248px;
				flex: 1;

				> span {
					border-radius: 4px;
					padding: 3px 10px;
					margin-left: 8px;
					font-size: 15px;
				}

				.join-month {
					background: $primary-color;
					color: white;
				}

				.this-month {
					background: #eff7ff;
					color: $primary-color;
				}
			}

			.tr {

			}

			.tbody {
				@include customScroll(scroll-y);
				max-height: 306px;

				.remain {
					padding-right: 12px;
				}
			}

			.th {
				font-weight: 500;
				text-align: left;
				font-size: 13px;
				text-transform: uppercase;
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&.adjustment svg {
					fill: #666;
					margin-left: 5px;
				}
			}

			.td {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&.use {
					color: #999;
				}

				&.remain {
					color: #333;
				}

				&.adjustment {
					overflow: visible;
					width: 120px;
					min-width: 90px;

					svg {
						fill: $primary-color;
					}
				}
			}

			.adjustment {
				flex: none;
				justify-content: flex-end;
			}

			.use {
				min-width: 80px;
				max-width: 96px;
			}

			.remain {
				max-width: 104px;
			}

			.th:not(.date):not(.adjustment),
			.td:not(.date):not(.adjustment) {
				min-width: 96px;
				text-align: center;
			}

			.td:not(.date):not(.adjustment) {
				font-weight: 400;
			}

			.btn-adjustment.active {
				svg {
					transform: rotate(180deg);
				}

				& + .adjustment-list {
					animation-name: fadeIn;
					animation-duration: 0.3s;
				}
			}

			// 조정 하기 dropdown
			.adjustment {
				position: relative;
				width: 130px;
				min-width: 100px;
				display: flex;
				align-items: center;

				.adjustment-list {
					transition: 0.3s;
					border-radius: 12px;
					border: 1px solid $border-color;
					background-color: #fff;
					position: absolute;
					top: calc(100% - 5px);
					right: 0;
					z-index: 10;

					.btn-add {
						color: $primary-color;
						font-weight: 500;

						svg {
							margin-right: 8px;
						}
					}
				}

				.adjustment-item {
					@include customScroll(scroll-y);
					padding: 12px 16px;
					text-align: center;
					line-height: 20px;
					font-size: 0.875rem;

					&:not(:last-child) {
						min-height: 42px;
						max-height: 158px;
						border-bottom: 1px solid $border-color;
					}

					.c__loader {
						padding: 0;
					}

					.btn-add {
						display: flex;
						align-items: center;
					}

					.title {
						font-weight: 300;
						font-size: 0.75rem;
						color: #666;
						letter-spacing: 0;
						line-height: 17px;
					}

					.date-info {
						margin-top: 12px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.tag {
							color: $primary-color;
							height: 20px;
							line-height: 20px;
							font-size: 0.75rem;
							padding: 0 8px;
							background: #e0eef9;
							border-radius: 10px;
						}

						.num {
							font-weight: 500;
							font-size: 1rem;
							color: #333;
						}
					}
				}
			}

			.desc {
				margin-top: 8px;
				color: #999;
				line-height: 20px;
				font-size: 0.875rem;
			}
		}
	}

	.modal-footer {
		border: none;
	}
}

/*------연차 상세 현황 > 연차 조정하기 모달 ------*/
.vacation-modify-modal {
	.modal-header {
		padding: 0 5px 0 20px;
	}

	.modal-title {
		font-weight: 400;
	}

	.modal-content {
		padding: 0 20px;
	}

	.top {
		display: flex;
		align-items: center;
		padding: 4px 0 18px;
		border-bottom: 1px solid #d9d9d9;

		.img-wrap {
			width: 40px;
			height: 40px;
			margin-right: 8px;
			flex-shrink: 0;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.profile-wrap {
			.name {
				color: #333;
				line-height: 20px;
			}

			.team {
				line-height: 20px;
				font-size: 0.75rem;
				color: #999;
				margin-left: 4px;
			}

			.email {
				line-height: 14px;
				font-size: 0.75rem;
				color: #999;
				margin-top: 2px;
			}
		}
	}

	.bottom {
		padding: 15px 0 20px;

		.f__group {
			&:first-child {
				min-width: 125px;
				margin: 0 10px 15px 0;
			}

			&:last-child {
				width: 100%;
			}
		}

		.f__label {
			font-weight: 500;
			margin-bottom: 8px;
		}

		.c__textarea {
			color: #333;
			min-height: 80px;
		}
	}

	.vacation-manage-form {
		display: flex;
		flex-flow: wrap;

		.f__group {
			width: 100%;

			&:nth-child(-n + 2) {
				width: calc(50% - 5px);
			}
		}

		.f__control-wrap {
			display: flex;

			.f__control {
				&:not(:last-child) {
					margin-right: 10px;
				}

				&:first-child {
					width: 60px;

					.c__select {
						border-radius: 2px 0 0 2px;

						&:not(.disabled) {
							&:hover {
								border-color: #d7d7d7;
							}

							&.open {
								border-color: #d7d7d7;
								box-shadow: 0 0 0 1px rgba(#d7d7d7, 0.2);
							}
						}
					}
				}

				&:last-child {
					width: 100px;
					margin-left: -1px;
				}

				&:has(.unit) {
					.c__input {
						padding-right: 40px;
					}

					&:last-child {
						.c__input {
							padding-right: 50px;
						}
					}
				}
			}
		}

		.payday-wrap {
			margin-bottom: 15px;
		}

	}

	.desc {
		color: #999;
		font-size: 12px;
		font-weight: 300;
		margin-bottom: 15px;
	}

	.modal-footer {
		border-top: 0;

		.btn__solid {
			margin: 0 0 20px;
		}
	}
}
