@charset "UTF-8";
/*layout/layout.scss*/

.row {
	display: flex;
	align-items: center;
}

.column {
	display: flex;
	flex-flow: column;
}

a {
	text-decoration: none;
	color: inherit;
}

.wrapper {
	width: 1200px;
	margin: 0 auto;
	padding: 0 20px;

	@media screen and (max-width: 1216px) {
		width: calc(304px + (1200 - 304) * ((100vw - 320px) / (1216 - 320)));
	}
}
