@charset "UTF-8";
@import "src/assets/sass/utils/var";

.quill {
	border: 1px solid #d7d7d7;

	.ql-toolbar {
		border: {
			top: none !important;
			left: none !important;
			right: none !important;
		}
	}

	.ql-container {
		border: none !important;
	}

	&:not(:disabled):not(.ql-disabled):not(:read-only):hover,
	&:not(:disabled):not(.ql-disabled):not(:read-only):focus {
		border-color: rgba($primary-color, 0.6) !important;
		box-shadow: 0 0 0 2px rgba($primary-color, 0.2) !important;
		transition: 0.3s;
	}

	.ql-snow {
		border-bottom-color: #d7d7d7;
	}

	.ql-stroke {
		stroke: #333;
	}

	.ql-snow .ql-fill {
		fill: #333;
	}

	$border-style: stroke, fill;

	@each $type in $border-style {
		.ql-formats button {
			&:hover,
			&.ql-active {
				.ql-#{$type} {
					transition: 0.3s;
					#{$type}: $primary-color;
				}
			}
		}
	}

	.ql-disabled {
		background: #f8f8f8;
		cursor: not-allowed;

		.ql-editor {
			color: #b2b2b2;

			p {
				cursor: not-allowed;
			}

			img {
				opacity: 0.3;
				cursor: not-allowed;
			}
		}
	}

	.ql-editor {
		//height: 378px;
		font-weight: 300;
		line-height: 1.5;
		color: #333;

		&.ql-blank::before {
			color: #868686;
			font-style: inherit;
		}
	}

	.has-error .quill:not(:hover):not(:focus) {
		border-color: $error-color;
		box-shadow: 0 0 0 2px rgba($error-color, 0.2);
	}
}
