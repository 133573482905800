@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * TabMenu
*/

.c__tabMenu {
	display: inline-flex;
	align-items: center;
	width: 100%;
	margin-bottom: 30px;
	border-bottom: 1px solid $border-color;

	&Item {
		line-height: 1.5;
		font-size: 0.875rem;
		font-weight: 300;
		color: #333;
		letter-spacing: -0.03em;
		padding: 10px 3px;
		transition: color 0.3s ease-in-out;
		position: relative;
		margin-bottom: 2.5px;

		&:first-child:after {
			width: 100%;
			left: 0;
		}

		&:not(:last-child) {
			margin-right: 20px;
		}

		&:not(.disabled):hover,
		&.active {
			color: $primary-color;
			cursor: pointer;
		}

		&::after {
			width: 100%;
			height: 2px;
			position: absolute;
			left: 0;
			bottom: -3px;
			background-color: $primary-color;
		}

		&.active:after {
			content: "";
		}

		&.disabled {
			color: rgba(0, 0, 0, 0.25);
			cursor: not-allowed;

			&:after {
				display: none;
			}
		}
	}
}
