@charset "UTF-8";
@import "../../../assets/sass/utils/var";

/***
  * 태그
*/

.c__tag-wrap {
	padding-top: 4px !important;
	padding-bottom: 4px !important;

	.f__control,
	ul {
		flex-flow: wrap;
	}
}

.c__tag-edit,
.c__tag-view,
.c__tag-add-button,
.c__tag-add-input {
	width: fit-content;
	min-width: 50px;
	height: 30px;
	margin: 3px 6px 3px 0;
	border-radius: 2px;
	border: 1px solid #d7d7d7;
	display: flex;
	justify-content: center;
	align-items: center;

	&:last-child {
		margin-right: 0;
	}
}

.c__tag-wrap .c__tag-view {
	padding: 0 10px;
	background: #fafafa;
}

.c__tag-wrap .c__tag-edit {
	padding: 0 6px 0 10px;
	background: #fafafa;

	// close 아이콘
	&-remove {
		margin-left: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: 0.3s;

		svg {
			width: 9px;
			height: 9px;
			fill: #999;
		}
	}
}

.c__tag-add {
	// 추가 버튼
	&-button {
		color: #999;
		width: 74px;
		padding: 0 14px;
		border: 1px dashed #999;
		transition: 0.3s;

		&:hover {
			cursor: text;
			border-color: $primary-color;
			color: $primary-color;
		}
	}

	// 추가 인풋 영역
	&-input {
		padding: 0 10px;
		border: 1px solid $primary-color;
		transition: 0.3s;

		> input {
			height: 28px;
			color: #333;
			background: #fff;
		}
	}
}
