@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 마이페이지 > 플러그인 > 상세 모달
*/

.plugin-detail-modal .modal-container {
	@media screen and (max-width: 1200px) {
		width: calc(100vw - 100px) !important;
	}
}

.plugin-detail-modal {
	.plugin-banner {
		width: 100%;
		height: 0;
		padding-top: calc((290 / 1000) * 100%);
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			object-fit: cover;
		}
	}

	.detail-container {
		flex: 1;
		display: flex;
	}

	.c__tabMenu {
		margin-bottom: 15px;

		@media screen and (max-width: 1200px) {
			margin-bottom: 20px;
		}

		&Item {
			font-weight: 500;
			letter-spacing: -0.06em;
		}
	}

	//설명 탭
	.desc-wrap {
		padding-top: 15px;

		dl:not(:last-child) {
			margin-bottom: 30px;
			padding-right: 16px;

			@media screen and (max-width: 1200px) {
				margin-bottom: 20px;
			}
		}

		dt {
			font-size: 1.25rem;
			font-weight: 500;
			line-height: 1;
			letter-spacing: -0.06em;
			margin-bottom: 14px;

			@media screen and (max-width: 1200px) {
				font-size: calc(18px + (20 - 18) * ((100vw - 768px) / (1200 - 768)));
				margin-bottom: 10px;
			}
		}

		dd {
			color: #666;
			line-height: 1.71;
			letter-spacing: -0.35px;
			margin-left: 4px;

			@media screen and (max-width: 1200px) {
				font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1200 - 768)));
			}

			&.diff {
				color: #666;
				text-indent: -0.6em;
				margin-left: 0.6em;
			}
		}
	}
}

.plugin-detail-modal .detail-container .tab-section {
	flex: 1;
	padding: 30px 30px 20px;

	@media screen and (max-width: 1200px) {
		padding: 20px;
	}

	> div:not(.c__noData) {
		@include customScroll(scroll-y);
		max-height: 310px;

		&::-webkit-scrollbar {
			width: 8px;
		}
	}
}

.plugin-detail-modal .detail-container .summary-section {
	width: 260px;
	padding: 30px 40px 60px;
	border-bottom-right-radius: 13px;
	background-color: #f8f8f8;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-shrink: 0;

	@media screen and (max-width: 1200px) {
		width: 220px;
		padding: 20px 30px 40px;
	}

	.img-wrap {
		width: 140px;
		height: 140px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.info-wrap {
		width: 100%;
	}

	.plugin-status {
		font-family: "Poppins", sans-serif;
		width: 100%;
		height: 40px;
		margin: 30px 0 18px;
	}

	.plugin-summary {
		width: 100%;
		padding-left: 8px;
		letter-spacing: -0.06em;
		color: #333;

		> div {
			display: flex;

			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}

		dt {
			font-weight: 500;
			margin-right: 5px;
		}

		dd {
			font-weight: 300;

			&.active {
				color: #58a605;
			}
		}
	}
}
