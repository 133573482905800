@charset "UTF-8";
@import "src/assets/sass/utils/mixin";

/***
  * 사이드바
*/

$menu-collapse-time: 300ms;
$main-menu-width: 120px;
$sub-menu-width: 230px;

.l__sidebar {
	width: $main-menu-width + $sub-menu-width;
	display: flex;
	flex-shrink: 0;
	position: relative;
	transition: width 0.3s ease-in-out;
	background: #fff;
}

.menu-button {
	width: $main-menu-width;
 	margin-left: 20px;
	height: 80px;
	position: fixed;
	top: 0;
	z-index: 999;

	.mobile {
		display: none;
	}
}

.l__mainMenu {
	width: $main-menu-width;
	//transition: border-radius 0.5s, transform 0.3s;
	//box-shadow: 5px 0 6px 0 rgba(0, 0, 0, 0.05);
	z-index: 99;
	background: #fff;
	position: absolute;
	transition: 0.3s ease-in-out;
	height: 100%;
	left: 0;

	li {
		position: relative;
		color: #212121;

		button {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 100%;
			height: 110px;
			font-size: 0.8125rem;
			line-height: 1.5em;
			letter-spacing: -0.05em;
			border-bottom: 1px solid #f0f0f0;
			cursor: pointer;
			transition: 0.3s ease-in-out;
			color: currentColor;

			svg {
				margin: 10px 0 5px 0;
				fill: currentColor;
			}
		}

		&:hover {
			color: $primary-color;
		}

		&.active {
			color: $primary-color;

			&:after {
				content: " ";
				// background: #00a4ff;
				background: $primary-color;
				border-radius: 10px;
				position: absolute;
				width: 6px;
				height: 90px;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
			}
		}
	}

	//마이페이지 only
	.logo-wrap {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 120px;
		border-bottom: 1px solid #f0f0f0;

		.logo {
			display: inline-block;
			width: 60px;
			height: 60px;
			border: 1px solid #ddd;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			overflow: hidden;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.l__subMenu {
	width: $sub-menu-width;
	left: $main-menu-width;
	z-index: 95;
	border-left: 1px solid #f0f0f0;
	//transition: transform 0.3s;
	border-radius: 0 13px 13px 0;
	box-shadow: 5px 0 6px 0 rgba(0, 0, 0, 0.05);
	background: #fff;
	padding: 26px 0;
	position: absolute;
	transition: 0.3s ease-in-out;
	height: 100%;

	//background: red !important;

	//----------
	//box-shadow: 5px 0 6px 0 rgba(blue, 0.05);
	//border: 1px solid rgba(red, 0.2);

	li {
		width: 100%;
		height: 35px;
		line-height: 35px;
		padding-left: 28px;
		font-size: 0.8125rem;
		margin-bottom: 12px;
		position: relative;
		transition: color 0.3s ease-in-out;

		&.disabled {
			color: #a7a7a7;
			cursor: not-allowed;
		}

		&:not(.disabled):hover {
			color: $primary-color;
			cursor: pointer;
		}

		&.active {
			color: $primary-color;

			&:after {
				content: " ";
				background: #0D9488;
				border-radius: 10px;
				width: 4px;
				height: 4px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 18px;
			}
		}
	}

	//마이페이지 only
	.company-info {
		margin-top: -26px;
		margin-bottom: 22px;
		width: 100%;
		height: 120px;
		background: linear-gradient(90deg, #fafafa, #fff);
		padding-top: 34px;
		padding-left: 28px;
		border-top-right-radius: 13px;
		overflow: hidden;

		.company-name {
			padding-bottom: 4px;
			font-size: 1rem;
			font-weight: 400;
			letter-spacing: -0.025em;
			line-height: 1;
			color: #333;
		}

		.account-role {
			font-size: 0.75rem;
			font-weight: 300;
			line-height: 1;
			color: #999;
			padding-bottom: 2px;
		}

		.license {
			display: inline-flex;
			align-items: center;
			line-height: 1;

			dt {
				font-size: 0.75rem;
				font-weight: 400;
				line-height: 1;
				color: #999;

				&:after {
					content: "|";
					padding: 0 7px;
					vertical-align: top;
					font-size: 9px;
					line-height: 1;
					color: #e2e2e2;
				}
			}

			dd {
				font-size: 0.75rem;
				font-weight: 700;
				line-height: 1;
				color: $primary-color;
			}
		}
	}
}

$menuHidden: #999;
$menuActive: #424242;

.menu-button > svg {
	fill: $menuActive;
}

//동적 사이드바 기본
//2단 히든
.subMenu-hidden {
	width: $main-menu-width;

	&:not(.subMenu-float) .l__mainMenu {
		border-radius: 0 13px 13px 0;
	}

	.l__subMenu {
		left: -($sub-menu-width - $main-menu-width);
	}

	.menu-button > svg.sub {
		fill: $menuHidden;
	}
}

//2단 컨턴츠 위로 뜨기
.subMenu-float {
	width: $main-menu-width;

	.l__subMenu {
		left: $main-menu-width !important;
	}
}

.mainMenu-float {
	width: 0;

	.l__subMenu {
		left: -($sub-menu-width - $main-menu-width);
	}

	&:not(.subMenu-float) .l__mainMenu {
		border-radius: 0 13px 13px 0;
	}
}

//1단 히든
.sidebar-hidden {
	width: 0;

	.l__mainMenu {
		left: -($main-menu-width);
	}

	.l__subMenu {
		left: -($sub-menu-width);
		box-shadow: none;
	}

	.menu-button > svg {
		fill: $menuHidden;
	}
}

//반응형
@media screen and (max-width: 1440px) and (min-width: 768px) {
	.l__sidebar:not(.sidebar-hidden) {
		width: $main-menu-width;
	}
}

@media screen and (max-width: 767px) {
	.l__sidebar {
		width: 0;
	}

	//.menu-button{
	//	width: 65px;
	//}

	.menu-button .main,
	.menu-button .sub {
		display: none;
	}

	.menu-button .mobile {
		display: inline-block;
		fill: #424242;
	}
}
