@charset "UTF-8";
@import "../../../../sass/utils/var";
@import "../../../../sass/utils/mixin";

/**
  * 커뮤니티 > 내가쓴글
**/

.p__community-myPage {
  .community-post-section {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  }

  //---- 내가쓴글 내 정보
  .member-info {
    display: flex;
    align-items: center;
    background: #fafafa;
    border-bottom: 1px solid $border-color;
    padding: 16px 40px;
    border-radius: 12px 12px 0 0;

    .profile-img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 1px solid #e4e4e4;
      background: #e4e4e4;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .profile-info {
      margin-left: 16px;

      .profile-name {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 16px;
        color: #333;
        margin-right: 4px;
      }

      .profile-email {
        font-family: "Poppins", sans-serif;
        color: #999;
        font-weight: 300;
        font-size: 1.125rem;
        line-height: 14px;
      }

      .state-info {
        display: flex;
        align-items: center;
        margin-top: 16px;
      }

      .state,
      .state-num {
        font-weight: 300;
        font-size: 0.875rem;
        line-height: 14px;
        letter-spacing: -0.06em;
      }

      .state {
        color: #666;
        margin-right: 8px;
      }

      .state-num {
        color: $primary-color;
      }

      .btn-more {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 14px;
        letter-spacing: -0.06em;
        color: #333;
        padding-left: 8px;
        border-left: 1px solid #d7d7d7;

        svg {
          width: 10px;
          fill: #333;
          transform: rotate(-90deg);
        }
      }
    }
  }

  //---- 내가쓴글 카드
  .community-post-wrap {
    padding: 20px 40px 40px;

    .community-post-card {
      border: 1px solid $border-color;
      border-radius: 8px;
    }

    .c__tabMenu {
      margin-bottom: 19px;
    }
  }

  // 전체내역 헤더
  .community-post-header {
    height: 55px;
    padding: 0 20px;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .post-title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 28px;
      color: #333;
      margin-right: 8px;
    }

    .c__search {
      width: 240px;

      .c__input {
        border-radius: 50px;
      }
    }
  }

  // 내가쓴글 리스트
  .community-post-list {
    height: 100%;
    min-height: 500px;
    //min-height: calc(
    //	100vh - 100px - 70px - 40px - 27px - 52px - 30px - 113px - 45px - 80px - 55px - 64px
    //);
    position: relative;

    .row {
      height: 50px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #333;
      font-weight: 300;
      font-size: 1rem;
      letter-spacing: -0.06em;

      @media screen and (max-width: 1700px) {
        padding: 7px calc(10px + (20 - 10) * ((100vw - 768px) / (1700 - 768)));
      }

      > div {
        color: #333;
        font-weight: 300;
        line-height: 23px;
        font-size: 1rem;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      &:nth-child(2n-1) {
        background: rgba(248, 248, 248, 0.8);
      }
    }

    .c__noData {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .title {
      @include ellipsis;
      min-width: 280px;
      flex: 1;
    }

    .title-tag {
      font-size: 1rem;
      font-weight: 500;
      margin-right: 4px;

      &.active {
        color: $primary-color;
      }
    }

    .name,
    .date {
      width: 160px;
    }

    .date {
      text-align: center;
    }

    .position {
      color: #999;
    }
  }

  // 내가쓴글 테이블
  .community-post-table {
    .community-post-list {
      height: 100%;
      min-height: 500px;
      position: relative;
    }

    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 16px;

      .table-title {
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }
    }

    .th {
      color: #333;
      font-size: 16px;
      font-weight: 500;

      &.content {
        justify-content: center;
      }
    }

    .td {
      @include ellipsis;
      align-self: center;
      height: fit-content;
      font-size: 16px;
      color: #333;

      b {
        font-size: inherit;
        color: $primary-color;
      }
    }


    // 간격
    .title,
    .name,
    .write {
      width: 300px;
    }

    .num, .date {
      width: 180px;
      text-align: center;
    }

    .title {
      flex: 1;
    }

    .content {
      flex: 1;
      display: flex;
      align-items: center;

      .content-text {
        @include ellipsis;
        font-size: 16px;
        color: #333;
        font-weight: 300;
      }

      .img-wrap {
        min-width: 74px;
        height: 74px;
        border: 1px solid #d7d7d7;
        margin: 14px 16px 14px 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .pagination {
      border-top: 0;
    }
  }
}
