@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 팀원 > 설정 > 휴가설정
*/

//----- view
.p__vacation .vacation-card,
.p__hr-setting .vacation-card {
	max-width: 1075px;
	padding: 33px 30px 40px;

	.sub-title {
		color: #333;
		font-weight: 700;
		font-size: 1.125rem;
		line-height: 26px;
		margin: 30px 0 25px 0;
	}
}

// 전체 연차 휴가 설정
.p__vacation .vacation-card .vacation-setting-section,
.p__hr-setting .vacation-card .vacation-setting-section {
	.content-wrap {
		margin-bottom: 30px;
	}

	.content-title {
		color: #666;
		font-size: 1rem;
		font-weight: 400;
		line-height: 23px;
		margin-bottom: 10px;
	}

	.content {
		color: #9e9e9e;
		font-size: 0.75rem;
		line-height: 17px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;

		@media screen and (max-width: 890px) {
			flex-direction: column;
			align-items: flex-start;
		}

		svg {
			width: 12px;
			height: 12px;
			fill: #6e757d;
			margin-right: 4px;
		}

		.btn-more {
			color: $primary-color;
			font-size: 0.75rem;
			line-height: 17px;
			border-bottom: 1px solid $primary-color;
			margin-left: 6px;

			@media screen and (max-width: 890px) {
				margin-left: 0;
			}
		}
	}

	.radio-wrap {
		display: flex;
		align-items: center;
		width: 320px;
		height: 35px;
		border: 1px solid $primary-color;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;

		li {
			height: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: #333;

			&:hover {
				cursor: pointer;
			}

			&:not(:last-child) {
				border-right: 1px solid $primary-color;
			}

			&.active {
				background: $primary-color;
				color: #fff;
			}
		}
	}

	.vacationPolicy .radio {
		width: calc(100% / 2);
	}

	.unit .radio {
		width: calc(100% / 3);
	}

	// 스위치
	.switch-group .row {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		.switch-label {
			color: #333;
			font-weight: 500;
			line-height: 20px;
			margin-right: 20px;
		}
	}

	.alarm {
		cursor: not-allowed;
	}
}

// 회사 휴가 목록
.p__vacation .list-header,
.p__hr-setting .list-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 2px solid #000;
	padding-bottom: 10px;

	.list-title {
		display: flex;
		align-items: center;

		.sub-title {
			margin: 0 12px 0 0;
		}

		.filter-route {
			display: flex;
			align-items: center;

			li {
				color: #999;
				padding: 0 10px;
				cursor: pointer;

				&:not(:last-child) {
					border-right: 2px solid $border-color;
				}

				&.active {
					color: $primary-color;
				}
			}
		}
	}

	.btn-group button {
		width: 80px;
		height: 30px;
		font-size: 0.75rem;
		font-weight: 700;
		line-height: 17px;
		border-radius: 4px;

		&:first-child {
			margin-right: 4px;
		}

		svg {
			width: 10px;
			height: 10px;
			margin-right: 4px;
		}
	}
}

.p__vacation .vacation-list,
.p__hr-setting .vacation-list {
	&-row {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		border-bottom: 1px solid $border-color;
		cursor: pointer;
	}

	.row-name-wrap {
		color: #333;
		display: flex;
		align-items: center;
		padding-left: 12px;

		.vacation-name {
			font-weight: 500;
			line-height: 20px;
			margin-right: 4px;
		}
	}

	.detail-status-wrap {
		min-width: 251px;
		margin-right: 10px;
	}

	.alarm-status {
		margin-right: 15px;
	}
}

// 무급
.unpaid-badge {
	width: fit-content;
	min-width: 35px;
	height: 20px;
	color: #424242;
	font-size: 0.75rem;
	line-height: 17px;
	background-color: #e8e8e8;
	border-radius: 4px;
	margin-left: 4px;
	padding: 0 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

// 휴가 태그
.detail-status-wrap {
	display: flex;
	align-items: center;

	> span {
		height: 20px;
		font-size: 0.75rem;
		border-radius: 10px;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&:not(:last-child) {
			margin-right: 6px;
		}
	}

	// 디테일 상태
	.alarm-status svg {
		fill: #999;
	}

	.alarm-status.active svg {
		fill: $primary-color;
	}

	.day-status {
		min-width: 50px;
		color: #fff;
		background-color: $primary-color;
	}

	.public-holiday {
		color: #f7377b;
		background-color: #fce4ec;
	}

	.contract-holiday {
		min-width: 57px;
		color: #4caf50;
		background-color: #e8f5e9;
	}

	.auto-approval-off {
		color: #545454;
		background-color: #f8f8f8;
	}

	.auto-approval-on {
		color: #195ea9;
		background-color: #e0eef9;
	}

	.public-holiday,
	.auto-approval-off,
	.auto-approval-on {
		min-width: 80px;
	}
}

/*---추가하기 모달, 회사 휴가목록 클릭 시 모달---*/
.vacation-add-modal .modal-content,
.vacation-list-modal .modal-content {
	@include switchRadio;
	padding: 30px;

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}

	.f__group.required {
		align-items: flex-start;
	}

	.f__label {
		width: 113px;
		font-weight: 500;
		line-height: 20px;
		margin-right: 61px !important;
	}

	.desc {
		color: #999;
		font-weight: 300;
		font-size: 0.75rem;
		line-height: 17px;
		margin: -6px 0 20px 174px;

		span {
			color: inherit;
			font-weight: inherit;
			font-size: inherit;
			margin-left: 14px;
		}
	}

	// 지급대상 체크박스
	.checkbox {
		display: inline-flex;
		align-items: center;
		margin-right: 0;

		.checkbox-custom {
			margin-right: 20px;
		}

		.checkmark {
			margin-right: 12px;
		}

		.checkbox-label {
			font-weight: 400;
			font-size: 0.875rem;
		}
	}
}

.vacation-add-modal .c__form .f__group,
.vacation-list-modal .c__form .f__group {
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

/*---일괄설정 모달---*/
.vacation-blanket-modal .modal-content {
	@include customScroll(scroll-y);

	height: 506px;
	padding-left: 20px;

	.btn-vacation-policy {
		width: 108px;
		height: 30px;
		font-weight: 700;
		font-size: 0.75rem;
		border-radius: 4px;
		margin: 30px 30px 20px 423px;

		svg {
			width: 10px;
			height: 10px;
			margin-right: 4px;
		}
	}

	.policy-card-wrap {
		min-height: calc(100% - 80px);

		.c__noData {
			height: 346px;
		}
	}

	.policy-card {
		display: inline-block;
		width: 250px;
		height: 84px;
		border: 1px solid $border-color;
		border-radius: 4px;
		box-shadow: none;
		margin: 0 10px 20px;
		overflow: hidden;
		cursor: pointer;
	}

	.policy-card .card-top {
		height: 50px;
		padding: 13px 6px 13px 12px;
		border-bottom: 1px solid $border-color;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.switch-label {
			color: #333;
			font-size: 1rem;
			font-weight: 400;
			display: flex;
			align-items: center;
		}

		.control-wrap {
			display: flex;
			align-items: center;

			.switch-wrap {
				margin-right: 5px;
			}
		}

		svg {
			fill: #999;
		}
	}

	.policy-card .card-bottom {
		height: 34px;
		display: flex;
		align-items: center;
		padding: 0 12px;
		background-color: #fafafa;

		> span {
			font-size: 0.625rem;
		}

		// 넓이설정
		.day-status {
			min-width: 39px;
		}

		.public-holiday,
		.auto-approval-off,
		.auto-approval-on {
			min-width: 74px;
		}

		.auto-approval-off {
			background-color: #e8e8e8;
		}
	}

	.btn-delete {
		height: 25px;
		padding: 3px 6px 0;
		transition: 0.3s ease-in-out;

		&:hover svg {
			fill: $primary-color;
		}
	}
}

/*---연차 부여일 기준 모달---*/
.annual-leave-modal .modal-content {
	padding: 10px 30px 30px;

	.desc {
		font-weight: 300;
		line-height: 24px;
		margin: -10px 0 20px;

		b {
			color: $primary-color;
			border-bottom: 1px solid $primary-color;
		}
	}

	.standard-wrap {
		border: 1px solid $primary-color;
		background: #eef9ff;
		border-radius: 4px;
		padding: 20px;
		color: #333;
		font-weight: 300;
		font-size: 0.75rem;
		line-height: 18px;
	}
}

/*---alert 모달---*/
// 휴가 삭제 모달
.vacation-delete-modal .desc-box {
	width: 350px;
	padding: 20px;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: -0.06em;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}
