@charset "UTF-8";
@import "src/assets/sass/utils/var";

$correctColor: #369303;

//팀원 정보 모달 > 기본정보 탭 > 인사정보, 개인정보 수정 공통

//----공통
.title {
	margin-bottom: 18px !important;
}

.f__group {
	width: 100%;
	transition: padding-bottom 0.25s ease-in;
	gap: 10px;
	flex-direction: column;

	&:not(:last-of-type):not(.has-error) {
		padding-bottom: 16px;
	}

	.f__label {
		font-size: 0.875rem;
		color: #666;
	}

	.f__control, .f__control-wrap {
		width: 100%;
	}
	
	.f__control, .c__selection-item, .c__select-dropdown, .c__selection-item > span{
		font-size: 14px;
	}

	.f__control {
		.f__item, .c__input, .react-datepicker__input-container::after, .btn__postcode{
			height: 40px;
			border-radius: 8px;
		}
	}
}