@charset "UTF-8";
@import "src/assets/sass/utils/var";

.p__login {
	@import "src/assets/sass/pages/auth/common/authForm";
}

/***
  * 로그인
*/
.p__login {
	height: 100vh;
}

.p__login .l__onePageContainer {
	display: grid;
	grid-template-columns: repeat(12, [col-start] 1fr);
	gap: 1.25%;
	padding: 0 1.25%;
}

.p__login .content-side {
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2.5rem;
	grid-column: col-start 6 / -1;
	max-height: 100%;
	color: #fff;

	.slick-slider {
		border-radius: 4% 4% 4.8% 4.8%;
		overflow: hidden;
	}

	.prev-btn,
	.next-btn {
		display: inline-flex;
		align-items: center;
		position: absolute;
		top: auto;
		bottom: 1%;
		width: 5.4%;
		height: 6.1%;
		z-index: 2;

		> span {
			color: #999;
			margin: 0 14px;
			transition: color 0.3s ease-in-out;
		}

		&:not(:disabled):hover {
			> span {
				color: $primary-color;
			}

			.round-arrow-icon {
				border-color: $primary-color;
				fill: $primary-color;
			}
		}

		&:disabled {
			opacity: 1;
			> span {
				color: #d7d7d7;
			}
			.round-arrow-icon {
				fill: #d7d7d7;
				border-color: $border-color;
			}
		}

		&::before {
			display: none;
		}
	}

	.prev-btn {
		left: auto;
		right: 10.5%;
	}

	.next-btn {
		right: 3%;
	}

	.round-arrow-icon {
		width: 100%;
		height: 100%;
		fill: #999;
		border: 1px solid #999;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		transition: 0.3s ease-in-out;
	}

	.next-btn .round-arrow-icon {
		transform: rotate(180deg);
	}

	.image-slide {
		padding: 2px;
		position: relative;

		.image-content {
			position: absolute;
			bottom: 5%;
			padding-left: 5%;
			padding-right: 5%;
		}

		.persona-icon {
			position: absolute;
			width: 8%;
			top: 5%;
			left: 5%;
		}
	
		.quotes-icon {
			width: 20px;
			margin-bottom: 8px;
		}

		h2 {
			margin-bottom: 16px;
			font-size: clamp(16px, 2vw, 36px);
		}

		h4 {
			font-size: clamp(12px, 1.5vw, 20px);
			font-weight: 700;
		}

		p {
			font-size: clamp(8px, 1.5vw, 16px);
		}
	}
}

.p__login .form-side {
	display: flex;
	flex-flow: column;
	grid-column: col-start 2 / span 3;
	height: 100%;
	background: #fff;
	justify-content: space-between;
	padding: 2.5rem 0;
	align-items: center;
	
	.logo {
		width: 157px;
		margin-bottom: 50px;

		&:hover {
			cursor: pointer;
		}

		img {
			width: 100%;
		}
	}

	.form-content {
		width: 100%;
		margin-bottom: 2rem;

		.form-title {
			width: 100%;
			display: inline-flex;
			column-gap: 0.625rem;
			row-gap: 0.25rem;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			.welcome-text {
				font-size: 2.375rem;
				font-weight: 700;
				background: linear-gradient(270deg, #0F766E 0%, #042F2E 100%);
				display: inline-block;
				width: fit-content;
				color: transparent;
				background-clip: text;
				padding-bottom: 6px;
				line-height: 1.25;
			}
		}

		.form-sub-title {
			text-align: center;
			margin: 1rem 0 2.5rem;
			font-size: 18px;
		}
	}

	.login-form-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding-top: 4px;
		font-size: 0.875rem;
		color: #333;
		height: 30px;
		margin-bottom: 40px;
	}

	.checkbox-custom {
		display: inline-flex;
		align-items: center;

		input:checked ~ .checkmark {
			background-color: $primary-950;
			border-color: $primary-950;
		}

		.checkmark {
			margin-right: 8px;
			top: 1.5px;
			width: 14px;
			height: 14px;
		}

		.checkbox-label {
			font-size: 0.875rem;
			font-weight: 300;
			letter-spacing: -0.04em;
			color: inherit;
			line-height: 1;
			margin-right: 10px;
		}
	}

	.btn-pwd-find {
		font-size: inherit;
		font-weight: 300;
		color: $primary-700;

		&:hover,
		&:active {
			color: darken($primary-color, 6%);
			text-decoration: underline;
		}
	}

	.btn-login {
		height: 48px;
		font-size: 1rem;
		font-weight: 500;
		letter-spacing: -0.025em;
		padding-bottom: 3px;
		transition: 0.3s;
		border-radius: 8px;
		width: 100%;
		background-color: $primary-950;
	}

	.register-btn-wrap {
		display: inline-block;
		width: max-content;
		text-align: center;
		font-size: 0.875rem;
		font-weight: 300;
		letter-spacing: -0.025em;
		line-height: 1.43;
		color: #333;
	}

	.btn-register {
		font-size: inherit;
		font-weight: 400;
		padding-left: 5px;
		background-color: #F0FDFA;
		color: #042F2E;
		padding: 12px 20px;
		border-radius: 8px;
		margin-left: 20px;

		&:hover,
		&:active {
			font-weight: 700;
		}
	}

	.l__authFooter {
		width: max-content;
	}
}

@media screen and (max-width: 1280px) {
	.p__login {
		.form-side {
			grid-column: col-start 2 / span 4;
			padding-right: 2.5rem;
		}
	}
}

@media screen and (max-width: 1060px) {
	.p__login {
		.form-side {
			grid-column: col-start 4 / span 6;
			grid-row: row-start 1 / span 5;
			padding: 1.5rem 0;

			.form-content {
				margin: 1.5rem 0;
			}
		}

		.content-side {
			grid-column: col-start 3 / span 8;
			grid-row: row-start 6 / span 11;
			padding: 0 0 2.5rem 0;
		}
	}
}

@media screen and (max-width: 824px) {
	.p__login {
		.form-side {
			grid-column: col-start 2 / span 10;
			grid-row: auto;
			padding: 1rem 0;
		}

		.content-side {
			display: none;
		}
	}
}
