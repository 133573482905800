@charset "UTF-8";
@import "src/assets/sass/utils/var";

$correctColor: #369303;

//팀원 정보 모달 > 기본정보 탭 > 인사정보, 개인정보 상세 공통

//----공통
dl .row {
	width: 100%;
	min-height: 51px;
	display: flex;
	align-items: center;
	padding-top: 5px;
	position: relative;
	gap: 10px;

	// &:after {
	// 	content: "";
	// 	width: calc(100% - 90px);
	// 	display: block;
	// 	border-bottom: 1px solid #f2f2f2;
	// 	position: absolute;
	// 	bottom: 0;
	// 	right: 0;
	// }
}

dt {
	width: 140px;
	font-size: 0.875rem;
	color: #666;
}

dd {
	font-size: 0.875rem;
	display: inline-flex;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
	color: #333;

	.noData {
		color: #bbb;
		font-size: 0.875rem
	}

	.note {
		font-size: 0.75rem;
		color: #bbb;
		margin-left: 10px;
	}
}
