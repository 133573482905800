@charset "UTF-8";
@import "src/assets/sass/utils/var";

$correctColor: #369303;

//팀원 정보 모달 > 기본정보 탭 > 인사정보 상세

//----공통
.teamInfo-payment.detail {
	@import "basicInfoCommon";

	dl .row {
		min-height: auto;
		padding: 17.5px 0 12.5px;
	}
}