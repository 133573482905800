@charset "UTF-8";
@import "src/assets/sass/utils/var";
/***
  * 뱃지
*/

.c__badge {
	display: inline-block;
	position: absolute;
	line-height: 1;
	padding: 0.4em 1.4em 0.5em;
	text-align: center;
	font-size: 0.8125rem;
	font-weight: 500;
	letter-spacing: -0.05em;
	border-radius: 20px;
	background: #0D9488;
	color: #fff;
	z-index: 1;
}
