@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/***
  * 기업 > 제품정보
*/

//제품정보 공통
.p__company-product {
}

/*---제품정보 초기화면---*/
.p__company-product .no-content-card {
	width: 345px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	overflow: hidden;

	.no-img {
		width: 100%;
		height: 220px;
		background-color: #f8f8f8;
		display: flex;
		justify-content: center;
		align-items: center;

		.img-wrap {
			width: 72px;
			height: 64px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.card-content {
		width: 100%;
		height: 152px;
		padding-top: 46px;
		text-align: center;
		color: #666;
		font-weight: 300;
		letter-spacing: -0.025em;
		line-height: 1.57;

		b {
			font-weight: 300;
			color: $primary-color;
		}
	}
}

/*---제품정보 리스트---*/
.p__company-product .product-list-card {
	.c__card {
		height: auto;
		position: relative;

		&:hover {
			cursor: pointer;
		}
	}

	// 배지
	.custom-badge {
		position: absolute;
		right: auto;
		top: 30px;
		left: 20px;
		color: #fff;
		font-size: 0.6875rem;
		font-weight: 500;
		letter-spacing: -0.025em;
		padding: 5px 12px;
		display: inline-block;
		border-radius: 20px;
		background: $primary-color;
		line-height: 1;
		text-align: center;
		z-index: 9;
	}

	// 상단 제품이미지
	.product-img {
		width: 100%;
		height: 0;
		padding-top: 63.76812%;
		overflow: hidden;
		border-top-right-radius: 13px;
		border-top-left-radius: 13px;
		position: relative;

		img {
			width: 100%;
			max-width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			object-fit: cover;
		}
	}

	// 제품내용
	.product-container {
		width: 100%;
		height: 140px;
		padding: 14px 4px 14px 20px;
		display: flex;
		flex-flow: column;
		justify-content: space-between;

		.product-content {
			padding-right: 16px;
			overflow: hidden;

			dt {
				@include ellipsis;
				color: #333;
				font-size: 1rem;
				font-weight: 300;
				letter-spacing: -0.05em;
				line-height: 1.5;
				margin-bottom: 4px;
			}

			dd {
				@include customScroll(scroll-y);
				height: 65px;
				color: #333;
				font-weight: 300;
				line-height: 1.5;
				letter-spacing: -0.05em;
			}
		}
	}

	// 하단
	.product-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.date {
			color: #999;
			font-size: 0.75rem;
			font-weight: 300;
			line-height: 1.67;
		}

		.c__dropdown-wrap {
			position: inherit;

			.c__dropdown {
				display: block;
				top: calc(100% - 15px);
				right: 10px;
			}
		}
	}
}

/*---제품정보 상세페이지(보기)---*/
.p__company-product .company-product-view {
	//----- 상단 카드
	.company-product {
		padding: 60px;
		margin-bottom: 30px;
	}

	// 상단
	.product-detail-content {
		display: flex;
		justify-content: space-between;
		padding-bottom: 60px;

		@media screen and (max-width: 1024px) {
			flex-direction: column;
		}

		// 좌측 이미지 슬라이드
		.carousel-wrap {
			width: 47.03%;

			@media screen and (max-width: 1024px) {
				width: 100%;
			}

			.slick-slide > div > div {
				height: 0;
				overflow: hidden;
				padding-top: 59.84252%;
				position: relative;
			}

			img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				object-fit: cover;
			}

			.img-wrap {
				width: 100%;
				height: 0;
				overflow: hidden;
				padding-top: 60%;
				position: relative;
			}

			.slick-dots {
				display: block;
				align-items: center;
				justify-content: space-between;
				text-align: left;
				position: static;
				margin-top: 7px;

				li {
					width: calc((100% - 35px) / 6);
					height: auto;
					margin: 0 7px 0 0;
				}
			}
		}

		// 우측 주요제품 설명
		.product-basic-info {
			width: 52.963%;
			padding-left: 40px;
			height: auto;
			display: flex;
			flex-direction: column;

			@media screen and (max-width: 1024px) {
				width: 100%;
				padding: 20px 0 0;
			}

			.product-info-top {
				position: relative;
				margin-bottom: 12px;
				word-break: break-word;

				//제품명
				.product-name {
					width: calc(100% - 93px);
					display: flex;
					align-items: baseline;

					&-title {
						color: #333;
						font-size: 1.625rem;
						font-weight: 700;
						line-height: 32px;
						letter-spacing: -0.05em;
					}

					.product-site {
						color: #637283;
						font-size: 1rem;
						font-weight: 400;
						line-height: 1;
						margin-left: 13px;
					}
				}

				//공개여부 배지 위치
				.product-view-mode {
					position: absolute;
					top: 0;
					right: 0;
					width: 83px;
					height: 42px;
					font-size: 1.125rem;
					font-weight: 400;
					line-height: 1;
					letter-spacing: -0.025em;
					display: flex;
					align-items: center;
					justify-content: center;

					@media screen and (max-width: 768px) {
						width: 73px;
						height: 38px;
						font-size: 1rem;
					}
				}

				// 공개
				.product-view-mode.open {
					border: 1px solid $primary-color;
					color: $primary-color;
				}

				// 비공개
				.product-view-mode.private {
					border: 1px solid #ccc;
					color: #ccc;
				}
			}

			.product-desc {
				padding: 30px 0;
				font-weight: 300;
				line-height: 1.75;
				letter-spacing: -0.05em;
				color: #333;
			}

			.product-tag {
				display: inline-block;
				font-weight: 300;
				line-height: 1;
				letter-spacing: -0.7px;
				color: #999;
				margin: 0 6px 0 0;

				&:before {
					content: "\0023";
					color: inherit;
				}
			}

			.product-info-bottom {
				height: 56px;
				display: flex;
				align-items: center;
				margin-top: auto;

				// SNS
				.product-sns-group {
					display: flex;
					align-items: center;
					margin-right: 4.445%;

					li:not(:last-child) {
						margin-right: 18px;
					}

					svg {
						width: 24px;
						height: 24px;
					}

					.disabled {
						fill: #f0f0f0;
						cursor: unset;
					}

					a:not(.disabled):hover {
						fill: $primary-color;
						transition: 0.2s;
					}
				}

				// 링크
				.product-link {
					padding-left: 4.445%;
					border-left: 1px solid #b2b2b2;
					display: flex;
					flex-flow: column;

					span {
						display: block;
						font-weight: 300;
						line-height: 1;
						color: #666;

						&:first-child {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}

	// 하단
	.product-detail-info {
		.f__group:not(:last-child) {
			margin-bottom: 50px;
		}

		.f__label {
			color: #333;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1;
			letter-spacing: -0.5px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 20px;
			padding-bottom: 10px;
		}

		.f__control {
			color: #333;
			font-weight: 300;
			line-height: 1.5;
			padding: initial;
			overflow: visible;
		}
	}

	//----- 하단 카드
	.similar-company {
		padding: 60px;

		.card-main-title {
			font-size: 1.375rem;
			font-weight: 700;
			line-height: 1;
			letter-spacing: -1.4px;
			color: #333;
			padding-bottom: 17px;
			border-bottom: 2px solid #333;
		}

		.similar-company-table {
			width: 100%;

			tr {
				height: 50px;
				border-bottom: 1px solid #ccc;

				th {
					text-align: center;
					vertical-align: middle;
					font-size: 1rem;
					font-weight: 500;
					letter-spacing: -0.025em;
					color: #333;
				}
			}
		}

		.no-content {
			width: 100%;
			padding-top: 45px;
			padding-bottom: 17px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.125rem;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.05em;
			text-align: center;
			color: #333;
		}
	}
}

/*---제품정보-상세페이지(쓰기)---*/
.p__company-product .company-product-edit {
	padding: 60px;

	> section:not(:last-child) {
		margin-bottom: 50px;
	}

	.section-title {
		padding-bottom: 16px;
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: -0.05em;
		color: #333;
		border-bottom: 1px solid $border-color;
	}

	.f__label {
		width: 143px;
		font-weight: 500;
		letter-spacing: -0.025em;
		white-space: pre-wrap;
	}

	.c__textarea {
		height: 140px;
	}

	// 글자수
	.character-length {
		position: unset;
		text-align: right;
		display: block;
	}

	//제품정보
	.section-basic {
		.section-title {
			font-size: 1.625rem;
			font-weight: 600;
			letter-spacing: -0.025em;
			border-color: #666;
		}

		.f__group {
			padding: 7px 0;
			border-bottom: 1px solid $border-color;
		}

		.product-img .f__label {
			line-height: 1.5;
			padding-top: 7px;
		}
	}

	//정보 및 sns
	.section-info,
	.section-sns {
		.c__form {
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid $border-color;
		}

		.f__group {
			width: 50%;
			padding: 7px 10px;
		}

		@media screen and (max-width: 1200px) {
			.c__form {
				border-bottom: none;
			}

			.f__group {
				width: 100%;
				border-bottom: 1px solid $border-color;
			}
		}
	}

	.section-sns .f__group:not(:last-child) {
		border-bottom: 1px solid $border-color;
	}

	//특징 및 공개여부
	.section-desc {
		.public-notice {
			margin-left: 6px;
		}

		// 아니오
		.private {
			color: #999;
			line-height: 1.38;
			.warning {
				color: $error-color
			}
		}

		// 예
		.public {
			color: $primary-color;
		}
		.f__label {
			width: 100%;
			font-size: 0.875rem;
			padding-bottom: 16px;

			&.isPublic {
				border-bottom: 1px solid $border-color;
				margin-bottom: 15px;

				&:not(:first-child) {
					margin-top: 50px;
				}
			}
		}
	}
}
