@charset "UTF-8";
@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

/**
  * 커뮤니티 > 사내커뮤니티
**/

.p__community-community {
	//---- 커뮤니티 방 리스트 키드
	.community-list-card {
		.c__card {
			height: auto;
			position: relative;

			&:hover {
				cursor: pointer;
			}
		}

		//-- 카드 상단 배지
		.custom-badge {
			width: 100%;
			display: flex;
			justify-content: space-between;
			position: absolute;
			z-index: 10;
			padding: 16px 16px 0 24px;

			.badge-icon {
				height: 24px;
				background: #f2f2f2;
				border-radius: 50px;
				padding: 0 8px;
				display: flex;
				align-items: center;

				b {
					font-family: "Inter", sans-serif;
					font-size: 0.75rem;
					color: #a9a9a9;
				}
				svg {
					margin-right: 2px;
				}
			}
			.badge-btn {
				svg {
					fill: #ccc;
				}
				&.active svg {
					fill: #0D9488;
				}
			}
		}

		//-- 커뮤니티 카드 제목
		.community-header {
			display: flex;
			justify-content: space-between;
			position: relative;

			.community-title {
				@include ellipsis;

				font-weight: 700;
				font-size: 1.25rem;
				line-height: 29px;
				color: #333;
				margin: 0 20px 10px 0;
			}

			.c__dropdown {
				top: calc(100% - 10px);
				right: 10px;
			}
		}

		//-- 커뮤니티 카드 이미지
		.community-img {
			width: 100%;
			height: 0;
			padding-top: 56.25%;
			overflow: hidden;
			border-top-right-radius: 13px;
			border-top-left-radius: 13px;
			position: relative;

			img {
				width: 100%;
				max-width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				object-fit: cover;
			}
		}

		//-- 커뮤니티 카드 내용
		.community-container {
			width: 100%;
			height: 140px;
			padding: 14px 4px 20px 20px;
			display: flex;
			flex-flow: column;

			.community-content {
				@include customScroll(scroll-y);
				font-size: 0.875rem;
				line-height: 22px;
				color: #666;
				word-break: break-word;
				line-break: auto;
			}
		}
	}

	//------ 사내커뮤니티 게시판
	.p__community-list {
		display: flex;

		@media screen and (max-width: 1200px) {
			display: block;
		}

		//-- 게시판
		.community-list-section {
			width: calc(100% - 390px);

			@media screen and (max-width: 1200px) {
				width: 100%;
			}

			.c__noData {
				@media screen and (max-width: 1200px) {
					height: calc(
						100vh - 100px - 70px - 40px - 27px - 52px - 30px - 40px - 100px - 52px -
							55px - 380px - 30px
					);
				}
			}

			.community-list-table,
			.community-card-table,
			.community-album-table {
				@media screen and (max-width: 1200px) {
					min-height: calc(
						100vh - 100px - 70px - 40px - 27px - 52px - 30px - 40px - 100px - 52px -
							55px - 380px - 30px
					);
				}
			}

			.community-album-table {
				.c__card {
					@media screen and (max-width: 1750px) and (min-width: 1024px) {
						width: calc(33.3% - 20px) !important;
					}
				}
			}

			.card-title {
				font-weight: 500;
				font-size: 1.375rem;
				line-height: 32px;
				color: #333;
				margin-bottom: 20px;
				word-break: break-word;
			}
		}

		// 게시판 참여자 멤버

		.community-list-member {
			width: 360px;
			min-width: 360px;
			height: 811px;
			margin-left: 30px;
			box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);

			@media screen and (max-width: 1200px) {
				width: 100%;
				height: 380px;
				margin-left: 0;
				margin-top: 30px;
			}

			.card-header {
				background: #fafafa;
				border-bottom: 1px solid #d7d7d7;
				border-radius: 12px 12px 0 0;
				padding: 28px 20px 10px;

				.card-title {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.title {
						font-weight: 300;
						font-size: 1.125rem;
						letter-spacing: -0.06em;

						.num {
							font-size: 1.125rem;
							color: $primary-color;
						}
					}
					.btn__outline {
						padding: 13.5px;
						height: 32px;
					}
				}
				.c__search {
					width: 100%;
					margin-top: 10px;
				}
			}
			// 숫자 공통
			.num {
				font-family: "Poppins", sans-serif;
				font-weight: 500;
			}

			.member-section {
				@include customScroll(scroll-y);
				height: calc(100% - 115px);
				padding: 16px 6px 0 20px;

				/* no data */
				.c__noData {
					height: calc(100% - 16px);
				}

				.member-wrap:not(:last-child) {
					border-bottom: 1px solid #d7d7d7;
					padding-bottom: 8px;
					margin-bottom: 16px;
				}

				.participant {
					font-weight: 500;
					color: #333;
					margin-bottom: 10px;

					.num {
						color: #666;
					}
				}

				.member-list {
					.member-item {
						display: flex;
						align-items: center;
						position: relative;
						height: 60px;

						&:hover {
							background-color: #f3f9fd;
							cursor: pointer;
						}

						// 클릭되었을 때
						&.active {
							background-color: #f3f9fd;
							border-color: $primary-color;
						}
					}
					.profile-img {
						width: 40px;
						height: 40px;
						margin-right: 8px;
						border-radius: 50%;
						flex-shrink: 0;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 50px;
						}
					}
					.profile-name {
						font-size: 1rem;
						color: #333;
						margin-right: 4px;
					}
					.profile-position,
					.profile-email {
						color: #999;
						font-size: 0.75rem;
					}
					.profile-email {
						margin-top: 6px;
					}
					.retired-tag {
						color: $error-color;
						font-size: 0.625rem;
						font-weight: 300;
						letter-spacing: -0.06em;
					}
					.c__dropdown {
						top: calc(100% - 40px);
						right: 15px;
					}
				}
			}
		}
	}

	/*--- 사내커뮤니티 게시판 상세보기  ---*/
	.community-detail-card {
		.content-profile {
			margin-bottom: 51px;
		}
	}
}
