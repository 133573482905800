@import "src/assets/sass/utils/var";
@import "src/assets/sass/utils/mixin";

$color1: #0D9488;
$color2: #35bfb3;
$color3: #72d9d0;
$color4: #b7e8e4;
$height: -12px;

.c__loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.loader {
		width: 11px;
		height: 11px;
		border-radius: 50%;
		margin: 15px auto;
		position: relative;
		color: rgba(152, 213, 255, 1);
		box-sizing: border-box;
		animation: animloader-sm 0.8s linear infinite alternate;
	}
}

//모달 로딩  sm
.c__modal .c__loader {
	height: 100%;
	padding-top: 30%;

	.loader {
		width: 11px;
		height: 11px;
	}
}

.c__modal .modal-loading {
	width: 100%;
	text-align: center;
	margin: auto 0;

	.c__loader {
		padding-top: 0;

		.loader {
			width: 16px;
			height: 16px;
			animation: animloader-md 0.5s linear infinite alternate;
		}
	}

	.loading-title {
		font-size: 18px;
		margin-top: 18px;
	}
}

//페이지 전체 로딩   md
.l__content > .c__loader {
	height: 75%;
	display: flex;
	align-items: center;
	justify-content: center;

	.loader {
		width: 15px;
		height: 15px;
		animation: animloader-md 0.5s linear infinite alternate;
	}
}

@keyframes animloader-sm {
	0% {
		box-shadow: -36px $height $color1, -12px 0 $color2, 12px 0 $color3, 36px 0 $color4;
	}
	33% {
		box-shadow: -36px 0px $color1, -12px $height $color2, 12px 0 $color3, 36px 0 $color4;
	}
	66% {
		box-shadow: -36px 0px $color1, -12px 0 $color2, 12px $height $color3, 36px 0 $color4;
	}
	100% {
		box-shadow: -36px 0 $color1, -12px 0 $color2, 12px 0 $color3, 36px $height $color4;
	}
}

@keyframes animloader-md {
	0% {
		box-shadow: -60px $height $color1, -20px 0 $color2, 20px 0 $color3, 60px 0 $color4;
	}
	33% {
		box-shadow: -60px 0px $color1, -20px $height $color2, 20px 0 $color3, 60px 0 $color4;
	}
	66% {
		box-shadow: -60px 0px $color1, -20px 0 $color2, 20px $height $color3, 60px 0 $color4;
	}
	100% {
		box-shadow: -60px 0 $color1, -20px 0 $color2, 20px 0 $color3, 60px $height $color4;
	}
}
